import React, { useState } from 'react';
import './sms.css';
import { Avatar, Box, Button, CircularProgress, TextField } from '@mui/material';
import { Icon } from '@iconify/react';
import { Send } from '@mui/icons-material';
import io from 'socket.io-client';

const socket = io('https://zdr4wk5m-3001.inc1.devtunnels.ms/');

function SMSInterface({ selectedCandidate, onClose }) {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [input, setInput] = useState();

  const handleSendMessage = async () => {
    if (input.trim()) {
      const messageData = {
        text: input,
        sender: '+15078586181',
        status: 'pending',
        timestamp: new Date().toISOString(),
      };

      const to = `${selectedCandidate?.personal_phone}`;
      const name = selectedCandidate?.full_name;
      socket.emit('send-sms', {
        to,
        message: messageData,
      });
      setInput('');
    }
  };
  return (
    <div className="chat-container">
      <div
        style={{
          display: 'flex',
          backgroundColor: '#E1F7FC',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 15px',
          zIndex: 1,
          // Ensure the text is visible
        }}
      >
        <Box display="flex" alignItems="center">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              style={{
                marginRight: '8px', // Adds space between avatar and name
                backgroundColor: '',
                color: '#fff',
                width: '50px',
                height: '50px',
                fontSize: '16px',
                textAlign: 'center',
                marginLeft: '7px',
              }}
            >
              <Icon icon="heroicons:user-16-solid" style={{ fontSize: '24px' }} />
            </Avatar>
            <p style={{ fontSize: '16px', marginLeft: '7px', color: 'black' }}>{selectedCandidate?.personal_phone}</p>
          </div>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Icon
            icon="bitcoin-icons:cross-filled"
            style={{ fontSize: '22px', cursor: 'pointer' }}
            onClick={() => onClose()}
          />
        </Box>
      </div>
      <div className="chat-body">
        {isLoading ? (
          //show circular progress in center
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress thickness={2} />
          </div>
        ) : (
          <>
            {messages?.map((msg) => (
              <div
                key={msg.id}
                className={`chat-message ${
                  msg.sender === '+15078586181' ? 'chat-message-sender' : 'chat-message-receiver'
                }`}
                style={{ position: 'relative' }} // Add relative positioning for better control
              >
                <p className="chat-text">{msg.text}</p>

                {/* Timestamps and ticks */}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: '5px',
                    fontSize: '12px',
                    color: '#555',
                  }}
                >
                  {/* Format timestamp */}
                  <span>
                    {new Date(msg.timestamp).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true,
                    })}
                  </span>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      <div className="chat-footer">
        <TextField
          placeholder="Text message"
          fullWidth
          value={input}
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          onChange={(e) => setInput(e.target.value)}
          sx={{
            borderRadius: '25px',
            padding: '5px 5px',
            textAlign: 'center',
            height: '50px',
            overflowY: 'scroll',
            minHeight: '50px',
            backgroundColor: '#E6EBF5',
            '& .MuiOutlinedInput-root': {
              borderRadius: '40px',
              '& fieldset': {
                border: 'none', // Remove border
              },
            },
            '&:hover': {
              backgroundColor: '#E6EBF5', // Keep background color same on hover
            },
          }}
          size="small"
        />

        <Button
          variant="contained"
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            marginLeft: '5px',
            backgroundColor: '#00C1FE',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: 'unset',
            '&:hover': {
              backgroundColor: '#00C1FE',
              color: 'white',
            },
          }}
          onClick={() => handleSendMessage()}
        >
          <Send fontSize="small" />
        </Button>
      </div>
    </div>
  );
}

export default SMSInterface;
