import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

function LeaveRole({ open, onClose, roleId, onSubmit }) {
  const [reason, setReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const userId = localStorage.getItem('userId');
  // Handle change function to allow deselection
  const handleChangeReason = (event) => {
    const selectedValue = event.target.value;
    // If the selected value is already chosen, deselect it by setting reason to ''
    if (selectedValue === reason) {
      setReason('');
    } else {
      setReason(selectedValue);
    }
  };

  const reasonss = ['Leaving office', 'Moving to another role', 'Not able to complete', 'Other'];

  console.log(reason);
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        hideBackdrop={false}
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '800px',
          },
        }}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#E1F7FC',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" className="ml-8">
              Leave Role
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display={'flex'} flexDirection="column" marginLeft="10px" marginRight="10px">
            <FormControl sx={{ marginTop: '15px' }} fullWidth>
              <label
                htmlFor="demo-multiple-checkbox-label"
                style={{ fontWeight: 'bold', textAlign: 'left', marginBottom: '5px' }}
              >
                Select Reason
              </label>
              <Select
                native
                value={reason}
                onChange={handleChangeReason}
                inputProps={{
                  id: 'select-single-native',
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
                sx={{
                  '.MuiSelect-select': {
                    color: '#000000',
                    padding: '8px 10px',
                    height: '20px',
                    borderRadius: 0,
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#D9D9D9',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#D9D9D9',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#D9D9D9',
                  },
                  '.MuiSelect-select.MuiSelect-outlined': {
                    backgroundColor: '#FFFFFF',
                    border: '2px solid #D9D9D9',
                  },
                  '.MuiSelect-icon': {
                    color: '#000000',
                  },
                }}
              >
                {reasonss?.map((reasonOption) => (
                  <option key={reasonOption} value={reasonOption}>
                    {reasonOption}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
          {reason === 'Other' && (
            <Box display="flex" flexDirection="column" marginTop="15px" marginLeft="10px" marginRight="10px">
              <label htmlFor="reason_of_less_li_profiles" style={{ fontWeight: 'bold', paddingTop: '5px' }}>
                Enter reason
              </label>
              <TextField
                name="otherReason"
                fullWidth
                multiline
                required // Make it required
                value={otherReason}
                onChange={(e) => setOtherReason(e.target.value)}
                sx={{
                  backgroundColor: 'white',
                  outline: 'none',
                  border: '1px solid #D7D7D7',
                  marginBottom: '3px',
                  marginTop: '5px',
                  borderRadius: '5px',
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: '#D7D7D7',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#D7D7D7',
                    },
                  },
                }}
                size="large"
              />
            </Box>
          )}
          <Box display="flex" justifyContent="center" marginTop="1rem" alignItems="center">
            <Button
              sx={{
                backgroundColor: '#0ec4fe',
                fontSize: '16px',
                color: 'white',
                width: '15%',
                marginLeft: '1rem',
                padding: '10px 20px',
                '&:hover': {
                  color: 'white !important',
                  backgroundColor: '#0ec4fe !important',
                },
              }}
              onClick={() => {
                const payload = {
                  roleId: roleId,
                  userId: userId,
                  reason: reason ? reason : otherReason,
                };
                onSubmit(payload);
              }}
            >
              Leave
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default LeaveRole;
