import React from 'react';

function RecruiterReports(props) {
    return (
        <div>
            
        </div>
    );
}

export default RecruiterReports;