import { Close } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { rejectedHireable, shortlist } from './constants';

function SubmitInterviewReviewDialog({ open, onClose }) {
  const groupedOptions = [...shortlist, ...rejectedHireable];
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        hideBackdrop={false}
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{
          sx: {
            ml: 'auto',
            mt: 'auto',
            mb: 'auto',
            mr: 'auto',
            width: '100%',
            maxWidth: '850px',
            height: '67%',
          },
        }}
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#E2F2FF',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" className="ml-8" sx={{ color: '#787878' }}>
              Submit Review
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <br />
          <Box display="flex" flexDirection="column">
            <label htmlFor="status" style={{ fontWeight: 'normal', color: '#787878', marginLeft: '10px' }}>
              Select Status
            </label>
            <Autocomplete
              id="status"
              fullWidth
              // value={} // Change here
              options={groupedOptions}
              groupBy={(option) => option.group}
              getOptionLabel={(option) => option.label}
              onChange={(event, newValue) => {}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select  status"
                  variant="outlined"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '20px', // Customize border-radius here
                      '& fieldset': {
                        borderColor: '#dfe1e5', // Customize border color here
                      },
                      '&:hover fieldset': {
                        borderColor: '#dfe1e5',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#dfe1e5',
                      },
                    },
                  }}
                />
              )}
            />
          </Box>
          <Box display="flex" flexDirection="column" marginTop="15px">
            <label htmlFor="recommendation" style={{ fontWeight: 'normal', color: '#787878', marginLeft: '10px' }}>
              Overall Recommendation
            </label>
            <TextField
              id="recommendation"
              value=""
              type="text"
              multiline
              rows={8}
              size="small"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                backgroundColor: 'white',
                borderRadius: '0.3rem',
                marginBottom: '3px',
                marginTop: '5px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '20px',
                  '& fieldset': {
                    borderColor: '#dfe1e5',
                  },
                  '&:hover fieldset': {
                    borderColor: '#dfe1e5',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#dfe1e5',
                  },
                },
              }}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" marginTop="20px">
            <Button
              sx={{
                background: '#A3A3A3',
                color: '#fff',
                height: '45px',
                border: '1px solid #A3A3A3',
                '&:hover': {
                  background: '#A3A3A3',
                  color: '#fff',
                },
                borderRadius: '15px',
                padding: '25px',
                marginLeft: '20px',
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                background: '#00C1FE',
                color: '#fff',
                height: '45px',
                border: '1px solid #00C1FE',
                '&:hover': {
                  background: '#00C1FE',
                  color: '#fff',
                },
                borderRadius: '15px',
                padding: '25px',
                marginLeft: '20px',
              }}
            >
              Submit
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SubmitInterviewReviewDialog;
