import { ArrowBack } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfirmSaveSequenceDialog, RoleSequenceSteps, SequenceStepsTemplates } from '.';
import { useCallback, useEffect, useState } from 'react';
import { Get, Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { useSnackbar } from 'notistack';
function RoleSequence(props) {
  const navigate = useNavigate();
  const { roleId } = useParams();
  const [selectedStep, setSelectedStep] = useState();
  const [openSaveSequenceDialog, setOpenSaveSequenceDialog] = useState(false);
  const [roleInfo, setRoleInfo] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [isAddingSequence, setIsAddingSequence] = useState(false);
  const [isAddingSteps, setIsAddingSteps] = useState(false);
  const userId = localStorage.getItem('userId');
  const [areStepsFilled, setAreStepsFilled] = useState(false);
  const [communicationData, setCommunicationData] = useState([
    {
      title: 'Outreach Email',
      icon: 'ic:baseline-email',
      color: '#00C1FE',
      isSaved: false,
      details: [
        { label: 'Subject line', value: '' },
        { label: 'Content', value: '' },
      ],
      date: null,
      time: null,
      stats: [
        { label: 'Emailed', icon: 'mdi:tick', color: '#3FC28A', count: 0 },
        { label: 'No response', icon: 'bitcoin-icons:cross-filled', color: 'red', count: 0 },
        { label: 'Auto reply', icon: 'mdi:auto-mode', color: '#3F8CFF', count: 0 },
        { label: 'Potential reply', icon: 'tabler:message-up', color: '#43EE3F', count: 0 },
      ],
    },
    {
      title: 'Follow Up Email 1',
      icon: 'ic:baseline-email',
      color: '#00C1FE',
      isSaved: false,
      details: [
        { label: 'Subject line', value: '' },
        { label: 'Content', value: '' },
      ],
      date: null,
      time: null,
      stats: [
        { label: 'Emailed', icon: 'mdi:tick', color: '#3FC28A', count: 0 },
        { label: 'No response', icon: 'bitcoin-icons:cross-filled', color: 'red', count: 0 },
        { label: 'Auto reply', icon: 'mdi:auto-mode', color: '#3F8CFF', count: 0 },
        { label: 'Potential reply', icon: 'tabler:message-up', color: '#43EE3F', count: 0 },
      ],
    },
    {
      title: 'WhatsApp Message',
      icon: 'logos:whatsapp-icon',
      color: '#25D366',
      isSaved: false,
      details: [
        { label: 'Subject line', value: '' },
        { label: 'Content', value: '' },
      ],
      date: null,
      time: null,
      stats: [
        { label: 'Messaged', icon: 'mdi:tick', color: '#3FC28A', count: 0 },
        { label: 'Replied', icon: 'hugeicons:message-02', color: 'blue', count: 0 },
        { label: 'No response', icon: 'bitcoin-icons:cross-filled', color: 'red', count: 0 },
      ],
    },
    {
      title: 'SMS',
      icon: 'material-symbols:sms',
      color: '#009688',
      isSaved: false,
      details: [
        { label: 'Subject line', value: '' },
        { label: 'Content', value: '' },
      ],
      date: null,
      time: null,
      stats: [
        { label: 'Messaged', icon: 'mdi:tick', color: '#3FC28A', count: 0 },
        { label: 'Replied', icon: 'hugeicons:message-02', color: 'blue', count: 0 },
        { label: 'No response', icon: 'bitcoin-icons:cross-filled', color: 'red', count: 0 },
      ],
    },
    {
      title: 'LI Connection',
      icon: 'ri:linkedin-fill',
      color: '#0076B2',
      isSaved: false,
      details: [
        { label: 'Subject line', value: '' },
        { label: 'Content', value: '' },
      ],
      date: null,
      time: null,
      stats: [
        { label: 'Messaged', icon: 'mdi:tick', color: '#3FC28A', count: 0 },
        { label: 'Replied', icon: 'codicon:verified-filled', color: '#E5B137', count: 0 },
        { label: 'No response', icon: 'lucide:refresh-cw-off', color: 'red', count: 0 },
      ],
    },
    {
      title: 'Call',
      icon: 'material-symbols:call',
      color: '#60D669',
      isSaved: false,
      details: [
        { label: 'Subject line', value: '' },
        { label: 'Content', value: 'Any' },
      ],
      stats: [
        { label: 'Messaged', icon: 'mdi:tick', color: '#3FC28A', count: 0 },
        { label: 'Responded', icon: 'solar:outgoing-call-bold', color: '#F7CB45', count: 0 },
        { label: 'No response', icon: 'subway:call-3', color: 'red', count: 0 },
      ],
    },
    {
      title: 'InMail',
      icon: 'mdi:linkedin',
      color: '#C8773F',
      isSaved: false,
      details: [
        { label: 'Subject line', value: '' },
        { label: 'Content', value: '' },
      ],
      stats: [
        { label: 'Messaged', icon: 'mdi:tick', color: '#3FC28A', count: 0 },
        { label: 'Replied', icon: 'tabler:message-up', color: '#43EE3F', count: 0 },
        { label: 'No response', icon: 'lucide:refresh-cw-off', color: 'red', count: 0 },
      ],
    },
  ]);

  // update the communication dat
  const handleUpdateData = (data) => {
    console.log('Received data to update:', data);

    // Extract step_title from data
    const { step_title, subject, content, date, time } = data;

    // Create a new array to update the communication data
    const updatedCommunicationData = communicationData?.map((step) => {
      // Check if the step title matches step_title from the data
      if (step.title === step_title) {
        return {
          ...step,
          details: step.details.map((detail) => {
            if (detail.label === 'Subject line') {
              return { ...detail, value: subject || detail.value }; // Update subject if provided
            }
            if (detail.label === 'Content') {
              return { ...detail, value: content || detail.value }; // Update content if provided
            }
            if (date) {
              return { ...detail, value: date || detail.value }; // Update date if provided
            }
            if (time) {
              return { ...detail, value: time || detail.value }; // Update time if provided
            }
            return detail; // Return the original detail if there's no match
          }),
          date: date || step?.date,
          time: time || step?.time,
          isSaved: true,
        };
      }
      return step; // Return the original step if there's no match
    });

    // Set the updated communication data
    setCommunicationData(updatedCommunicationData);
  };

  const getSequence = useCallback(() => {
    try {
      Get(
        {
          roleId: roleId,
        },
        API_URLS.getSequenceByRoleId,
        (resp) => {
          console.log(resp);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }, [roleId]);

  useEffect(() => {
    getSequence();
  }, [getSequence]);

  const getCandidateByRoleId = useCallback(() => {
    try {
      Get(
        {
          roleId: roleId,
        },
        API_URLS.getRoleCandidatesByIdWithoutPagination,
        (resp) => {
          setRoleInfo(resp?.data);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }, [roleId]);

  useEffect(() => {
    getCandidateByRoleId();
  }, [getCandidateByRoleId]);

  const handleSaveSequence = () => {
    setIsAddingSequence(true);
    try {
      Post(
        {
          role_id: roleId,
          name: roleInfo?.roleTitle,
          total_candidates: roleInfo?.ids?.map((item) => item?.cv_ids?.length)?.reduce((a, b) => a + b, 0),
          candidate_ids: roleInfo?.ids?.map((item) => item?.cv_ids).flat(),
          user_id: userId,
        },
        API_URLS.createRoleSequence,
        (resp) => {
          handleSaveSequenceSteps(resp?.id);
          setIsAddingSequence(false);
          // navigate(-1);
          // enqueueSnackbar('Sequence saved successfully', { variant: 'success' });
        },
        (error) => {
          enqueueSnackbar(error?.response?.data?.message || 'error in creating sequence ', { variant: 'error' });
          console.log(error?.response?.data?.message || 'error in creating sequence ', error);
          setIsAddingSequence(false);
        }
      );
    } catch (e) {
      console.log(e);
      setIsAddingSequence(false);
    }
  };
  const handleSaveSequenceSteps = (sequenceId) => {
    setIsAddingSteps(true);
    try {
      Post(
        {
          steps: communicationData,
          sequence_id: sequenceId,
        },
        API_URLS.createRoleSequenceSteps,
        (resp) => {
          setIsAddingSteps(false);
          setOpenSaveSequenceDialog(false);
          enqueueSnackbar('Sequence saved successfully', { variant: 'success' });
          navigate(-1);
        },
        (error) => {
          console.log('error in creating sequence steps ', error);
          setIsAddingSteps(false);
          deleteSequence(sequenceId);
        }
      );
    } catch (error) {
      console.log(error);
      setIsAddingSteps(false);
    }
  };

  const deleteSequence = (id) => {
    try {
      Post(
        { sequence_id: id },
        API_URLS.deleteSequenceById,
        (resp) => {
          // enqueueSnackbar('Sequence deleted successfully', { variant: 'success' });
          // navigate(-1);
        },
        (error) => {
          console.log('error in deleting sequence ', error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  // write a function to check if any step has empty  message  in the commuincation data , if yes then return false
  const checkIfAllStepsAreFilled = () => {
    let isFilled = true;
    communicationData?.forEach((step) => {
      step.details.forEach((detail) => {
        if (detail.label === 'Content' && detail.value === '') {
          isFilled = false;
        }
      });
    });
    setAreStepsFilled(isFilled);
    return isFilled;
  };

  return (
    <div style={{ width: '97%', margin: 'auto' }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <ArrowBack
          fontSize="small"
          onClick={() => {
            navigate(-1);
          }}
          sx={{
            color: '#00C1FE',
            cursor: 'pointer',
          }}
        />
        &nbsp;&nbsp;{' '}
        <Typography
          variant="subtitle2"
          sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </Typography>
      </Box>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <RoleSequenceSteps
            communicationData={communicationData}
            onSelectedStep={(step) => {
              setSelectedStep(step);
            }}
            onStepRemoved={(data) => {
              setCommunicationData(data);
            }}
            onStepAdded={(data) => {
              setCommunicationData(data);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <SequenceStepsTemplates
            step={selectedStep}
            onDataUpdation={(data) => {
              handleUpdateData(data);
            }}
            onSaveSequence={() => {
              setOpenSaveSequenceDialog(true);
              checkIfAllStepsAreFilled();
            }}
          />
        </Grid>
      </Grid>

      {openSaveSequenceDialog && (
        <ConfirmSaveSequenceDialog
          open={openSaveSequenceDialog}
          onClose={() => {
            setOpenSaveSequenceDialog(false);
          }}
          onSave={() => {
            handleSaveSequence();
          }}
          isAddingSequence={isAddingSequence}
          isAddingSteps={isAddingSteps}
          areStepsFilled={areStepsFilled}
        />
      )}
    </div>
  );
}

export default RoleSequence;
