import React from 'react';
import { CampaignSection } from 'src/sections/direct360';

function RecruiterCampaigns(props) {
  return (
    <div>
      <CampaignSection />
    </div>
  );
}

export default RecruiterCampaigns;
