import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CreateInterview } from './components';

function InterviewSection(props) {
  const [page, setPage] = useState(0);
  const location = useLocation();
  const [rowsPerPage, setRowsPerPage] = useState(2); // Default rows per page
  const [openCreateInterviewDialog, setOpenCreateInterviewDialog] = useState(false);
  const navigate = useNavigate()
  // Handle page change
  const data = [
    {
      interview_name: 'ABC Interview',
      from: 'Dec 30, 2019 05:00',
      to: 'Jan 30, 2020 05:00',
      candidate_name: 'Vishal',
      status: 'Pending',
      posting_title: 'UI Designer',
      recruiter: 'Vishal',
      resourcer: 'Vishal',
      acm: 'Vishal',
    },
    {
      interview_name: 'Designer',
      from: 'Dec 30, 2019 05:00',
      to: 'Jan 30, 2020 05:00',
      candidate_name: 'Vishal',
      status: 'Pending',
      posting_title: 'UX Designer',
      recruiter: 'Vishal',
      resourcer: 'Vishal',
      acm: 'Vishal',
    },
    {
      interview_name: 'Designer',
      from: 'Dec 30, 2019 05:00',
      to: 'Jan 30, 2020 05:00',
      candidate_name: 'Vishal',
      status: 'Pending',
      posting_title: 'UI Designer',
      recruiter: 'Vishal',
      resourcer: 'Vishal',
      acm: 'Vishal',
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div style={{ width: '97%', margin: 'auto', height: '79vh' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <p style={{ fontSize: '32px', fontWeight: 'bold', marginTop: '10px' }}>Interviews</p>
        <Button
          startIcon={<Icon icon="tabler:plus" style={{ fontSize: '20px' }} />}
          sx={{
            background: '#00C1FE',
            color: '#fff',
            height: '45px',
            border: '1px solid #00C1FE',
            '&:hover': {
              background: '#00C1FE',
              color: '#fff',
            },
            borderRadius: '15px',
            padding: '25px',
            marginLeft: '10px',
            marginRight: '10px',
          }}
          onClick={() => setOpenCreateInterviewDialog(true)}
        >
          Create Interview
        </Button>
      </Box>
      <br />
      <Box sx={{ padding: 2, borderRadius: 4, backgroundColor: 'white' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <TableCell style={{ textAlign: 'center' }}>Interview Name</TableCell>
                <TableCell style={{ textAlign: 'center' }}>From</TableCell>
                <TableCell style={{ textAlign: 'center' }}>To</TableCell>
                <TableCell style={{ textAlign: 'center' }}>Candidate</TableCell>
                <TableCell style={{ textAlign: 'center' }}>Status</TableCell>
                <TableCell style={{ textAlign: 'center' }}>Posting Title</TableCell>
                <TableCell style={{ textAlign: 'center' }}>Recruiter</TableCell>
                <TableCell style={{ textAlign: 'center' }}>Resourcer</TableCell>
                <TableCell style={{ textAlign: 'center' }}>ACM</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Paginate the rows */}
              {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <p
                      style={{
                        textAlign: 'center',
                        margin: 'auto',
                        color: '#4CD4FF',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        navigate(`/recruiter/interviewDetails/1`, {
                          state: { previousPath: location.pathname }, // Pass the current path as state
                        })
                      }
                    >
                      {row.interview_name}
                    </p>
                  </TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>
                    <p
                      style={{
                        textAlign: 'center',
                        margin: 'auto',
                      }}
                    >
                      {row.from}
                    </p>
                  </TableCell>
                  <TableCell>
                    <p
                      style={{
                        textAlign: 'center',
                        margin: 'auto',
                      }}
                    >
                      {row.to}
                    </p>
                  </TableCell>
                  <TableCell>
                    <p
                      style={{
                        textAlign: 'center',
                        margin: 'auto',
                        color: '#4CD4FF',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        navigate(`/recruiter/candidateInfo/1/1`, {
                          state: { previousPath: location.pathname }, // Pass the current path as state
                        })
                      }
                    >
                      {row.candidate_name}
                    </p>
                  </TableCell>
                  <TableCell>
                    <p
                      style={{
                        textAlign: 'center',
                        margin: 'auto',
                      }}
                    >
                      {row.status}
                    </p>
                  </TableCell>
                  <TableCell>
                    <p
                      style={{
                        textAlign: 'center',
                        margin: 'auto',
                        color: '#4CD4FF',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        navigate(`/recruiter/jobDetails/1`, {
                          state: { previousPath: location.pathname }, // Pass the current path as state
                        })
                      }
                    >
                      {row.posting_title}
                    </p>
                  </TableCell>
                  <TableCell>
                    <p
                      style={{
                        textAlign: 'center',
                        margin: 'auto',
                      }}
                    >
                      {row.recruiter}
                    </p>
                  </TableCell>
                  <TableCell>
                    <p
                      style={{
                        textAlign: 'center',
                        margin: 'auto',
                      }}
                    >
                      {row.resourcer}
                    </p>
                  </TableCell>
                  <TableCell>
                    <p
                      style={{
                        textAlign: 'center',
                        margin: 'auto',
                      }}
                    >
                      {row.acm}
                    </p>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination component */}
        <TablePagination
          component="div"
          count={data.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[2, 5, 10]} // Optional: Different options for rows per page
        />
      </Box>
      {openCreateInterviewDialog && (
        <CreateInterview open={openCreateInterviewDialog} onClose={() => setOpenCreateInterviewDialog(false)} />
      )}
    </div>
  );
}

export default InterviewSection;
