import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import { Autocomplete, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import axios from 'axios';

function BooleanSearch({onSearch}) {
  const [jobTitle, setJobTitle] = useState();
  const [miles, setMiles] = useState("0");
  const [selectedLocation, setSelectedLocation] = useState();

  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchLocations = async (query) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search?format=json&q=${query}`
      );
      const locations = response.data.map((location) => ({
        label: location.display_name,
        value: location.place_id,
      }));
      
      setOptions(locations);
    } catch (error) {
      
    }
    setLoading(false);
  };

  const handleInputChange = (event, value) => {
    setInputValue(value);
    if (value.length > 2) {
      fetchLocations(value);
      setSelectedLocation(value);
    } else {
      setOptions([]);
    }
  };

  return (
    <div>
      <div style={{ width: '97%', marginTop: '10px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              id="job_title"
              name="job_title"
              type="text"
              placeholder="Job Title or Skill"
              size="large"
              value={jobTitle}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setJobTitle(e.target.value)}
              sx={{
                backgroundColor: 'white',
                outline: 'none',
                border: '1px solid #D9D9D9',
                marginBottom: '3px',
                borderRadius: 0,
                marginLeft: '5px',
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#cccccc',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#cccccc',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <FormControl
              fullWidth
              sx={{
                backgroundColor: 'white',
                border: '1px solid #D9D9D9',
                borderRadius: 0,
                marginLeft: '5px',
                marginBottom: '3px',
              }}
            >
              {/* <InputLabel shrink htmlFor="miles">
                Miles
              </InputLabel> */}
              <Select
                id="miles"
                value={miles}
                onChange={(e) => setMiles(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: '#cccccc',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#cccccc',
                    },
                  },
                }}
              >
                <MenuItem value="0">Within 0 miles of</MenuItem>
                <MenuItem value="3">Within 3 miles of</MenuItem>
                <MenuItem value="5">Within 5 miles of</MenuItem>
                <MenuItem value="10">Within 10 miles of</MenuItem>
                <MenuItem value="20">Within 20 miles of</MenuItem>
                <MenuItem value="50">Within 50 miles of</MenuItem>
              </Select>
            </FormControl>

            {/* <TextField
              id="miles"
              name="miles"
              type="text"
              value={miles}
              placeholder="Within 0 miles of"
              size="large"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setMiles(e.target.value)}
              sx={{
                backgroundColor: 'white',
                outline: 'none',
                border: '1px solid #D9D9D9',
                marginBottom: '3px',
                borderRadius: 0,
                marginLeft: '5px',
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#cccccc',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#cccccc',
                  },
                },
              }}
            /> */}
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Autocomplete
              freeSolo
              options={options}
              value={selectedLocation}
              getOptionLabel={(option) => option.label}
              onInputChange={handleInputChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Town or Postcode"
                  placeholder="Search location"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  sx={{
                    backgroundColor: 'white',
                    border: '1px solid #D9D9D9',
                    borderRadius: 0,
                    marginLeft: '5px',
                    marginBottom: '3px',
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#cccccc',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#cccccc',
                      },
                    },
                  }}
                />
              )}
            />
            {/* <TextField
              id="postalCode"
              name="postalCode"
              type="text"
              value={postalCode}
              placeholder="Town or postcode"
              size="large"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setPostalCode(e.target.value)}
              sx={{
                backgroundColor: 'white',
                outline: 'none',
                border: '1px solid #D9D9D9',
                marginBottom: '3px',
                borderRadius: 0,
                marginLeft: '5px',
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#cccccc',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#cccccc',
                  },
                },
              }}
            /> */}
          </Grid>
          <Grid item xs={12} sm={12} md={1} lg={1}>
            <Button
              size="large"
              fullWidth
              sx={{
                backgroundColor: '#0ec4fe',
                color: 'white',
                height: '97%',
                marginLeft: '5px',
                '&:hover': {
                  color: 'white !important',
                  backgroundColor: '#0ec4fe !important',
                },
              }}
              onClick={() => {
                const payload = {
                  jobTitle,
                  miles,
                  selectedLocation,
                };
                onSearch(payload);
              }}
            >
              <Icon icon="teenyicons:search-outline" style={{ fontSize: '24px' }} />
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default BooleanSearch;
