import React from 'react';
import DatePicker from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import './DateAndTimePicker.css'; // Import your custom CSS

function DateAndTimePicker({ onChange, sx }) {
  return (
    <div className="date-time-picker-container" >
      <DatePicker
        format="MM/DD/YYYY hh:mm:ss A"
        plugins={[<TimePicker position="bottom" />]} // You can set the time picker position if needed
        onChange={onChange}
        className="custom-date-picker"
      />
    </div>
  );
}

export default DateAndTimePicker;
