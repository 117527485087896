import MaterialReactTable from 'material-react-table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Get } from 'src/actions/API/apiActions';
import _, { replace } from 'lodash'; // Import lodash for debounce
import { Box, Snackbar, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import { API_URLS } from 'src/constants/apiURLs';

function PersonsTableReporting({
  selectedUser,
  selectedCountry,
  selectedSector,
  selectedIndustry,
  formattedDates,
  selectedFilter,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [persons, setPersons] = useState([]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'full_name',
        header: 'Name',
        minWidth: 170,
        Cell: ({ row }) => (
          <Box component="span" display="flex" alignItems="center">
            <img
              src={row.original.avator}
              // alt={row.original.full_name}
              style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}
            />
            <a
              href={row.original.profile_url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: '#4B93FF' }}
            >
              {row.original.full_name}
            </a>
          </Box>
        ),
      },
      {
        accessorKey: 'current_title',
        header: 'Title',
        minWidth: 170,
        Cell: ({ cell }) => {
          const [isExpanded, setIsExpanded] = useState(false);
          const fullContent = cell.getValue();
          const trimmedContent = fullContent?.split(' ').slice(0, 3).join(' ');

          return (
            <div>
              <div>
                {isExpanded ? fullContent : trimmedContent}
                {fullContent !== trimmedContent && (
                  <button
                    onClick={() => setIsExpanded(!isExpanded)}
                    style={{ marginLeft: 8, cursor: 'pointer', color: '#00C1FE', textDecoration: 'underline' }}
                  >
                    {isExpanded ? 'Less' : 'More'}
                  </button>
                )}
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: 'company.name',
        header: 'Company',
        minWidth: 170,
        Cell: ({ row }) => (
          <Box component="span" display="flex" alignItems="center">
            <a
              href={row.original.company.profile_url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: '#4B93FF' }}
            >
              {row?.original?.company?.name}
            </a>
          </Box>
        ),
      },
      {
        accessorKey: 'company.name',
        header: 'Company Size',
        minWidth: 170,
        Cell: ({ row }) => (
          <Box component="span" display="flex" alignItems="center" justifyContent="space-around">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Icon icon="mdi:linkedin" style={{ fontSize: '18px', color: '#00C1FE' }} />
              <p style={{ fontSize: '10px', fontWeight: 'bold' }}>{row?.original?.company?.staff_count}</p>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Icon icon="mdi:company" style={{ fontSize: '18px', color: '#00C1FE' }} />
              <p style={{ fontSize: '10px', fontWeight: 'bold' }}>
                {row?.original?.company?.staff_count_range_start} - {row?.original?.company?.staff_count_range_end}
              </p>
            </Box>
          </Box>
        ),
      },
      {
        accessorKey: 'personal_email',
        header: 'Personal Email',
        minWidth: 170,
        Cell: ({ row }) => {
          const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar state

          const handleCopyEmail = () => {
            if (navigator.clipboard) {
              navigator.clipboard
                .writeText(row?.original?.personal_email)
                .then(() => {
                  setOpenSnackbar(true); // Show notification
                })
                .catch((err) => {
                  console.error('Failed to copy email: ', err);
                });
            } else {
              console.warn('Clipboard API not supported.');
            }
          };
          // Get the number of additional emails
          const emailCount = row?.original?.personalEmails?.length || 0;
          return (
            <Box component="span">
              <Typography variant="body2">
                {row?.original?.personal_email ? (
                  // Render email with superscript
                  <span
                    style={{ cursor: 'pointer', color: '#3F8CFF', textDecoration: 'none' }}
                    onClick={handleCopyEmail}
                  >
                    {row?.original?.personal_email}
                    <span
                      style={{
                        backgroundColor: row?.original?.is_replacement_needed === false ? '#3FC28A' : '#E45E5B',
                        fontSize: '12px',
                        marginLeft: '4px',
                        borderRadius: '50%',
                        height: '16px', // Reduced height for better appearance
                        width: '16px', // Reduced width for better appearance
                        display: 'inline-block', // To apply width and height
                        lineHeight: '16px', // To center the text vertically
                        textAlign: 'center', // To center the text horizontally
                        color: 'white', // Text color for better contrast
                        verticalAlign: 'super',
                      }}
                    >
                      {emailCount}
                    </span>
                  </span>
                ) : (
                  <Icon
                    icon="ic:baseline-question-mark"
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                )}
              </Typography>

              <Snackbar
                open={openSnackbar}
                autoHideDuration={3000} // Snackbar hides after 3 seconds
                onClose={() => setOpenSnackbar(false)}
                message="Email copied to clipboard!"
              />
            </Box>
          );
        },
      },
      {
        accessorKey: 'business_email',
        header: 'Business Email',
        minWidth: 170,
        Cell: ({ row }) => {
          const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar state

          const handleCopyEmail = () => {
            if (navigator.clipboard) {
              navigator.clipboard
                .writeText(row?.original?.business_email)
                .then(() => {
                  setOpenSnackbar(true); // Show notification
                })
                .catch((err) => {
                  console.error('Failed to copy email: ', err);
                });
            } else {
              console.warn('Clipboard API not supported.');
            }
          };

          // Get the number of additional emails
          const emailCount = row?.original?.businessEmails?.length || 0;

          return (
            <Box component="span">
              <Typography variant="body2">
                {row?.original?.business_email ? (
                  // Render email with superscript
                  <span
                    style={{ cursor: 'pointer', color: '#3F8CFF', textDecoration: 'none' }}
                    onClick={handleCopyEmail}
                  >
                    {row?.original?.business_email}
                    <span
                      style={{
                        backgroundColor: row?.original?.is_replacement_needed === false ? '#3FC28A' : '#E45E5B',
                        fontSize: '12px',
                        marginLeft: '4px',
                        borderRadius: '50%',
                        height: '16px', // Reduced height for better appearance
                        width: '16px', // Reduced width for better appearance
                        display: 'inline-block', // To apply width and height
                        lineHeight: '16px', // To center the text vertically
                        textAlign: 'center', // To center the text horizontally
                        color: 'white', // Text color for better contrast
                        verticalAlign: 'super',
                      }}
                    >
                      {emailCount}
                    </span>
                  </span>
                ) : (
                  <Icon
                    icon="ic:baseline-question-mark"
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                )}
              </Typography>

              <Snackbar
                open={openSnackbar}
                autoHideDuration={3000} // Snackbar hides after 3 seconds
                onClose={() => setOpenSnackbar(false)}
                message="Email copied to clipboard!"
              />
            </Box>
          );
        },
      },
      {
        accessorKey: 'country.country',
        header: 'Country',
        minWidth: 170,
      },
      {
        accessorKey: 'sector.sector',
        header: 'Sector',
        minWidth: 170,
      },
      {
        accessorKey: 'abc',
        header: 'Lead Expert',
        minWidth: 170,
        Cell: ({ row }) => {
          const user_assigned = row?.original?.user_assigned
            ? row?.original?.user_assigned?.first_name + ' ' + row?.original?.user_assigned?.last_name
            : 'Not Asigned';
          const email_not_found_users = row?.original?.email_not_found_claim_users?.length > 0 ? row?.original?.email_not_found_claim_users.map(user => user.first_name + ' ' + user.last_name).join(', ') : ''
          const replacement_not_found_users = row?.original?.replacement_not_found_claim_users?.length > 0 ? row?.original?.replacement_not_found_claim_users.map(user => user.first_name + ' ' + user.last_name).join(', ') : ''

          // const is_user_id_matches = row?.original?.businessEmails[0]?.user_id === row?.original?.user_assigned?.id  ? true : false;
          return (
            <Box component="span">
              <Tooltip title="Currently Assigned" arrow>
                <Typography
                  variant="body2"
                  style={{ color: '#3F8CFF', cursor: 'pointer' }}
                >
                  {user_assigned}
                </Typography>
              </Tooltip>
              {email_not_found_users && (
                <Tooltip title="Email not found" arrow>
                  <Typography variant="body2" style={{ color: '#3F8CFF', cursor: 'pointer' }}>
                    ,{email_not_found_users}
                  </Typography>
                </Tooltip>
              )}
              {replacement_not_found_users && (
                <Tooltip title="Replacement not found" arrow>
                  <Typography variant="body2" style={{ color: '#3F8CFF', cursor: 'pointer' }}>
                    ,{replacement_not_found_users}
                  </Typography>
                </Tooltip>
              )}
            </Box>
          );
        }
      },
    ],
    [persons] // Recalculate when counters change
  );

  const [totalCount, setTotalCount] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [isError, setIsError] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const fetchPersons = useCallback(
    _.debounce(() => {
      try {
        setLoadingData(true);
        Get(
          {
            country_id: selectedCountry?.id,
            sector_id: selectedSector?.id,
            industry: selectedIndustry,
            from_date: formattedDates[0],
            to_date: formattedDates[1],
            user_id: selectedUser,
            page: pagination.pageIndex,
            size: pagination.pageSize,
            selectedFilter: selectedFilter,
          },
          API_URLS.getCountryAndSectorWisePersons,
          (resp) => {
            let data = resp?.data?.processedPersons;
            data = data.map((row) => {
              if (row?.businessEmails?.length > 0) {
                row.business_email = `${row.businessEmails[0].email_id}`;
              }
              if (row?.personalEmails?.length > 0) {
                row.personal_email = `${row.personalEmails[0].email_id}`;
              }
              if (row?.businessPhones?.length > 0) {
                row.business_phone = `${row.businessPhones[0].phone_number}`;
              }
              if (row?.personalPhones?.length > 0) {
                row.personal_phone = `${row.personalPhones[0].phone_number}`;
              }
              return row;
            });
            setPersons(data);
            setTotalCount(resp?.data?.pagination?.total);
            setLoadingData(false);
          },
          (error) => {
            enqueueSnackbar('Failed to get the persons', { variant: 'error' });
            setIsError(true);
            setLoadingData(false);
          }
        );
      } catch (error) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
        setIsError(true);
        setLoadingData(false);
      }
    }, 300), // 300ms debounce time
    [
      selectedUser,
      formattedDates,
      enqueueSnackbar,
      selectedCountry,
      selectedSector,
      selectedIndustry,
      pagination.pageSize,
      pagination.pageIndex,
      selectedFilter,
    ]
  );

  useEffect(() => {
    fetchPersons();
  }, [fetchPersons]);

  return (
    <div style={{ margin: '5px' }}>
      <Typography variant="h5" sx={{ marginBottom: '5px' }}>
        Total Leads
      </Typography>
      <MaterialReactTable
        enableStickyHeader
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: 'error',
                children: 'Error Loading Data',
              }
            : undefined
        }
        columns={columns}
        data={persons}
        enableTopToolbar={false}
        positionToolbarAlertBanner="bottom"
        muiTableBodyRowProps={({ row }) => ({
          hover: false,
          sx: {
            backgroundColor: row.original.is_hiring_person
              ? '#C1F2DD'
              : row.original.is_replacement_needed
              ? '#FFD0D0'
              : 'white', // Corrected logic
          },
        })}
        manualPagination
        rowCount={totalCount}
        onPaginationChange={setPagination}
        manualFiltering
        state={{
          pagination,
          isLoading: loadingData,
        }}
      />
    </div>
  );
}

export default PersonsTableReporting;
