import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Snackbar, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Get, Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { useSnackbar } from 'notistack';
import MaterialReactTable from 'material-react-table';
import { Icon } from '@iconify/react';
import {
  AddEmailsFromSpreadSheetDialog,
  AddPersonalEmailsDialog,
  AddReplacamentDialog,
  EditPersonDialog,
  MarkAsNotFound,
  ShowEmailsDialog,
  ShowPersonsDialog,
} from './components';
import AddBusinessEmailDialog from './components/AddBusinessEmailDialog';
import MarkAsReplacementNotFound from './components/MarkAsReplacementNotFound';
import Spreadsheet from 'react-spreadsheet';

// Styled button component with conditional styling
const CustomButton = styled(Button)(({ theme, active }) => ({
  width: '150px',
  height: '50px',
  border: '2px solid #00C1FE',
  borderRadius: '10px',
  color: active ? '#ffffff' : '#00C1FE',
  backgroundColor: active ? '#00C1FE' : '#ffffff',
  '&:hover': {
    backgroundColor: active ? '#00C1FE' : '#f0f0f0',
  },
  marginLeft: theme.spacing(1),
}));

// Styled TextField with focused outline color
const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '60%', // Specify the width here
  height: '60px',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#00C1FE',
    },
    '&:hover fieldset': {
      borderColor: '#00C1FE',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00C1FE',
    },
  },
  marginLeft: theme.spacing(1),
}));

function MainEmailSearchSection(props) {
  const [persons, setPersons] = useState([]);
  const [openShowEmailsDialog, setOpenShowEmailsDialog] = useState(false);
  const [personsForSpreadSheet, setPersonsForSpreadSheet] = useState([]);
  const [emailsToShow, setEmailsToShow] = useState([]);
  const [emailsToUpdate, setEmailsToUpdate] = useState();
  const [status, setStatus] = useState('');
  const [searchString, setSearchString] = useState('');
  const [openReplacementNtFoundDialog, setOpenReplacementNtFoundDialog] = useState(false);
  const [openEditInfo, setOpenEditInfo] = useState(false);
  const [toggleState, setToggleState] = useState('custom');
  const [openBulEmailConfirmationDialog, setOpenBulEmailConfirmationDialog] = useState(false);
  const [isSubmittingEmails, setIsSubmittingEmails] = useState(false);
  const [isProcessingData, setIsProcessingData] = useState(false);
  const [peoplePagination, setPeoplePagination] = useState({ 
    pageIndex: 0,
    pageSize: 100,
   });
  const dataRef = useRef([]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'full_name',
        header: 'Name',
        minWidth: 170,
        Cell: ({ row }) => (
          <Box component="span" display="flex" alignItems="center">
            {/* Show avatar image */}
            <img
              src={row.original.avator} // Assuming "avator" holds the avatar image URL
              // alt={row.original.full_name}
              style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}
            />
            {/* Clickable name */}
            <a
              href={row.original.profile_url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: '#4B93FF' }}
            >
              {row.original.full_name}
            </a>
          </Box>
        ),
      },
      {
        accessorKey: 'current_title',
        header: 'Title',
        minWidth: 170,
      },
      {
        accessorKey: 'company.name',
        header: 'Company',
        minWidth: 170,
        Cell: ({ row }) => (
          <Box component="span" display="flex" alignItems="center">
            <a
              href={row?.original?.company?.profile_url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: '#4B93FF' }}
            >
              {row?.original?.company?.name}
            </a>
          </Box>
        ),
      },
      {
        accessorKey: 'latestJobPost.job_title',
        header: 'Job Post',
        minWidth: 170,
        Cell: ({ row }) => (
          <Box component="span" display="flex" alignItems="center">
            <a
              href={row?.original?.latestJobPost?.job_posting_link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: '#4B93FF' }}
            >
              {row?.original?.latestJobPost?.job_title}
            </a>
          </Box>
        ),
      },
      {
        accessorKey: 'personal_email',
        header: 'Personal Email',
        minWidth: 170,
        Cell: ({ row }) => {
          const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar state

          const handleCopyEmail = () => {
            if (navigator.clipboard) {
              navigator.clipboard
                .writeText(row?.original?.personal_email)
                .then(() => {
                  setOpenSnackbar(true); // Show notification
                })
                .catch((err) => {
                  console.error('Failed to copy email: ', err);
                });
            } else {
              console.warn('Clipboard API not supported.');
            }
          };
          // Get the number of additional emails
          const emailCount = row?.original?.personalEmails?.length || 0;
          return (
            <Box component="span">
              <Typography variant="body2">
                {row?.original?.personal_email ? (
                  // Render email with superscript
                  <span
                    style={{ cursor: 'pointer', color: '#3F8CFF', textDecoration: 'none' }}
                    onClick={handleCopyEmail}
                  >
                    {row?.original?.personal_email}
                    <span
                      style={{
                        backgroundColor: row?.original?.is_replacement_needed === false ? '#3FC28A' : '#E45E5B',
                        fontSize: '12px',
                        marginLeft: '4px',
                        borderRadius: '50%',
                        height: '16px', // Reduced height for better appearance
                        width: '16px', // Reduced width for better appearance
                        display: 'inline-block', // To apply width and height
                        lineHeight: '16px', // To center the text vertically
                        textAlign: 'center', // To center the text horizontally
                        color: 'white', // Text color for better contrast
                        verticalAlign: 'super',
                      }}
                      onClick={() => {
                        setOpenShowEmailsDialog(true);
                        setEmailsToShow(row?.original?.personalEmails);
                      }}
                    >
                      {emailCount}
                    </span>
                  </span>
                ) : (
                  <Tooltip arrow title="Add Personal Emails" placement="bottom">
                    <Icon
                      icon="ic:baseline-question-mark"
                      onClick={() => {
                        setOpenAddEmailDialog(true);
                        setSelectedPerson(row?.original);
                      }}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                )}
              </Typography>

              <Snackbar
                open={openSnackbar}
                autoHideDuration={3000} // Snackbar hides after 3 seconds
                onClose={() => setOpenSnackbar(false)}
                message="Email copied to clipboard!"
              />
            </Box>
          );
        },
      },
      {
        accessorKey: 'business_email',
        header: 'Business Email',
        minWidth: 170,
        Cell: ({ row }) => {
          const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar state

          const handleCopyEmail = () => {
            if (navigator.clipboard) {
              navigator.clipboard
                .writeText(row?.original?.business_email)
                .then(() => {
                  setOpenSnackbar(true); // Show notification
                })
                .catch((err) => {
                  console.error('Failed to copy email: ', err);
                });
            } else {
              console.warn('Clipboard API not supported.');
            }
          };

          // Get the number of additional emails
          const emailCount = row?.original?.businessEmails?.length || 0;

          return (
            <Box component="span">
              <Typography variant="body2">
                {row?.original?.business_email ? (
                  // Render email with superscript
                  <span
                    style={{ cursor: 'pointer', color: '#3F8CFF', textDecoration: 'none' }}
                    onClick={handleCopyEmail}
                  >
                    {row?.original?.business_email}
                    <span
                      style={{
                        backgroundColor: row?.original?.is_replacement_needed === false ? '#3FC28A' : '#E45E5B',
                        fontSize: '12px',
                        marginLeft: '4px',
                        borderRadius: '50%',
                        height: '16px', // Reduced height for better appearance
                        width: '16px', // Reduced width for better appearance
                        display: 'inline-block', // To apply width and height
                        lineHeight: '16px', // To center the text vertically
                        textAlign: 'center', // To center the text horizontally
                        color: 'white', // Text color for better contrast
                        verticalAlign: 'super',
                      }}
                      onClick={() => {
                        setOpenShowEmailsDialog(true);
                        setEmailsToShow(row?.original?.businessEmails);
                      }}
                    >
                      {emailCount}
                    </span>
                  </span>
                ) : (
                  <Tooltip arrow title="Add Business Emails" placement="bottom">
                    <Icon
                      icon="ic:baseline-question-mark"
                      onClick={() => {
                        setOpenAddBuinessEmailsDialog(true);
                        setSelectedPerson(row?.original);
                      }}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                )}
              </Typography>

              <Snackbar
                open={openSnackbar}
                autoHideDuration={3000} // Snackbar hides after 3 seconds
                onClose={() => setOpenSnackbar(false)}
                message="Email copied to clipboard!"
              />
            </Box>
          );
        },
      },

      {
        accessorKey: 'personal_phone',
        header: 'Personal Num',
        enableClickToCopy: true,
        minWidth: 170,
        Cell: ({ cell }) => (
          <Box component="span">
            <Typography variant="body2">
              {cell.getValue() ? cell.getValue() : <Icon icon="ic:baseline-question-mark" />}
            </Typography>
          </Box>
        ),
      },
      {
        accessorKey: 'business_phone',
        header: 'Business Num',
        enableClickToCopy: true,
        minWidth: 170,
        Cell: ({ cell }) => (
          <Box component="span">
            <Typography variant="body2">
              {cell.getValue() ? cell.getValue() : <Icon icon="ic:baseline-question-mark" />}
            </Typography>
          </Box>
        ),
      },
    ],
    [persons] // Recalculate when counters change
  );
  const emailsAddedColumns = useMemo(
    () => [
      {
        accessorKey: 'full_name',
        header: 'Name',
        minWidth: 170,
        Cell: ({ row }) => (
          <Box component="span" display="flex" alignItems="center">
            {/* Show avatar image */}
            <img
              src={row.original.avator} // Assuming "avator" holds the avatar image URL
              // alt={row.original.full_name}
              style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}
            />
            {/* Clickable name */}
            <a
              href={row.original.profile_url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: '#4B93FF' }}
            >
              {row.original.full_name}
            </a>
          </Box>
        ),
      },
      {
        accessorKey: 'current_title',
        header: 'Title',
        minWidth: 170,
      },
      {
        accessorKey: 'company.name',
        header: 'Company',
        minWidth: 170,
        Cell: ({ row }) => (
          <Box component="span" display="flex" alignItems="center">
            <a
              href={row?.original?.company?.profile_url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: '#4B93FF' }}
            >
              {row?.original?.company?.name}
            </a>
          </Box>
        ),
      },
      {
        accessorKey: 'latestJobPost.job_title',
        header: 'Job Post',
        minWidth: 170,
        Cell: ({ row }) => (
          <Box component="span" display="flex" alignItems="center">
            <a
              href={row?.original?.latestJobPost?.job_posting_link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: '#4B93FF' }}
            >
              {row?.original?.latestJobPost?.job_title}
            </a>
          </Box>
        ),
      },
      {
        accessorKey: 'personal_email',
        header: 'Personal Email',
        minWidth: 170,
        Cell: ({ row }) => {
          const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar state

          const handleCopyEmail = () => {
            if (navigator.clipboard) {
              navigator.clipboard
                .writeText(row?.original?.personal_email)
                .then(() => {
                  setOpenSnackbar(true); // Show notification
                })
                .catch((err) => {
                  console.error('Failed to copy email: ', err);
                });
            } else {
              console.warn('Clipboard API not supported.');
            }
          };
          // Get the number of additional emails
          const emailCount = row?.original?.personalEmails?.length || 0;
          return (
            <Box component="span">
              <Typography variant="body2">
                {row?.original?.personal_email ? (
                  // Render email with superscript
                  <span
                    style={{ cursor: 'pointer', color: '#3F8CFF', textDecoration: 'none' }}
                    onClick={handleCopyEmail}
                  >
                    {row?.original?.personal_email}
                    <span
                      style={{
                        backgroundColor: row?.original?.is_replacement_needed === false ? '#3FC28A' : '#E45E5B',
                        fontSize: '12px',
                        marginLeft: '4px',
                        borderRadius: '50%',
                        height: '16px', // Reduced height for better appearance
                        width: '16px', // Reduced width for better appearance
                        display: 'inline-block', // To apply width and height
                        lineHeight: '16px', // To center the text vertically
                        textAlign: 'center', // To center the text horizontally
                        color: 'white', // Text color for better contrast
                        verticalAlign: 'super',
                      }}
                      onClick={() => {
                        setOpenShowEmailsDialog(true);
                        setEmailsToShow(row?.original?.personalEmails);
                      }}
                    >
                      {emailCount}
                    </span>
                  </span>
                ) : (
                  <Tooltip arrow title="Add Personal Emails" placement="bottom">
                    <Icon
                      icon="ic:baseline-question-mark"
                      onClick={() => {
                        setOpenAddEmailDialog(true);
                        setSelectedPerson(row?.original);
                      }}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                )}
              </Typography>

              <Snackbar
                open={openSnackbar}
                autoHideDuration={3000} // Snackbar hides after 3 seconds
                onClose={() => setOpenSnackbar(false)}
                message="Email copied to clipboard!"
              />
            </Box>
          );
        },
      },
      {
        accessorKey: 'business_email',
        header: 'Business Email',
        minWidth: 170,
        Cell: ({ row }) => {
          const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar state

          const handleCopyEmail = () => {
            if (navigator.clipboard) {
              navigator.clipboard
                .writeText(row?.original?.business_email)
                .then(() => {
                  setOpenSnackbar(true); // Show notification
                })
                .catch((err) => {
                  console.error('Failed to copy email: ', err);
                });
            } else {
              console.warn('Clipboard API not supported.');
            }
          };

          // Get the number of additional emails
          const emailCount = row?.original?.businessEmails?.length || 0;

          return (
            <Box component="span">
              <Typography variant="body2">
                {row?.original?.business_email ? (
                  // Render email with superscript
                  <span
                    style={{ cursor: 'pointer', color: '#3F8CFF', textDecoration: 'none' }}
                    onClick={handleCopyEmail}
                  >
                    {row?.original?.business_email}
                    <span
                      style={{
                        backgroundColor: row?.original?.is_replacement_needed === false ? '#3FC28A' : '#E45E5B',
                        fontSize: '12px',
                        marginLeft: '4px',
                        borderRadius: '50%',
                        height: '16px', // Reduced height for better appearance
                        width: '16px', // Reduced width for better appearance
                        display: 'inline-block', // To apply width and height
                        lineHeight: '16px', // To center the text vertically
                        textAlign: 'center', // To center the text horizontally
                        color: 'white', // Text color for better contrast
                        verticalAlign: 'super',
                      }}
                      onClick={() => {
                        setOpenShowEmailsDialog(true);
                        setEmailsToShow(row?.original?.businessEmails);
                      }}
                    >
                      {emailCount}
                    </span>
                  </span>
                ) : (
                  <Tooltip arrow title="Add Business Emails" placement="bottom">
                    <Icon
                      icon="ic:baseline-question-mark"
                      onClick={() => {
                        setOpenAddBuinessEmailsDialog(true);
                        setSelectedPerson(row?.original);
                      }}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                )}
              </Typography>

              <Snackbar
                open={openSnackbar}
                autoHideDuration={3000} // Snackbar hides after 3 seconds
                onClose={() => setOpenSnackbar(false)}
                message="Email copied to clipboard!"
              />
            </Box>
          );
        },
      },

      {
        accessorKey: 'personal_phone',
        header: 'Personal Num',
        enableClickToCopy: true,
        minWidth: 170,
        Cell: ({ cell }) => (
          <Box component="span">
            <Typography variant="body2">
              {cell.getValue() ? cell.getValue() : <Icon icon="ic:baseline-question-mark" />}
            </Typography>
          </Box>
        ),
      },
      {
        accessorKey: 'business_phone',
        header: 'Business Num',
        enableClickToCopy: true,
        minWidth: 170,
        Cell: ({ cell }) => (
          <Box component="span">
            <Typography variant="body2">
              {cell.getValue() ? cell.getValue() : <Icon icon="ic:baseline-question-mark" />}
            </Typography>
          </Box>
        ),
      },
    ],
    [persons] // Recalculate when counters change
  );

  const noEmailFoundColumns = useMemo(
    () => [
      {
        accessorKey: 'full_name',
        header: 'Name',
        minWidth: 170,
        Cell: ({ row }) => (
          <Box component="span" display="flex" alignItems="center">
            {/* Show avatar image */}
            <img
              src={row.original.avator} // Assuming "avator" holds the avatar image URL
              // alt={row.original.full_name}
              style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}
            />
            {/* Clickable name */}
            <a
              href={row.original.profile_url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: '#4B93FF' }}
            >
              {row.original.full_name}
            </a>
          </Box>
        ),
      },
      {
        accessorKey: 'current_title',
        header: 'Title',
        minWidth: 170,
      },
      {
        accessorKey: 'company.name',
        header: 'Company',
        minWidth: 170,
        Cell: ({ row }) => (
          <Box component="span" display="flex" alignItems="center">
            <a
              href={row?.original?.company?.profile_url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: '#4B93FF' }}
            >
              {row?.original?.company?.name}
            </a>
          </Box>
        ),
      },
      {
        accessorKey: 'latestJobPost.job_title',
        header: 'Job Post',
        minWidth: 170,
        Cell: ({ row }) => (
          <Box component="span" display="flex" alignItems="center">
            <a
              href={row?.original?.latestJobPost?.job_posting_link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: '#4B93FF' }}
            >
              {row?.original?.latestJobPost?.job_title}
            </a>
          </Box>
        ),
      },
      {
        accessorKey: 'personal_email',
        header: 'Personal Email',
        minWidth: 170,
        Cell: ({ row }) => {
          const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar state

          const handleCopyEmail = () => {
            if (navigator.clipboard) {
              navigator.clipboard
                .writeText(row?.original?.personal_email)
                .then(() => {
                  setOpenSnackbar(true); // Show notification
                })
                .catch((err) => {
                  console.error('Failed to copy email: ', err);
                });
            } else {
              console.warn('Clipboard API not supported.');
            }
          };

          return (
            <Box component="span">
              <Typography variant="body2">
                {row?.original?.personal_email ? (
                  // Only the email should be copyable
                  <span
                    style={{ cursor: 'pointer', color: '#3F8CFF', textDecoration: 'none' }}
                    onClick={handleCopyEmail}
                  >
                    {row?.original?.personal_email}
                  </span>
                ) : (
                  // Question mark should not be copyable
                  <Tooltip arrow title="Add Personal Emails" placement="bottom">
                    <Icon
                      icon="ic:baseline-question-mark"
                      onClick={() => {
                        setOpenAddEmailDialog(true);
                        setSelectedPerson(row?.original);
                      }}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                )}
              </Typography>

              {/* Snackbar for copy notification */}
              <Snackbar
                open={openSnackbar}
                autoHideDuration={3000} // Snackbar hides after 3 seconds
                onClose={() => setOpenSnackbar(false)}
                message="Email copied to clipboard!"
              />
            </Box>
          );
        },
      },
      {
        accessorKey: 'business_email',
        header: 'Business Email',
        minWidth: 170,
        Cell: ({ row }) => {
          const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar state

          const handleCopyEmail = () => {
            if (navigator.clipboard) {
              navigator.clipboard
                .writeText(row?.original?.business_email)
                .then(() => {
                  setOpenSnackbar(true); // Show notification
                })
                .catch((err) => {
                  console.error('Failed to copy email: ', err);
                });
            } else {
              console.warn('Clipboard API not supported.');
            }
          };

          return (
            <Box component="span">
              <Typography variant="body2">
                {row?.original?.business_email ? (
                  // Only the email should be copyable
                  <span
                    style={{ cursor: 'pointer', color: '#3F8CFF', textDecoration: 'none' }}
                    onClick={handleCopyEmail}
                  >
                    {row?.original?.business_email}
                  </span>
                ) : (
                  // Question mark should not be copyable
                  <Tooltip arrow title="Add Email" placement="bottom">
                    <Icon
                      icon="ic:baseline-question-mark"
                      onClick={() => {
                        setOpenAddEmailDialog(true);
                        setSelectedPerson(row?.original);
                      }}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                )}
              </Typography>

              {/* Snackbar for copy notification */}
              <Snackbar
                open={openSnackbar}
                autoHideDuration={3000} // Snackbar hides after 3 seconds
                onClose={() => setOpenSnackbar(false)}
                message="Email copied to clipboard!"
              />
            </Box>
          );
        },
      },
      {
        accessorKey: 'claimed_by',
        header: 'Claimed by',
        minWidth: 170,
        // map the cliamed_by in this column if the length is greater then 0 show user name by using forward slash between them
        Cell: ({ row }) => (
          <Box component="span" display="flex" alignItems="center">
            {row?.original?.claimed_by?.length > 0
              ? row?.original?.claimed_by?.map((user) => user.name).join(' / ')
              : 'Not Claimed'}
          </Box>
        ),
      },
    ],
    [persons] // Recalculate when counters change
  );
  const replacementClaimColumns = useMemo(
    () => [
      {
        accessorKey: 'full_name',
        header: 'Name',
        minWidth: 170,
        Cell: ({ row }) => (
          <Box component="span" display="flex" alignItems="center">
            {/* Show avatar image */}
            <img
              src={row.original.avator} // Assuming "avator" holds the avatar image URL
              // alt={row.original.full_name}
              style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}
            />
            {/* Clickable name */}
            <a
              href={row.original.profile_url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: '#4B93FF' }}
            >
              {row.original.full_name}
            </a>
          </Box>
        ),
      },
      {
        accessorKey: 'current_title',
        header: 'Title',
        minWidth: 170,
      },
      {
        accessorKey: 'company.name',
        header: 'Company',
        minWidth: 170,
        Cell: ({ row }) => (
          <Box component="span" display="flex" alignItems="center">
            <a
              href={row?.original?.company?.profile_url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: '#4B93FF' }}
            >
              {row?.original?.company?.name}
            </a>
          </Box>
        ),
      },
      {
        accessorKey: 'latestJobPost.job_title',
        header: 'Job Post',
        minWidth: 170,
        Cell: ({ row }) => (
          <Box component="span" display="flex" alignItems="center">
            <a
              href={row?.original?.latestJobPost?.job_posting_link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: '#4B93FF' }}
            >
              {row?.original?.latestJobPost?.job_title}
            </a>
          </Box>
        ),
      },
      {
        accessorKey: 'personal_email',
        header: 'Personal Email',
        minWidth: 170,
        Cell: ({ row }) => {
          const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar state

          const handleCopyEmail = () => {
            if (navigator.clipboard) {
              navigator.clipboard
                .writeText(row?.original?.personal_email)
                .then(() => {
                  setOpenSnackbar(true); // Show notification
                })
                .catch((err) => {
                  console.error('Failed to copy email: ', err);
                });
            } else {
              console.warn('Clipboard API not supported.');
            }
          };
          // Get the number of additional emails
          const emailCount = row?.original?.personalEmails?.length || 0;
          return (
            <Box component="span">
              <Typography variant="body2">
                {row?.original?.personal_email ? (
                  // Render email with superscript
                  <span
                    style={{ cursor: 'pointer', color: '#3F8CFF', textDecoration: 'none' }}
                    onClick={handleCopyEmail}
                  >
                    {row?.original?.personal_email}
                    <span
                      style={{
                        backgroundColor: row?.original?.is_replacement_needed === false ? '#3FC28A' : '#E45E5B',
                        fontSize: '12px',
                        marginLeft: '4px',
                        borderRadius: '50%',
                        height: '16px', // Reduced height for better appearance
                        width: '16px', // Reduced width for better appearance
                        display: 'inline-block', // To apply width and height
                        lineHeight: '16px', // To center the text vertically
                        textAlign: 'center', // To center the text horizontally
                        color: 'white', // Text color for better contrast
                        verticalAlign: 'super',
                      }}
                      onClick={() => {
                        setOpenShowEmailsDialog(true);
                        setEmailsToShow(row?.original?.personalEmails);
                      }}
                    >
                      {emailCount}
                    </span>
                  </span>
                ) : (
                  <Tooltip arrow title="Add Personal Emails" placement="bottom">
                    <Icon
                      icon="ic:baseline-question-mark"
                      onClick={() => {
                        setOpenAddEmailDialog(true);
                        setSelectedPerson(row?.original);
                      }}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                )}
              </Typography>

              <Snackbar
                open={openSnackbar}
                autoHideDuration={3000} // Snackbar hides after 3 seconds
                onClose={() => setOpenSnackbar(false)}
                message="Email copied to clipboard!"
              />
            </Box>
          );
        },
      },
      {
        accessorKey: 'business_email',
        header: 'Business Email',
        minWidth: 170,
        Cell: ({ row }) => {
          const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar state

          const handleCopyEmail = () => {
            if (navigator.clipboard) {
              navigator.clipboard
                .writeText(row?.original?.business_email)
                .then(() => {
                  setOpenSnackbar(true); // Show notification
                })
                .catch((err) => {
                  console.error('Failed to copy email: ', err);
                });
            } else {
              console.warn('Clipboard API not supported.');
            }
          };

          // Get the number of additional emails
          const emailCount = row?.original?.businessEmails?.length || 0;

          return (
            <Box component="span">
              <Typography variant="body2">
                {row?.original?.business_email ? (
                  // Render email with superscript
                  <span
                    style={{ cursor: 'pointer', color: '#3F8CFF', textDecoration: 'none' }}
                    onClick={handleCopyEmail}
                  >
                    {row?.original?.business_email}
                    <span
                      style={{
                        backgroundColor: row?.original?.is_replacement_needed === false ? '#3FC28A' : '#E45E5B',
                        fontSize: '12px',
                        marginLeft: '4px',
                        borderRadius: '50%',
                        height: '16px', // Reduced height for better appearance
                        width: '16px', // Reduced width for better appearance
                        display: 'inline-block', // To apply width and height
                        lineHeight: '16px', // To center the text vertically
                        textAlign: 'center', // To center the text horizontally
                        color: 'white', // Text color for better contrast
                        verticalAlign: 'super',
                      }}
                      onClick={() => {
                        setOpenShowEmailsDialog(true);
                        setEmailsToShow(row?.original?.businessEmails);
                      }}
                    >
                      {emailCount}
                    </span>
                  </span>
                ) : (
                  <Tooltip arrow title="Add Business Emails" placement="bottom">
                    <Icon
                      icon="ic:baseline-question-mark"
                      onClick={() => {
                        setOpenAddBuinessEmailsDialog(true);
                        setSelectedPerson(row?.original);
                      }}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                )}
              </Typography>

              <Snackbar
                open={openSnackbar}
                autoHideDuration={3000} // Snackbar hides after 3 seconds
                onClose={() => setOpenSnackbar(false)}
                message="Email copied to clipboard!"
              />
            </Box>
          );
        },
      },
      {
        accessorKey: 'replacement_calim',
        header: 'Claimed by',
        minWidth: 170,
        // map the cliamed_by in this column if the length is greater then 0 show user name by using forward slash between them
        Cell: ({ row }) => (
          <Box component="span" display="flex" alignItems="center">
            {row?.original?.replacement_calim?.length > 0
              ? row?.original?.replacement_calim?.map((user) => user.name).join(' / ')
              : 'Not Claimed'}
          </Box>
        ),
      },
    ],
    [persons] // Recalculate when counters change
  );
  const { enqueueSnackbar } = useSnackbar();
  const [activeIndex, setActiveIndex] = useState(null);
  const user_id = localStorage.getItem('userId');

  const [openAddEmailDialog, setOpenAddEmailDialog] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [findContactNotAdded, setFindContactNotAdded] = useState(false);
  const [replacementNeeded, setReplacementNeeded] = useState(false);
  const [editEmailInfo, setEditEmailInfo] = useState(false);
  // const [editEmail, setEditEmail] = useState(null);
  const [openMarkAsNotFoundDialog, setOpenMarkAsNotFoundDialog] = useState(false);
  const [markIdsAsEmailNotFound, setMarkIdsAsEmailNotFound] = useState();
  const [noEmailFound, setNoEmailFound] = useState(false);
  const [findHiringPersons, setFindHiringPersons] = useState(false);
  const [findBounceBacks, setFindBounceBacks] = useState(false);
  const [openActionConfirmationDialog, setOpenActionConfirmationDialog] = useState(false);
  const [openReplacementDialog, setOpenReplacementDialog] = useState(false);
  const [replacementNeededPerson, setReplacementNeededPerson] = useState(null);
  const [markIdsAsReplacementNotFound, setMarkIdsAsReplacementNotFound] = useState();
  const [othersReplacements, setOthersReplacements] = useState(false);
  const [openAddBuinessEmailsDialog, setOpenAddBuinessEmailsDialog] = useState(false);
  const [personsWithNoEmail, setPersonsWithNoEmail] = useState([]);
  const [havingReplacementCliamByOther, setHavingReplacementCliamByOther] = useState([]);

  // Mock functions for API calls or page navigation
  const applyContactInfoNotAddedFilter = () => {
    setFindContactNotAdded((prevState) => !prevState); // Toggle state correctly
    setReplacementNeeded(false); // Reset replacement state
    setOthersReplacements(false); // Reset others replacement state
    setFindHiringPersons(false); // Reset hiring persons state
    setNoEmailFound(false); // Reset no email found state
    setFindBounceBacks(false); // Reset bounce back state
    setEditEmailInfo(false); // Reset edit email info state
  };

  const applyReplacementNeededFilter = () => {
    setReplacementNeeded((prevState) => !prevState); // Toggle state correctly
    setOthersReplacements((prevState) => !prevState); // Toggle state correctly
    setFindContactNotAdded(false); // Reset contact info state
    setFindHiringPersons(false); // Reset hiring persons state
    setNoEmailFound(false); // Reset no email found state
    setEditEmailInfo(false); // Reset edit email info state
    setFindBounceBacks(false); // Reset bounce back state
  };

  const applyHiringPersonsFilter = () => {
    setFindHiringPersons((prevState) => !prevState); // Toggle state correctly
    setFindContactNotAdded(false); // Reset contact info state
    setFindBounceBacks(false); // Reset bounce back state
    setReplacementNeeded(false); // Reset replacement state
    setOthersReplacements(false); // Reset others replacement state
    setNoEmailFound(false); // Reset no email found state
    setEditEmailInfo(false); // Reset edit email info state
  };
  const applyBounceBacksFilter = () => {
    setFindBounceBacks((prevState) => !prevState); // Toggle state correctly
    setFindContactNotAdded(false); // Reset contact info state
    setReplacementNeeded(false); // Reset replacement state
    setOthersReplacements(false); // Reset hiring persons state
    setNoEmailFound(false); // Reset no email found state
    setEditEmailInfo(false); // Reset edit email info state
    setFindHiringPersons(false); // Reset find hiring persons state
  };

  const applyNoEmailFountFilter = () => {
    setNoEmailFound((prevState) => !prevState); // Toggle state correctly
    setFindContactNotAdded(false); // Reset contact info state
    setReplacementNeeded(false); // Reset replacement state
    setFindHiringPersons(false); // Reset hiring persons state
    setOthersReplacements(false); // Reset others replacement state
    setEditEmailInfo(false); // Reset edit email info state
    setFindBounceBacks(false); // Reset bounce back state
  };

  // table states
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  });
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleButtonClick = (index, fetchFunction) => {
    if (activeIndex === index) {
      setActiveIndex(null);
      if (index === 1) {
        setFindContactNotAdded(false);
      } else if (index === 3) {
        setReplacementNeeded(false);
        setOthersReplacements(false);
      } else if (index === 2) {
        setEditEmailInfo(false);
      } else if (index === 5) {
        setNoEmailFound(false);
      } else if (index === 6) {
        setFindHiringPersons(false);
      } else if (index === 4) {
        setFindBounceBacks(false);
      }
    } else {
      setActiveIndex(index);
      fetchFunction();
      if (index === 1) {
        setFindContactNotAdded(true);
      }
    }
  };

  const assignPersons = () => {
    try {
      Post(
        { userId: parseInt(user_id) },
        API_URLS.assignPersonsToUser,
        (resp) => {
          getAssignedPersons();
        },
        (error) => {
          enqueueSnackbar(error?.response?.data?.message || 'Something went wrong', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const getAssignedPersons = useCallback(() => {
    try {
      setIsLoading(true);
      Get(
        {
          userId: user_id,
          havingNoContactInfo: findContactNotAdded.toString(),
          noEmailFound: noEmailFound.toString(),
          editEmailInfo: editEmailInfo.toString(),
          findHiringPersons: findHiringPersons.toString(),
          findBounceBacks: findBounceBacks.toString(),
          searchString: searchString,
          othersReplacements: othersReplacements.toString(),
          needReplacement: replacementNeeded.toString(),
          page: pagination.pageIndex,
          pageSize: pagination.pageSize,
        }, // Convert to string
        API_URLS.getAssignedPersonsByUserId,
        (resp) => {
          console.log('kjsdgfkjsdbfsd', resp?.data);
          setIsLoading(false);
          let data = resp?.data;
          data = data.map((row) => {
            if (row?.businessEmails?.length > 0) {
              row.business_email = `${row.businessEmails[0].email_id}`;
            }
            if (row?.personalEmails?.length > 0) {
              row.personal_email = `${row.personalEmails[0].email_id}`;
            }
            if (row?.businessPhones?.length > 0) {
              row.business_phone = `${row.businessPhones[0].phone_number}`;
            }
            if (row?.personalPhones?.length > 0) {
              row.personal_phone = `${row.personalPhones[0].phone_number}`;
            }
            return row;
          });
          // filter the persons frm the data where is_email_info_added is false
          const havingNoContactInfo = data?.filter((person) => {
            return person.is_business_email_added === false;
          });
          //filter the persons from the data where is_email_info_added is true AND is_business_email_added IS ALSO TRUE
          const havingContactInfo = data?.filter((person) => {
            return person.is_business_email_added === true && person?.replacement_calim?.length === 0;
          });
          const havingReplacementCalim = data?.filter((person) => {
            return person.replacement_calim?.length > 0;
          });
          if (replacementNeeded) {
            // filter the persons from the resp where the is_replacement_need is true
            const needReplacement = data.filter((person) => {
              return person.is_replacement_needed === true;
            });
            setPersons(needReplacement);
          }
          setPersons(havingContactInfo);
          setHavingReplacementCliamByOther(havingReplacementCalim);
          setPersonsWithNoEmail(havingNoContactInfo);
          setPersonsForSpreadSheet(havingNoContactInfo);
        },
        (error) => {
          console.log('errror', error);
          enqueueSnackbar(error?.response?.data?.message || 'Something went wrong', { variant: 'error' });
          setIsLoading(false);
          setIsError(true);
        }
      );
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
    }
  }, [
    user_id,
    findContactNotAdded,
    noEmailFound,
    editEmailInfo,
    findHiringPersons,
    findBounceBacks,
    searchString,
    othersReplacements,
    replacementNeeded,
    pagination.pageIndex,
    pagination.pageSize,
  ]);

  useEffect(() => {
    getAssignedPersons();
  }, [getAssignedPersons]);

  const handleEmailSave = (personalEmails) => {
    try {
      Post(
        {
          user_id: user_id,
          person_id: selectedPerson?.id,
          personalEmails: personalEmails,
        },
        API_URLS.addPersonalEmails,
        (resp) => {
          enqueueSnackbar('Email info added successfully', { variant: 'success' });
          setOpenAddEmailDialog(false);
          getAssignedPersons();
        },
        (error) => {
          if (error?.response?.data?.existingEmails) {
            const existingEmails = error.response.data.existingEmails;
            const emailMessages = existingEmails
              .map((e) => `The ${e.type} email "${e.email}" already exists for this person.`)
              .join('\n');

            enqueueSnackbar(emailMessages, { variant: 'warning' });
          } else {
            console.log('error', error?.response?.data);
            enqueueSnackbar('Something went wrong', { variant: 'error' });
          }
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const handleBusinessEmailsSave = (businessEmails) => {
    try {
      Post(
        {
          user_id: user_id,
          person_id: selectedPerson?.id,
          businessEmails: businessEmails,
        },
        API_URLS.addBusinessEmails,
        (resp) => {
          enqueueSnackbar('Business emails  added successfully', { variant: 'success' });
          setOpenAddBuinessEmailsDialog(false);
          getAssignedPersons();
        },
        (error) => {
          if (error?.response?.data?.existingEmails) {
            const existingEmails = error.response.data.existingEmails;
            const emailMessages = existingEmails
              .map((e) => `The ${e.type} email "${e.email}" already exists for this person.`)
              .join('\n');

            enqueueSnackbar(emailMessages, { variant: 'warning' });
          } else {
            console.log('error', error?.response?.data);
            enqueueSnackbar('Something went wrong', { variant: 'error' });
          }
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const handleReplacementSave = (replacementEmails) => {
    console.log('replacementEmails', replacementEmails);
    try {
      Post(
        {
          user_id: user_id,
          replaced_emails: replacementEmails,
          person_id: replacementNeededPerson?.id,
        },
        API_URLS.addReplacementEmails,
        (resp) => {
          enqueueSnackbar('Replacement emails added successfully', { variant: 'success' });
          setOpenReplacementDialog(false);
          getAssignedPersons();
        },
        (error) => {
          enqueueSnackbar(error?.response?.data?.message || 'Something went wrong', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const handleUpdatePersonInfo = (values) => {
    try {
      Post(
        values,
        API_URLS.updatePersonInfo,
        (resp) => {
          console.log(resp);
          enqueueSnackbar('Person info updated successfully', { variant: 'success' });
          getAssignedPersons();
          setOpenEditInfo(false);
        },
        (error) => {
          enqueueSnackbar(error?.response?.data?.message || 'Failed to update the person info', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };
  // Prepare the data for the spreadsheet
  const dataForSpreadSheet = personsForSpreadSheet?.map((person) => [
    { value: person.full_name || '' },
    { value: '' },
    { value: person.profile_url || '' },
    { value: person.company ? person.company.name : '' },
    { value: person.company ? person.company.website : '' },
    // { value: '' },
    // { value: '' },
    // { value: '' },
  ]);

  // Define column labels
  const columnLabels = [
    'Name',
    'Business Email',
    'LI Link',
    'Company',
    'Website Link',
    // 'Personal Email',
    // 'Personal Number',
    // 'Business Number',
  ];

  const handleNewData = (newData) => {
    console.log('new data handling', newData);
    // Update the ref without causing re-render
    dataRef.current = newData;
  };

  const processDataForBackend = (emails) => {
    // Start processing
    setIsProcessingData(true);

    try {
      // Use only the rows that match the length of personsForSpreadSheet
      const validEmails = emails.slice(0, personsForSpreadSheet?.length);

      const processedData = {
        // personalEmails: [],
        businessEmails: [],
        // emptyPersonalEmails: [],
        emptyBusinessEmails: [],
      };

      validEmails.forEach((row) => {
        const name = row[0]?.value;
        const businessEmail = row[1]?.value;
        const profileLink = row[2]?.value;
        const company = row[3]?.value;
        // const personalEmail = row[4]?.value;

        // Normalize the profile link for matching
        const normalizedProfileLink = profileLink.trim().toLowerCase();
        const person = personsForSpreadSheet.find(
          (person) => person.profile_url.trim().toLowerCase() === normalizedProfileLink
        );

        // Process personal email
        // if (personalEmail) {
        //   processedData.personalEmails.push({
        //     email: personalEmail,
        //     name,
        //     company,
        //     profileLink,
        //     person_id: person?.id,
        //   });
        // } else {
        //   processedData.emptyPersonalEmails.push({ email: '', name, company, profileLink, person_id: person?.id });
        // }

        // Process business email
        if (businessEmail) {
          processedData.businessEmails.push({
            email: businessEmail,
            name,
            company,
            profileLink,
            person_id: person?.id,
          });
        } else {
          processedData.emptyBusinessEmails.push({ email: '', name, company, profileLink, person_id: person?.id });
        }
      });

      return processedData;
    } catch (error) {
      console.error('An error occurred while processing data:', error);
      alert('An error occurred while processing data. Please try again.');
      return null; // Return null on error
    } finally {
      // End processing
      setIsProcessingData(false);
    }
  };

  const addPersonsEmailsFromSpreadSheet = async () => {
    try {
      setIsSubmittingEmails(true);
      const processedData = await processDataForBackend(dataRef.current); // Use the ref data
      console.log('fdsfefds', processedData);
      Post(
        {
          user_id: user_id,
          // personalEmails: processedData?.personalEmails,
          businessEmails: processedData?.businessEmails,
          // notFoundPersonalEmails: processedData?.emptyPersonalEmails,
          notFoundBusinessEmials: processedData?.emptyBusinessEmails,
        },
        API_URLS.addEmailsFromSpreadSheet,
        (resp) => {
          console.log('emails added');
          // setNewDataWithEmails(emailsToSend); // Optionally update state if you want to keep track
          setIsSubmittingEmails(false)
          setOpenBulEmailConfirmationDialog(false);
          enqueueSnackbar('Emails added successfully' , 'success')
        },
        (error) => {
          console.log('error', error);
          setIsSubmittingEmails(false)
          setOpenBulEmailConfirmationDialog(false);
          enqueueSnackbar('Failed to add emails' , 'error')
        }
      );
    } catch (error) {
      console.log('something went wrong at server');
      setIsSubmittingEmails(false)
      setOpenBulEmailConfirmationDialog(false);
      enqueueSnackbar('Something went wrong at server', 'error');
    }
  };

  // console.log("DSadsa" , personsForSpreadSheet)
  return (
    <div style={{ backgroundColor: '#EEF7FF' }}>
      <div style={{ paddingTop: '10px' }}>
        <Box mb={2}>
          <StyledTextField
            variant="outlined"
            placeholder="Search..."
            size="large"
            onChange={(e) => setSearchString(e.target.value)}
          />
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="flex-start">
          {toggleState === 'custom' && (
            <>
              <Box display="flex" alignItems="flex-start">
                <CustomButton
                  active={activeIndex === 1}
                  onClick={() => handleButtonClick(1, applyContactInfoNotAddedFilter)}
                >
                  Add Contact Info
                </CustomButton>
                {/* <CustomButton active={activeIndex === 2} onClick={() => handleButtonClick(2, applyEditInfoFilter)}>
              Edit Contact Info
            </CustomButton> */}
                <CustomButton
                  active={activeIndex === 3}
                  onClick={() => handleButtonClick(3, applyReplacementNeededFilter)}
                >
                  Replacements
                </CustomButton>
                <CustomButton active={activeIndex === 4} onClick={() => handleButtonClick(4, applyBounceBacksFilter)}>
                  Bounce Back
                </CustomButton>
                <CustomButton active={activeIndex === 5} onClick={() => handleButtonClick(5, applyNoEmailFountFilter)}>
                  No Email Found
                </CustomButton>
                <CustomButton active={activeIndex === 6} onClick={() => handleButtonClick(6, applyHiringPersonsFilter)}>
                  Hiring Persons
                </CustomButton>
              </Box>
            </>
          )}

          <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
            <ToggleButtonGroup
              color="primary"
              exclusive
              aria-label="Platform"
              style={{ backgroundColor: '#e1f7fc', marginRight: '10px', borderRadius: '10px', marginLeft: '1rem' }}
              onChange={(event, newValue) => {
                setToggleState(newValue);
              }}
            >
              <ToggleButton
                value="custom"
                style={{
                  backgroundColor: toggleState === 'custom' ? '#00C1FE' : '#BABABA',
                  border: 'none',
                  width: '100px',

                  color: 'white',
                }}
              >
                Custom
              </ToggleButton>
              <ToggleButton
                value="bulk"
                style={{
                  backgroundColor: toggleState === 'bulk' ? '#00C1FE' : '#BABABA',
                  border: 'none',
                  width: '100px',
                  color: 'white',
                }}
              >
                Bulk
              </ToggleButton>
            </ToggleButtonGroup>
            {toggleState === 'custom' ? (
              <Button
                color="primary"
                sx={{
                  width: '150px',
                  height: '48px',
                  border: '2px solid #00C1FE',
                  borderRadius: '10px',
                  color: '#ffffff',
                  backgroundColor: '#00C1FE',
                  '&:hover': {
                    backgroundColor: '#00C1FE',
                  },
                  marginRight: '10px', // Add margin between the last button and this button
                }}
                onClick={() => assignPersons()}
              >
                Get Persons
              </Button>
            ) : (
              <>
                <Button
                  color="primary"
                  sx={{
                    width: '150px',
                    height: '48px',
                    border: '2px solid #00C1FE',
                    borderRadius: '10px',
                    color: '#ffffff',
                    backgroundColor: '#00C1FE',
                    '&:hover': {
                      backgroundColor: '#00C1FE',
                    },
                    marginRight: '10px', // Add margin between the last button and this button
                  }}
                  onClick={() => setOpenBulEmailConfirmationDialog(true)}
                >
                  Submit as verified
                </Button>
              </>
            )}
          </Box>
        </Box>
      </div>

      <br />

      {toggleState === 'custom' && (
        <>
          {!replacementNeeded && !findBounceBacks && (
            <>
              <Typography variant="h6" style={{ marginLeft: '1rem' }}>
                Add Emails
              </Typography>
              <div style={{ margin: '1rem' }}>
                <MaterialReactTable
                  columns={noEmailFound ? noEmailFoundColumns : columns}
                  data={personsWithNoEmail}
                  enableColumnActions={false}
                  enableColumnFilters={false}
                  enableColumnResizing
                  enableRowSelection
                  options={{
                    selection: true,
                    selectionType: 'checkbox',
                    selectionCheckboxProps: {
                      color: 'primary',
                    },
                  }}
                  defaultColumn={{
                    size: 150, // Default size for columns if not explicitly set
                  }}
                  displayColumnDefOptions={{
                    'mrt-row-actions': {
                      muiTableHeadCellProps: {
                        align: 'center',
                      },
                      size: 150, // Ensure 'mrt-row-actions' column also has equal width
                      background: 'black',
                    },
                  }}
                  // manualPagination
                  onColumnFiltersChange={setColumnFilters}
                  onGlobalFilterChange={setGlobalFilter}
                  onPaginationChange={setPagination}
                  onSortingChange={setSorting}
                  muiTableBodyRowProps={({ row }) => ({
                    hover: false,
                    sx: {
                      backgroundColor: row.original.is_hiring_person
                        ? '#C1F2DD'
                        : row.original.is_replacement_needed
                        ? '#FFD0D0'
                        : 'white', // Corrected logic
                    },
                  })}
                  state={{
                    columnFilters,
                    globalFilter,
                    isLoading,
                    pagination,
                    showAlertBanner: isError,
                    sorting,
                  }}
                  initialState={{
                    columnPinning: { right: ['mrt-row-actions'] },
                    density: 'compact',
                  }}
                  enableRowActions
                  renderRowActions={({ row, table }) => (
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <Tooltip title="Edit Info" arrow>
                        <Icon
                          icon="mdi:edit-outline"
                          style={{
                            cursor: 'pointer',
                            fontSize: '22px',
                            marginRight: '10px',
                            color: '#3F8CFF',
                          }}
                          onClick={() => {
                            setOpenEditInfo(true);
                            setSelectedPerson(row?.original);
                          }}
                        />
                      </Tooltip>
                      {row?.original?.is_business_email_added === false && (
                        <Typography
                          variant="body2"
                          sx={{
                            width: '150px',
                            height: '35px',
                            border: '2px solid #00C1FE',
                            borderRadius: '5px',
                            color: '#ffffff',
                            backgroundColor: '#00C1FE',
                            fontSize: '14px', // Adjust to match the button
                            fontWeight: '500', // Adjust to match the button
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            '&:hover': {
                              backgroundColor: '#00C1FE',
                            },
                          }}
                        >
                          Email Not Added
                        </Typography>
                      )}
                    </Box>
                  )}
                  renderTopToolbarCustomActions={({ table }) => {
                    const isSomeRowsSelected = table.getIsSomeRowsSelected();
                    const getSelectedRowModel = table.getSelectedRowModel();
                    console.log('isSomeRowsSelected', isSomeRowsSelected, getSelectedRowModel);
                    return (
                      <div style={{ display: 'flex', gap: '0.5rem' }}>
                        {replacementNeeded ? (
                          <Button
                            disabled={table.getSelectedRowModel().flatRows.length === 0}
                            color="primary"
                            sx={{
                              width: '180px',
                              height: '45px',
                              border: '2px solid #00C1FE',
                              borderRadius: '10px',
                              color: '#ffffff',
                              backgroundColor: '#00C1FE',
                              textTransform: 'none',
                              '&:hover': {
                                backgroundColor: '#00C1FE',
                              },
                              '&:disabled': {
                                backgroundColor: 'rgba(0, 193, 254, 0.5)', // Dimmed background for disabled state
                                color: '#ffffff', // Text color remains white
                              },
                            }}
                            onClick={() => {
                              const persons = table.getSelectedRowModel().flatRows.map((row) => row.original.id);
                              console.log('personids', persons);
                              setMarkIdsAsReplacementNotFound(persons);
                              setOpenReplacementNtFoundDialog(true);
                              table.resetRowSelection();
                            }}
                          >
                            Replacement not found
                          </Button>
                        ) : (
                          <Button
                            disabled={table.getSelectedRowModel().flatRows.length === 0}
                            onClick={() => {
                              const persons = table.getSelectedRowModel().flatRows.map((row) => row.original.id);
                              console.log('personids', persons);
                              setMarkIdsAsEmailNotFound(persons);
                              setOpenMarkAsNotFoundDialog(true);
                              table.resetRowSelection();
                            }}
                            color="primary"
                            sx={{
                              width: '180px',
                              height: '45px',
                              border: '2px solid #00C1FE',
                              borderRadius: '10px',
                              color: '#ffffff',
                              backgroundColor: '#00C1FE',
                              textTransform: 'none',
                              '&:hover': {
                                backgroundColor: '#00C1FE',
                              },
                              '&:disabled': {
                                backgroundColor: 'rgba(0, 193, 254, 0.5)', // Dimmed background for disabled state
                                color: '#ffffff', // Text color remains white
                              },
                            }}
                          >
                            Mark as not found
                          </Button>
                        )}
                      </div>
                    );
                  }}
                />
              </div>
            </>
          )}
          <br />

          {!findContactNotAdded && !noEmailFound && (
            <>
              <Typography variant="h6" style={{ marginLeft: '1rem' }}>
                Update Bounce Back Response
              </Typography>
              <div style={{ margin: '1rem' }}>
                <MaterialReactTable
                  columns={noEmailFound ? noEmailFoundColumns : emailsAddedColumns}
                  data={persons}
                  enableColumnActions={false}
                  enableColumnResizing
                  enableColumnFilters={false}
                  defaultColumn={{
                    size: 150, // Default size for columns if not explicitly set
                  }}
                  displayColumnDefOptions={{
                    'mrt-row-actions': {
                      muiTableHeadCellProps: {
                        align: 'center',
                      },
                      size: 150, // Ensure 'mrt-row-actions' column also has equal width
                    },
                  }}
                  manualPagination
                  onColumnFiltersChange={setColumnFilters}
                  onGlobalFilterChange={setGlobalFilter}
                  onPaginationChange={setPagination}
                  onSortingChange={setSorting}
                  enableRowSelection
                  muiTableBodyRowProps={({ row }) => ({
                    hover: false,
                    sx: {
                      backgroundColor: row.original.is_hiring_person
                        ? '#C1F2DD'
                        : row.original.is_replacement_needed
                        ? '#FFD0D0'
                        : 'white', // Corrected logic
                    },
                  })}
                  state={{
                    columnFilters,
                    globalFilter,
                    isLoading,
                    pagination,
                    showAlertBanner: isError,
                    sorting,
                  }}
                  initialState={{
                    columnPinning: { right: ['mrt-row-actions'] },
                    density: 'compact',
                  }}
                  enableRowActions
                  renderRowActions={({ row, table }) => (
                    <Box display="flex" justifyContent="center" alignItems="center">
                      {editEmailInfo ? (
                        <Button
                          startIcon={<Icon icon="ic:twotone-edit" />}
                          color="primary"
                          sx={{
                            width: '150px',
                            height: '35px',
                            border: '2px solid #3FC28A',
                            borderRadius: '5px',
                            color: '#ffffff',
                            backgroundColor: '#3FC28A',
                            fontSize: '14px', // Adjust to your needs
                            fontWeight: '500', // Adjust to your needs
                            '&:hover': {
                              backgroundColor: '#3FC28A',
                            },
                          }}
                          onClick={() => {
                            setOpenAddEmailDialog(true);
                          }}
                        >
                          Edit Email
                        </Button>
                      ) : (
                        <>
                          {row?.original?.is_business_email_added === false && !noEmailFound && (
                            <Typography
                              variant="body2"
                              sx={{
                                width: '150px',
                                height: '35px',
                                border: '2px solid #00C1FE',
                                borderRadius: '5px',
                                color: '#ffffff',
                                backgroundColor: '#00C1FE',
                                fontSize: '14px', // Adjust to match the button
                                fontWeight: '500', // Adjust to match the button
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                '&:hover': {
                                  backgroundColor: '#00C1FE',
                                },
                              }}
                            >
                              Email Not Added
                            </Typography>
                          )}
                          {noEmailFound && !editEmailInfo && row?.original?.is_email_not_found === true && (
                            <Typography
                              variant="body2"
                              sx={{
                                width: '150px',
                                height: '35px',
                                border: '2px solid #FF6F6F',
                                borderRadius: '5px',
                                color: '#ffffff',
                                backgroundColor: '#FF6F6F',
                                fontSize: '14px', // Adjust to match the button
                                fontWeight: '500', // Adjust to match the button
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                '&:hover': {
                                  backgroundColor: '#FF6F6F',
                                },
                              }}
                            >
                              <Icon
                                icon="ic:twotone-email"
                                style={{
                                  fontSize: '20px',
                                }}
                              />{' '}
                              &nbsp; Not found
                            </Typography>
                          )}
                          {row?.original?.is_business_email_added === true &&
                            row?.original?.is_replacement_needed === false && (
                              <Typography
                                variant="body2"
                                sx={{
                                  width: '150px',
                                  height: '35px',
                                  border: '2px solid #3FC28A',
                                  borderRadius: '5px',
                                  color: '#ffffff',
                                  backgroundColor: '#3FC28A',
                                  fontSize: '14px', // Adjust to match the button
                                  fontWeight: '500', // Adjust to match the button
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  textAlign: 'center',
                                  '&:hover': {
                                    backgroundColor: '#3FC28A',
                                  },
                                }}
                              >
                                <Icon icon="mdi:tick" style={{ fontSize: '20px' }} /> &nbsp; Email Added
                              </Typography>
                            )}
                          {row?.original?.is_replacement_needed && !findBounceBacks && (
                            <Button
                              startIcon={<Icon icon="solar:refresh-circle-outline" />}
                              color="primary"
                              sx={{
                                width: '150px',
                                height: '35px',
                                border: '2px solid #E45E5B',
                                borderRadius: '5px',
                                color: '#ffffff',
                                backgroundColor: '#E45E5B',
                                fontSize: '14px', // Adjust to your needs
                                fontWeight: '500', // Adjust to your needs
                                '&:hover': {
                                  backgroundColor: '#E45E5B',
                                },
                              }}
                              onClick={() => {
                                setOpenReplacementDialog(true);
                                setReplacementNeededPerson(row?.original);
                              }}
                            >
                              Replacement
                            </Button>
                          )}
                          {findBounceBacks && row?.original?.is_replacement_needed && (
                            <Typography
                              variant="body2"
                              sx={{
                                width: '150px',
                                height: '35px',
                                border: '2px solid #E45E5B',
                                borderRadius: '5px',
                                color: '#ffffff',
                                backgroundColor: '#E45E5B',
                                fontSize: '14px', // Adjust to match the button
                                fontWeight: '500', // Adjust to match the button
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                '&:hover': {
                                  backgroundColor: '#E45E5B',
                                },
                              }}
                            >
                              <Icon icon="fluent:arrow-bounce-20-regular" style={{ fontSize: '20px' }} /> &nbsp; Bounced
                            </Typography>
                          )}
                        </>
                      )}
                    </Box>
                  )}
                  renderTopToolbarCustomActions={({ table }) => {
                    const isSomeRowsSelected = table.getIsSomeRowsSelected();
                    const getSelectedRowModel = table.getSelectedRowModel();
                    console.log('isSomeRowsSelected', isSomeRowsSelected, getSelectedRowModel);
                    return (
                      <div style={{ display: 'flex', gap: '0.5rem' }}>
                        {replacementNeeded && (
                          <Button
                            disabled={table.getSelectedRowModel().flatRows.length === 0}
                            color="primary"
                            sx={{
                              width: '180px',
                              height: '45px',
                              border: '2px solid #00C1FE',
                              borderRadius: '10px',
                              color: '#ffffff',
                              backgroundColor: '#00C1FE',
                              textTransform: 'none',
                              '&:hover': {
                                backgroundColor: '#00C1FE',
                              },
                              '&:disabled': {
                                backgroundColor: 'rgba(0, 193, 254, 0.5)', // Dimmed background for disabled state
                                color: '#ffffff', // Text color remains white
                              },
                            }}
                            onClick={() => {
                              const persons = table.getSelectedRowModel().flatRows.map((row) => row.original.id);
                              console.log('personids', persons);
                              setMarkIdsAsReplacementNotFound(persons);
                              setOpenReplacementNtFoundDialog(true);
                              table.resetRowSelection();
                            }}
                          >
                            Replacement not found
                          </Button>
                        )}
                        <Button
                          disabled={table.getSelectedRowModel().flatRows.length === 0}
                          sx={{
                            width: '180px',
                            height: '45px',
                            border: '2px solid #00C1FE',
                            borderRadius: '10px',
                            color: '#ffffff',
                            backgroundColor: '#00C1FE',
                            textTransform: 'none',
                            '&:hover': {
                              backgroundColor: '#00C1FE',
                            },
                            '&:disabled': {
                              backgroundColor: 'rgba(0, 193, 254, 0.5)', // Dimmed background for disabled state
                              color: '#ffffff', // Text color remains white
                            },
                          }}
                          onClick={() => {
                            const emails = table.getSelectedRowModel().flatRows.map((row) => row.original);
                            setEmailsToUpdate(emails);
                            setOpenActionConfirmationDialog(true);
                            setStatus('verified');
                          }}
                        >
                          Submit as Verified
                        </Button>
                        <Button
                          disabled={table.getSelectedRowModel().flatRows.length === 0}
                          onClick={() => {
                            const emails = table.getSelectedRowModel().flatRows.map((row) => row.original);
                            setEmailsToUpdate(emails);
                            setOpenActionConfirmationDialog(true);
                            setStatus('bounced');
                          }}
                          sx={{
                            width: '180px',
                            height: '45px',
                            border: '2px solid #00C1FE',
                            borderRadius: '10px',
                            color: '#ffffff',
                            backgroundColor: '#00C1FE',
                            textTransform: 'none',
                            '&:hover': {
                              backgroundColor: '#00C1FE',
                            },
                            '&:disabled': {
                              backgroundColor: 'rgba(0, 193, 254, 0.5)', // Dimmed background for disabled state
                              color: '#ffffff', // Text color remains white
                            },
                          }}
                        >
                          Submit as Bounce back
                        </Button>
                      </div>
                    );
                  }}
                />
              </div>
            </>
          )}
          <br />
          {othersReplacements && (
            <>
              <Typography variant="h6" style={{ marginLeft: '1rem' }}>
                Other Replacements
              </Typography>
              <div style={{ margin: '1rem' }}>
                <MaterialReactTable
                  columns={replacementClaimColumns}
                  data={havingReplacementCliamByOther}
                  enableColumnActions={false}
                  enableColumnResizing
                  enableColumnFilters={false}
                  defaultColumn={{
                    size: 150, // Default size for columns if not explicitly set
                  }}
                  displayColumnDefOptions={{
                    'mrt-row-actions': {
                      muiTableHeadCellProps: {
                        align: 'center',
                      },
                      size: 150, // Ensure 'mrt-row-actions' column also has equal width
                    },
                  }}
                  manualPagination
                  onColumnFiltersChange={setColumnFilters}
                  onGlobalFilterChange={setGlobalFilter}
                  onPaginationChange={setPagination}
                  onSortingChange={setSorting}
                  enableRowSelection
                  muiTableBodyRowProps={({ row }) => ({
                    hover: false,
                    sx: {
                      backgroundColor: row.original.is_hiring_person
                        ? '#C1F2DD'
                        : row.original.is_replacement_needed
                        ? '#FFD0D0'
                        : 'white', // Corrected logic
                    },
                  })}
                  state={{
                    columnFilters,
                    globalFilter,
                    isLoading,
                    pagination,
                    showAlertBanner: isError,
                    sorting,
                  }}
                  initialState={{
                    columnPinning: { right: ['mrt-row-actions'] },
                    density: 'compact',
                  }}
                  enableRowActions
                  renderRowActions={({ row, table }) => (
                    <Box display="flex" justifyContent="center" alignItems="center">
                      {editEmailInfo ? (
                        <Button
                          startIcon={<Icon icon="ic:twotone-edit" />}
                          color="primary"
                          sx={{
                            width: '150px',
                            height: '35px',
                            border: '2px solid #3FC28A',
                            borderRadius: '5px',
                            color: '#ffffff',
                            backgroundColor: '#3FC28A',
                            fontSize: '14px', // Adjust to your needs
                            fontWeight: '500', // Adjust to your needs
                            '&:hover': {
                              backgroundColor: '#3FC28A',
                            },
                          }}
                          onClick={() => {
                            setOpenAddEmailDialog(true);
                          }}
                        >
                          Edit Email
                        </Button>
                      ) : (
                        <>
                          {row?.original?.is_business_email_added === false && !noEmailFound && (
                            <Typography
                              variant="body2"
                              sx={{
                                width: '150px',
                                height: '35px',
                                border: '2px solid #00C1FE',
                                borderRadius: '5px',
                                color: '#ffffff',
                                backgroundColor: '#00C1FE',
                                fontSize: '14px', // Adjust to match the button
                                fontWeight: '500', // Adjust to match the button
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                '&:hover': {
                                  backgroundColor: '#00C1FE',
                                },
                              }}
                            >
                              Email Not Added
                            </Typography>
                          )}
                          {noEmailFound && !editEmailInfo && row?.original?.is_email_not_found === true && (
                            <Typography
                              variant="body2"
                              sx={{
                                width: '150px',
                                height: '35px',
                                border: '2px solid #FF6F6F',
                                borderRadius: '5px',
                                color: '#ffffff',
                                backgroundColor: '#FF6F6F',
                                fontSize: '14px', // Adjust to match the button
                                fontWeight: '500', // Adjust to match the button
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                '&:hover': {
                                  backgroundColor: '#FF6F6F',
                                },
                              }}
                            >
                              <Icon
                                icon="ic:twotone-email"
                                style={{
                                  fontSize: '20px',
                                }}
                              />{' '}
                              &nbsp; Not found
                            </Typography>
                          )}
                          {row?.original?.is_business_email_added === true &&
                            row?.original?.is_replacement_needed === false && (
                              <Typography
                                variant="body2"
                                sx={{
                                  width: '150px',
                                  height: '35px',
                                  border: '2px solid #3FC28A',
                                  borderRadius: '5px',
                                  color: '#ffffff',
                                  backgroundColor: '#3FC28A',
                                  fontSize: '14px', // Adjust to match the button
                                  fontWeight: '500', // Adjust to match the button
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  textAlign: 'center',
                                  '&:hover': {
                                    backgroundColor: '#3FC28A',
                                  },
                                }}
                              >
                                <Icon icon="mdi:tick" style={{ fontSize: '20px' }} /> &nbsp; Email Added
                              </Typography>
                            )}
                          {row?.original?.is_replacement_needed && !findBounceBacks && (
                            <Button
                              startIcon={<Icon icon="solar:refresh-circle-outline" />}
                              color="primary"
                              sx={{
                                width: '150px',
                                height: '35px',
                                border: '2px solid #E45E5B',
                                borderRadius: '5px',
                                color: '#ffffff',
                                backgroundColor: '#E45E5B',
                                fontSize: '14px', // Adjust to your needs
                                fontWeight: '500', // Adjust to your needs
                                '&:hover': {
                                  backgroundColor: '#E45E5B',
                                },
                              }}
                              onClick={() => {
                                setOpenReplacementDialog(true);
                                setReplacementNeededPerson(row?.original);
                              }}
                            >
                              Replacement
                            </Button>
                          )}
                          {findBounceBacks && row?.original?.is_replacement_needed && (
                            <Typography
                              variant="body2"
                              sx={{
                                width: '150px',
                                height: '35px',
                                border: '2px solid #E45E5B',
                                borderRadius: '5px',
                                color: '#ffffff',
                                backgroundColor: '#E45E5B',
                                fontSize: '14px', // Adjust to match the button
                                fontWeight: '500', // Adjust to match the button
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                '&:hover': {
                                  backgroundColor: '#E45E5B',
                                },
                              }}
                            >
                              <Icon icon="fluent:arrow-bounce-20-regular" style={{ fontSize: '20px' }} /> &nbsp; Bounced
                            </Typography>
                          )}
                        </>
                      )}
                    </Box>
                  )}
                  renderTopToolbarCustomActions={({ table }) => {
                    const isSomeRowsSelected = table.getIsSomeRowsSelected();
                    const getSelectedRowModel = table.getSelectedRowModel();
                    console.log('isSomeRowsSelected', isSomeRowsSelected, getSelectedRowModel);
                    return (
                      <div style={{ display: 'flex', gap: '0.5rem' }}>
                        {replacementNeeded ? (
                          <Button
                            disabled={table.getSelectedRowModel().flatRows.length === 0}
                            color="primary"
                            sx={{
                              width: '180px',
                              height: '45px',
                              border: '2px solid #00C1FE',
                              borderRadius: '10px',
                              color: '#ffffff',
                              backgroundColor: '#00C1FE',
                              textTransform: 'none',
                              '&:hover': {
                                backgroundColor: '#00C1FE',
                              },
                              '&:disabled': {
                                backgroundColor: 'rgba(0, 193, 254, 0.5)', // Dimmed background for disabled state
                                color: '#ffffff', // Text color remains white
                              },
                            }}
                            onClick={() => {
                              const persons = table.getSelectedRowModel().flatRows.map((row) => row.original.id);
                              console.log('personids', persons);
                              setMarkIdsAsReplacementNotFound(persons);
                              setOpenReplacementNtFoundDialog(true);
                              table.resetRowSelection();
                            }}
                          >
                            Replacement not found
                          </Button>
                        ) : (
                          <Button
                            disabled={table.getSelectedRowModel().flatRows.length === 0}
                            onClick={() => {
                              const persons = table.getSelectedRowModel().flatRows.map((row) => row.original.id);
                              console.log('personids', persons);
                              setMarkIdsAsEmailNotFound(persons);
                              setOpenMarkAsNotFoundDialog(true);
                              table.resetRowSelection();
                            }}
                            color="primary"
                            sx={{
                              width: '180px',
                              height: '45px',
                              border: '2px solid #00C1FE',
                              borderRadius: '10px',
                              color: '#ffffff',
                              backgroundColor: '#00C1FE',
                              textTransform: 'none',
                              '&:hover': {
                                backgroundColor: '#00C1FE',
                              },
                              '&:disabled': {
                                backgroundColor: 'rgba(0, 193, 254, 0.5)', // Dimmed background for disabled state
                                color: '#ffffff', // Text color remains white
                              },
                            }}
                          >
                            Mark as not found
                          </Button>
                        )}

                        <Button
                          disabled={table.getSelectedRowModel().flatRows.length === 0}
                          sx={{
                            width: '180px',
                            height: '45px',
                            border: '2px solid #00C1FE',
                            borderRadius: '10px',
                            color: '#ffffff',
                            backgroundColor: '#00C1FE',
                            textTransform: 'none',
                            '&:hover': {
                              backgroundColor: '#00C1FE',
                            },
                            '&:disabled': {
                              backgroundColor: 'rgba(0, 193, 254, 0.5)', // Dimmed background for disabled state
                              color: '#ffffff', // Text color remains white
                            },
                          }}
                          onClick={() => {
                            const emails = table.getSelectedRowModel().flatRows.map((row) => row.original);
                            setEmailsToUpdate(emails);
                            setOpenActionConfirmationDialog(true);
                            setStatus('verified');
                          }}
                        >
                          Submit as Verified
                        </Button>
                        <Button
                          disabled={table.getSelectedRowModel().flatRows.length === 0}
                          onClick={() => {
                            const emails = table.getSelectedRowModel().flatRows.map((row) => row.original);
                            setEmailsToUpdate(emails);
                            setOpenActionConfirmationDialog(true);
                            setStatus('bounced');
                          }}
                          sx={{
                            width: '180px',
                            height: '45px',
                            border: '2px solid #00C1FE',
                            borderRadius: '10px',
                            color: '#ffffff',
                            backgroundColor: '#00C1FE',
                            textTransform: 'none',
                            '&:hover': {
                              backgroundColor: '#00C1FE',
                            },
                            '&:disabled': {
                              backgroundColor: 'rgba(0, 193, 254, 0.5)', // Dimmed background for disabled state
                              color: '#ffffff', // Text color remains white
                            },
                          }}
                        >
                          Submit as Bounce back
                        </Button>
                      </div>
                    );
                  }}
                />
              </div>
            </>
          )}
        </>
      )}

      {toggleState === 'bulk' && (
        <>
          <div style={{ margin: '1rem' }}>
            <Spreadsheet
              data={dataForSpreadSheet}
              columnLabels={columnLabels}
              onChange={(newData) => handleNewData(newData)}
            />
          </div>
        </>
      )}
      {openMarkAsNotFoundDialog && (
        <MarkAsNotFound
          open={openMarkAsNotFoundDialog}
          onClose={() => setOpenMarkAsNotFoundDialog(false)}
          personIds={markIdsAsEmailNotFound}
          onUpdation={() => getAssignedPersons()}
        />
      )}
      {openShowEmailsDialog && (
        <ShowEmailsDialog
          open={openShowEmailsDialog}
          onClose={() => setOpenShowEmailsDialog(false)}
          emails={emailsToShow}
          onUpdation={() => getAssignedPersons()}
        />
      )}
      {openActionConfirmationDialog && (
        <ShowPersonsDialog
          open={openActionConfirmationDialog}
          onClose={() => setOpenActionConfirmationDialog(false)}
          emails={emailsToUpdate}
          status={status}
          onUpdation={() => getAssignedPersons()}
        />
      )}
      {openReplacementDialog && (
        <AddReplacamentDialog
          open={openReplacementDialog}
          onClose={() => setOpenReplacementDialog(false)}
          person={replacementNeededPerson}
          onSave={(replacementEmails) => {
            handleReplacementSave(replacementEmails);
          }}
        />
      )}
      {openAddBuinessEmailsDialog && (
        <AddBusinessEmailDialog
          open={openAddBuinessEmailsDialog}
          company={selectedPerson?.company?.name}
          onClose={() => setOpenAddBuinessEmailsDialog(false)}
          onSave={(businessEmails) => {
            handleBusinessEmailsSave(businessEmails);
          }}
        />
      )}
      {openAddEmailDialog && (
        <AddPersonalEmailsDialog
          open={openAddEmailDialog}
          onClose={() => setOpenAddEmailDialog(false)}
          onSave={(personalEmails) => {
            handleEmailSave(personalEmails);
          }}
        />
      )}
      {openReplacementNtFoundDialog && (
        <MarkAsReplacementNotFound
          open={openReplacementNtFoundDialog}
          onClose={() => setOpenReplacementNtFoundDialog(false)}
          personIds={markIdsAsReplacementNotFound}
          onUpdation={() => getAssignedPersons()}
        />
      )}
      {openEditInfo && (
        <EditPersonDialog
          open={openEditInfo}
          onClose={() => setOpenEditInfo(false)}
          person={selectedPerson}
          onSubmit={(values) => handleUpdatePersonInfo(values)}
        />
      )}
      {openBulEmailConfirmationDialog && (
        <AddEmailsFromSpreadSheetDialog
          open={openBulEmailConfirmationDialog}
          onClose={() => setOpenBulEmailConfirmationDialog(false)}
          onSubmit={() => {
            addPersonsEmailsFromSpreadSheet();
          }}
          isSubmittingEmails={isSubmittingEmails}
          isProcessingData={isProcessingData}
        />
      )}
    </div>
  );
}

export default MainEmailSearchSection;
