import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import React from 'react';
import { FadeLoader } from 'react-spinners';

function ConfirmSaveSequenceDialog({ open, onClose, onSave, isAddingSequence, isAddingSteps, areStepsFilled }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        hideBackdrop={false}
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{
          sx: {
            ml: 'auto',
            mt: 'auto',
            mb: 'auto',
            mr: 'auto',
            width: '100%',
            maxWidth: '550px',
            height: 'auto',
          },
        }}
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#E2F2FF',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" className="ml-8">
              Save Role Sequence
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <br />
          {areStepsFilled === false && (
            <Typography variant="h6" sx={{ textAlign: 'center' }} color="error">
              Please fill all the steps before saving or remove the empty steps.
            </Typography>
          )}
          {areStepsFilled === true && (
            <>
              {!isAddingSequence && !isAddingSteps ? (
                <>
                  <Typography variant="h6" sx={{ textAlign: 'center' }}>
                    Are you sure you want to save this role sequence?
                  </Typography>
                  <br />
                  <Box display="flex" justifyContent="flex-end" marginTop="20px">
                    <Button
                      sx={{
                        background: '#A3A3A3',
                        color: '#fff',
                        height: '45px',
                        border: '1px solid #A3A3A3',
                        '&:hover': {
                          background: '#A3A3A3',
                          color: '#fff',
                        },
                        borderRadius: '15px',
                        padding: '25px',
                        marginLeft: '20px',
                      }}
                      onClick={onClose}
                    >
                      No
                    </Button>
                    <Button
                      sx={{
                        background: '#00C1FE',
                        color: '#fff',
                        height: '45px',
                        border: '1px solid #00C1FE',
                        '&:hover': {
                          background: '#00C1FE',
                          color: '#fff',
                        },
                        borderRadius: '15px',
                        padding: '25px',
                        marginLeft: '20px',
                      }}
                      disabled={areStepsFilled === false}
                      onClick={() => onSave()}
                    >
                      Yes
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  {isAddingSequence ? (
                    <>
                      <Box display="flex" justifyContent="center">
                        <FadeLoader color="#00C1FE" loading={true} />
                      </Box>

                      <Typography variant="h6" sx={{ textAlign: 'center', marginTop: '10px' }}>
                        Saving sequence...
                      </Typography>
                      <br />
                    </>
                  ) : (
                    <>
                      <Box display="flex" justifyContent="center">
                        <FadeLoader color="#00C1FE" loading={true} />
                      </Box>
                      <Typography variant="h6" sx={{ textAlign: 'center', marginTop: '10px' }}>
                        Saving steps...
                      </Typography>
                      <br />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ConfirmSaveSequenceDialog;
