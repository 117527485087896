import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';

import { ArrowBack, Close } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import React, { useCallback, useEffect, useState } from 'react';

function IndividualClientReportDialog({ open, onClose, data, loading, clientNumber }) {
  console.log('datakjsgdfd', data[0]);
  const columns = [
    {
      accessorKey: 'role_date',
      header: 'Date',
      Cell: ({ cell }) => {
        const dateString = cell?.row?.original?.roleDate;
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const humanReadableDate = date.toLocaleDateString('en-US', options);
        return humanReadableDate;
      },
    },
    // {
    //   accessorKey: 'client_number',
    //   header: 'Client No',
    // },
    {
      accessorKey: 'title',
      header: 'Role Title',
      minWidth: 170,
    },
    {
      accessorKey: 'doneBy',
      header: 'Done By',
      minWidth: 170,
    },
    {
      accessorKey: 'status',
      header: 'Status',
      // Cell: ({ cell }) => (
      //   <Box
      //     component="span"
      //     sx={(theme) => ({
      //       backgroundColor:
      //         cell.getValue() === 'Issue'
      //           ? theme.palette.error.main
      //           : cell.getValue() === 'Done'
      //           ? 'white'
      //           : cell.getValue() === 'Completed'
      //           ? '#43EE3F'
      //           : cell.getValue() === 'pending'
      //           ? theme.palette.warning.main
      //           : cell.getValue() === 'inProgress'
      //           ? '#2196f3'
      //           : cell.getValue() === 're-working'
      //           ? '#D3C2CE'
      //           : cell.getValue() === 'final'
      //           ? theme.palette.success.dark
      //           : 'transparent',
      //       borderRadius: '0.25rem',
      //       p: '0.50rem',
      //     })}
      //   >
      //     {cell.getValue()}
      //   </Box>
      // ),
    },

    {
      accessorKey: 'cvs_submitted',
      header: 'No CVs',
      Cell: ({ cell }) => (
        <Box component="span">
          <span>{!cell?.row?.original.cvs_submitted ? 0 : cell?.row?.original.cvs_submitted}</span>
        </Box>
      ),
    },
    {
      accessorKey: 'lis_submitted',
      header: 'No LIS',
      Cell: ({ cell }) => (
        <Box component="span">
          <span>{cell?.row?.original.lis_submitted ? cell?.row?.original.lis_submitted : 0}</span>
        </Box>
      ),
    },
    // {
    //   accessorKey: 'timeTaken',
    //   header: 'Time spent',
    // },
    {
      accessorKey: 'jobBoardDetails',
      header: 'Job boards',
      minWidth: 170,
      // getting job board used as :
      // "{\"reeds\":12,\"cvl\":23,\"totalJobs\":45,\"linkedIn\":45,\"zoomInfo\":67}"
      //show the job boards seperated by comma
    },
    {
      accessorKey: 'months_back',
      header: 'Months Back',
      minWidth: 170,
    },
    {
      accessorKey: 'radius_miles',
      header: 'Radius Miles',
      minWidth: 170,
    },
    {
      accessorKey: 'reason_of_less_li_profiles',
      header: 'Less LI Reason',
      minWidth: 170,
    },
    {
      accessorKey: 'reason_of_less_cvs',
      header: 'Less CV Reason',
      minWidth: 170,
    },
    {
      accessorKey: 'client_feedback',
      header: 'Client Feedback',
      minWidth: 170,
    },
    {
      accessorKey: 'resolved_by',
      header: 'Resolved By',
      minWidth: 170,
    },
  ];

  const [clientSubscriptionInfo, setClientSubscriptionInfo] = useState([]);

  const getClientSubscriptionInfo = useCallback(() => {
    try {
      Get(
        {
          client_number: clientNumber,
        },
        API_URLS.getClientHistoryByClientNumber,
        (resp) => {
          console.log('respljksdgfsd', resp);
          setClientSubscriptionInfo(resp?.data);
        },
        (error) => {
          console.log('error', error);
        }
      );
    } catch (error) {}
  }, [clientNumber]);

  useEffect(() => {
    getClientSubscriptionInfo();
  }, [getClientSubscriptionInfo]);

  return (
    <div>
      <Dialog open={open} onClose={onClose} fullScreen>
        <DialogTitle
          sx={{
            backgroundColor: '#F4F9FD',
            paddingBottom: '16px',
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              backgroundColor: '#AFCFFF',
              padding: '16px',
            }}
          >
            <Typography variant="h4">
              <ArrowBack
                sx={{
                  cursor: 'pointer',
                }}
                onClick={onClose}
              />{' '}
              Client {data[0]?.client_number} Details
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {clientSubscriptionInfo[0]?.end_date ? (
                <Box
                  sx={{
                    padding: '8px',
                    borderRadius: '4px',
                    marginBottom: '8px',
                    textAlign: 'center',
                    backgroundColor: (() => {
                      const today = new Date();
                      const endDate = new Date(clientSubscriptionInfo[0]?.end_date);
                      if (today.toDateString() === endDate.toDateString()) {
                        return '#FFC107'; // Expiring today: yellow
                      } else if (endDate < today) {
                        return '#F44336'; // Expired: red
                      }
                      return '#2196F3'; // Active: blue
                    })(),
                    color: '#FFFFFF', // White text for contrast
                  }}
                >
                  <Typography variant="h6">
                    {(() => {
                      const today = new Date();
                      const endDate = new Date(clientSubscriptionInfo[0]?.end_date);
                      if (today.toDateString() === endDate.toDateString()) {
                        return 'Expiring Today';
                      } else if (endDate < today) {
                        return `Expired on ${endDate.toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })}`;
                      }
                      return `Expiring on ${endDate.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}`;
                    })()}
                  </Typography>
                </Box>
              ) : (
                <Typography variant="h6" sx={{ color: '#9E9E9E' }}>
                  No Expiry Date
                </Typography>
              )}
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center"></Box>
          <MaterialReactTable
            displayColumnDefOptions={{
              'mrt-row-actions': {
                muiTableHeadCellProps: {
                  align: 'center',
                },
                size: 50,
              },
            }}
            columns={columns}
            data={data}
            defaultColumn={{
              size: 100, //make columns wider by default
            }}
            enableColumnActions={false}
            enableColumnFilters={false}
            enableSorting={false}
            enableTopToolbar={false}
            positionToolbarAlertBanner="bottom"
            manualFiltering
            state={{
              isLoading: loading,
            }}
            actionsColumnIndex={-1}
          />

          <br />
          <Box display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
            {clientSubscriptionInfo[0]?.end_date ? (
              <Box
                sx={{
                  padding: '8px',
                  borderRadius: '4px',
                  marginBottom: '8px',
                  textAlign: 'center',
                  backgroundColor: (() => {
                    const today = new Date();
                    const endDate = new Date(clientSubscriptionInfo[0]?.end_date);
                    if (today.toDateString() === endDate.toDateString()) {
                      return '#FFC107'; // Expiring today: yellow
                    } else if (endDate < today) {
                      return '#F44336'; // Expired: red
                    }
                    return '#2196F3'; // Active: blue
                  })(),
                  color: '#FFFFFF', // White text for contrast
                }}
              >
                <Typography variant="h6">
                  {(() => {
                    const today = new Date();
                    const endDate = new Date(clientSubscriptionInfo[0]?.end_date);
                    if (today.toDateString() === endDate.toDateString()) {
                      return 'Expiring Today';
                    } else if (endDate < today) {
                      return `Expired on ${endDate.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}`;
                    }
                    return `Expiring on ${endDate.toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}`;
                  })()}
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  padding: '8px',
                  borderRadius: '4px',
                  marginBottom: '8px',
                  textAlign: 'center',
                  backgroundColor: '#E0E0E0',
                  color: '#9E9E9E', // Grey text
                }}
              >
                <Typography variant="h6">No Expiry Date</Typography>
              </Box>
            )}

            {[
              { label: 'Total Credits', value: clientSubscriptionInfo[0]?.no_of_credits },
              { label: 'Used Credits', value: clientSubscriptionInfo[0]?.credits_used },
              {
                label: 'Remaining Credits',
                value: clientSubscriptionInfo[0]?.no_of_credits - clientSubscriptionInfo[0]?.credits_used,
              },
            ].map((item, index) => (
              <Box
                key={index}
                sx={{
                  padding: '8px',
                  borderRadius: '4px',
                  marginBottom: '8px',
                  textAlign: 'center',
                  backgroundColor: '#E5F4E3', // Light green background
                  color: '#2C8EE1', // Text color
                  border: '1px solid #2C8EE1', // Border color
                }}
              >
                <Typography variant="h6">
                  {item.label} {item.value}
                </Typography>
              </Box>
            ))}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default IndividualClientReportDialog;
