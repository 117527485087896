import { ArrowBack } from '@mui/icons-material';
import { Box, Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function CreateCall(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const previousPath = location.state?.previousPath || '/recruiter/app';
  return (
    <div style={{ width: '97%', margin: 'auto' }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <ArrowBack
          fontSize="small"
          onClick={() => {
            navigate(previousPath);
          }}
          sx={{
            color: '#00C1FE',
            cursor: 'pointer',
          }}
        />
        &nbsp;&nbsp;{' '}
        <Typography
          variant="subtitle2"
          sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}
          onClick={() => {
            navigate(previousPath);
          }}
        >
          Back
        </Typography>
      </Box>
      <br />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <p style={{ fontSize: '32px', fontWeight: 'bold', marginTop: '10px', marginLeft: '10px' }}>Create Call</p>
        <Box display="flex">
          <Button
            sx={{
              background: '#CED5E0',
              height: '45px',
              border: '1px solid #CED5E0',
              '&:hover': {
                background: '#CED5E0',
                color: 'black',
              },
              borderRadius: '15px',
              padding: '25px',
              marginLeft: '10px',
            }}
            onClick={() => navigate(previousPath)}
          >
            Cancel
          </Button>
          <Button
            sx={{
              background: '#00C1FE',
              color: '#fff',
              height: '45px',
              border: '1px solid #00C1FE',
              '&:hover': {
                background: '#00C1FE',
                color: '#fff',
              },
              borderRadius: '15px',
              padding: '25px',
              marginLeft: '10px',
            }}
          >
            Save and Create Follow-up task
          </Button>
          <Button
            sx={{
              background: '#00C1FE',
              color: '#fff',
              height: '45px',
              border: '1px solid #00C1FE',
              '&:hover': {
                background: '#00C1FE',
                color: '#fff',
              },
              borderRadius: '15px',
              padding: '25px',
              marginRight: '10px',
              marginLeft: '10px',
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
      <br />
      <Box sx={{ backgroundColor: 'white', padding: '40px', borderRadius: 4, height: 'auto', marginTop: '15px' }}>
        <p style={{ fontSize: '18px' }}>Call Information</p>
        <Grid container spacing={2} sx={{ marginTop: '10px' }}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box display="flex" alignItems="center" marginTop="10px">
              <Grid container alignItems="center">
                <Grid item xs={4} md={2}>
                  <p style={{ fontSize: '18px', color: 'black', textAlign: 'left' }}>Subject</p>
                </Grid>
                <Grid item xs={8} md={10}>
                  <TextField
                    id="subject"
                    value=""
                    type="text"
                    size="large"
                    fullWidth
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '20px',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '20px',
                        '& fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&:hover fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#dfe1e5',
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" alignItems="center" marginTop="25px">
              <Grid container alignItems="center">
                <Grid item xs={4} md={2}>
                  <p style={{ fontSize: '18px', color: 'black', textAlign: 'left' }}>Call Type</p>
                </Grid>
                <Grid item xs={8} md={10}>
                  <FormControl sx={{ height: '55px',  }}>
                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                      <FormControlLabel
                        value="inbound"
                        control={
                          <Radio
                            sx={{
                              color: '#00C1FE',
                              '&.Mui-checked': {
                                color: '#00C1FE', // Checked color
                              },
                            }}
                          />
                        }
                        label="In bound"
                      />
                      <FormControlLabel
                        value="outbound"
                        control={
                          <Radio
                            sx={{
                              color: '#00C1FE',
                              '&.Mui-checked': {
                                color: '#00C1FE', // Checked color
                              },
                            }}
                          />
                        }
                        label="Out bound"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" alignItems="center" marginTop="25px">
              <Grid container alignItems="center">
                <Grid item xs={4} md={2}>
                  <p style={{ fontSize: '18px', color: 'black', textAlign: 'left' }}>Job Opening</p>
                </Grid>
                <Grid item xs={8} md={10}>
                  <TextField
                    id="job_opening"
                    value=""
                    type="text"
                    size="large"
                    fullWidth
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '20px',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '20px',
                        '& fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&:hover fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#dfe1e5',
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box display="flex" alignItems="center" marginTop="10px">
              <Grid container alignItems="center">
                <Grid item xs={4} md={2}>
                  <p style={{ fontSize: '18px', color: 'black', textAlign: 'left' }}>Call Purpose</p>
                </Grid>
                <Grid item xs={8} md={10}>
                  <TextField
                    id="call_purpose"
                    value=""
                    type="text"
                    size="large"
                    fullWidth
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '20px',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '20px',
                        '& fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&:hover fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#dfe1e5',
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" alignItems="center" marginTop="25px">
              <Grid container alignItems="center">
                <Grid item xs={4} md={2}>
                  <p style={{ fontSize: '18px', color: 'black', textAlign: 'left' }}>Candidate</p>
                </Grid>
                <Grid item xs={8} md={10}>
                  <TextField
                    id="candidate"
                    value=""
                    type="text"
                    size="large"
                    fullWidth
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '20px',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '20px',
                        '& fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&:hover fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#dfe1e5',
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" alignItems="center" marginTop="25px">
              <Grid container alignItems="center">
                <Grid item xs={4} md={2}>
                  <p style={{ fontSize: '18px', color: 'black', textAlign: 'left' }}>Call Result</p>
                </Grid>
                <Grid item xs={8} md={10}>
                  <TextField
                    id="call_result"
                    value=""
                    type="text"
                    size="large"
                    fullWidth
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '20px',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '20px',
                        '& fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&:hover fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#dfe1e5',
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} marginTop="25px">
            <Box display="flex" alignItems="center">
              <Grid container spacing={2}>
                <Grid item xs={4} md={1} sx={{ width: '100%' }}>
                  <p style={{ fontSize: '18px', color: 'black', textAlign: 'left' }}>Description</p>
                </Grid>
                <Grid item xs={8} md={11} sx={{ width: '100%' }}>
                  <TextField
                    id="subject"
                    value=""
                    type="text"
                    size="large"
                    multiline
                    rows={8}
                    fullWidth
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '20px',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '20px',
                        '& fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&:hover fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#dfe1e5',
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default CreateCall;
