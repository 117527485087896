import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { CVFormat } from '.';
import { useNavigate, useParams } from 'react-router-dom';
import { Post } from 'src/actions/API/apiActions';
import { useSnackbar } from 'notistack';
import { API_URLS } from 'src/constants/apiURLs';
import { Autorenew, Delete, DownloadOutlined, Refresh } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';

function LITable({ roleLIs, onPageChange, onRowsPerPageChange, totalRows, onRefreshRequest,roleLocation,roleTitle }) {
  const userRole = localStorage.getItem('role');
  const navigate = useNavigate();
  const { roleId } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [openViewLIDialog, setOpenViewLIDialog] = useState(false);
  const role = localStorage.getItem('role');
  const [selectedLI, setSelectedLI] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [downloadLiAsPdf, setDownloadLiAsPdf] = useState(false);
  const [liList, setLiList] = useState([]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onPageChange(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    onRowsPerPageChange(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page whenever rows per page changes
  };

  const acceptLI = (id) => {
    try {
      Post(
        {
          id,
        },
        API_URLS.acceptLI,
        (resp) => {
          enqueueSnackbar('LI accepted successfully', { variant: 'success' });
        },
        (error) => {
          enqueueSnackbar('Error accepting LI', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Error accepting LI', { variant: 'error' });
    }
  };

  const rejectLI = (id) => {
    try {
      Post(
        {
          id,
        },
        API_URLS.rejectLI,
        (resp) => {
          enqueueSnackbar('LI rejected successfully', { variant: 'success' });
        },
        (error) => {
          enqueueSnackbar('Error rejecting LI', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Error rejecting LI', { variant: 'error' });
    }
  };

  useEffect(() => {
    roleLIs?.forEach((element) => {
      setLiList((prev) => [...prev, element.li]);
    });
  }, [roleLIs]);

  const changeLIProfileStatus = (id) => {
    console.log("ksdgfsdnfsdf",id);
    try {
      Post(
        {
          id,
          status: 'pending',
        },
        API_URLS.changeLIProfileStatus,
        (resp) => {
          enqueueSnackbar('LI reverted to pending successfully', { variant: 'success' });
          onRefreshRequest();
        },
        (error) => {
          enqueueSnackbar('Error reverting LI to pending', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Error reverting LI to pending', { variant: 'error' });
    }
  }

  const deleteLIProfile = (id) => {
    console.log("ksdgfsdnfsdf",id);
    try {
      Post(
        {
          id,
        },
        API_URLS.deleteLIProfile,
        (resp) => {
          enqueueSnackbar('LI removed successfully', { variant: 'success' });
          onRefreshRequest();
        },
        (error) => {
          enqueueSnackbar('Error reverting LI to pending', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Error reverting LI to pending', { variant: 'error' });
    }
  }

  return (
    <div>
      <Paper
        sx={{
          width: '100%',
          overflowX: 'auto',
          position: 'relative',
          display: 'block',
          maxWidth: '100%',
          backgroundColor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.30)',
          border: '1px solid #D9D9D9',
          borderRadius: '10px',
          '& td, & th': {
            whiteSpace: 'nowrap',
            borderBottom: '1px solid #D9D9D9',
          },
        }}
      >
        <Box display="flex" justifyContent="flex-end" gap={1} alignItems="center" padding="10px">
          {userRole === 'resourcer' && (
            <Button
              sx={{
                background: '#00C1FE',
                color: '#fff',
                width: '80px',
                height: '40px',
                border: '1px solid #00C1FE',
                '&:hover': {
                  background: '#00C1FE',
                  color: 'white',
                },
                borderRadius: '5px',
                padding: '5px',
              }}
              onClick={() => {
                navigate(`/resourcing/addLI/${roleId}`);
              }}
            >
              Add LIs
            </Button>
          )}
          <Button
            sx={{
              background: '#00C1FE',
              color: '#fff',
              width: '80px',
              height: '40px',
              border: '1px solid #00C1FE',
              '&:hover': {
                background: '#00C1FE',
                color: 'white',
              },
              borderRadius: '5px',
              padding: '5px',
            }}
            onClick={() => {
              const selectedColumns = [
                'profile_type',
                'full_name',
                'profile_link',
                'current_company',
                'current_title',
                'personal_contact',
                'personal_email',
                'business_number',
                'business_email',
              ];

              const filteredLiList = liList.map((li) => {
                let filteredItem = {};
                selectedColumns.forEach((column) => {
                  filteredItem[column] = li[column];
                });
                return filteredItem;
              });

              // Create a worksheet
              const ws = XLSX.utils.json_to_sheet(filteredLiList);

              // Correctly set the hyperlinks in the `profile_link` column
              filteredLiList.forEach((item, index) => {
                if (item.profile_link) {
                  const cellAddress = XLSX.utils.encode_cell({
                    r: index + 1, // Excel rows are 1-based (index + 1 for data rows)
                    c: selectedColumns.indexOf('profile_link'), // Column index for `profile_link`
                  });

                  // Set a formula to create a hyperlink
                  ws[cellAddress] = {
                    t: 's', // Cell type as string
                    v: item.profile_link, // Visible text
                    l: { Target: item.profile_link }, // Link metadata
                  };
                }
              });

              // Create a new workbook and append the worksheet
              const wb = XLSX.utils.book_new();

              // Truncate the sheet name to 31 characters
              const sheetName = `${roleTitle} ${roleLocation} LIs`.substring(0, 31);
              XLSX.utils.book_append_sheet(wb, ws, sheetName);

              // Generate the file name
              const fileName = `${roleTitle} ${roleLocation}.xlsx`;
              XLSX.writeFile(wb, fileName);
            }}
          >
            Excel <DownloadOutlined />
          </Button>
          &nbsp; &nbsp;
          <Button
            sx={{
              background: '#00C1FE',
              color: '#fff',
              width: '40px',
              height: '40px',
              border: '1px solid #00C1FE',
              '&:hover': {
                background: '#00C1FE',
                color: 'white',
              },
              borderRadius: '5px',
              padding: '5px',
            }}
            onClick={() => {
              onRefreshRequest();
            }}
          >
            <Refresh />
          </Button>
        </Box>
        <TableContainer>
          <Table aria-labelledby="tableTitle">
            <TableHead>
              <TableRow style={{ backgroundColor: '#E1F7FC' }}>
                <TableCell
                  style={{
                    color: '#00C1FE',
                    fontWeight: 'bold',
                    fontSize: '20px',
                  }}
                >
                  Type
                </TableCell>
                <TableCell
                  style={{
                    color: '#00C1FE',
                    fontWeight: 'bold',
                    fontSize: '20px',
                  }}
                >
                  Link
                </TableCell>
                <TableCell
                  style={{
                    color: '#00C1FE',
                    fontWeight: 'bold',
                    fontSize: '20px',
                  }}
                >
                  LIs
                </TableCell>
                <TableCell
                  style={{
                    color: '#00C1FE',
                    fontWeight: 'bold',
                    fontSize: '20px',
                  }}
                >
                  {' '}
                  Status
                </TableCell>
                <TableCell
                  style={{
                    color: '#00C1FE',
                    fontWeight: 'bold',
                    fontSize: '20px',
                    textAlign: 'center',
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roleLIs?.length > 0 ? (
                <>
                  {roleLIs
                    ?.sort((a, b) => b?.li?.profile_type - a?.li?.profile_type)
                    .map((li) => (
                      <TableRow>
                        <TableCell>{li?.li ? li?.li?.profile_type : ''}</TableCell>
                        <TableCell>{li?.li ? li?.li?.profile_link : ''}</TableCell>
                        <TableCell>{li?.li ? li?.li?.full_name : ''}</TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              backgroundColor: li?.li?.profile_status === 'completed' ? 'green' : '#FFEFCA',
                              color: li?.li?.profile_status === 'completed' ? 'white' : 'black',
                              padding: '5px',
                              width: '105px',
                              margin: '5px',
                              fontWeight: 'bold',
                              textAlign: 'center',
                              display: 'flex', // Flexbox layout
                              alignItems: 'center', // Align items horizontally in the center
                              justifyContent: 'center', // Center content horizontally
                              borderRadius: '15px',
                            }}
                          >
                            {/* <Icon icon="fluent-mdl2:radio-bullet" style={{ marginRight: '5px', fontSize: '20px' }} />{' '} */}
                            {li?.li?.profile_status}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box display="flex" justifyContent="space-around" alignItems="center">
                            {li?.li?.profile_status === 'In-Progress' && (
                              <>
                                <CircularProgress value={100} variant="indeterminate" color="primary" size={20} />
                              </>
                            )}
                            <Button
                              sx={{
                                border: '1px solid #00C1FE',
                                padding: '5px 10px',
                                color: '#00C1FE',
                                width: '50px',
                                height: '34px',
                                marginRight: '20px',
                                marginLeft: '5px',
                              }}
                              onClick={() => changeLIProfileStatus(li?.li?.id)}
                            >
                              <Autorenew />
                            </Button>
                            <Button
                              sx={{
                                border: '1px solid #00C1FE',
                                padding: '5px 10px',
                                color: '#00C1FE',
                                width: '50px',
                                height: '34px',
                                marginRight: '20px',
                                marginLeft: '5px',
                              }}
                              onClick={() => deleteLIProfile(li?.li?.id)}
                            >
                              <Delete />
                            </Button>
                            <Button
                              sx={{
                                border: '1px solid #00C1FE',
                                padding: '5px 10px',
                                color: '#00C1FE',
                                width: '50px',
                                height: '34px',
                                marginRight: '20px',
                                marginLeft: '5px',
                              }}
                              onClick={() => {
                                // check the LI row
                                setSelectedLI(li);
                                setOpenViewLIDialog(true);
                              }}
                            >
                              View LI
                            </Button>
                            <Button
                              sx={{
                                border: '1px solid #00C1FE',
                                padding: '5px 10px',
                                color: '#00C1FE',
                                width: '50px',
                                height: '34px',
                                marginRight: '20px',
                                marginLeft: '5px',
                              }}
                              onClick={() => {
                                // check the LI row
                                setSelectedLI(li);
                                setDownloadLiAsPdf(true);
                                setOpenViewLIDialog(true);
                              }}
                            >
                              <DownloadOutlined />
                            </Button>

                            {role === 'QAResourcing' && (
                              <>
                                <Button
                                  sx={{
                                    backgroundColor: '#E1F7FC',
                                    height: '34px',
                                    width: '15px',
                                    '&:hover': {
                                      backgroundColor: '#E1F7FC',
                                    },
                                    borderRadius: '5px',
                                  }}
                                  onClick={() => {
                                    acceptLI(li?.li?.id);
                                  }}
                                >
                                  {' '}
                                  <Tooltip title="Reject LI" arrow placement="top">
                                    <Icon
                                      icon="bitcoin-icons:cross-filled"
                                      style={{ fontSize: '22px', color: '#0EC4FE', fontWeight: 'bold' }}
                                    />
                                  </Tooltip>
                                </Button>
                                <Button
                                  sx={{
                                    backgroundColor: '#E1F7FC',
                                    height: '34px',
                                    width: '15px',
                                    '&:hover': {
                                      backgroundColor: '#E1F7FC',
                                    },
                                    borderRadius: '5px',
                                  }}
                                  onClick={() => {
                                    rejectLI(li?.li?.id);
                                  }}
                                >
                                  {' '}
                                  <Tooltip title="Accept LI" arrow placement="top">
                                    <Icon
                                      icon="mdi:tick"
                                      style={{ fontSize: '22px', color: '#0EC4FE', fontWeight: 'bold' }}
                                    />
                                  </Tooltip>
                                </Button>
                                <Button
                                  sx={{
                                    backgroundColor: '#E1F7FC',
                                    height: '34px',
                                    width: '15px',
                                    '&:hover': {
                                      backgroundColor: '#E1F7FC',
                                    },
                                    borderRadius: '5px',
                                  }}
                                >
                                  {' '}
                                  <Tooltip title="Edit LI" arrow placement="top">
                                    <Icon
                                      icon="material-symbols-light:edit-outline"
                                      style={{ fontSize: '22px', color: '#0EC4FE', fontWeight: 'bold' }}
                                    />
                                  </Tooltip>
                                </Button>
                              </>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    ))}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={4} sx={{ textAlign: 'center' }}>
                    No LIs found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={totalRows} // The total number of records (from server)
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {openViewLIDialog && (
        <CVFormat
          open={openViewLIDialog}
          onClose={() => setOpenViewLIDialog(false)}
          openToWork={true}
          candidate={selectedLI?.li}
          downloadLiAsPdf={downloadLiAsPdf}
        />
      )}

      {/* <ExportAllCandidatesAsPDF candidates={liList} /> */}
      {/* <CVFormatDownload candidate={selectedLI?.li} OpenToWork={true} /> */}
    </div>
  );
}

export default LITable;

// function ExportAllCandidatesAsPDF({ candidates }) {
//   console.log('candsdsdidates', candidates);
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [triggerDownload, setTriggerDownload] = useState(false);

//   const handleExportAll = () => {
//     setCurrentIndex(0);
//     setTriggerDownload(true);
//   };

//   const handleNextCandidate = () => {
//     alert('Candidate Exported Successfully');
//     if (currentIndex < candidates.length - 1) {
//       setCurrentIndex((prev) => prev + 1);
//     } else {
//       setTriggerDownload(false);
//     }
//   };

//   useEffect(() => {
//     if (triggerDownload) {
//       console.log('Exporting Candidate', currentIndex + 1);
//     }
//   }, [currentIndex, triggerDownload]);

//   return (
//     <div>
//       <button onClick={handleExportAll}>Download All CVs</button>

//       {triggerDownload && (
//         <CVFormat
//           open={false} // You can set it false if you want to hide the dialog
//           onClose={() => {}}
//           openToWork={candidates[currentIndex]?.profile_type === 'openToWork'}
//           candidate={candidates[currentIndex]}
//           downloadLiAsPdf={true}
//           onExportComplete={handleNextCandidate} // Trigger next candidate
//         />
//       )}
//     </div>
//   );
// }