import { Clear, Close } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';
import { JobIconForDialog } from './images';
import { archived, hired, interview, offered, rejected, screening } from './constants';

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '20px', // Customize border-radius here
    '& fieldset': {
      borderColor: '#dfe1e5', // Customize border color here
    },
    '&:hover fieldset': {
      borderColor: '#dfe1e5',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#dfe1e5',
    },
  },
});

function AssociateCandidateToOtherRoleDialog({ open, onClose }) {
  const jobOptions = [
    { label: 'Software Engineer' },
    { label: 'Data Scientist' },
    { label: 'Product Manager' },
    // Add more job options as needed
  ];

  const groupedOptions = [...screening, ...interview, ...offered, ...hired, ...rejected, ...archived];
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        hideBackdrop={false}
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{
          sx: {
            ml: 'auto',
            mt: 'auto',
            mb: 'auto',
            mr: 'auto',
            width: '100%',
            maxWidth: '850px',
            height: 'auto',
          },
        }}
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#E2F2FF',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" className="ml-8">
              Associate Job Opening
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <br />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography variant="h6" sx={{ textAlign: 'center' }}>
                Choose Job Opening
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Autocomplete
                options={jobOptions}
                getOptionLabel={(option) => option.label}
                clearOnEscape // Enable clear on escape
                clearIcon={<Clear />} // Custom clear icon (cross)
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    placeholder="Choose job opening"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                          <InputAdornment position="end">
                            <img src={JobIconForDialog} alt="Select Job" />
                          </InputAdornment>
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" sx={{ marginTop: '15px' }}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography variant="h6" sx={{ textAlign: 'center' }}>
                Select Application Status
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Autocomplete
                id="status"
                fullWidth
                // value={} // Change here
                options={groupedOptions}
                groupBy={(option) => option.group} 
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => {
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select application status"
                    variant="outlined"
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '20px', // Customize border-radius here
                        '& fieldset': {
                          borderColor: '#dfe1e5', // Customize border color here
                        },
                        '&:hover fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#dfe1e5',
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" sx={{ marginTop: '15px' }}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography variant="h6" sx={{ textAlign: 'center' }}>
                Comments (if any)
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <TextField
                id="comments"
                value=""
                type="text"
                multiline
                rows={4}
                placeholder="Write comments (if any)"
                size="small"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '0.3rem',
                  marginBottom: '3px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '20px',
                    '& fieldset': {
                      borderColor: '#dfe1e5',
                    },
                    '&:hover fieldset': {
                      borderColor: '#dfe1e5',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#dfe1e5',
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" marginTop="20px">
            <Button
              sx={{
                background: '#A3A3A3',
                color: '#fff',
                height: '45px',
                border: '1px solid #A3A3A3',
                '&:hover': {
                  background: '#A3A3A3',
                  color: '#fff',
                },
                borderRadius: '15px',
                padding: '25px',
                marginLeft: '20px',
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                background: '#00C1FE',
                color: '#fff',
                height: '45px',
                border: '1px solid #00C1FE',
                '&:hover': {
                  background: '#00C1FE',
                  color: '#fff',
                },
                borderRadius: '15px',
                padding: '25px',
                marginLeft: '20px',
              }}
            >
              Associate Now
            </Button>
          </Box>
          <br />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AssociateCandidateToOtherRoleDialog;
