import { Icon } from '@iconify/react';
import { ArrowBack } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function CallHistory(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const previousPath = location.state?.previousPath || `/recruiter/app`;
  const data = [
    {
      name: 'Alan Milne',
      date: 'Oct 7, 2019',
      title: 'Supervisor',
      time: '56 mints 56 secs',
      event_name: 'Call clients on JOb Opening',
      type: 'Outbound',
    },
    {
      name: 'Alan Milne',
      date: 'Oct 7, 2019',
      title: 'Supervisor',
      time: '56 mints 56 secs',
      event_name: 'Call clients on JOb Opening',
      type: 'Outbound',
    },
    {
      name: 'Alan Milne',
      date: 'Oct 7, 2019',
      title: 'Supervisor',
      time: '56 mints 56 secs',
      event_name: 'Call clients on JOb Opening',
      type: 'Outbound',
    },
    {
      name: 'Alan Milne',
      date: 'Oct 7, 2019',
      title: 'Supervisor',
      time: '56 mints 56 secs',
      event_name: 'Call clients on JOb Opening',
      type: 'Outbound',
    },
    {
      name: 'Alan Milne',
      date: 'Oct 7, 2019',
      title: 'Supervisor',
      time: '56 mints 56 secs',
      event_name: 'Call clients on JOb Opening',
      type: 'Outbound',
    },
    {
      name: 'Alan Milne',
      date: 'Oct 7, 2019',
      title: 'Supervisor',
      time: '56 mints 56 secs',
      event_name: 'Call clients on JOb Opening',
      type: 'Outbound',
    },
  ];
  return (
    <div style={{ width: '97%', margin: 'auto' }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <ArrowBack
          fontSize="small"
          onClick={() => {
            navigate(previousPath);
          }}
          sx={{
            color: '#00C1FE',
            cursor: 'pointer',
          }}
        />
        &nbsp;&nbsp;{' '}
        <Typography
          variant="subtitle2"
          sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}
          onClick={() => {
            navigate(previousPath);
          }}
        >
          Back
        </Typography>
      </Box>
      <br />
      <Box sx={{ backgroundColor: 'white', padding: '10px', borderRadius: 4, height: 'auto', marginTop: '15px' }}>
        <Grid container spacing={2} sx={{ margin: 'auto', width: '99%' }}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box
              sx={{
                position: 'relative', // Important to position the pseudo-element relative to this Box
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '15px',
                borderRadius: '20px',
                backgroundColor: '#ECF3FF',
                '&::after': {
                  content: '""', // Empty content for the pseudo-element
                  position: 'absolute',
                  top: '50%', // This will center it vertically
                  transform: 'translateY(-50%)', // Adjusts so it starts exactly in the middle
                  right: '0', // Align to the right edge
                  width: '6px', // Border thickness
                  height: '60%', // Set the specific height here (50% or any value you want)
                  backgroundColor: '#00C1FE', // Border color
                  borderRadius: '0px 20px 20px 0px', // Adjust if needed to match the Box border-radius
                },
              }}
            >
              <Typography variant="h6" sx={{ textTransform: 'none' }}>
                Placed Candidates
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: '10px',
                backgroundColor: '#ECF3FF',
                borderRadius: '20px',
                padding: '15px',
                height: '100vh',
              }}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" sx={{ textTransform: 'none', color: '#9AA3B1' }}>
                No activities in this category
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box
              sx={{
                position: 'relative', // Important to position the pseudo-element relative to this Box
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '15px',
                borderRadius: '20px',
                backgroundColor: '#ECF3FF',
                '&::after': {
                  content: '""', // Empty content for the pseudo-element
                  position: 'absolute',
                  top: '50%', // This will center it vertically
                  transform: 'translateY(-50%)', // Adjusts so it starts exactly in the middle
                  right: '0', // Align to the right edge
                  width: '6px', // Border thickness
                  height: '60%', // Set the specific height here (50% or any value you want)
                  backgroundColor: '#00C1FE', // Border color
                  borderRadius: '0px 20px 20px 0px', // Adjust if needed to match the Box border-radius
                },
              }}
            >
              <Typography variant="h6" sx={{ textTransform: 'none' }}>
                Job Opening
              </Typography>
            </Box>

            {data?.map((item, index) => (
              <Box
                key={index} // Always use a unique key when mapping arrays
                sx={{
                  marginTop: '10px',
                  backgroundColor: '#ECF3FF',
                  borderRadius: '20px',
                  padding: '15px',
                }}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                {/* Event Name */}
                <Box display="flex" alignItems="center">
                  <Icon icon="material-symbols-light:call-sharp" style={{ fontSize: '20px' }} />
                  <Typography variant="subtitle1" sx={{ textTransform: 'none', marginLeft: '10px' }}>
                    {item.event_name}
                  </Typography>
                </Box>

                {/* Date */}
                <p style={{ fontSize: '14px', color: '#9AA3B1' }}>{item.date}</p>

                {/* Title and Time */}
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography variant="subtitle2" sx={{ textTransform: 'none', marginLeft: '10px' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="caption" sx={{ textTransform: 'none', marginLeft: '10px' }}>
                    {item.time}
                  </Typography>
                </Box>

                {/* Name and Type */}
                <Box display="flex" alignItems="center" justifyContent="flex-start">
                  <Typography variant="subtitle2" sx={{ textTransform: 'none', marginLeft: '10px' }}>
                    {item.name}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ textTransform: 'none', marginLeft: '30px' }}>
                    {item.type}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box
              sx={{
                position: 'relative', // Important to position the pseudo-element relative to this Box
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '15px',
                borderRadius: '20px',
                backgroundColor: '#ECF3FF',
                '&::after': {
                  content: '""', // Empty content for the pseudo-element
                  position: 'absolute',
                  top: '50%', // This will center it vertically
                  transform: 'translateY(-50%)', // Adjusts so it starts exactly in the middle
                  right: '0', // Align to the right edge
                  width: '6px', // Border thickness
                  height: '60%', // Set the specific height here (50% or any value you want)
                  backgroundColor: '#00C1FE', // Border color
                  borderRadius: '0px 20px 20px 0px', // Adjust if needed to match the Box border-radius
                },
              }}
            >
              <Typography variant="h6" sx={{ textTransform: 'none' }}>
                Prospective Candidates
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: '10px',
                backgroundColor: '#ECF3FF',
                borderRadius: '20px',
                padding: '15px',
                height: '100vh',
              }}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" sx={{ textTransform: 'none', color: '#9AA3B1' }}>
                No activities in this category
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <br/>
      </Box>
    </div>
  );
}

export default CallHistory;
