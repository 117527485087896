import { Icon } from '@iconify/react';
import { Box, Button, Checkbox, Grid, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function CandidateCard({ onShowDetails, index, candidate }) {
  const [recentExperience, setRecentExperience] = useState('experience');
  const [status, setStatus] = useState('current');
  const navigate = useNavigate();
  const { roleId } = useParams();

  const parseSalary = (looking_for) => {
    if (!looking_for || looking_for.length === 0) return 'Salary not available';

    try {
      const parsedData = JSON.parse(looking_for[0]); // Parse the first element
      return parsedData.salary; // Return the salary value
    } catch (error) {
      console.error('Error parsing salary:', error);
      return 'Invalid salary data';
    }
  };
  const parseJobType = (looking_for) => {
    if (!looking_for || looking_for.length === 0) return 'Salary not available';

    try {
      const parsedData = JSON.parse(looking_for[0]); // Parse the first element
      return parsedData.job_type; // Return the salary value
    } catch (error) {
      console.error('Error parsing salary:', error);
      return 'Invalid salary data';
    }
  };

  const parseWorkHistory = (work_history) => {
    // "{"headline":"Talent Acquisition PartneratLloydsPharmacy","title":"Talent Acquisition Partner","company":"LloydsPharmacy","date":"Oct 2021","responsibilities":""}"
    try {
      const parsedData = JSON.parse(work_history); // Parse the first element
      const headline = parsedData.headline;
      const title = parsedData.title;
      const company = parsedData.company;
      const date = parsedData.date;
      const responsibilities = parsedData.responsibilities;
      const workHistory = {
        headline,
        title,
        company,
        date,
        responsibilities,
        workHistory: title + ' at ' + company + ' (' + date + ')',
      };

      return workHistory; // Return the salary value
    } catch (error) {
      console.error('Error parsing salary:', error);
      return 'Invalid salary data';
    }
  }

  return (
    <div style={{ width: '100%', backgroundColor: 'white', height: 'auto' }}>
      <Box display="flex" justifyContent="space-between" marginTop="5px" sx={{ borderRadius: '10px' }} padding="1rem">
        <Box display="flex" alignItems="center">
          <Checkbox
            sx={{
              color: 'black',
              fontSize: '20px',
              '&.Mui-checked': {
                color: '#00C1FE', // Set checkbox color to match the theme when checked
              },
            }}
          />
          <p
            style={{ fontSize: '22px', marginLeft: '10px', color: '#00C1FE', cursor: 'pointer' }}
            onClick={() => navigate(`/resourcing/candidateDetail/${roleId}/1`)}
          >
            {index + 1}. {candidate?.full_name}
          </p>
        </Box>
        <Box display="flex" justifyContent="space-between" width="21%">
          <Button
            sx={{
              backgroundColor: '#E1F7FC',
              height: '34px',
              width: '8px',
              '&:hover': {
                backgroundColor: '#E1F7FC',
              },
              borderRadius: '5px',
            }}
          >
            <Icon icon="iconoir:arrow-email-forward" style={{ fontSize: '22px', fontWeight: 'bold' }} />
          </Button>
          <Button
            sx={{
              backgroundColor: '#E1F7FC',
              height: '34px',
              width: '8px',
              '&:hover': {
                backgroundColor: '#E1F7FC',
              },
              borderRadius: '5px',
            }}
          >
            <Tooltip title="Download CV" arrow placement="top">
              <Icon icon="material-symbols-light:download-sharp" style={{ fontSize: '24px', fontWeight: 'bold' }} />
            </Tooltip>
          </Button>
          <Button
            sx={{
              backgroundColor: '#E1F7FC',
              height: '34px',
              width: '8px',
              '&:hover': {
                backgroundColor: '#E1F7FC',
              },
              borderRadius: '5px',
            }}
          >
            <Tooltip title="Save " arrow placement="top">
              <Icon icon="material-symbols:save-sharp" style={{ fontSize: '22px', fontWeight: 'bold' }} />
            </Tooltip>
          </Button>
        </Box>
      </Box>
      <p
        style={{
          fontSize: '20px',
          marginLeft: '60px',
          color: '#6E7787',
          verticalAlign: 'sub',
          fontSize: '14px',
          marginTop: '-15px',
        }}
      >
        {candidate?.current_title} | {candidate?.location}
      </p>
      <hr style={{ width: '100%', color: '#CAC5C5' }} />
      <Box display="flex" marginTop="10px" marginLeft="20px" alignItems="center" padding="8px">
        <Icon icon="lets-icons:user-fill" style={{ fontSize: '18px' }} />
        <p style={{ fontSize: '14px', marginLeft: '10px' }}>Active 10 months ago</p>
        <Icon icon="material-symbols:save-sharp" style={{ fontSize: '18px', marginLeft: '10px' }} />
        <p style={{ fontSize: '14px', marginLeft: '10px' }}>Updated 11 months ago</p>
      </Box>
      <hr style={{ width: '100%', color: '#CAC5C5' }} />
      <Grid container spacing={2} sx={{ height: '80%', overflow: 'hidden' }}>
        <Grid item xs={12} sm={12} md={7} lg={7}>
          <Box display="flex" flexDirection="column">
            <ToggleButtonGroup
              value={recentExperience}
              exclusive
              onChange={(e, value) => {
                if (value !== null) {
                  setRecentExperience(value);
                }
              }}
              aria-label="company sections"
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                marginLeft: '20px',
                marginBottom: '10px', // Add margin bottom for spacing
              }}
            >
              <ToggleButton
                value="experience"
                aria-label="experience"
                style={{
                  border: 'none',
                  color: recentExperience === 'experience' ? '#00C1FE' : 'black',
                  borderBottom: recentExperience === 'experience' ? '3px solid #00C1FE' : 'none',
                  borderRadius: 0,
                  paddingBottom: 2,
                  backgroundColor: 'white',
                  fontSize: '14px',
                  fontWeight: 'normal',
                }}
              >
                Recent Experience
              </ToggleButton>
              <ToggleButton
                value="snippets"
                aria-label="snippets"
                style={{
                  border: 'none',
                  color: recentExperience === 'snippets' ? '#00C1FE' : 'black',
                  borderBottom: recentExperience === 'snippets' ? '3px solid #00C1FE' : 'none',
                  borderRadius: 0,
                  paddingBottom: 2,
                  width: '180px',
                  marginLeft: '10px',
                  backgroundColor: 'white',
                  fontSize: '14px',
                  fontWeight: 'normal',
                }}
              >
                Other CV Snippets
              </ToggleButton>
            </ToggleButtonGroup>
            <hr
              style={{
                width: '50%',
                marginLeft: '20px',
                color: '#CAC5C5',
                border: 'none',
                borderTop: '1px solid #CAC5C5',
                marginTop: '-10px',
              }}
            />
          </Box>
          {recentExperience === 'experience' && (
            <Box display="flex" marginLeft="20px" justifyContent="space-between" marginTop="10px">
              <p style={{ fontSize: '16px', marginLeft: '10px' }}>
                {parseWorkHistory(candidate?.work_history[0])?.title}
              </p>
              <p style={{ fontSize: '16px', marginLeft: '10px' }}>
                {parseWorkHistory(candidate?.work_history[0])?.date}
              </p>
            </Box>
          )}
          {recentExperience === 'snippets' && (
            <Box
              display="flex"
              marginLeft="20px"
              justifyContent="space-between"
              flexDirection="column"
              marginTop="10px"
            >
              {candidate?.work_history.map((work, index) => (
                <>
                  <Box key={index} display="flex" justifyContent="space-between" width="100%">
                    <p style={{ fontSize: '16px', marginLeft: '10px', color: 'blue' }}>
                      {parseWorkHistory(work)?.title}
                    </p>
                    <p style={{ fontSize: '16px', marginLeft: '10px', color: 'blue' }}>
                      {parseWorkHistory(work)?.date}
                    </p>
                  </Box>
                  <hr style={{ width: '100%', color: '#CAC5C5' }} />
                  {/* resposibilities */}
                  <p
                    style={{
                      fontSize: '14px',
                      marginLeft: '10px',
                      color: '#6E7787',
                      marginTop: '10px',
                    }}
                  >
                    {parseWorkHistory(work)?.responsibilities}
                  </p>
                </>
              ))}
            </Box>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          sx={{
            borderLeft: '1px solid #E2DDDD',
            marginLeft: '1rem',
          }}
        >
          <ToggleButtonGroup
            value={status}
            exclusive
            onChange={(e, value) => {
              if (value !== null) {
                setStatus(value);
              }
            }}
            aria-label="company sections"
            style={{
              display: 'flex',
              width: '390px',
              justifyContent: 'flex-start',
              marginLeft: '20px',
              marginBottom: '10px', // Add margin bottom for spacing
            }}
          >
            <ToggleButton
              value="current"
              aria-label="current"
              style={{
                border: 'none',
                color: status === 'current' ? '#00C1FE' : 'black',
                borderBottom: status === 'current' ? '3px solid #00C1FE' : 'none',
                borderRadius: 0,
                paddingBottom: 2,
                width: '180px',
                backgroundColor: 'white',
                fontSize: '14px',
                fontWeight: 'normal',
              }}
            >
              Current Status
            </ToggleButton>
            <ToggleButton
              value="desired"
              aria-label="desired"
              style={{
                border: 'none',
                color: status === 'desired' ? '#00C1FE' : 'black',
                borderBottom: status === 'desired' ? '3px solid #00C1FE' : 'none',
                borderRadius: 0,
                paddingBottom: 2,
                width: '180px',
                marginLeft: '10px',
                backgroundColor: 'white',
                fontSize: '14px',
                fontWeight: 'normal',
              }}
            >
              Desired role
            </ToggleButton>
            <ToggleButton
              value="skils"
              aria-label="skils"
              style={{
                border: 'none',
                color: status === 'skils' ? '#00C1FE' : 'black',
                borderBottom: status === 'skils' ? '3px solid #00C1FE' : 'none',
                borderRadius: 0,
                paddingBottom: 2,
                width: '180px',
                marginLeft: '10px',
                backgroundColor: 'white',
                fontSize: '14px',
                fontWeight: 'normal',
              }}
            >
              Key Skills
            </ToggleButton>
          </ToggleButtonGroup>
          {status === 'current' && (
            <>
              <Box display="flex" alignItems="center" marginLeft="20px">
                <Icon icon="carbon:location" style={{ fontSize: '20px' }} />
                <p style={{ fontSize: '14px', marginLeft: '10px' }}>{candidate?.location}</p>
              </Box>
              <Box display="flex" justifyContent="space-between" width="70%" marginLeft="25px" marginTop="5px">
                <p style={{ fontSize: '12px', marginLeft: '10px' }}>N/A mins</p>
                <Icon icon="mingcute:car-fill" style={{ marginLeft: '10px' }} />
                <p style={{ fontSize: '12px', marginLeft: '5px' }}>27 mins</p>
                <Icon icon="majesticons:bus" style={{ marginLeft: '10px' }} />
                <p style={{ fontSize: '12px', marginLeft: '5px' }}>52 mins</p>
              </Box>
              <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
                <Icon icon="fluent-mdl2:money" style={{ fontSize: '20px' }} />
                <p style={{ fontSize: '14px', marginLeft: '5px' }}>{parseSalary(candidate?.looking_for)}</p>
              </Box>

              <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
                <Icon icon="material-symbols-light:balance" style={{ fontSize: '20px' }} />
                <p style={{ fontSize: '14px', marginLeft: '5px' }}> 12 months experience </p>
              </Box>
              <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
                <Icon icon="mdi:tick-circle-outline" style={{ fontSize: '20px' }} />
                <p style={{ fontSize: '14px', marginLeft: '5px' }}> Eligible to work in UK </p>
              </Box>
              <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
              <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
                <p style={{ fontSize: '16px' }}> Contact Details</p>
              </Box>
              <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
                <Icon icon="fa6-regular:user" style={{ fontSize: '12px' }} />
                <p style={{ fontSize: '14px', marginLeft: '5px' }}>
                  {' '}
                  Alan Milne ( <span style={{ color: '#00C1FE' }}>View Profile</span>){' '}
                </p>
              </Box>
              <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
                <Icon icon="ic:outline-email" style={{ fontSize: '16px' }} />
                <p style={{ fontSize: '14px', marginLeft: '5px', color: '#00C1FE' }}> alan@gmail.com </p>
              </Box>
              <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
                <Icon icon="bi:phone" style={{ fontSize: '16px' }} />
                <p style={{ fontSize: '14px', marginLeft: '5px' }}> +44 4444 4444 </p>
              </Box>
            </>
          )}
          {status === 'desired' && (
            <>
              <Box display="flex" marginLeft="20px" marginTop="15px" alignItems="center">
                <Icon icon="uil:suitcase-alt" style={{ fontSize: '18px' }} />
                <p style={{ fontSize: '14px', marginLeft: '5px' }}> {candidate?.desired_title || 'N/A'} </p>
              </Box>
              <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
                <Icon icon="carbon:location" style={{ fontSize: '18px' }} />
                <p style={{ fontSize: '14px', marginLeft: '5px' }}> {candidate?.preferred_locations[0]} </p>
              </Box>
              <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
                <Icon icon="la:money-bill" style={{ fontSize: '18px' }} />
                <p style={{ fontSize: '14px', marginLeft: '5px' }}> {candidate?.expected_salary} </p>
              </Box>
              <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
                <Icon icon="clarity:contract-line" style={{ fontSize: '18px' }} />
                <p style={{ fontSize: '14px', marginLeft: '5px' }}>
                  {parseJobType(candidate?.looking_for)?.split(',')[0]}
                </p>
              </Box>

              <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
                <Icon icon="carbon:time" style={{ fontSize: '18px' }} />
                <p style={{ fontSize: '14px', marginLeft: '5px' }}>
                  {parseJobType(candidate?.looking_for)?.split(', ')[1]}
                </p>
              </Box>
            </>
          )}
          {status === 'skils' && (
            <>
              <p style={{ fontSize: '14px', fontWeight: 'bold', marginRight: '10px' }}>Skills:</p>
              <Box display="flex" marginLeft="20px" marginTop="15px" flexWrap="wrap" alignItems="center">
                {candidate?.skills?.map((skill, index) => (
                  <Box
                    key={index}
                    sx={{
                      backgroundColor: '#e0e0e0',
                      color: '#333',
                      padding: '5px 10px',
                      borderRadius: '15px',
                      margin: '5px',
                      fontSize: '13px',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    {skill}
                  </Box>
                ))}
              </Box>
            </>
          )}
        </Grid>
      </Grid>

      <br />
    </div>
  );
}

export default CandidateCard;
