import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

function AddJobBoardDialog({ open, onClose, onSubmit, jobBoards }) {
  const [name, setName] = useState();
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        hideBackdrop={false}
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{
          sx: {
            width: '100%',
          },
        }}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#E1F7FC',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" className="ml-8">
              Add Job Board
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <br />
          <TextField
            required
            id="name"
            name="name"
            value={name}
            type="string"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter name of Job Board"
            size="large"
            fullWidth
            sx={{
              backgroundColor: 'white',
              borderRadius: '0.3rem',
              marginBottom: '15px',
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  borderColor: '#00C1FE',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#00C1FE',
                },
              },
              '& .MuiInputBase-root': {
                '&.Mui-focused': {
                  boxShadow: '0 0 0 1px #00C1FE',
                },
              },
            }}
          />

          <Box display="flex" justifyContent="flex-end" marginTop="1rem">
            <Button
              onClick={onClose}
              size="medium"
              sx={{
                backgroundColor: '#A3A3A3',
                fontSize: '16px',
                color: 'white',
                marginLeft: '1rem',
                '&:hover': {
                  color: 'white !important',
                  backgroundColor: '#A3A3A3 !important',
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => onSubmit(name)}
              size="medium"
              sx={{
                backgroundColor: '#0ec4fe',
                fontSize: '16px',
                color: 'white',
                marginLeft: '1rem',
                '&:hover': {
                  color: 'white !important',
                  backgroundColor: '#0ec4fe !important',
                },
              }}
            >
              Add Job Board
            </Button>
          </Box>
          {jobBoards?.length > 0 && (
            <>
              <Typography variant="h6">List of added Job Boards</Typography>
              <Box marginTop="15px" marginLeft="1rem">
                <ul style={{ fontWeight: '600', listStyleType: 'disc', paddingLeft: '20px', color: '#0ec4fe' }}>
                  {jobBoards?.map((jobBoard, index) => {
                    return (
                      <li key={index} style={{ paddingBottom: '10px', color: 'black' }}>
                        {jobBoard?.name}
                      </li>
                    );
                  })}
                </ul>
              </Box>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddJobBoardDialog;
