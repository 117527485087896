import { ArrowBack } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import MaterialReactTable from 'material-react-table';
import React, { useCallback, useEffect, useState } from 'react';
import { Get, Post } from 'src/actions/API/apiActions';
import { notify } from 'src/components/notify';
import ViewRolesIssues from './ViewRolesIssues';
import ViewResolvedIssueRoles from './ViewResolvedIssueRoles';
import { API_URLS } from 'src/constants/apiURLs';
import MultiDatePicker from 'src/components/MultiDatePicker';
import { calculateTimeTaken, parseJobBoards } from 'src/helpers';
// import { Post_GetResourcerRoles_URL } from 'src/constants/apiURLs';
// import { ViewResolvedIssueRoles, ViewRolesIssues } from '.';

function IndividualReportDialog({ open, onClose, resourcer }) {
  console.log('resourcer', resourcer);
  const [roleDate, setRoleDate] = useState(null);
  const [roles, setRoles] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);

  const columns = [
    {
      accessorKey: 'client_number',
      header: 'Client Number',
    },
    {
      accessorKey: 'title',
      header: 'Title',
    },
    {
      accessorKey: 'status',
      header: 'Status',
    },
    {
      accessorKey: 'cvs_submitted',
      header: 'CVs Submitted',
    },
    {
      accessorKey: 'lis_submitted',
      header: 'LIs Submitted',
    },
    { accessorKey: 'timeTaken', header: 'Time Taken' },
    {
      accessorKey: 'jobBoardDetails',
      header: 'Job Boards Used',
      Cell: ({ row }) => (
        <div>
          {row?.original?.jobBoardDetails?.split(', ').map((detail, index) => (
            <div key={index}>{detail}</div>
          ))}
        </div>
      ),
    },
    {
      accessorKey: 'reason_of_less_li_profiles',
      header: 'Reason of Less LI Profiles',
    },
    {
      accessorKey: 'reason_of_less_cvs',
      header: 'Reason of Less CVs',
    },
    {
      accessorKey: 'role_months_back',
      header: 'Months Back',
    },
    {
      accessorKey: 'reworking_reason',
      header: 'Reworking Reason',
    },
    {
      accessorKey: 'leave_reason',
      header: 'Leave Reason',
    },
    {
      accessorKey: 'feedback',
      header: 'Client Feedback',
    },
    // {
    //   accessorKey: 'queries',
    //   header: 'Queries',
    // },
    // {
    //   accessorKey: 'suggestions',
    //   header: 'Suggestions',
    // },
    // {
    //   accessorKey: 'info',
    //   header: 'Info',
    // },
    // {
    //   accessorKey: 'issues',
    //   header: 'Issues',
    // },
  ];


  const getResourcerRoles = useCallback(() => {
    try {
      setLoading(true);
      Get(
        {
          resourcerId: resourcer?.id,
          startDate: roleDate[0],
          endDate: roleDate[1],
          page: pagination.pageIndex,
          pageSize: pagination.pageSize,
          searchString: '',
        },
        API_URLS.getResourcerIndividualSheet,
        (resp) => {
          const data = resp?.data?.data?.map((role) => ({
            ...role,
            timeTaken: calculateTimeTaken(role.createdAt, role.updatedAt),
            jobBoardDetails: parseJobBoards(role?.job_board_used ?? []),
          }));
          setRoles(data);
          setLoading(false);
          setTotalRecords(resp?.data?.totalRecords);
        },
        (error) => {
          notify('Failed to retrieve roles', 'error', 1000);
          setLoading(false);
        }
      );
    } catch (error) {
      notify('Failed to retrieve roles', 'error', 1000);
      setLoading(false);
    }
  }, [resourcer, roleDate, pagination]);

  useEffect(() => {
    getResourcerRoles();
  }, [getResourcerRoles]);

  // function getMonthName(dateString) {
  //   const dateArray = dateString.split('-');
  //   const month = parseInt(dateArray[1]) - 1;
  //   const dateObj = new Date(dateArray[0], month);
  //   return dateObj.toLocaleString('default', { month: 'long' });
  // }

  console.log('jkagvfhisdaf', roles);
  return (
    <div>
      <Dialog open={open} onClose={onClose} fullScreen>
        <DialogTitle
          sx={{
            backgroundColor: '#F4F9FD',
          }}
        >
          <Box
            display="flex"
            sx={{
              width: 'calc(100%)',
              backgroundColor: '#D9EEFF',
            }}
          >
            <IconButton onClick={onClose}>
              <ArrowBack />
            </IconButton>
            <Typography
              variant="h4"
              sx={{
                padding: '15px',
                marginLeft: '5px',
              }}
            >
              {resourcer?.first_name + ' ' + resourcer?.last_name + ' Work Details'}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          {/* <Grid container spacing={2} sx={{ marginTop: '10px' }}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box display="flex">
                <Button
                  sx={{
                    fontSize: '16px',
                    borderRadius: '10px',
                    borderWidth: '3px',
                    height: '50px',
                    color: 'white',
                    width: '15ch',
                    backgroundColor: '#57CC99',
                    marginLeft: '10px',
                    '&:hover': {
                      color: 'white !important',
                      borderColor: '#57CC99 !important',
                      backgroundColor: '#57CC99 !important',
                    },
                  }}
                  size="small"
                  onClick={() => {
                    setCurrentMonth(true);
                    setPreviousMonth(false);
                    setRoleDate(null);
                  }}
                >
                  Current Month
                </Button>
                <Button
                  sx={{
                    fontSize: '16px',
                    borderRadius: '10px',
                    borderWidth: '3px',
                    height: '50px',
                    color: 'white',
                    width: '15ch',
                    backgroundColor: '#0ec4fe',
                    marginLeft: '10px',
                    '&:hover': {
                      color: 'white !important',
                      borderColor: '#0ec4fe !important',
                      backgroundColor: '#0ec4fe !important',
                    },
                  }}
                  size="small"
                  onClick={() => {
                    setPreviousMonth(true);
                    setCurrentMonth(false);
                    setRoleDate(null);
                  }}
                >
                  Previous Months
                </Button>
                <TextField
                  type="date"
                  value={roleDate}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      border: '2px solid #0EC4FE',
                      width: '16ch',
                      marginLeft: '10px',
                      height: '50px',
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                      },
                    },
                  }}
                  size="small"
                  onChange={(e) => {
                    setRoleDate(new Date(e.target.value).toISOString().split('T')[0]);
                    setCurrentMonth(false);
                    setPreviousMonth(false);
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  sx={{
                    fontSize: '16px',
                    borderRadius: '10px',
                    borderWidth: '3px',
                    height: '50px',
                    color: 'white',
                    width: '15ch',
                    backgroundColor: '#E95A5A',
                    '&:hover': {
                      color: 'white !important',
                      borderColor: '#E95A5A !important',
                      backgroundColor: '#E95A5A !important',
                    },
                  }}
                  size="small"
                  onClick={() => {
                    setOpenViewIssuesDialog(true);
                    //filter the roles where status is issue
                    const issueRoles = roles.filter((role) => role.roleStatus === 'issue');
                    setIssueRoles(issueRoles);
                  }}
                >
                  Total Issues : {resourcerRoleStats?.totalIssues}
                </Button>
                <Button
                  sx={{
                    fontSize: '16px',
                    borderRadius: '10px',
                    borderWidth: '3px',
                    height: '50px',
                    color: 'white',
                    width: '16ch',
                    backgroundColor: '#57CC99',
                    marginLeft: '10px',
                    '&:hover': {
                      color: 'white !important',
                      borderColor: '#57CC99 !important',
                      backgroundColor: '#57CC99 !important',
                    },
                  }}
                  size="small"
                  onClick={() => {
                    setOpenViewResolvedIssuesDialog(true);
                    const resolvedRoles = roles.filter((role) => role.roleStatus === 'resolved');
                    setResolvedRoles(resolvedRoles);
                  }}
                >
                  Resolved Issues : {resourcerRoleStats?.resolved}
                </Button>
                <Button
                  sx={{
                    fontSize: '16px',
                    borderRadius: '10px',
                    borderWidth: '3px',
                    height: '50px',
                    color: 'white',
                    width: '18ch',
                    backgroundColor: '#0ec4fe',
                    marginLeft: '10px',
                    marginRight: '30px',
                    '&:hover': {
                      color: 'white !important',
                      borderColor: '#0ec4fe !important',
                      backgroundColor: '#0ec4fe !important',
                    },
                  }}
                  size="small"
                  onClick={() => setOpenViewIssuesDialog(true)}
                >
                  Remaining Issues : {resourcerRoleStats?.remaining}
                </Button>
              </Box>
            </Grid>
          </Grid> */}
          <MultiDatePicker
            onChange={(dates) => {
              console.log('dates', dates);
              setRoleDate(dates);
            }}
            height="50px"
            width="100%"
          />

          <MaterialReactTable
            columns={columns}
            data={roles}
            isLoading={isLoading}
            manualPagination
            rowCount={totalRecords}
            state={{
              pagination,
            }}
            onPaginationChange={(newPagination) => setPagination(newPagination)}
            muiTablePaginationProps={{
              rowsPerPageOptions: [10, 30, 50, 100],
              showFirstButton: true,
              showLastButton: true,
            }}
          />
          {/* {previousMonth === false && (
            <MaterialReactTable
              displayColumnDefOptions={{
                'mrt-row-actions': {
                  muiTableHeadCellProps: {
                    align: 'center',
                  },
                  size: 50,
                },
              }}
              columns={columns}
              data={roles}
              defaultColumn={{
                size: 100, // make columns wider by default
              }}
              enableColumnActions={false}
              enableColumnFilters={false}
              enableSorting={false}
              enableTopToolbar={false}
              positionToolbarAlertBanner="bottom"
              manualFiltering
              state={{
                isLoading: loading,
              }}
              actionsColumnIndex={-1}
              getRowProps={(row) => {
                return {
                  style: {
                    backgroundColor: row.original.roleStatus === 'issue' ? '#f44336' : 'inherit',
                  },
                };
              }}
            />
          )}

          <br />
          {previousMonth === true && (
            <>
              {resourcerRoleStats?.monthData &&
                Object.keys(resourcerRoleStats?.monthData)?.map((month) => (
                  <div key={month}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                      <Box>
                        <h2
                          style={{
                            fontSize: '16px',
                            borderRadius: '10px',
                            borderWidth: '3px',
                            height: '45px',
                            color: 'white',
                            width: '11ch',
                            backgroundColor: '#0ec4fe',
                            marginLeft: '10px',
                            marginRight: '30px',
                            borderColor: '#0ec4fe',
                            padding: '5px',
                            borderStyle: 'solid', // You need
                            borderStyle: 'solid', // You need to specify the border style to see the border
                          }}
                        >
                          {getMonthName(month)} 2024
                        </h2>
                      </Box>

                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          sx={{
                            fontSize: '14px',
                            borderRadius: '10px',
                            borderWidth: '3px',
                            border: '2px solid #E95A5A',
                            height: '45px',
                            color: '#E95A5A',
                            width: '15ch',
                            '&:hover': {
                              borderColor: '#E95A5A !important',
                              color: '#E95A5A !important',
                            },
                          }}
                          size="small"
                          color="error"
                          onClick={() => {
                            setOpenViewIssuesDialog(true);
                            const issueRoles = resourcerRoleStats?.monthData[month]?.roles.filter(
                              (role) => role.roleStatus === 'issue'
                            );
                            setIssueRoles(issueRoles);
                          }}
                        >
                          Total Issues : {resourcerRoleStats?.monthData[month]?.totalIssues}
                        </Button>
                        <Button
                          sx={{
                            fontSize: '14px',
                            borderRadius: '10px',
                            borderWidth: '3px',
                            border: '2px solid #57CC99',
                            height: '45px',
                            color: '#57CC99',
                            marginLeft: '10px',
                            width: '18ch',
                            '&:hover': {
                              borderColor: '#57CC99 !important',
                              color: '#57CC99 !important',
                            },
                          }}
                          size="small"
                          color="success"
                          onClick={() => {
                            setOpenViewResolvedIssuesDialog(true);
                            const resolvedRoles = resourcerRoleStats?.monthData[month]?.roles.filter(
                              (role) => role.roleStatus === 'resolved'
                            );
                            setResolvedRoles(resolvedRoles);
                          }}
                        >
                          Resolved Issues : {resourcerRoleStats?.monthData[month]?.resolved}
                        </Button>
                        <Button
                          sx={{
                            fontSize: '14px',
                            borderRadius: '10px',
                            borderWidth: '3px',
                            border: '2px solid #0ec4fe',
                            height: '45px',
                            color: '#0ec4fe',
                            marginLeft: '10px',
                            width: '18ch',
                            '&:hover': {
                              borderColor: '#0ec4fe !important',
                              color: '#0ec4fe !important',
                            },
                          }}
                          size="small"
                          color="primary"
                          onClick={() => {
                            setOpenViewIssuesDialog(true);
                            const issueRoles = resourcerRoleStats?.monthData[month]?.roles.filter(
                              (role) => role.roleStatus === 'issue'
                            );
                            setIssueRoles(issueRoles);
                          }}
                        >
                          Remaining Issues : {resourcerRoleStats?.monthData[month]?.remaining}
                        </Button>
                      </Box>
                    </div>

                    <MaterialReactTable
                      displayColumnDefOptions={{
                        'mrt-row-actions': {
                          muiTableHeadCellProps: {
                            align: 'center',
                          },
                          size: 50,
                        },
                      }}
                      columns={columns}
                      data={roles}
                      defaultColumn={{
                        size: 100, // make columns wider by default
                      }}
                      enableColumnActions={false}
                      enableColumnFilters={false}
                      enableSorting={false}
                      enableTopToolbar={false}
                      positionToolbarAlertBanner="bottom"
                      manualFiltering
                      state={{
                        isLoading: loading,
                      }}
                      actionsColumnIndex={-1}
                      getRowProps={(row) => {
                        return {
                          style: {
                            backgroundColor: row.original.roleStatus === 'issue' ? '#f44336' : 'inherit',
                          },
                        };
                      }}
                    />
                  </div>
                ))}
            </>
          )} */}
        </DialogContent>
      </Dialog>

      {/* <ViewRolesIssues open={openViewIssuesDialog} onClose={() => setOpenViewIssuesDialog(false)} roles={issueRoles} />
      <ViewResolvedIssueRoles
        open={openViewResolvedIssuesDialog}
        onClose={() => setOpenViewResolvedIssuesDialog(false)}
        roles={resolvedRoles}
      /> */}
    </div>
  );
}

export default IndividualReportDialog;