import React from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Typography } from '@mui/material';

function ClientRolesTable({ clientRolesWorking }) {
    console.log("Client Roles Working Data:", clientRolesWorking);
    
    const columns = [
        {
            accessorKey: 'start_date',
            header: 'Start Date',
            Cell: ({ cell }) => new Date(cell?.getValue())?.toLocaleDateString(),
        },
        {
            accessorKey: 'role_type',
            header: 'Role Type',
        },
        {
            accessorKey: 'roles_working_role.title',
            header: 'Role',
        },
        {
            accessorKey: 'acm_name',
            header: 'ACM Name',
        },
        {
            accessorKey: 'status',
            header: 'Status',
        },
        {
            accessorKey: 'working_count',
            header: 'Working Count',
        },
        {
            accessorKey: 'role_date',
            header: 'Role Start Date',
            Cell: ({ cell }) => new Date(cell?.getValue())?.toLocaleDateString(),
        },
        {
            accessorKey: 'role_end_date',
            header: 'Role End Date',
            Cell: ({ cell }) => cell?.getValue() ? new Date(cell?.getValue())?.toLocaleDateString() : 'Ongoing',
        },
    ];

    const subTableColumns = [
        {
            accessorKey: 'status',
            header: 'Status',
        },
        {
            accessorKey: 'cvs_submitted',
            header: 'CVs Submitted',
        },
        {
            accessorKey: 'lis_submitted',
            header: 'LIs Submitted',
        },
        {
            accessorKey: 'is_current',
            header: 'Current',
            Cell: ({ cell }) => (cell?.getValue() ? 'Yes' : 'No'),
        },
        {
            accessorKey: 'user_id',
            header: 'Done By',
        },
        // {
        //     accessorKey: 'queries',
        //     header: 'Queries',
        //     Cell: ({ cell }) =>
        //         cell?.getValue()?.map((query, i) => {
        //             const queryObj = JSON.parse(query);
        //             return (
        //                 <Typography key={i} variant="body2">
        //                     {queryObj?.date_time} - {queryObj?.query_added} by {queryObj?.full_name}
        //                 </Typography>
        //             );
        //         }) || 'N/A',
        // },
        // {
        //     accessorKey: 'info',
        //     header: 'Info',
        //     Cell: ({ cell }) =>
        //         cell?.getValue()?.map((info, i) => {
        //             const infoObj = JSON.parse(info);
        //             return (
        //                 <Typography key={i} variant="body2">
        //                     {infoObj?.date_time} - {infoObj?.info_added} by {infoObj?.full_name}
        //                 </Typography>
        //             );
        //         }) || 'N/A',
        // },
    ];

    return (
        <MaterialReactTable
            columns={columns}
            data={clientRolesWorking}
            enableRowActions
            renderDetailPanel={({ row }) => (
                <Box padding={2}>
                    <Typography variant="h6" gutterBottom>
                        Role Working History
                    </Typography>
                    <MaterialReactTable
                        columns={subTableColumns}
                        data={row.original.role_working_history || []}
                        enablePagination={false}
                        enableSorting={false}
                        enableGlobalFilter={false}
                        enableColumnFilter={false}
                        enableFullScreenToggle={false}
                        enableDensityToggle={false}
                        enableColumnReorder={false}
                        enableColumnChooser={false}
                    />
                </Box>
            )}
        />
    );
}

export default ClientRolesTable;
