import { Icon } from '@iconify/react';
import {
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Checkbox,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { CandidateFeedbackDialog, SMSInterface, UpdateEmailResponse } from '.';
import { useNavigate } from 'react-router-dom';
import { Chat } from 'src/components/whatsapp/mobile';
import { Get, Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { Email } from '@mui/icons-material';

function CandidateWorkingTable({
  candidates,
  onPageChange,
  onRowsPerPageChange,
  loading,
  totalCount,
  roleId,
  onReload,
}) {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default rows per page
  const [selected, setSelected] = useState([]); // State for selected rows
  const [openCandidateFeedbackDialog, setOpenCandidateFeedbackDialog] = useState(false);
  const [openWhatsappDialog, setOpenWhatsappDialog] = useState(false);
  const [openSmsDialog, setOpenSmsDialog] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(false);
  const userId = localStorage.getItem('userId');
  const [sequence, setSequence] = useState();
  const [openUpdateEmailResDialog, setOpenUpdateEmailResDialog] = useState(false);
  const statusOptions = ['Interested', 'Not Interested', 'Irrelevant', 'WFR', 'NCR'];
  const statusBgColors = {
    Interested: '#D7FFD6',
    NotInterested: '#FFD2D2',
    WFR: '#C7DEFF',
    Irrelevant: '#E4CFFF',
    NCR: '#FFEBB1',
  };

  const statusTextColors = {
    Interested: '#1FAF38',
    NotInterested: '#FF2D2D',
    WFR: '#3F8CFF',
    Irrelevant: '#9747FF',
    NCR: '#FFC727',
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onPageChange(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    onRowsPerPageChange(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle checkbox toggle
  const handleSelect = (id) => {
    setSelected((prev) => (prev.includes(id) ? prev.filter((candidateId) => candidateId !== id) : [...prev, id]));
  };
  const getSequence = useCallback(() => {
    try {
      Get(
        {
          roleId: roleId,
          userId: userId,
        },
        API_URLS.findSequenceByRoleId,
        (resp) => {
          console.log('response', resp);
          setSequence(resp?.data);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }, [roleId, userId]);

  useEffect(() => {
    getSequence();
  }, [getSequence]);

  const addCommunicationLogToDB = () => {
    try {
      Post(
        {
          channel: 'WhatsApp Message',
          message: 'Template',
          sequenceId: sequence?.id,
          roleId: roleId,
          candidateId: selectedCandidate?.id,
        },
        API_URLS.addCommunicationLog,
        (resp) => {
          console.log('response', resp);
          onReload();
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  console.log('asfdasfas', sequence);
  const updateResponse = (response) => {
    try {
      Post(
        {
          candidateId: selectedCandidate?.id,
          roleId: parseInt(roleId),
          sequenceId: sequence?.id,
          channel: 'Outreach Email',
          response: response,
          response_content: 'response content',
        },
        API_URLS.updateCommunicationLog,
        (resp) => {
          console.log('response', resp);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div style={{ marginTop: '1rem' }}>
      <Box sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 2, borderRadius: 4, backgroundColor: 'white' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <TableCell style={{ textAlign: 'left' }}></TableCell>
                <TableCell style={{ textAlign: 'left' }}>Candidate Name</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Email</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Number</TableCell>
                <TableCell style={{ textAlign: 'center' }}>Activities</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Resourcer</TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  <Box>
                    <Autocomplete
                      id="status"
                      options={statusOptions}
                      size="small"
                      getOptionLabel={(option) => option}
                      onChange={(event, value) => {}}
                      disableClearable // Optional: Prevents the clearable option
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Status"
                          sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              border: 'none', // Remove border
                              outline: 'none', // Remove outline
                              '& fieldset': {
                                display: 'none', // Hide the fieldset (the default border)
                              },
                            },
                            '& .MuiInputBase-input': {
                              padding: '6px 12px', // Adjust padding as needed
                              color: 'black',
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true, // Disable the underline
                          }}
                        />
                      )}
                      // Optionally customize the popup of the dropdown to be hidden or styled
                      // PopperComponent={(props) => <div {...props} style={{ display: 'none' }} />} // Hides the dropdown
                    />
                  </Box>
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Feedback</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {candidates?.data?.length > 0 ? (
                <>
                  {candidates?.data?.map((row, index) => {
                    const responseStatus =
                      row?.candidate_communication_logs?.length > 0
                        ? row?.candidate_communication_logs[row?.candidate_communication_logs?.length - 1]
                            ?.response_status
                        : '';
                    return (
                      <TableRow key={index} selected={selected?.includes(row?.candidateId)}>
                        <TableCell>
                          <Box display="flex" alignItems="center" justifyContent="space-around">
                            {/* Checkbox for selection */}
                            <Checkbox
                              checked={selected?.includes(row.full_name)}
                              onChange={() => handleSelect(row.full_name)}
                              inputProps={{ 'aria-label': 'select row' }}
                              sx={{
                                color: '#808080',
                                '&.Mui-checked': {
                                  color: '#00C1FE', // Set checkbox color to blue when checked
                                },
                              }}
                            />
                          </Box>
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                          >
                            {/* Avatar with the first character */}
                            <Avatar
                              style={{
                                marginRight: '8px', // Adds space between avatar and name
                                backgroundColor: '#4CD4FF',
                                color: '#fff',
                                width: '30px',
                                height: '30px',
                                fontSize: '16px',
                                textAlign: 'center',
                              }}
                            >
                              {row?.full_name?.charAt(0)}
                            </Avatar>

                            {/* Candidate name */}
                            <p
                              style={{
                                color: '#4CD4FF',
                                fontWeight: 'bold',
                                margin: 0,
                              }}
                              onClick={() => navigate(`/recruiter/candidateWorkingDetails/1`)}
                            >
                              {row?.full_name}
                            </p>
                          </div>
                        </TableCell>
                        <TableCell>
                          <p>{row?.personal_email}</p>
                        </TableCell>
                        <TableCell>
                          <p>{row?.personal_phone}</p>
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          <CommunicationChannelPipeline pipeline={row?.candidate_communication_logs} />
                        </TableCell>
                        <TableCell>
                          <p>
                            {row?.cv_adder[0]?.first_name} {row?.cv_adder[0]?.last_name}
                          </p>
                        </TableCell>

                        <TableCell>
                          <p
                            style={{
                              backgroundColor: statusBgColors[responseStatus],
                              color: statusTextColors[responseStatus],
                              padding: '5px 10px',
                              textAlign: 'center',
                              fontWeight: 'bold',
                            }}
                          >
                            {responseStatus}
                          </p>
                        </TableCell>
                        <TableCell>
                          <Box display="flex" alignItems="center" justifyContent="space-between">
                            {/* mui email icon */}
                            <IconButton
                              onClick={() => {
                                setOpenUpdateEmailResDialog(true);
                                setSelectedCandidate(row);
                              }}
                            >
                              <Email style={{ color: '#00C1FE' }} />
                            </IconButton>
                            <Icon
                              icon="iconoir:message-text-solid"
                              style={{ fontSize: '24px', color: '#5C61F2', margin: '2px', cursor: 'pointer' }}
                              onClick={() => {
                                setOpenSmsDialog(true);
                                setSelectedCandidate(row);
                              }}
                            />
                            <Icon
                              icon="logos:whatsapp-icon"
                              style={{ fontSize: '24px', margin: '2px', cursor: 'pointer' }}
                              onClick={() => {
                                setOpenWhatsappDialog(true);
                                setSelectedCandidate(row);
                              }}
                            />
                            <Icon
                              icon="fluent:call-add-20-filled"
                              style={{ fontSize: '24px', color: '#00C1FE', margin: '2px', cursor: 'pointer' }}
                            />
                            <Icon
                              icon="ri:linkedin-fill"
                              style={{ fontSize: '24px', color: '#3F8CFF', margin: '2px', cursor: 'pointer' }}
                            />
                            <Icon
                              icon="mdi:linkedin"
                              style={{ fontSize: '24px', color: '#E5B137', margin: '2px', cursor: 'pointer' }}
                            />
                          </Box>
                        </TableCell>
                        <TableCell>
                          <p onClick={() => setOpenCandidateFeedbackDialog(true)} style={{ cursor: 'pointer' }}>
                            {row?.feedback}
                          </p>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={9} style={{ textAlign: 'center' }}>
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 50, 100]} // Optional: Different options for rows per page
        />
      </Box>
      {openCandidateFeedbackDialog && (
        <CandidateFeedbackDialog
          open={openCandidateFeedbackDialog}
          onClose={() => setOpenCandidateFeedbackDialog(false)}
        />
      )}
      {openWhatsappDialog && (
        // <ChatInterface /
        <Chat
          selectedCandidate={selectedCandidate}
          title={candidates?.roleTitle}
          location={candidates?.location}
          onClose={() => setOpenWhatsappDialog(false)}
          onMessageSent={() => {
            addCommunicationLogToDB();
          }}
        />
      )}
      {openSmsDialog && (
        <SMSInterface
          selectedCandidate={selectedCandidate}
          title={candidates?.roleTitle}
          location={candidates?.location}
          onClose={() => setOpenSmsDialog(false)}
          onMessageSent={() => {
            addCommunicationLogToDB();
          }}
        />
      )}
      {openUpdateEmailResDialog && (
        <UpdateEmailResponse
          open={openUpdateEmailResDialog}
          onClose={() => setOpenUpdateEmailResDialog(false)}
          onSubmit={(response) => updateResponse(response)}
        />
      )}
    </div>
  );
}

export default CandidateWorkingTable;

const CommunicationChannelPipeline = ({ pipeline }) => {
  console.log('pipdsadsdeline', pipeline);
  const statusColors = {
    interested: '#16BF12',
    notInterested: '#FF2D2D',
    wfr: '#3F8CFF',
    irrelevant: '#9747FF',
    skipped: '#7D8592', // Grey for skipped steps
  };

  const renderIcon = (type) => {
    console.log('key', type);
    if (type?.includes('Email')) {
      return <Icon icon="mdi:envelope" style={{ color: 'white', fontSize: '16px' }} />;
    } else if (type?.includes('WhatsApp')) {
      return <Icon icon="mingcute:whatsapp-fill" style={{ color: 'white', fontSize: '18px' }} />;
    } else if (type?.includes('SMS')) {
      return <Icon icon="material-symbols:sms" style={{ color: 'white', fontSize: '16px' }} />;
    } else if (type?.includes('Call')) {
      return <Icon icon="ic:round-phone" style={{ color: 'white', fontSize: '16px' }} />;
    } else if (type?.includes('LI')) {
      return <Icon icon="ri:linkedin-fill" style={{ color: 'white', fontSize: '18px' }} />;
    } else if (type?.includes('InMail')) {
      return <Icon icon="mdi:linkedin" style={{ color: 'white', fontSize: '18px' }} />;
    }
    return null;
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {pipeline?.map((activity, index) => {
        const isSkipped = !activity?.is_step_executed;
        const bgColor = isSkipped ? statusColors.skipped : statusColors[activity?.response_status?.toLowerCase()]; // Default to 'wfr' if status is not in the color map

        return (
          <Stack direction="row" justifyContent="center" alignItems="center" key={index}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  backgroundColor: bgColor,
                  color: '#fff',
                  width: '32px',
                  height: '32px',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '18px',
                }}
              >
                <Tooltip title={activity?.channel} arrow>
                  {renderIcon(activity?.channel)}
                </Tooltip>
              </div>
            </div>
            {index !== pipeline?.length - 1 && (
              <Box sx={{ width: 20, height: 2.5, backgroundColor: '#00bfff', marginLeft: '-2px' }} />
            )}
          </Stack>
        );
      })}
    </div>
  );
};
