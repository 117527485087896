import { Icon } from '@iconify/react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import ReactQuillEditor from './ReactQuillEditor';
import { save } from 'src/sections/admin/reeds/components';

function SequenceStepsTemplates({ step, onDataUpdation, onSaveSequence }) {
  console.log('stepdsdfsa', step);

  // if step changes set the message and subject to empty
  useEffect(() => {
    setMessage('');
    setSubject('');
    setTime(null);
    setDate(null);
  }, [step]);
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [subject, setSubject] = useState('');
  const [regards, setRegards] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    setRegards(`<body> 
            Regards
            <div>Amna Yaseen</div>
            <div>Senior Business Development Executive</div>
            <div>Email: <span style="margin-left: 2.5rem;"> amna@ultimateoutsourcing.co.uk</span></div>
            <div><b>Tel: <span style="margin-left: 4rem;"> 03065495216 </span></b></div>
            <div><b>Tel US: <span style="margin-left: 1.9rem;">+1 347 480 4177</span></b></div>
            <div>Website: <a href="https://ultimateoutsourcing.co.uk/" target="_blank"
                style="margin-left: 2rem;">www.ultimateoutsourcing.co.uk</a> </div>
          </b> <img src="https://ultimateoutsourcing.co.uk/wp-content/uploads/2021/03/300.png" alt="Ultimate outsourcing LTD"
          style="padding-top: 2rem; padding-bottom: 2rem;"<br>
          <p><p><i><strong>Ultimate outsourcing – Home to the UK’s, Europe, UAE, US's &amp; Canada largest Candidate Sourcing Services.</strong></i></p><p>Ultimate Outsourcing is a limited liability company registered in Wyoming USA Under Filling ID: 2023-001264578. Ultimate Outsourcing is a limited company registered in England &amp; Wales with Registered Number 12980312.</p><p>Disclaimer:&nbsp;<br>This email and any files transmitted with it are confidential and intended solely for the use of the individual or entity to whom they are addressed. If you have received this email in error please contact the sender and delete the message from your computer. Ultimate Outsourcing LTD &amp; Ultimate Outsourcing LLC has taken every reasonable step to minimize the risk of viruses, it cannot accept responsibility for any damage which you may sustain as a result of a virus attached to this e-mail. Click <a href="https://ultimateoutsourcing.co.uk/unsubscribe/">Unsubscribe</a> to unsubscribe our services.</p></p>
          </body>`);
  }, []);

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: 3,
            height: 'auto',
            width: '35%',
            padding: '15px',
          }}
          display="flex"
          alignItems="center"
        >
          <Icon icon={step?.icon} style={{ fontSize: '30px', color: step?.color }} />
          <Typography variant="h6" sx={{ ml: 2, fontWeight: 'normal' }}>
            {step?.title}
          </Typography>
        </Box>
        <Button
          size="large"
          sx={{
            backgroundColor: '#0ec4fe',
            color: 'white',
            width: '15%',
            '&:hover': {
              color: 'white !important',
              backgroundColor: '#0ec4fe !important',
            },
          }}
          onClick={() => {
            const payload = {
              subject: subject,
              content: message,
              date: date,
              time: time,
              step_title: step?.title,
            };
            onDataUpdation(payload);
          }}
        >
          <img src={save} alt="email" height={13} width={13} />
          &nbsp; &nbsp; Save Step
        </Button>
      </Box>

      {step?.title?.includes('Follow Up') && (
        <>
          <Box
            sx={{
              backgroundColor: 'white',
              borderRadius: 3,
              height: 'auto',
              marginRight: '10px',
              width: '45%',
              padding: '10px',
            }}
            display="flex"
            alignItems="center"
            marginTop={2}
          >
            <TextField
              id="date"
              name="date"
              value={date}
              onChange={(e) => {
                setDate(e.target.value);
              }}
              type="date"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                borderRadius: '0.3rem',
                marginRight: '20px',
                width: '150px',
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none',
                  },
                },
                '& .MuiInputBase-root': {
                  '&.Mui-focused': {
                    boxShadow: '0 0 0 1px #00C1FE',
                  },
                },
              }}
            />
            <Typography variant="subtitle1" sx={{ ml: 2, fontWeight: 'normal' }}>
              {time ? time : 'Select time'}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label=""
                onChange={(newValue) => {
                  setTime(dayjs(newValue).format('hh:mm A'));
                }}
                sx={{
                  width: '10%',
                  cursor: 'pointer',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none', // Removes the border of the input
                    },
                  },
                  '& .MuiInputBase-input': {
                    outline: 'none', // Removes the focus outline
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
        </>
      )}
      {step?.title?.includes('Email') && (
        <TextField
          id="subject"
          name="subject"
          type="text"
          placeholder="Subject line"
          size="large"
          value={step?.details[0]?.value || subject}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setSubject(e.target.value);
          }}
          sx={{
            marginTop: 2,
            backgroundColor: 'white',
            outline: 'none',
            marginBottom: '3px',
            borderRadius: '15px',
          }}
        />
      )}
      {!step?.title?.includes('Call') ? (
        <>
          <Box
            sx={{
              backgroundColor: 'white',
              borderRadius: 3,
              height: 'auto',
              marginRight: '10px',
              padding: '10px',
            }}
            display="flex"
            flexDirection="column"
            marginTop={2}
          >
            <ReactQuillEditor
              showTags={false}
              editorLabel=""
              htmlText={step?.details[1]?.value || message}
              onEditorStateChange={(html) => {
                console.log('html', html);
                setMessage(html?.data);
              }}
              maxCharacters={
                step?.title?.includes('WhatsApp Message') ? 300 : step?.title?.includes('SMS') ? 200 : 2000
              }
            />
            <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
            {step?.title?.includes('Email') && (
              <Typography
                variant="subtitle2"
                sx={{
                  padding: '5px',
                  paddingLeft: '20px',
                  marginBottom: '5px',
                  marginLeft: '10px',
                  height: '600px',
                  overflowX: 'auto', // Add horizontal scrollbar if necessary
                  overflowY: 'auto', // Add vertical scrollbar if necessary
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: regards,
                  }}
                />
              </Typography>
            )}
          </Box>
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundColor: '#f2f2f2',
            padding: '10px',
            height: '700px',
            borderRadius: 3,
          }}
          marginTop={2}
        >
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            No messages to be associated with this step
          </Typography>
        </Box>
      )}

      <Box display="flex" justifyContent="flex-end" alignItems="center" marginTop={2} marginRight={2}>
        <Button
          size="large"
          sx={{
            backgroundColor: '#0ec4fe',
            color: 'white',
            width: '15%',
            '&:hover': {
              color: 'white !important',
              backgroundColor: '#0ec4fe !important',
            },
          }}
          onClick={() => {
            onSaveSequence();
          }}
        >
          <img src={save} alt="email" height={13} width={13} />
          &nbsp; &nbsp; Save Sequence
        </Button>
      </Box>
    </div>
  );
}

export default SequenceStepsTemplates;
