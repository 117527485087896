import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Tooltip,
  TablePagination,
  Switch,
  Skeleton,
} from '@mui/material';
import { AssociateCandidate, Microscope } from './images';
import { useLocation, useNavigate } from 'react-router-dom';
import { AskSendEmailConfirmationDialog, JobDescriptionDialog } from '.';
import { styled } from '@mui/styles';

function RolePipelinesTable({ title, roles, onPageChange, onRowsPerPageChange, totalCount, isLoading }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default rows per page
  const location = useLocation();
  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onPageChange(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    onRowsPerPageChange(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page whenever rows per page changes
  };

  const navigate = useNavigate();
  const [openJobDescriptionDialog, setOpenJobDescriptionDialog] = useState(false);
  const [selectedJob, setSelectedJob] = useState();
  // Track toggle state individually for each row
  const [emailToggleStates, setEmailToggleStates] = useState({});
  // Toggle the email dialog for specific row
  const handleToggle = (rowIndex) => {
    setEmailToggleStates((prevState) => ({
      ...prevState,
      [rowIndex]: !prevState[rowIndex],
    }));
  };
  return (
    <Box sx={{ padding: 2, borderRadius: 4, backgroundColor: 'white' }}>
      <Typography variant="subtitle2" gutterBottom sx={{ marginLeft: 2 }}>
        {title}
      </Typography>
      <hr style={{ width: '100%', margin: 'auto', color: '#E6EBF5' }} />
      {/* Colored bar directly above table headers */}
      <Box
        display="flex"
        sx={{
          height: '5px',
          width: '61%',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          marginLeft: 'auto',
          marginRight: 3,
        }}
      >
        <span
          style={{
            display: 'inline-block',
            flexGrow: 1,
            backgroundColor: '#EB65E5', // Designer
            height: '100%',
          }}
        ></span>
        <span
          style={{
            display: 'inline-block',
            flexGrow: 1,
            backgroundColor: '#88BCF6', // Developer
            height: '100%',
          }}
        ></span>
        <span
          style={{
            display: 'inline-block',
            flexGrow: 1,
            backgroundColor: '#AFBAE0', // Writer
            height: '100%',
          }}
        ></span>
        <span
          style={{
            display: 'inline-block',
            flexGrow: 1,
            backgroundColor: '#FF9999', // Rejected
            height: '100%',
          }}
        ></span>
        <span
          style={{
            display: 'inline-block',
            flexGrow: 1,
            backgroundColor: '#EBB794', // Interview
            height: '100%',
          }}
        ></span>
        <span
          style={{
            display: 'inline-block',
            flexGrow: 1,
            backgroundColor: '#67E4BB', // Offered
            height: '100%',
          }}
        ></span>
        <span
          style={{
            display: 'inline-block',
            flexGrow: 1,
            backgroundColor: '#E0CC38', // Hired
            height: '100%',
          }}
        ></span>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
              <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
              {/* <TableCell style={{ textAlign: 'left' }}>Marketing</TableCell> */}
              <TableCell style={{ textAlign: 'center' }}>Posting Title</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Resourcer</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Recruiters</TableCell>
              <TableCell style={{ textAlign: 'center' }}>All Candidates</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Screening</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Submissions</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Rejected</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Interview</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Offered</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Hired</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              Array.from(new Array(5)).map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Skeleton variant="reactangular" width={24} height={24} />
                      <Skeleton variant="rectangular" width={16} height={16} style={{ marginLeft: '8px' }} />
                    </Box>
                  </TableCell>
                  <TableCell>
                    {' '}
                    <Skeleton variant="text" width="40%" />{' '}
                  </TableCell>{' '}
                  <TableCell>
                    <Skeleton variant="text" width="40%" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width="40%" />
                  </TableCell>{' '}
                  <TableCell>
                    <Skeleton variant="text" width="40%" />
                  </TableCell>{' '}
                  <TableCell>
                    <Skeleton variant="text" width="40%" />
                  </TableCell>{' '}
                  <TableCell>
                    <Skeleton variant="text" width="40%" />
                  </TableCell>{' '}
                  <TableCell>
                    <Skeleton variant="text" width="40%" />
                  </TableCell>{' '}
                  <TableCell>
                    <Skeleton variant="text" width="40%" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width="40%" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width="40%" />
                  </TableCell>
                </TableRow>
              ))
            ) : roles?.length > 0 ? (
              <>
                {roles?.map((row, index) => {
                  return (
                    <>
                      <TableRow key={index}>
                        <TableCell sx={{ width: '6%' }}>
                          <Box display="flex" alignItems="center" justifyContent="space-around">
                            <Tooltip title="View JD" placement="top">
                              <img
                                src={Microscope}
                                alt="View JD"
                                width="18px"
                                height="14px"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  setOpenJobDescriptionDialog(true);
                                  setSelectedJob(row);
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Associate candidate" placement="top">
                              <img
                                src={AssociateCandidate}
                                alt="Associate Candidate"
                                width="12px"
                                height="12px"
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  navigate(`/recruiter/jobApplicants/${row?.id}`, {
                                    state: { previousPath: location.pathname }, // Pass the current path as state
                                  })
                                }
                              />
                            </Tooltip>
                          </Box>
                        </TableCell>
                        {/* <TableCell sx={{ textAlign: 'center' }}>
                          <FormControlLabel
                            control={<Android12Switch />}
                            label=""
                            checked={!!emailToggleStates[index]}
                            onChange={() => handleToggle(index)}
                          />
                        </TableCell> */}
                        <TableCell sx={{ width: '10%' }}>
                          <p
                            style={{
                              textAlign: 'center',
                              margin: 'auto',
                            }}
                          >
                            {' '}
                            <span
                              style={{ color: '#4CD4FF', cursor: 'pointer' }}
                              onClick={() => navigate('/recruiter/jobDetails/1')}
                            >
                              {row.title}
                            </span>{' '}
                            <span
                              style={{ cursor: 'pointer' }}
                              onClick={() => navigate(`/recruiter/jobApplicants/${row?.id}`)}
                            >
                              ({row?.cv_ids?.length})
                            </span>
                          </p>
                        </TableCell>
                        <TableCell sx={{ width: '10%' }}>
                          <p
                            style={{
                              textAlign: 'center',
                              margin: 'auto',
                            }}
                          >
                            {row?.resourcer ? (
                              <>
                                {row?.resourcer?.first_name} {row?.resourcer?.last_name}
                              </>
                            ) : (
                              'N/A'
                            )}
                          </p>
                        </TableCell>
                        <TableCell sx={{ width: '10%' }}>
                          <p
                            style={{
                              textAlign: 'center',
                              margin: 'auto',
                              fontSize: '12px',
                            }}
                          >
                            {row?.recruiters?.length > 0 ? (
                              <>
                                {/* map the recruiter names and when we hover , give the tooltip of status against this recruiter */}
                                {row?.recruiters?.map((recruiter, index) => (
                                  <Tooltip
                                    title={`${recruiter?.status},  ${recruiter?.sub_task ? recruiter?.sub_task : ''}`}
                                    placement="top"
                                  >
                                    <span key={index}>
                                      {recruiter?.recruiter_roles_workings_user?.first_name}{' '}
                                      {recruiter?.recruiter_roles_workings_user?.last_name},
                                    </span>
                                  </Tooltip>
                                ))}
                              </>
                            ) : (
                              'Role not started'
                            )}
                          </p>
                        </TableCell>
                        <TableCell sx={{ width: '9%', textAlign: 'center' }}>
                          <p
                            style={{
                              backgroundColor: '#FFE2FE',
                              padding: '10px',
                              textAlign: 'center',
                              width: '55%',
                              margin: 'auto',
                              borderRight: '5px solid #EB65E5',
                              cursor: 'pointer',
                            }}
                            onClick={() => navigate(`/recruiter/allCandidates/${row?.id}`)}
                          >
                            {row?.cv_ids?.length}
                          </p>
                        </TableCell>
                        <TableCell sx={{ width: '9%' }}>
                          <p
                            style={{
                              backgroundColor: '#DDECFD',
                              padding: '10px',
                              textAlign: 'center',
                              width: '55%',
                              margin: 'auto',
                              borderRight: '5px solid #88BCF6',
                              cursor: 'pointer',
                            }}
                            onClick={() => navigate(`/recruiter/screeningCandidates/${row?.id}`)}
                          >
                            {row?.cv_ids?.length}
                          </p>
                        </TableCell>
                        <TableCell sx={{ width: '9%' }}>
                          <p
                            style={{
                              backgroundColor: '#DCE1F3',
                              padding: '10px',
                              width: '55%',
                              margin: 'auto',
                              textAlign: 'center',
                              borderRight: '5px solid #AFBAE0',
                              cursor: 'pointer',
                            }}
                            onClick={() => navigate('/recruiter/submittedCandidates/1')}
                          >
                            0
                          </p>
                        </TableCell>
                        <TableCell sx={{ width: '9%' }}>
                          <p
                            style={{
                              backgroundColor: '#F6D3D3',
                              padding: '10px',
                              width: '55%',
                              margin: 'auto',
                              textAlign: 'center',
                              borderRight: '5px solid #FF9999',
                              cursor: 'pointer',
                            }}
                            onClick={() => navigate('/recruiter/rejectedCandidates/1')}
                          >
                            0
                          </p>
                        </TableCell>
                        <TableCell sx={{ width: '9%' }}>
                          <p
                            style={{
                              backgroundColor: '#FFE6D6',
                              padding: '10px',
                              textAlign: 'center',
                              width: '55%',
                              margin: 'auto',
                              borderRight: '5px solid #EBB794',
                              cursor: 'pointer',
                            }}
                            onClick={() => navigate('/recruiter/interviewCandidates/1')}
                          >
                            0
                          </p>
                        </TableCell>
                        <TableCell sx={{ width: '9%' }}>
                          <p
                            style={{
                              backgroundColor: '#C7FFED',
                              padding: '10px',
                              textAlign: 'center',
                              width: '55%',
                              margin: 'auto',
                              borderRight: '5px solid #67E4BB',
                              cursor: 'pointer',
                            }}
                            onClick={() => navigate('/recruiter/offeredCandidates/1')}
                          >
                            0
                          </p>
                        </TableCell>
                        <TableCell sx={{ width: '9%' }}>
                          <p
                            style={{
                              backgroundColor: '#FFF5AE',
                              padding: '10px',
                              textAlign: 'center',
                              width: '55%',
                              margin: 'auto',
                              borderRight: '5px solid #E0CC38',
                              cursor: 'pointer',
                            }}
                            onClick={() => navigate('/recruiter/hiredCandidates/1')}
                          >
                            0
                          </p>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </>
            ) : (
              <>
                <TableRow>
                  <TableCell colSpan={10} sx={{ textAlign: 'center' }}>
                    No records found
                  </TableCell>
                </TableRow>
              </>
            )}

            {/* {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ width: '6%' }}>
                  <Box display="flex" alignItems="center" justifyContent="space-around">
                    <Tooltip title="View JD" placement="top">
                      <img
                        src={Microscope}
                        alt="View JD"
                        width="18px"
                        height="14px"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setOpenJobDescriptionDialog(true);
                          setSelectedJob(row);
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Associate candidate" placement="top">
                      <img
                        src={AssociateCandidate}
                        alt="Associate Candidate"
                        width="12px"
                        height="12px"
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          navigate(`/recruiter/jobApplicants/1`, {
                            state: { previousPath: location.pathname }, // Pass the current path as state
                          })
                        }
                      />
                    </Tooltip>
                  </Box>
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  <FormControlLabel
                    control={<Android12Switch />}
                    label=""
                    checked={!!emailToggleStates[index]}
                    onChange={() => handleToggle(index)}
                  />
                </TableCell>
                <TableCell sx={{ width: '10%' }}>
                  <p
                    style={{
                      textAlign: 'center',
                      margin: 'auto',
                    }}
                  >
                    {' '}
                    <span
                      style={{ color: '#4CD4FF', cursor: 'pointer' }}
                      onClick={() => navigate('/recruiter/jobDetails/1')}
                    >
                      {row.role}
                    </span>{' '}
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        navigate(`/recruiter/jobApplicants/1`, {
                          state: { previousPath: location.pathname }, // Pass the current path as state
                        })
                      }
                    >
                      ({row.candidates})
                    </span>
                  </p>
                </TableCell>
                <TableCell sx={{ width: '12%', textAlign: 'center' }}>
                  <p
                    style={{
                      backgroundColor: '#FFE2FE',
                      padding: '10px',
                      textAlign: 'center',
                      width: '55%',
                      margin: 'auto',
                      borderRight: '5px solid #EB65E5',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate('/recruiter/allCandidates/1')}
                  >
                    {row.stages.allCandidates}
                  </p>
                </TableCell>
                <TableCell sx={{ width: '12%' }}>
                  <p
                    style={{
                      backgroundColor: '#DDECFD',
                      padding: '10px',
                      textAlign: 'center',
                      width: '55%',
                      margin: 'auto',
                      borderRight: '5px solid #88BCF6',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate('/recruiter/screeningCandidates/1')}
                  >
                    {row.stages.screening}
                  </p>
                </TableCell>
                <TableCell sx={{ width: '12%' }}>
                  <p
                    style={{
                      backgroundColor: '#DCE1F3',
                      padding: '10px',
                      width: '55%',
                      margin: 'auto',
                      textAlign: 'center',
                      borderRight: '5px solid #AFBAE0',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate('/recruiter/submittedCandidates/1')}
                  >
                    {row.stages.submissions}
                  </p>
                </TableCell>
                <TableCell sx={{ width: '12%' }}>
                  <p
                    style={{
                      backgroundColor: '#F6D3D3',
                      padding: '10px',
                      width: '55%',
                      margin: 'auto',
                      textAlign: 'center',
                      borderRight: '5px solid #FF9999',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate('/recruiter/rejectedCandidates/1')}
                  >
                    {row.stages.rejected}
                  </p>
                </TableCell>
                <TableCell sx={{ width: '12%' }}>
                  <p
                    style={{
                      backgroundColor: '#FFE6D6',
                      padding: '10px',
                      textAlign: 'center',
                      width: '55%',
                      margin: 'auto',
                      borderRight: '5px solid #EBB794',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate('/recruiter/interviewCandidates/1')}
                  >
                    {row.stages.interview}
                  </p>
                </TableCell>
                <TableCell sx={{ width: '12%' }}>
                  <p
                    style={{
                      backgroundColor: '#C7FFED',
                      padding: '10px',
                      textAlign: 'center',
                      width: '55%',
                      margin: 'auto',
                      borderRight: '5px solid #67E4BB',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate('/recruiter/offeredCandidates/1')}
                  >
                    {row.stages.offered}
                  </p>
                </TableCell>
                <TableCell sx={{ width: '12%' }}>
                  <p
                    style={{
                      backgroundColor: '#FFF5AE',
                      padding: '10px',
                      textAlign: 'center',
                      width: '55%',
                      margin: 'auto',
                      borderRight: '5px solid #E0CC38',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate('/recruiter/hiredCandidates/1')}
                  >
                    {row.stages.hired}
                  </p>
                </TableCell>
              </TableRow>
            ))} */}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination component */}
      <TablePagination
        component="div"
        count={totalCount}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 15, 25]} // Optional: Different options for rows per page
      />

      {openJobDescriptionDialog && (
        <JobDescriptionDialog
          open={openJobDescriptionDialog}
          onClose={() => setOpenJobDescriptionDialog(false)}
          jobDetails={selectedJob}
        />
      )}
      {Object?.values(emailToggleStates)?.some((value) => value) && (
        <AskSendEmailConfirmationDialog
          open={Object.values(emailToggleStates).some((value) => value)}
          onClose={() => setEmailToggleStates({})}
        />
      )}
    </Box>
  );
}

export default RolePipelinesTable;
