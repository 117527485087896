import { Icon } from '@iconify/react';
import { ArrowBack } from '@mui/icons-material';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, timelineItemClasses, TimelineSeparator } from '@mui/lab';
import { Avatar, Badge, Box, Button, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function CandidateCommunicationDetails(props) {
  const navigate = useNavigate();
  return (
    <div style={{ width: '98%', margin: 'auto' }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <ArrowBack
          fontSize="small"
          onClick={() => {
            navigate(-1);
          }}
          sx={{
            color: '#00C1FE',
            cursor: 'pointer',
          }}
        />
        &nbsp;&nbsp;{' '}
        <Typography
          variant="subtitle2"
          sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </Typography>
      </Box>
      <Grid container sx={{ marginTop: '15px' }} spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{height:'100vh', backgroundColor:'white', overflowY:'scroll'}}> 
          <Box sx={{ backgroundColor: 'white', height: 'auto' }}>
            <Box display="flex" alignItems="center" justifyContent="flex-start" sx={{ padding: '20px' }}>
              <Avatar
                style={{
                  marginRight: '20px', // Adds space between avatar and name
                  backgroundColor: '#4CD4FF',
                  color: '#fff',
                  width: '75px',
                  height: '75px',
                  fontSize: '40px',
                  textAlign: 'center',
                }}
              >
                A
              </Avatar>
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Typography variant="h5" sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}>
                  {'Ali Raza'}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ textTransform: 'none', fontStyle: 'italic', color: '#555555', fontWeight: 'normal' }}
                >
                  aliraza@gmail.com
                </Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="center">
              <Box display="flex" flexDirection="column">
                <p
                  style={{
                    backgroundColor: '#E1F7FC',
                    borderRadius: '50%',
                    height: '50px',
                    width: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Icon
                    icon="mingcute:message-4-fill"
                    style={{
                      color: '#00C1FE',
                      fontSize: '28px',
                    }}
                  />
                </p>
                <Typography
                  variant="caption"
                  sx={{
                    textTransform: 'none',
                    fontStyle: 'italic',
                    color: '#555555',
                    fontWeight: 'normal',
                    marginTop: '4px',
                  }}
                >
                  Message
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" marginLeft="30px">
                <p
                  style={{
                    backgroundColor: '#E1F7FC',
                    borderRadius: '50%',
                    height: '50px',
                    width: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Icon
                    icon="ri:whatsapp-fill"
                    style={{
                      color: '#00C1FE',
                      fontSize: '28px',
                    }}
                  />
                </p>
                <Typography
                  variant="caption"
                  sx={{
                    textTransform: 'none',
                    fontStyle: 'italic',
                    color: '#555555',
                    fontWeight: 'normal',
                    marginTop: '4px',
                  }}
                >
                  WhatsApp
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" marginLeft="30px" alignItems="center">
                <p
                  style={{
                    backgroundColor: '#E1F7FC',
                    borderRadius: '50%',
                    height: '50px',
                    width: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Icon
                    icon="fluent:call-add-20-filled"
                    style={{
                      color: '#00C1FE',
                      fontSize: '28px',
                    }}
                  />
                </p>
                <Typography
                  variant="caption"
                  sx={{
                    textTransform: 'none',
                    fontStyle: 'italic',
                    color: '#555555',
                    fontWeight: 'normal',
                    marginTop: '4px',
                  }}
                >
                  Call
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" marginLeft="30px" alignItems="center">
                <p
                  style={{
                    backgroundColor: '#E1F7FC',
                    borderRadius: '50%',
                    height: '50px',
                    width: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Icon
                    icon="dashicons:linkedin"
                    style={{
                      color: '#00C1FE',
                      fontSize: '28px',
                    }}
                  />
                </p>
                <Typography
                  variant="caption"
                  sx={{
                    textTransform: 'none',
                    fontStyle: 'italic',
                    color: '#555555',
                    fontWeight: 'normal',
                    marginTop: '4px',
                  }}
                >
                  LinkedIn
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" marginLeft="30px" alignItems="center">
                <p
                  style={{
                    backgroundColor: '#E1F7FC',
                    borderRadius: '50%',
                    height: '50px',
                    width: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Icon
                    icon="mdi:linkedin"
                    style={{
                      color: '#00C1FE',
                      fontSize: '28px',
                    }}
                  />
                </p>
                <Typography
                  variant="caption"
                  sx={{
                    textTransform: 'none',
                    fontStyle: 'italic',
                    color: '#555555',
                    fontWeight: 'normal',
                    marginTop: '4px',
                  }}
                >
                  InMail
                </Typography>
              </Box>
            </Box>
            <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '15px' }} />
            <Typography
              variant="subtitle"
              sx={{ fontWeight: 'bold', display: 'flex', marginLeft: '20px', marginTop: '10px' }}
            >
              {' '}
              <Icon icon="ep:arrow-down" style={{ fontSize: '20px', marginRight: '10px' }} /> About this contact
            </Typography>
            <Box display="flex" sx={{ padding: '20px' }} justifyContent="space-between" alignItems="center">
              <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: 'bold', display: 'flex', marginLeft: '20px', marginTop: '10px' }}
                >
                  {' '}
                  <Icon icon="ic:outline-email" style={{ fontSize: '20px', marginRight: '10px', color: '#3F8CFF' }} />
                  Email
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    textTransform: 'none',
                    fontStyle: 'italic',
                    color: '#555555',
                    fontWeight: 'normal',
                    marginTop: '4px',
                  }}
                >
                  aliraza@gmail.com
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: 'bold', display: 'flex', marginLeft: '20px', marginTop: '10px' }}
                >
                  {' '}
                  <Icon
                    icon="material-symbols:call"
                    style={{ fontSize: '20px', marginRight: '10px', color: '#3F8CFF' }}
                  />
                  Phone Number
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    textTransform: 'none',
                    fontStyle: 'italic',
                    color: '#555555',
                    fontWeight: 'normal',
                    marginTop: '4px',
                  }}
                >
                  +44 543545 4324
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: 'bold', display: 'flex', marginLeft: '20px', marginTop: '10px' }}
                >
                  {' '}
                  <Icon icon="mdi:user" style={{ fontSize: '20px', marginRight: '10px', color: '#3F8CFF' }} />
                  Resourcer
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    textTransform: 'none',
                    fontStyle: 'italic',
                    color: '#555555',
                    fontWeight: 'normal',
                    marginTop: '4px',
                  }}
                >
                  Adnan
                </Typography>
              </Box>
            </Box>
            <SequenceBox />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} sx={{ height: '100vh', overflowY: 'scroll',  backgroundColor:'white', }}>
          <Box sx={{ backgroundColor: 'white' }}>
            <RoleHiringCommunication />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default CandidateCommunicationDetails;

const data = [
  {
    name: 'Ali Raza',
    email: 'ali@gmail.com',
    number: '123456789',
    activities: [
      { type: 'email', sent: true, response: '', isRepeated: false },
      { type: 'whatsapp', sent: true, response: 'interested', isRepeated: false },
      { type: 'mobileText', sent: true, response: '', isRepeated: false },
      { type: 'call', sent: false, response: '', isRepeated: false },
      { type: 'linkedinConnection', sent: false, response: '', isRepeated: false },
      { type: 'linkedinInmail', sent: false, response: '', isRepeated: false },
    ],
    resourcer: 'Vishal',
    recruiter: 'John',
    status: 'Interested',
    feedback: 'Good',
  },
  {
    name: 'Ali Raza',
    email: 'ali@gmail.com',
    number: '123456789',
    activities: [
      { type: 'email', sent: true, response: '', isRepeated: false },
      { type: 'whatsapp', sent: true, response: 'notInterested', isRepeated: false },
      { type: 'mobileText', sent: false, response: '', isRepeated: false },
      { type: 'call', sent: false, response: '', isRepeated: false },
      { type: 'linkedinConnection', sent: false, response: '', isRepeated: false },
      { type: 'linkedinInmail', sent: false, response: '', isRepeated: false },
    ],
    resourcer: 'Vishal',
    recruiter: 'John',
    status: 'Interested',
    feedback: 'Good',
  },
];
const SequenceBox = () => {
  return (
    <>
      <Box display="flex" flexDirection="column" padding="20px">
        <Typography
          variant="subtitle"
          sx={{ fontWeight: 'normal', display: 'flex', marginLeft: '20px', marginTop: '10px' }}
        >
          Sequence (1)
        </Typography>
        <Box sx={{ border: '2px solid #D8D8D8', height: 'auto', marginTop: '5px' }}>
          <Box display="flex" justifyContent="space-between" padding="10px">
            <p style={{ fontSize: '15px', color: '#00C1FE' }}>Taniya (Designer)</p>
            <Typography variant="caption">12/02/2024</Typography>
          </Box>
          <CommunicationChannelPipeline pipeline={data[0]} />
          <Box display="flex" justifyContent="space-between" padding="20px">
            <Box marginTop="20px" marginLeft="20px" display="flex">
              <Typography variant="body2" sx={{ fontWeight: 'normal', fontStyle: 'italic' }}>
                Status:
              </Typography>
              <Typography
                variant="caption"
                sx={{ color: 'green', backgroundColor: '#C0FFCB', padding: '7px', marginLeft: '10px' }}
              >
                In Progress
              </Typography>
            </Box>
            <Box marginTop="20px" marginLeft="20px" display="flex" marginRight="20px">
              <Typography variant="body2" sx={{ fontWeight: 'normal', fontStyle: 'italic' }}>
                Results:
              </Typography>
              <Typography
                variant="caption"
                sx={{ color: 'green', backgroundColor: '#C0FFCB', padding: '7px', marginLeft: '10px' }}
              >
                Interested
              </Typography>
            </Box>
            <br />
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" padding="20px">
        <Typography
          variant="subtitle"
          sx={{ fontWeight: 'normal', display: 'flex', marginLeft: '20px', marginTop: '10px' }}
        >
          Sequence (2)
        </Typography>
        <Box sx={{ border: '2px solid #D8D8D8', height: 'auto', marginTop: '5px' }}>
          <Box display="flex" justifyContent="space-between" padding="10px">
            <p style={{ fontSize: '15px', color: '#00C1FE' }}>Adeel (Designer)</p>
            <Typography variant="caption">12/02/2024</Typography>
          </Box>
          <CommunicationChannelPipeline pipeline={data[1]} />
          <Box display="flex" justifyContent="space-between" padding="20px">
            <Box marginTop="20px" marginLeft="20px" display="flex">
              <Typography variant="body2" sx={{ fontWeight: 'normal', fontStyle: 'italic' }}>
                Status:
              </Typography>
              <Typography
                variant="caption"
                sx={{ color: 'red', backgroundColor: '#FCD6D6', padding: '7px', marginLeft: '10px' }}
              >
                Stop
              </Typography>
            </Box>
            <Box marginTop="20px" display="flex" marginRight="20px">
              <Typography variant="body2" sx={{ fontWeight: 'normal', fontStyle: 'italic' }}>
                Results:
              </Typography>
              <Typography
                variant="caption"
                sx={{ color: 'red', backgroundColor: '#FCD6D6', padding: '7px', marginLeft: '10px' }}
              >
                Not Interested
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const CommunicationChannelPipeline = ({ pipeline }) => {
  const statusColors = {
    interested: '#16BF12',
    notInterested: '#F50100',
    wfr: '#16BF12',
    irrelevant: '#9747FF',
    skipped: '#7D8592', // Grey for skipped steps
  };

  // Ensure activities is always an array
  const activities = Array.isArray(pipeline.activities) ? pipeline.activities : Object.values(pipeline.activities);

  const renderIcon = (type) => {
    console.log('key', type);
    switch (type) {
      case 'email':
        return <Icon icon="mdi:envelope" style={{ color: 'white', fontSize: '16px' }} />;
      case 'whatsapp':
        return <Icon icon="mingcute:whatsapp-fill" style={{ color: 'white', fontSize: '18px' }} />;
      case 'mobileText':
        return <Icon icon="material-symbols:sms" style={{ color: 'white', fontSize: '16px' }} />;
      case 'call':
        return <Icon icon="ic:round-phone" style={{ color: 'white', fontSize: '16px' }} />;
      case 'linkedinConnection':
        return <Icon icon="ri:linkedin-fill" style={{ color: 'white', fontSize: '16px' }} />;
      case 'linkedinInmail':
        return <Icon icon="carbon:logo-linkedin" style={{ color: 'white', fontSize: '16px' }} />;
      default:
        return null;
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginLeft: '20px' }}>
      {activities?.map((activity, index) => {
        const isSkipped = !activity.sent && activity.response === '';
        const bgColor = isSkipped
          ? statusColors.skipped
          : statusColors[activity.response.toLowerCase()] ||
            statusColors[
              activity.type === 'whatsapp' && activity.response === 'notInterested' ? 'notInterested' : 'wfr'
            ];

        return (
          <Stack direction="row" justifyContent="flex-start" alignItems="center" key={index}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {activity.isRepeated && ( // Conditional rendering of Badge
                <Badge badgeContent={4} color="error">
                  <div
                    style={{
                      backgroundColor: bgColor,
                      color: '#fff',
                      width: '28px',
                      height: '28px',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '18px',
                    }}
                  >
                    {renderIcon(activity.type)}
                  </div>
                </Badge>
              )}
              {!activity.isRepeated && ( // Render the icon without Badge if isRepeated is false
                <div
                  style={{
                    backgroundColor: bgColor,
                    color: '#fff',
                    width: '32px',
                    height: '32px',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '18px',
                  }}
                >
                  {renderIcon(activity.type)}
                </div>
              )}
            </div>
            {index !== activities?.length - 1 && (
              <Box sx={{ width: 20, height: 2.5, backgroundColor: '#00bfff', marginLeft: '-2px' }} />
            )}
          </Stack>
        );
      })}
    </div>
  );
};

const buttons = [
  {
    buttonText: 'Total',
    value: 100,
    borderTop: '#F688F2',
    buttonFontSize: '18px',
  },
  {
    buttonText: 'Screening',
    value: 25,
    borderTop: '#88BCF6',
    buttonFontSize: '18px',
  },
  {
    buttonText: 'Submissions',
    value: 25,
    borderTop: '#A4B0DB',
    buttonFontSize: '18px',
  },
  {
    buttonText: 'Rejected',
    value: 25,
    borderTop: '#FF9999',
    buttonFontSize: '18px',
  },
  {
    buttonText: 'Interview',
    value: 25,
    borderTop: '#EBB794',
    buttonFontSize: '18px',
  },
  {
    buttonText: 'Offered',
    value: 25,
    borderTop: '#67E4BB',
    buttonFontSize: '18px',
  },
  {
    buttonText: 'Hired',
    value: 25,
    borderTop: '#E0CC38',
    buttonFontSize: '18px',
  },
];
const RoleHiringCommunication = () => {
  // Dummy data for the nested timeline
  const data = [
    {
      name: 'Taniya',
      interactions: [
        {
          type: 'Email',
          content: 'Sample email content for Taniya',
          status: 'No Response',
          date: '06/11/2024',
        },
        {
          type: 'Message',
          content: 'Sample message content for Taniya',
          status: 'No Response',
          date: '06/11/2024',
        },
        {
          type: 'Whatsapp',
          content: 'Sample WhatsApp content for Taniya',
          status: 'Interested',
          date: '06/11/2024',
        },
      ],
    },
    {
      name: 'Adeel',
      interactions: [
        {
          type: 'Email',
          content: 'Sample email content for Adeel',
          status: 'No Response',
          date: '06/11/2024',
        },
      ],
    },
  ];

  return (
    <div style={{ width: '98%', margin: 'auto' }}>
      <Box padding="20px" paddingBottom="5px">
        <Typography variant="subtitle" sx={{ fontWeight: 'bold', color: '#00C1FE' }}>
          Hiring Pipeline
        </Typography>
      </Box>

      <Box sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', margin: 'auto', backgroundColor: '#F4F9FD' }}>
        <Stack direction="row">
          {buttons?.map((button) => (
            <Button
              variant="outlined"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 2,
                outline: 'none',
                flexGrow: 1,
                border: 'none',
                '&:hover': {
                  backgroundColor: '#F4F9FD',
                  outline: 'none',
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  borderTop: `7px solid ${button?.borderTop}`,
                },
                '&:focus, &:active': {
                  outline: 'none',
                  borderTop: `7px solid ${button?.borderTop}`,
                  borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                  boxShadow: 'none',
                },
                borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                borderTop: `7px solid ${button?.borderTop}`,
                borderRadius: 0,
              }}
            >
              <p style={{ fontSize: '20px', fontWeight: 'normal' }}>{button?.value}</p>
              <p style={{ fontSize: `${button?.buttonFontSize}`, color: '#838383', fontWeight: 'normal' }}>
                {button?.buttonText}
              </p>
            </Button>
          ))}
        </Stack>
      </Box>

      <Box>
        <Typography
          variant="h6"
          sx={{
            color: '#00C1FE',
            marginTop: '20px',
            borderBottom: '2px solid #00C1FE',
            paddingLeft: '20px',
            width: '15%',
          }}
        >
          Role Activity
        </Typography>
      </Box>
      <hr style={{ width: '100%', color: '#CAC5C5' }} />
      {/* Timeline Box */}
      <Box sx={{ backgroundColor: '#E3F3FF', padding: '30px', marginTop: '20px' }}>
        <Typography
          variant="subtitle"
          sx={{ fontWeight: 'bold', display: 'flex', marginLeft: '20px', marginTop: '10px' }}
        >
          {' '}
          <Icon icon="ep:arrow-down" style={{ fontSize: '20px', marginRight: '10px' }} /> UI/UX Designer
        </Typography>

        {/* Main Timeline */}
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {data.map((user, index) => (
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineDot
                  sx={{
                    backgroundColor: '#00C1FE',
                    height: '35px',
                    width: '35px',
                    padding: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {index + 1}
                </TimelineDot>
                <TimelineConnector sx={{ backgroundColor: '#00C1FE' }} />
              </TimelineSeparator>
              <TimelineContent>
                <Box>
                  <Typography variant="h6" color="#00C1FE">
                    {user.name}
                  </Typography>
                  <Timeline
                    sx={{
                      [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                      },
                    }}
                  >
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot
                          sx={{
                            backgroundColor: '#3FC28A',
                            borderColor: '#3FC28A',
                            borderRadius: '50%',
                            padding: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center', // Center icon in dot
                          }}
                        >
                          <Icon icon="ic:baseline-email" style={{ fontSize: '18px', color: 'white' }} />
                        </TimelineDot>
                        <TimelineConnector sx={{ height: '40px' }} />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Box sx={{ backgroundColor: 'white', padding: '10px', height: 'auto', borderRadius: '10px' }}>
                          <Box display="flex" justifyContent="space-between">
                            <p style={{ fontSize: '12px', color: '#00C1FE', padding: '2px' }}>Email</p>
                            <p style={{ fontSize: '10px', backgroundColor: '#FFE5E5', color: 'red', padding: '2px' }}>
                              No response
                            </p>
                          </Box>
                        </Box>
                      </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot
                          sx={{
                            backgroundColor: '#F50100',
                            borderColor: '#F50100',
                            borderRadius: '50%',
                            padding: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center', // Center icon in dot
                          }}
                        >
                          <Icon icon="material-symbols:call" style={{ fontSize: '18px', color: 'white' }} />
                        </TimelineDot>
                        <TimelineConnector sx={{ height: '40px' }} />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Box sx={{ backgroundColor: 'white', padding: '10px', height: 'auto', borderRadius: '10px' }}>
                          <Box display="flex" justifyContent="space-between">
                            <p style={{ fontSize: '12px', color: '#00C1FE', padding: '2px' }}>Email</p>
                            <p style={{ fontSize: '10px', backgroundColor: '#FFE5E5', color: 'red', padding: '2px' }}>
                              Not Interested
                            </p>
                          </Box>
                          <Box sx={{ padding: '5px' }} display="flex" justifyContent="space-between" alignItems='center'>
                            <p style={{ fontSize: '12px', padding: '2px' }}>Not interested in your job role</p>
                            <p style={{ fontSize: '10px' }}>07/07/2024 7:23 PM</p>
                          </Box>
                        </Box>
                      </TimelineContent>
                    </TimelineItem>
                  </Timeline>
                </Box>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Box>
      <Box sx={{ backgroundColor: '#E3F3FF', padding: '30px', marginTop: '20px' }}>
        <Typography
          variant="subtitle"
          sx={{ fontWeight: 'bold', display: 'flex', marginLeft: '20px', marginTop: '10px' }}
        >
          {' '}
          <Icon icon="ep:arrow-down" style={{ fontSize: '20px', marginRight: '10px' }} /> Designer
        </Typography>

        {/* Main Timeline */}
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {data.map((user, index) => (
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineDot
                  sx={{
                    backgroundColor: '#00C1FE',
                    height: '35px',
                    width: '35px',
                    padding: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {index + 1}
                </TimelineDot>
                <TimelineConnector sx={{ backgroundColor: '#00C1FE' }} />
              </TimelineSeparator>
              <TimelineContent>
                <Box>
                  <Typography variant="h6" color="#00C1FE">
                    {user.name}
                  </Typography>
                  <Timeline
                    sx={{
                      [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                      },
                    }}
                  >
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot
                          sx={{
                            backgroundColor: '#3FC28A',
                            borderColor: '#3FC28A',
                            borderRadius: '50%',
                            padding: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center', // Center icon in dot
                          }}
                        >
                          <Icon icon="ic:baseline-email" style={{ fontSize: '18px', color: 'white' }} />
                        </TimelineDot>
                        <TimelineConnector sx={{ height: '40px' }} />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Box sx={{ backgroundColor: 'white', padding: '10px', height: 'auto', borderRadius: '10px' }}>
                          <Box display="flex" justifyContent="space-between">
                            <p style={{ fontSize: '12px', color: '#00C1FE', padding: '2px' }}>Email</p>
                            <p style={{ fontSize: '10px', backgroundColor: '#FFE5E5', color: 'red', padding: '2px' }}>
                              No response
                            </p>
                          </Box>
                        </Box>
                      </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot
                          sx={{
                            backgroundColor: '#3FC28A',
                            borderColor: '#3FC28A',
                            borderRadius: '50%',
                            padding: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center', // Center icon in dot
                          }}
                        >
                          <Icon icon="ic:baseline-email" style={{ fontSize: '18px', color: 'white' }} />
                        </TimelineDot>
                        <TimelineConnector sx={{ height: '40px' }} />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Box sx={{ backgroundColor: 'white', padding: '10px', height: 'auto', borderRadius: '10px' }}>
                          <Box display="flex" justifyContent="space-between">
                            <p style={{ fontSize: '12px', color: '#00C1FE', padding: '2px' }}>Email</p>
                            <p style={{ fontSize: '10px', backgroundColor: '#FFE5E5', color: 'red', padding: '2px' }}>
                              No response
                            </p>
                          </Box>
                          {/* <Box sx={{ padding: '5px' }}>
                            <p style={{ fontSize: '12px', padding: '2px' }}>Not interested</p>
                          </Box> */}
                        </Box>
                      </TimelineContent>
                    </TimelineItem>
                  </Timeline>
                </Box>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Box>
    </div>
  );
};
