import { ArrowBack } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CandidatesSection } from 'src/sections/direct360';

function AllCandidates(props) {
  const navigate = useNavigate();

  return (
    <div style={{ width: '97%', margin: 'auto' }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <ArrowBack
          fontSize="small"
          onClick={() => {
            navigate('/recruiter/app');
          }}
          sx={{
            color: '#00C1FE',
            cursor: 'pointer',
          }}
        />
        &nbsp;&nbsp;{' '}
        <Typography
          variant="subtitle2"
          sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}
          onClick={() => {
            navigate('/recruiter/app');
          }}
        >
          Back to Dashboard
        </Typography>
      </Box>
      <CandidatesSection />
    </div>
  );
}

export default AllCandidates;
