import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  Checkbox,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewResume } from './components/images';
import { JobDescriptionDialog } from './components';

function JobOpeningsSection(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2); // Default rows per page
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]); // State for selected rows
  const location = useLocation(); // Use the hook to get the location
  const [openViewJd, setOpenViewJd] = useState(false);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const data = [
    {
      job_id: 'UOL-354-SASfdSS',
      posting_title: 'Supervisor',
      assigned_recruiter: 'Miss xyz',
      assigned_acm: 'Amna',
      target_date: '12/30/2022',
      status: 'In progress',
      city: 'London',
    },
    {
      job_id: 'UOL-364-SASSS',
      posting_title: 'Supervisor',
      assigned_recruiter: 'Miss xyz',
      assigned_acm: 'Amna',
      target_date: '12/30/2022',
      status: 'In progress',
      city: 'London',
    },
    {
      job_id: 'UOL-364-SASSS',
      posting_title: 'Supervisor',
      assigned_recruiter: 'Miss xyz',
      assigned_acm: 'Amna',
      target_date: '12/30/2022',
      status: 'In progress',
      city: 'London',
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSelect = (id) => {
    setSelected((prev) => (prev.includes(id) ? prev.filter((candidateId) => candidateId !== id) : [...prev, id]));
  };

  return (
    <div style={{ width: '97%', margin: 'auto', height: '79vh' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <p style={{ fontSize: '32px', fontWeight: 'bold', marginTop: '10px' }}>Job Openings</p>
        <Box display="flex">
          <Button
            startIcon={<Icon icon="tabler:plus" style={{ fontSize: '20px' }} />}
            sx={{
              background: '#00C1FE',
              color: '#fff',
              height: '45px',
              border: '1px solid #00C1FE',
              '&:hover': {
                background: '#00C1FE',
                color: '#fff',
              },
              borderRadius: '15px',
              padding: '25px',
              marginLeft: '10px',
            }}
            onClick={() =>
              navigate(`/recruiter/toDos/addJob`, {
                state: { previousPath: location.pathname }, // Pass the current path as state
              })
            }
          >
            Add Job
          </Button>
          <Button
            startIcon={<Icon icon="iconamoon:arrow-down-2" style={{ fontSize: '20px' }} />}
            sx={{
              background: '#00C1FE',
              color: '#fff',
              height: '45px',
              border: '1px solid #00C1FE',
              '&:hover': {
                background: '#00C1FE',
                color: '#fff',
              },
              borderRadius: '15px',
              padding: '25px',
              marginLeft: '10px',
              marginRight: '20px',
            }}
            onClick={handleOpen}
          >
            Import Job
          </Button>
        </Box>
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            p: 0,
            marginTop: '10px',
            width: '12%',
            borderRadius: '25px',
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: '25px',
            },
          },
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          p={1}
          justifyContent="center"
          marginTop="auto"
          marginBottom="auto"
        >
          <p style={{ fontSize: '18px', color: '#838383', marginTop: '5px', cursor: 'pointer' }}>Import Job Opening</p>
          <p style={{ fontSize: '18px', color: '#838383', marginTop: '10px', marginBottom: '5px', cursor: 'pointer' }}>
            Import Notes
          </p>
        </Box>
      </Popover>
      <br />
      {selected?.length > 0 ? (
        <>
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <p style={{ fontSize: '20px', color: '#838383' }}>{selected?.length} &nbsp; Job(s) Selected</p>
          </Box>
        </>
      ) : null}
      <br />
      <Box sx={{ padding: 2, borderRadius: 4, backgroundColor: 'white' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Job Opening ID</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Posting Title</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Assigned Recruiter</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Assigned ACM</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Target Date</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Status</TableCell>
                <TableCell style={{ textAlign: 'left' }}>City</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Paginate the rows */}
              {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                <TableRow key={index}>
                  <Box display="flex" alignItems="center" justifyContent="space-around">
                    {/* Checkbox for selection */}
                    <Checkbox
                      checked={selected?.includes(row.job_id)}
                      onChange={() => handleSelect(row.job_id)}
                      inputProps={{ 'aria-label': 'select row' }}
                      sx={{
                        color: '#808080',
                        '&.Mui-checked': {
                          color: '#00C1FE', // Set checkbox color to blue when checked
                        },
                      }}
                    />
                    <Tooltip title="View JD" placement="top">
                      <img
                        src={ViewResume}
                        alt="View CV"
                        width="18px"
                        height="18px"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setOpenViewJd(true)}
                      />
                    </Tooltip>
                  </Box>
                  <TableCell>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {/* Candidate name */}
                      <p
                        style={{
                          margin: 0,
                        }}
                      >
                        {row.job_id}
                      </p>
                    </div>
                  </TableCell>
                  <TableCell>
                    <p
                      style={{ color: '#00C1FE', fontWeight: 'bold', cursor: 'pointer' }}
                      onClick={() =>
                        navigate(`/recruiter/jobDetails/1`)
                      }
                    >
                      {row.posting_title}
                    </p>
                  </TableCell>
                  <TableCell>
                    <p>{row.assigned_recruiter}</p>
                  </TableCell>
                  <TableCell>
                    <p>{row.assigned_acm}</p>
                  </TableCell>
                  <TableCell>
                    <p>{row.target_date}</p>
                  </TableCell>
                  <TableCell>
                    <p>{row.status}</p>
                  </TableCell>
                  <TableCell>
                    <p>{row.city}</p>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination component */}
        <TablePagination
          component="div"
          count={data.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[2, 5, 10]} // Optional: Different options for rows per page
        />
      </Box>
      {openViewJd && <JobDescriptionDialog open={openViewJd} onClose={() => setOpenViewJd(false)} />}
    </div>
  );
}

export default JobOpeningsSection;
