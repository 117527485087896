import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function ScheduleEmailForCandidate({ open, onClose }) {
  const [selectedOption, setSelectedOption] = useState('afterHours');
  const [hours, setHours] = useState(1);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(new Date());

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        hideBackdrop={false}
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{
          sx: {
            ml: 'auto',
            mt: 'auto',
            mb: 'auto',
            mr: 'auto',
            width: '100%',
            maxWidth: '850px',
            height: '400px',
          },
        }}
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#E2F2FF',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" className="ml-8">
              Schedule At
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <br />
          <RadioGroup value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
            {/* Option 1: Send mail after X hours */}
            <Box display="flex" alignItems="center" mb={2}>
              <FormControlLabel
                value="afterHours"
                control={
                  <Radio
                    sx={{
                      color: '#00C1FE',
                      fontSize: '40px',
                      '&.Mui-checked': {
                        color: '#00C1FE', // Checked color
                      },
                    }}
                  />
                }
                label="Send mail after"
              />
              {selectedOption === 'afterHours' && (
                <Select
                  value={hours}
                  onChange={(e) => setHours(e.target.value)}
                  sx={{ width: 70, ml: 1, height: 40, borderRadius: '15px', border: '1px solid #9DA3AD' }}
                >
                  {[...Array(24).keys()].map((hour) => (
                    <MenuItem key={hour} value={hour + 1}>
                      {hour + 1}
                    </MenuItem>
                  ))}
                </Select>
              )}
              <Typography ml={1}>hours</Typography>
            </Box>

            {/* Option 2: Tomorrow morning */}
            <FormControlLabel
              value="tomorrowMorning"
              control={
                <Radio
                  sx={{
                    color: '#00C1FE',
                    fontSize: '40px',
                    '&.Mui-checked': {
                      color: '#00C1FE', // Checked color
                    },
                  }}
                />
              }
              label="Tomorrow morning (9am)"
            />

            {/* Option 3: Specific date and time */}
            <Box display="flex" alignItems="center" mb={2}>
              <FormControlLabel
                value="specificDate"
                control={
                  <Radio
                    sx={{
                      color: '#00C1FE',
                      fontSize: '40px',
                      '&.Mui-checked': {
                        color: '#00C1FE', // Checked color
                      },
                    }}
                  />
                }
                label="Date"
              />
              {selectedOption === 'specificDate' && (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={selectedDate}
                    onChange={(newDate) => setSelectedDate(newDate)}
                    sx={{
                      ml: 1,
                      width: 130, // Adjust width for smaller size
                      height: 30, // Adjust height for smaller size
                      '& .MuiInputBase-input': {
                        fontSize: '14px', // Smaller font size
                        padding: '8px', // Adjust padding
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#9DA3AD', // Default border color
                        },
                        '&:hover fieldset': {
                          borderColor: '#9DA3AD', // Hover border color
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#9DA3AD', // Focused border color
                        },
                      },
                    }}
                  />
                  <TimePicker
                    value={selectedTime}
                    onChange={(newTime) => setSelectedTime(newTime)}
                    sx={{
                      ml: 2,
                      width: 120, // Adjust width for smaller size
                      height: 30, // Adjust height for smaller size
                      '& .MuiInputBase-input': {
                        fontSize: '14px', // Smaller font size
                        padding: '8px', // Adjust padding
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#9DA3AD', // Default border color
                        },
                        '&:hover fieldset': {
                          borderColor: '#9DA3AD', // Hover border color
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#9DA3AD', // Focused border color
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              )}
            </Box>
          </RadioGroup>
          <br />

          <Box display="flex" justifyContent="flex-end" alignItems="center" marginTop="20px">
            <Button
              sx={{
                background: '#A3A3A3',
                height: '45px',
                border: '1px solid #A3A3A3',
                color: 'white',
                '&:hover': {
                  background: '#A3A3A3',
                  color: 'white',
                },
                borderRadius: '15px',
                padding: '25px',
                marginLeft: '10px',
              }}
              onClick={onClose}
            >
              Schedule & Close
            </Button>
            <Button
              sx={{
                background: '#00C1FE',
                color: '#fff',
                height: '45px',
                border: '1px solid #00C1FE',
                '&:hover': {
                  background: '#00C1FE',
                  color: '#fff',
                },
                borderRadius: '15px',
                padding: '25px',
                marginRight: '10px',
                marginLeft: '10px',
              }}
            >
              Schedule
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ScheduleEmailForCandidate;
