import { Icon } from '@iconify/react';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Job } from './images';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="left"
      ref={ref}
      {...props}
      timeout={{ enter: 1000, exit: 500 }}
      easing={{ enter: 'cubic-bezier(0.4, 0, 0.2, 1)', exit: 'cubic-bezier(0.4, 0, 0.2, 1)' }}
    />
  );
});

function JobDescriptionDialog({ open, onClose, jobDetails }) {
  const [selectedState, setSelectedState] = useState('details');
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        hideBackdrop={false}
        disableBackdropClick
        disableEscapeKeyDown
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            ml: 'auto',
            mt: 'auto',
            mr: '1rem',
            width: '100%',
            maxWidth: '850px',
            height: '98%',
          },
        }}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#E2F2FF',
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" sx={{ backgroundColor: '#E2F2FF', height: '70px' }}>
              <Box display="flex" alignItems="center" justifyContent="flex-start" sx={{ padding: '20px' }}>
                <p
                  style={{
                    marginRight: '20px', // Adds space between avatar and name
                    backgroundColor: '#00C1FE',
                    color: '#fff',
                    width: '65px',
                    height: '65px',
                    fontSize: '40px',
                    borderRadius: '20px',
                    textAlign: 'center',
                    display: 'flex', // Enable flexbox
                    alignItems: 'center', // Vertically center the image
                    justifyContent: 'center', // Horizontally center the image
                  }}
                >
                  <img src={Job} alt="job" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                </p>

                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  <Box display="flex">
                    <Typography variant="subtitle1" sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}>
                      {'Designer'}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" marginTop="5px">
                    <p
                      style={{
                        fontSize: '12px',
                        backgroundColor: '#4CD4FF',
                        borderRadius: '5px',
                        padding: '7px',
                        color: 'white',
                      }}
                    >
                      {'In Progress'}
                    </p>
                    <Button
                      sx={{
                        marginLeft: '10px',
                        padding: '7px',
                        backgroundColor: '#C7E6FF',
                        '&:hover': { backgroundColor: '#C7E6FF' },
                      }}
                      size="small"
                    >
                      <Icon icon="charm:refresh" style={{ fontSize: '18px', color: '#00C1FE' }} />
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: '100%' }}>
            <ToggleButtonGroup
              value={selectedState}
              exclusive
              onChange={(e, value) => {
                if (value !== null) {
                  setSelectedState(value);
                }
              }}
              aria-label="company sections"
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                padding: 10,
                marginLeft: '20px',
              }}
            >
              <ToggleButton
                value="details"
                aria-label="details"
                style={{
                  border: 'none',
                  color: selectedState === 'details' ? '#00C1FE' : '#838383',
                  borderBottom: selectedState === 'details' ? '3px solid #00C1FE' : 'none',
                  borderRadius: 0,
                  paddingBottom: 2,
                  backgroundColor: 'white',
                  fontSize: '20px',
                  fontWeight: 'normal',
                }}
              >
                Details
              </ToggleButton>
              <ToggleButton
                value="jobDescription"
                aria-label="jobDescription"
                style={{
                  border: 'none',
                  color: selectedState === 'jobDescription' ? '#00C1FE' : '#838383',
                  borderBottom: selectedState === 'jobDescription' ? '3px solid  #00C1FE' : 'none',
                  borderRadius: 0,
                  paddingBottom: 2,
                  width: '180px',
                  marginLeft: '10px',
                  backgroundColor: 'white',
                  fontSize: '20px',
                  fontWeight: 'normal',
                }}
              >
                Job Description
              </ToggleButton>
              <ToggleButton
                value="timeline"
                aria-label="timeline"
                style={{
                  border: 'none',
                  color: selectedState === 'timeline' ? '#00C1FE' : '#838383',
                  borderBottom: selectedState === 'timeline' ? '3px solid  #00C1FE' : 'none',
                  borderRadius: 0,
                  paddingBottom: 2,
                  width: '180px',
                  marginLeft: '10px',
                  backgroundColor: 'white',
                  fontSize: '20px',
                  fontWeight: 'normal',
                }}
              >
                Timeline
              </ToggleButton>
              <ToggleButton
                value="attachments"
                aria-label="attachments"
                style={{
                  border: 'none',
                  color: selectedState === 'attachments' ? '#00C1FE' : '#838383',
                  borderBottom: selectedState === 'attachments' ? '3px solid  #00C1FE' : 'none',
                  borderRadius: 0,
                  paddingBottom: 2,
                  width: '180px',
                  marginLeft: '10px',
                  backgroundColor: 'white',
                  fontSize: '20px',
                  fontWeight: 'normal',
                }}
              >
                Attachments
              </ToggleButton>
            </ToggleButtonGroup>
            <hr style={{ width: '100%', margin: 'auto', marginTop: '-10px', color: '#CAC5C5' }} />
          </Box>
          {selectedState === 'details' && (
            <>
              <div style={{ fontFamily: 'Arial, sans-serif', color: '#6b6b6b', padding: '20px' }}>
                <p style={{ fontSize: '21px', color: '#838383', fontWeight: 'bold' }}>Job Opening Information</p>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '22px', marginTop: '10px' }}>
                  <div>
                    <p style={{ fontSize: '18px', color: '#838383', fontWeight: 'bold' }}>Job Opening ID</p>
                    <p style={{ fontSize: '18px', color: '#838383' }}>UOL-890-GHU</p>
                  </div>

                  <div>
                    <p style={{ fontSize: '18px', color: '#838383', fontWeight: 'bold' }}>Posting Title</p>
                    <p style={{ fontSize: '18px', color: '#838383' }}>Developer</p>
                  </div>

                  <div>
                    <p style={{ fontSize: '18px', color: '#838383', fontWeight: 'bold' }}>Number of Postion</p>
                    <p style={{ fontSize: '18px', color: '#838383' }}>1</p>
                  </div>

                  <div>
                    <p style={{ fontSize: '18px', color: '#838383', fontWeight: 'bold' }}>Department Name</p>
                    <p style={{ fontSize: '18px', color: '#838383' }}>----</p>
                  </div>

                  <div>
                    <p style={{ fontSize: '18px', color: '#838383', fontWeight: 'bold' }}>Title</p>
                    <p style={{ fontSize: '18px', color: '#838383' }}>----</p>
                  </div>

                  <div>
                    <p style={{ fontSize: '18px', color: '#838383', fontWeight: 'bold' }}>Assigned Recruiter</p>
                    <p style={{ fontSize: '18px', color: '#838383' }}>----</p>
                  </div>

                  <div>
                    <p style={{ fontSize: '18px', color: '#838383', fontWeight: 'bold' }}>Hiring Manager</p>
                    <p style={{ fontSize: '18px', color: '#838383' }}>Evan</p>
                  </div>

                  <div>
                    <p style={{ fontSize: '18px', color: '#838383', fontWeight: 'bold' }}>Target Date</p>
                    <p style={{ fontSize: '18px', color: '#838383' }}>----</p>
                  </div>

                  <div>
                    <p style={{ fontSize: '18px', color: '#838383', fontWeight: 'bold' }}>Date Opened</p>
                    <p style={{ fontSize: '18px', color: '#838383' }}>03/01/2024</p>
                  </div>

                  <div>
                    <p style={{ fontSize: '18px', color: '#838383', fontWeight: 'bold' }}>Job Opening Status</p>
                    <p style={{ fontSize: '18px', color: '#838383' }}>In Progress</p>
                  </div>

                  <div>
                    <p style={{ fontSize: '18px', color: '#838383', fontWeight: 'bold' }}>Job Type</p>
                    <p style={{ fontSize: '18px', color: '#838383' }}>Full time</p>
                  </div>

                  <div>
                    <p style={{ fontSize: '18px', color: '#838383', fontWeight: 'bold' }}>Industry</p>
                    <p style={{ fontSize: '18px', color: '#838383' }}>----</p>
                  </div>

                  <div>
                    <p style={{ fontSize: '18px', color: '#838383', fontWeight: 'bold' }}>Work Experience</p>
                    <p style={{ fontSize: '18px', color: '#838383' }}>----</p>
                  </div>

                  <div>
                    <p style={{ fontSize: '18px', color: '#838383', fontWeight: 'bold' }}>Required Skills</p>
                    <p style={{ fontSize: '18px', color: '#838383' }}>----</p>
                  </div>
                </div>
              </div>
            </>
          )}
          {selectedState === 'jobDescription' && (
            <Box display="flex" alignItems="center" margin="20px">
              <p style={{ fontSize: '21px', color: '#838383', fontWeight: 'bold' }}>No job description available</p>
            </Box>
          )}
          {selectedState === 'timeline' && (
            <Box display="flex" alignItems="center" margin="20px">
              <p style={{ fontSize: '21px', color: '#838383', fontWeight: 'bold' }}>No timeline available</p>
            </Box>
          )}
          {selectedState === 'attachments' && (
            <Box display="flex" alignItems="center" margin="20px">
              <p style={{ fontSize: '21px', color: '#838383', fontWeight: 'bold' }}>No attachments available</p>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default JobDescriptionDialog;
