import { Icon } from '@iconify/react';
import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function CreateInterview({ open, onClose }) {
  const navigate = useNavigate()
  const location = useLocation(); // Use the hook to get the location
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        hideBackdrop={false}
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '800px',
          },
        }}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#F4F9FD',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" className="ml-8">
              Create Interview
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <br />
          <Box>
            {/* Internal Interview */}
            <Box
              display="flex"
              alignItems="center"
              sx={{
                padding: '10px',
                border: '1px solid #E6EAEF',
                borderRadius: '20px',
                cursor: 'pointer',
                marginBottom: '15px',
                height: '140px',
              }}
              onClick={() =>
                navigate('/recruiter/internalInterview', {
                  state: { previousPath: location.pathname }, // Pass the current path as state
                })
              }
            >
              <Box
                sx={{ marginRight: '15px', width: '25%' }}
                justifyContent="center"
                alignItems="center"
                display="flex"
              >
                <Icon icon="f7:person-2-fill" style={{ width: '60px', height: '60px', color: '#00C1FE' }} />
              </Box>
              <Box sx={{ borderLeft: '1px solid #E6EAEF', width: '75%' }}>
                <Typography variant="h6" sx={{ marginLeft: '30px' }}>
                  Internal Interview
                </Typography>
                <Typography variant="body2" sx={{ marginLeft: '30px', color: '#848B94' }}>
                  Schedule and conduct Interviews with Candidates, Interviewers, Recruiters and Contact.
                </Typography>
              </Box>
            </Box>

            {/* Online Interview */}
            <Box
              display="flex"
              alignItems="center"
              sx={{
                padding: '10px',
                border: '1px solid #E6EAEF',
                borderRadius: '20px',
                cursor: 'pointer',
                marginBottom: '15px',
                height: '140px',
              }}
              onClick={() =>
                navigate('/recruiter/onlineInterview', {
                  state: { previousPath: location.pathname }, // Pass the current path as state
                })
              }
            >
              <Box
                sx={{ marginRight: '15px', width: '25%' }}
                justifyContent="center"
                alignItems="center"
                display="flex"
              >
                <Icon icon="fluent:video-28-filled" style={{ width: '60px', height: '60px', color: '#00C1FE' }} />
              </Box>
              <Box sx={{ borderLeft: '1px solid #E6EAEF', width: '75%' }}>
                <Typography variant="h6" sx={{ marginLeft: '30px' }}>
                  Online Interview
                </Typography>
                <Typography variant="body2" sx={{ marginLeft: '30px', color: '#848B94' }}>
                  Schedule and conduct both recorded and live video Interviews with Candidates, Recruiters and
                  Interviewers.
                </Typography>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CreateInterview;
