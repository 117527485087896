import React from 'react';
import { LimitSettingsSection } from 'src/settings';
function LimitSettingPage() {
  return (
    <>
      <LimitSettingsSection />
    </>
  );
}

export default LimitSettingPage;
