import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function ToDoSection(props) {
  const data = [
    {
      subject: 'Follow call',
      to_do_type: 'Call',
      from: 'Jan 30, 2020 05:00',
      to: 'Jan 30, 2020 06:00',
      status: 'Pending',
      priority: 'high',
    },
    {
      subject: 'Conversation call',
      to_do_type: 'Call',
      from: 'Jan 30, 2020 05:00',
      to: 'Jan 30, 2020 06:00',
      status: 'Pending',
      priority: 'normal',
    },
    {
      subject: 'Conversation call',
      to_do_type: 'Call',
      from: 'Jan 30, 2020 05:00',
      to: 'Jan 30, 2020 06:00',
      status: 'Pending',
      priority: 'Strong',
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2); // Default rows per page
  const navigate = useNavigate();
  const location = useLocation(); // Use the hook to get the location
  return (
    <div style={{ width: '97%', margin: 'auto', height: '79vh' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <p style={{ fontSize: '32px', fontWeight: 'bold', marginTop: '10px' }}>All To-Dos</p>
        <Box display="flex">
          <Button
            startIcon={<Icon icon="tabler:plus" style={{ fontSize: '20px' }} />}
            sx={{
              background: '#00C1FE',
              color: '#fff',
              height: '45px',
              border: '1px solid #00C1FE',
              '&:hover': {
                background: '#00C1FE',
                color: '#fff',
              },
              borderRadius: '15px',
              padding: '25px',
              marginLeft: '10px',
            }}
            onClick={() =>
              navigate(`/recruiter/toDos/createTask`, {
                state: { previousPath: location.pathname }, // Pass the current path as state
              })
            }
          >
            Task
          </Button>
          <Button
            startIcon={<Icon icon="tabler:plus" style={{ fontSize: '20px' }} />}
            sx={{
              background: '#00C1FE',
              color: '#fff',
              height: '45px',
              border: '1px solid #00C1FE',
              '&:hover': {
                background: '#00C1FE',
                color: '#fff',
              },
              borderRadius: '15px',
              padding: '25px',
              marginLeft: '10px',
            }}
          >
            Event
          </Button>
          <Button
            startIcon={<Icon icon="tabler:plus" style={{ fontSize: '20px' }} />}
            sx={{
              background: '#00C1FE',
              color: '#fff',
              height: '45px',
              border: '1px solid #00C1FE',
              '&:hover': {
                background: '#00C1FE',
                color: '#fff',
              },
              borderRadius: '15px',
              padding: '25px',
              marginLeft: '10px',
            }}
            onClick={() =>
              navigate(`/recruiter/toDos/createCall`, {
                state: { previousPath: location.pathname }, // Pass the current path as state
              })
            }
          >
            Call
          </Button>
          <Button
            sx={{
              background: '#00C1FE',
              color: '#fff',
              height: '45px',
              border: '1px solid #00C1FE',
              '&:hover': {
                background: '#00C1FE',
                color: '#fff',
              },
              borderRadius: '15px',
              padding: '25px',
              marginLeft: '10px',
              marginRight: '10px',
              textTransform: 'none',
            }}
            onClick={() =>
              navigate(`/recruiter/toDos/callHistory`, {
                state: { previousPath: location.pathname }, // Pass the current path as state
              })
            }
          >
            Call history
          </Button>
        </Box>
      </Box>
      <br />
      <Box sx={{ padding: 2, borderRadius: 4, backgroundColor: 'white' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <TableCell style={{ textAlign: 'center' }}>Subject</TableCell>
                <TableCell style={{ textAlign: 'center' }}>To-Do-Type</TableCell>
                <TableCell style={{ textAlign: 'center' }}>From</TableCell>
                <TableCell style={{ textAlign: 'center' }}>Due Date</TableCell>
                <TableCell style={{ textAlign: 'center' }}>Status</TableCell>
                <TableCell style={{ textAlign: 'center' }}>Priority</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Paginate the rows */}
              {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <p
                      style={{
                        textAlign: 'center',
                        margin: 'auto',
                        color: '#4CD4FF',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                      }}
                      onClick={() => {
                        navigate('/recruiter/toDos/callDetails/1');
                      }}
                    >
                      {row.subject}
                    </p>
                  </TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>
                    <p
                      style={{
                        textAlign: 'center',
                        margin: 'auto',
                      }}
                    >
                      {row.to_do_type}
                    </p>
                  </TableCell>
                  <TableCell>
                    <p
                      style={{
                        textAlign: 'center',
                        margin: 'auto',
                      }}
                    >
                      {row.from}
                    </p>
                  </TableCell>
                  <TableCell>
                    <p
                      style={{
                        textAlign: 'center',
                        margin: 'auto',
                      }}
                    >
                      {row.to}
                    </p>
                  </TableCell>
                  <TableCell>
                    <p
                      style={{
                        textAlign: 'center',
                        margin: 'auto',
                      }}
                    >
                      {row.status}
                    </p>
                  </TableCell>
                  <TableCell>
                    <p
                      style={{
                        textAlign: 'center',
                        margin: 'auto',
                      }}
                    >
                      {row.priority}
                    </p>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination component */}
        <TablePagination
          component="div"
          count={data.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[2, 5, 10]} // Optional: Different options for rows per page
        />
      </Box>
    </div>
  );
}

export default ToDoSection;
