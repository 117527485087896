import React, { useState, useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Autocomplete,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import useFetchRoleUsers from './components/useFetchRoleUsers';
import { Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { useSnackbar } from 'notistack';

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  limit: Yup.number().min(1, 'Limit must be greater than zero').required('Limit is required'),
  applyToAll: Yup.boolean(),
});

const UserLimitForm = () => {
  const [interval, setInterval] = useState(50);
  const { enqueueSnackbar } = useSnackbar();
  const { users, loading, error } = useFetchRoleUsers('accounts');
  const allLimits = [
    50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750, 800, 850, 900, 950, 1000,
  ];

  // Filtered limits based on selected interval
  const filteredLimits = useMemo(() => {
    return allLimits.filter((limit) => limit % interval === 0);
  }, [interval]);

  const initialValues = {
    limit: 0,
    acms: [],
    acmIds:[],
    applyToAll: true,
  };

  const handleSubmitLimits = async (values) => {
    try {
        Post(
          values,
          API_URLS.createLimitSettings,
          (resp) => {
            enqueueSnackbar(resp.message, { variant: 'success' });
          },
          (error) => {
            enqueueSnackbar(error.message, { variant: 'error' });
          }
        );
    } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });        
    }
  };
  return (
    <Paper elevation={3} style={{ padding: '20px', maxWidth: '500px', margin: 'auto' }}>
      <Typography variant="h5" gutterBottom align="center">
        Set User Limits
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmitLimits(values);
        }}
      >
        {({ values, errors, touched, handleChange, setFieldValue, handleSubmit }) => (
          <Form>
            <Grid container spacing={2}>
              {/* Interval Selection */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox checked={interval === 50} onChange={() => setInterval(50)} />}
                  label="Interval 50"
                />
                <FormControlLabel
                  control={<Checkbox checked={interval === 100} onChange={() => setInterval(100)} />}
                  label="Interval 100"
                />
                <FormControlLabel
                  control={<Checkbox checked={interval === 200} onChange={() => setInterval(200)} />}
                  label="Interval 200"
                />
              </Grid>

              {/* Limit Selection */}
              <Grid item xs={12}>
                <Autocomplete
                  options={filteredLimits}
                  getOptionLabel={(option) => option.toString()}
                  onChange={(event, newValue) => {
                    setFieldValue('limit', parseInt(newValue));
                  }}
                  value={values.limit}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Limit"
                      variant="outlined"
                      fullWidth
                      error={touched.limit && Boolean(errors.limit)}
                      helperText={touched.limit && errors.limit}
                    />
                  )}
                />
              </Grid>

              {/* Apply to All Checkbox */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.applyToAll}
                      onChange={(event) => {
                        const isChecked = event.target.checked;
                        setFieldValue('applyToAll', isChecked);
                        if (isChecked) {
                          setFieldValue('acms', []); // Clear ACMs if applying to all
                        }
                      }}
                    />
                  }
                  label="Apply to All"
                />
              </Grid>

              {/* ACM Selection */}
              {!values.applyToAll && (
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    options={users}
                    getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
                    onChange={(event, newValue) => {
                      setFieldValue('acms', newValue);
                        setFieldValue(
                          'acmIds',
                          newValue.map((acm) => acm.id)
                        );
                    }}
                    value={values.acms}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select ACM"
                        variant="outlined"
                        fullWidth
                        error={touched.acms && Boolean(errors.acms)}
                        helperText={touched.acms && errors.acms}
                      />
                    )}
                  />
                </Grid>
              )}

              {/* Submit Button */}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};

export default UserLimitForm;