import { Box } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import React from 'react';

function TeamSheetComponent({ data, loadingData, resourcer }) {

  const columns = [
    {
      accessorKey: 'fullName',
      header: 'Resourcer',
      Cell: ({ cell }) => (
        <Box component="span">
          <>
            <span style={{  color: '#00C1FE', cursor : 'pointer' }} onClick={() => {
              resourcer(cell?.row?.original?.resourcer)
            }}>
              {cell.getValue()}
            </span>
          </>
        </Box>
      ),
    },
    {
      accessorKey: 'roles',
      header: 'No of Roles',
    },
    {
      accessorKey: 'cvUploads',
      header: 'No of CVs',
    },
    {
      accessorKey: 'liProfiles',
      header: 'No of LIS',
    },
    {
      accessorKey: 'avgcvs',
      header: 'Avg CVs',
      // Cell :({cell})=>{
      //   cell?.row?.original?.avgcvs > 0 ? <span style={{color : 'green'}}>{cell?.row?.original?.avgcvs}</span> : <span style={{color : 'red'}}>{cell?.row?.original?.avgcvs}</span>
      // }
    },
    {
      accessorKey: 'avgLis',
      header: 'Avg LIS',
    },
  ];

  return (
    <div style={{ margin: '2rem' }}>
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 50,
          },
        }}
        columns={columns}
        data={data}
        defaultColumn={{
          size: 100, //make columns wider by default
        }}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableSorting={false}
        enableTopToolbar={false}
        positionToolbarAlertBanner="bottom"
        manualFiltering
        state={{
          isLoading: loadingData,
        }}
        actionsColumnIndex={-1}
      />
    </div>

  );
}

export default TeamSheetComponent;
