import React from 'react';
import { DatabaseCandidatesSection } from 'src/sections/direct360';

function CandidatesPage(props) {
  return (
    <div>
      <DatabaseCandidatesSection />
    </div>
  );
}

export default CandidatesPage;
