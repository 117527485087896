import React, { useCallback, useEffect, useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { TextField, Button, Grid, Typography, Box, Autocomplete } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { API_URLS } from 'src/constants/apiURLs';
import { Get } from 'src/actions/API/apiActions';

const monthsBackEnums = [
  {
    months_back: 1,
    lastActive: '1 day',
  },
  {
    months_back: 1,
    lastActive: '2 days',
  },
  {
    months_back: 1,
    lastActive: '1 Week',
  },
  {
    months_back: 1,
    lastActive: '2 Weeks',
  },
  {
    months_back: 1,
    lastActive: '1 Month',
  },
  {
    months_back: 3,
    lastActive: '3 Months',
  },
  {
    months_back: 6,
    lastActive: '6 Months',
  },
  {
    months_back: 12,
    lastActive: '12 Months',
  },
  {
    months_back: 24,
    lastActive: '24 Months',
  },
  {
    months_back: 36,
    lastActive: 'More than 24 Months',
  },
];

// const salaryUnitEnums = [
//   {
//     unit: 'p.h',
//     value: 'Per Hour',
//   },
//   {
//     unit: 'p.d',
//     value: 'Per Day',
//   },
//   {
//     unit: 'p.w',
//     value: 'Per Week',
//   },
//   {
//     unit: 'p.m',
//     value: 'Per Month',
//   },
//   {
//     unit: 'p.a',
//     value: 'Per Annum',
//   },
// ];

const ResumeForm = ({ initialValues, onSubmit }) => {

  const [jobBoards, setJobBoards] = useState([]);
  const [selectedJobBoard, setSelectedJobBoard] = useState(null);
  const [selectedMonthsBack, setSelectedMonthsBack] = useState(null);
  // const [selectedSalary, setSelectedSalary] = useState(null);

  const emptyValues = {
    address: {
      city: '',
      country: '',
      postalCode: '',
      street: '',
    },
    currentJobTitle: '',
    domainAndField: {
      domain: '',
      industry: '',
    },
    education: [
      {
        degree: '',
        graduationYear: '',
        institution: '',
      },
    ],
    email: '',
    experience: [
      {
        company: '',
        duration: '',
        jobTitle: '',
      },
    ],
    fullName: '',
    phoneNumber: '',
    salary: null,
    cv_job_board: null,
    months_back: null,
    radius_miles: null,
    last_active: null,
    salary_unit: null,
    min_salary: null,
    max_salary: null,
  };

  const formValues = initialValues || emptyValues;
  console.log('initialValues', initialValues, formValues, emptyValues);

  const getAllJobBoards = useCallback(()=>{
    try {
      Get(
        {},
        API_URLS.getAllJobBoards,
        (resp) => {
          console.log(resp);
          setJobBoards(resp?.data);
        },
        (error) => {
          console.log(error);
        }
      );
  } catch (error) {
      console.log(error);
  }
  },[])

  useEffect(() => {
    getAllJobBoards();
  }, []);
  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={(values, actions) => {
        onSubmit(values, actions);
      }}
    >
      {({ values, getFieldProps, handleSubmit, setFieldValue }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">About CV Source</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                <Autocomplete
                  id="cv_job_board"
                  size="small"
                  options={jobBoards}
                  fullWidth
                  value={selectedJobBoard}
                  getOptionLabel={(option) => option?.name}
                  onChange={(event, newJobBoard) => {
                    if (newJobBoard) {
                      setSelectedJobBoard(newJobBoard);
                      setFieldValue('cv_job_board', newJobBoard.name);
                    } else {
                      setSelectedJobBoard(null);
                      setFieldValue('cv_job_board', null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Job Board"
                      fullWidth
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        marginBottom: '3px',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  )}
                />
                {/* <TextField
                  size="small"
                  {...getFieldProps('cv_job_board')}
                  fullWidth
                  placeholder="Job Board or CV Source name?"
                  required
                /> */}
                <Autocomplete
                  id="months_back"
                  size="small"
                  options={monthsBackEnums}
                  fullWidth
                  value={selectedMonthsBack}
                  getOptionLabel={(option) => option?.lastActive}
                  onChange={(event, newMonthsBack) => {
                    if (newMonthsBack) {
                      setSelectedMonthsBack(newMonthsBack);
                      setFieldValue('months_back', newMonthsBack.months_back);
                      setFieldValue('last_active', newMonthsBack.lastActive);
                    } else {
                      setSelectedMonthsBack(null);
                      setFieldValue('months_back', null);
                      setFieldValue('last_active', null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Months Back"
                      fullWidth
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        marginBottom: '3px',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  )}
                />
                <TextField
                  size="small"
                  {...getFieldProps('radius_miles')}
                  fullWidth
                  label="Radius Miles"
                  placeholder="Radius Miles"
                  required
                />
                <TextField
                  size="small"
                  {...getFieldProps('salary')}
                  fullWidth
                  placeholder="Salary Min"
                  required
                  label="Salary Min"
                />
                {/* <TextField
                  size="small"
                  {...getFieldProps('min_salary')}
                  fullWidth
                  placeholder="Salary Min"
                  required
                  type="number"
                  label="Salary Min"
                />
                <TextField
                  size="small"
                  {...getFieldProps('max_salary')}
                  fullWidth
                  placeholder="Salary Max"
                  type="number"
                  required
                  label="Salary Max"
                /> */}
                {/* <Autocomplete
                  id="salary_unit"
                  size="small"
                  fullWidth
                  options={salaryUnitEnums}
                  maxWidth="100px"
                  value={selectedSalary}
                  getOptionLabel={(option) => option?.value}
                  onChange={(event, newSalaryUnit) => {
                    if (newSalaryUnit) {
                      setSelectedSalary(newSalaryUnit);
                      setFieldValue('salary_unit', newSalaryUnit.unit);
                    } else {
                      setSelectedSalary(null);
                      setFieldValue('salary_unit', null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Unit"
                      fullWidth
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        marginBottom: '3px',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  )}
                /> */}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={() => {
                  if (
                    values.cv_job_board &&
                    values.months_back &&
                    values.radius_miles &&
                    values.salary
                  ) {
                    handleSubmit();
                  } else {
                    alert('Please fill all the fields');
                  }
                }}
                fullWidth
                sx={{
                  mt: 2,
                  padding: '15px',
                  display: 'flex',
                  marginBottom: '10px',
                  justifyContent: 'center',
                  backgroundColor: '#2BCBFE',
                  color: 'white',
                  '&:hover': {
                    background: '#00C1FE',
                    color: '#fff',
                  },
                }}
              >
                Add CV to this role
              </Button>
            </Grid>
            <Grid item xs={4}>
              <label htmlFor="fullName" style={{ color: '#979797' }}>
                Full Name
              </label>
              <TextField size="small" {...getFieldProps('fullName')} fullWidth />
            </Grid>
            <Grid item xs={4}>
              <label htmlFor="fullName" style={{ color: '#979797' }}>
                Phone Number
              </label>
              <TextField size="small" {...getFieldProps('phoneNumber')} fullWidth />
            </Grid>
            <Grid item xs={4}>
              <label htmlFor="fullName" style={{ color: '#979797' }}>
                Email
              </label>
              <TextField size="small" {...getFieldProps('email')} fullWidth />
            </Grid>
            <Grid item xs={4}>
              <label htmlFor="fullName" style={{ color: '#979797' }}>
                Current Job Title
              </label>
              <TextField size="small" {...getFieldProps('currentJobTitle')} fullWidth />
            </Grid>
            <Grid item xs={4}>
              <label htmlFor="fullName" style={{ color: '#979797' }}>
                Domain
              </label>
              <TextField size="small" {...getFieldProps('domainAndField.domain')} fullWidth />
            </Grid>
            <Grid item xs={4}>
              <label htmlFor="fullName" style={{ color: '#979797' }}>
                Indusrty
              </label>
              <TextField size="small" {...getFieldProps('domainAndField.industry')} fullWidth />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Address</Typography>
            </Grid>
            <Grid item xs={6}>
              <label htmlFor="fullName" style={{ color: '#979797' }}>
                City
              </label>
              <TextField size="small" {...getFieldProps('address.city')} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <label htmlFor="fullName" style={{ color: '#979797' }}>
                Country
              </label>
              <TextField size="small" {...getFieldProps('address.country')} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <label htmlFor="fullName" style={{ color: '#979797' }}>
                Postal Code
              </label>
              <TextField size="small" {...getFieldProps('address.postalCode')} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <label htmlFor="fullName" style={{ color: '#979797' }}>
                Street
              </label>
              <TextField size="small" {...getFieldProps('address.street')} fullWidth />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Education</Typography>
              <br />
              <FieldArray name="education">
                {({ push, remove }) => (
                  <div>
                    {values?.education?.map((_, index) => (
                      <div key={index}>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <label htmlFor="fullName" style={{ color: '#979797' }}>
                              Degree
                            </label>
                            <TextField size="small" {...getFieldProps(`education[${index}].degree`)} fullWidth />
                          </Grid>
                          <Grid item xs={4}>
                            <label htmlFor="fullName" style={{ color: '#979797' }}>
                              Graduation year
                            </label>
                            <TextField
                              size="small"
                              {...getFieldProps(`education[${index}].graduationYear`)}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <label htmlFor="fullName" style={{ color: '#979797' }}>
                              Institution
                            </label>
                            <TextField size="small" {...getFieldProps(`education[${index}].institution`)} fullWidth />
                          </Grid>
                          <Grid item xs={12}>
                            <Button type="button" onClick={() => remove(index)}>
                              <Delete style={{ color: '#00C1FE' }} />
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                    <Button
                      type="button"
                      onClick={() => push({ degree: '', graduationYear: '', institution: '' })}
                      sx={{ color: '#00C1FE' }}
                    >
                      Add Education
                    </Button>
                  </div>
                )}
              </FieldArray>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Experience</Typography>
              <br />
              <FieldArray name="experience">
                {({ push, remove }) => (
                  <div>
                    {values?.experience?.map((_, index) => (
                      <div key={index}>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <label htmlFor="fullName" style={{ color: '#979797' }}>
                              company
                            </label>
                            <TextField size="small" {...getFieldProps(`experience[${index}].company`)} fullWidth />
                          </Grid>
                          <Grid item xs={4}>
                            <label htmlFor="fullName" style={{ color: '#979797' }}>
                              Duration
                            </label>
                            <TextField size="small" {...getFieldProps(`experience[${index}].duration`)} fullWidth />
                          </Grid>
                          <Grid item xs={4}>
                            <label htmlFor="fullName" style={{ color: '#979797' }}>
                              Job Title
                            </label>
                            <TextField size="small" {...getFieldProps(`experience[${index}].jobTitle`)} fullWidth />
                          </Grid>
                          <Grid item xs={12}>
                            <Button type="button" onClick={() => remove(index)}>
                              <Delete style={{ color: '#00C1FE' }} />
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                    <Button
                      type="button"
                      onClick={() => push({ company: '', duration: '', jobTitle: '' })}
                      sx={{ color: '#00C1FE' }}
                    >
                      Add Experience
                    </Button>
                  </div>
                )}
              </FieldArray>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ResumeForm;
