import React from 'react';
import MainEmailSearchSection from 'src/sections/leadFinder/MainEmailSearchSection';

function EmailResearcherPage(props) {
    return (
        <div>
            <MainEmailSearchSection />
        </div>
    );
}

export default EmailResearcherPage;