import React from 'react';
import { RolesPage } from 'src/sections/resourcing';

function ResourcingRoles(props) {
  return (
    <div>
      <RolesPage />
    </div>
  );
}

export default ResourcingRoles;
