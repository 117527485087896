import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import './chat.css';
import { styled } from '@mui/styles';
import { Icon } from '@iconify/react';
import { Alert, Avatar, Box, Button, CircularProgress, Snackbar, TextField, Typography } from '@mui/material';
import { Send } from '@mui/icons-material';
import { ref, set, onValue } from 'firebase/database';
import database from 'src/config/firebase';
const db = database;

const socket = io('https://zdr4wk5m-3001.inc1.devtunnels.ms/');

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const Chat = ({ selectedCandidate, title, location, onClose, onMessageSent }) => {
  // console.log('selectedCandidate', selectedCandidate);
  const [messages, setMessages] = useState([]);
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [isError, setIsError] = useState(false);
  const [showMediaMessage, setShowMediaMessage] = useState('');
  const [isSendingFile, setIsSendingFile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [input, setInput] = useState();

  // Fetch messages from Firebase
  const fetchMessages = (userNo, chatWith) => {
    setIsLoading(true);
    const messagesPath = `users/${userNo}/chats/${chatWith}/messages`;
    const messageRef = ref(db, messagesPath);

    // Real-time listener
    onValue(
      messageRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();

          // Convert object to array and avoid duplicates
          const messagesArray = Object.values(data);
          setMessages(messagesArray);
        } else {
          console.log('No messages found.');
          setMessages([]);
        }
        setIsLoading(false);
      },
      (error) => {
        console.error('Error fetching messages:', error);
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    if (selectedCandidate) {
      fetchMessages('+15078586181', selectedCandidate?.personal_phone);
    }
  }, [selectedCandidate]);

  useEffect(() => {
    // if file is selected then empty the message
    if (file) {
      setInput('');
    }
  }, []);

  useEffect(() => {
    if (messages?.length === 0) {
      const message = `
    Hi ${selectedCandidate?.full_name}, Coming across your profile, I thought you are a great match for my client's ${title} role in ${location}.
    
    Are you interested and open to explore more?
    `;
      setInput(message);
    } else {
      setInput('');
    }
  }, [messages]);

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch('https://zdr4wk5m-3001.inc1.devtunnels.ms/uploadToTwilioAsset', {
        method: 'POST',
        body: formData,
      });

      const url = await response.json();
      console.log('Uploaded file URL:', url);
      return url?.url;
      // console.log('Uploaded file URL:', response);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleSendMessage = async () => {
    if (input.trim()) {
      const messageData = {
        text: input,
        sender: '+15078586181',
        status: 'pending',
        timestamp: new Date().toISOString(),
      };

      const to = `whatsapp:${selectedCandidate?.personal_phone}`;
      const name = selectedCandidate?.full_name;
      socket.emit('send-message', {
        to,
        message: messageData,
        name,
        title,
        location,
        isTemplate: messages?.length === 0 ? true : false,
      });
      setInput('');
    } else if (file) {
      const to = `whatsapp:${selectedCandidate?.personal_phone}`;
      setIsSendingFile(true);
      const mediaUrl = await uploadFile(file);
      const filename = file.name;
      socket.emit('send-media-message', {
        to,
        mediaUrl,
        filename,
      });
      setFile(null);
    }
  };
  useEffect(() => {
    // Listen for errors from the server
    socket.on('send-error', (error) => {
      // alert(error.message); // Display a user-friendly error message
      setIsError(true);
      console.error('Detailed error:', error.errorDetails);
    });

    return () => {
      socket.off('send-error'); // Cleanup on component unmount
    };
  }, []);

  useEffect(() => {
    //Listen for bulding status
    socket.on('build-status', (status) => {
      // check the status and show the message accordingly
      if (status === 'building') {
        setIsSendingFile(false);
        setShowMediaMessage('Uploading file...');
      } else if (status === 'completed') {
        setShowMediaMessage('Preparing to send...');
      }
    });

    return () => {
      socket.off('build-status');
    };
  }, []);

  // console.log('messages', messages);
  //---------------------------------------- ADD MSG TO FIREBASE ------------------------------------------------

  // Write messages to Firebase
  const saveMessageToFirebase = async (userNo, chatWith, messageData) => {
    try {
      // Generate a sanitized key
      const sanitizedTimestamp = messageData.timestamp.replace(/[:.]/g, '-') || Date.now();
      const messagePath = `users/${userNo}/chats/${chatWith}/messages/${sanitizedTimestamp}`;
      const messageRef = ref(db, messagePath);

      await set(messageRef, messageData);
      console.log('Message saved successfully!');
    } catch (error) {
      console.error('Error saving message to Firebase:', error);
    }
  };

  useEffect(() => {
    socket.on('add-to-firebase', (msgStatus) => {
      console.log('status', msgStatus);
      const { message } = msgStatus;
      const messageToSave = {
        messageSid: message?.sid,
        sender: message?.from?.replace('whatsapp:', ''),
        text: message?.body,
        status: 'sent',
        msgType: 'text',
        timestamp: new Date().toISOString(),
      };
      // Remove 'whatsapp:' from the phone numbers
      const fromNumber = message?.from?.replace('whatsapp:', '');
      const toNumber = message?.to?.replace('whatsapp:', '');
      saveMessageToFirebase(fromNumber, toNumber, messageToSave);
    });
    return () => {
      socket.off('add-to-firebase');
    };
  }, [messages]);

  useEffect(() => {
    socket.on('add-media-to-firebase', (msgStatus) => {
      console.log('sdasdstatus', msgStatus);
      const { message, file } = msgStatus;
      const messageToSave = {
        messageSid: message?.sid,
        sender: message?.from?.replace('whatsapp:', ''),
        text: file,
        status: 'sent',
        msgType: 'media',
        timestamp: new Date().toISOString(),
      };
      const fromNumber = message?.from?.replace('whatsapp:', '');
      const toNumber = message?.to?.replace('whatsapp:', '');
      saveMessageToFirebase(fromNumber, toNumber, messageToSave);
    });
    return () => {
      socket.off('add-media-to-firebase');
    };
  }, []);
  useEffect(() => {
    socket.on('template-sent', (message) => {
      onMessageSent();
    });
     return () => {
      socket.off('template-sent');
    };
  }, []);
  return (
    <div className="chat-container">
      <div
        style={{
          display: 'flex',
          backgroundColor: '#E1F7FC',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 15px',
          zIndex: 1,
          // Ensure the text is visible
        }}
      >
        <Box display="flex" alignItems="center">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              style={{
                marginRight: '8px', // Adds space between avatar and name
                backgroundColor: '',
                color: '#fff',
                width: '50px',
                height: '50px',
                fontSize: '16px',
                textAlign: 'center',
                marginLeft: '7px',
              }}
            >
              <Icon icon="heroicons:user-16-solid" style={{ fontSize: '24px' }} />
            </Avatar>
            <p style={{ fontSize: '16px', marginLeft: '7px', color: 'black' }}>{selectedCandidate?.personal_phone}</p>
          </div>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Icon
            icon="bitcoin-icons:cross-filled"
            style={{ fontSize: '22px', cursor: 'pointer' }}
            onClick={() => onClose()}
          />
        </Box>
      </div>
      <div className="chat-body">
        {isLoading ? (
          //show circular progress in center
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress thickness={2} />
          </div>
        ) : (
          <>
            {messages?.map((msg) => (
              <div
                key={msg.id}
                className={`chat-message ${
                  msg.sender === '+15078586181' ? 'chat-message-sender' : 'chat-message-receiver'
                }`}
                style={{ position: 'relative' }} // Add relative positioning for better control
              >
                <p className="chat-text">{msg.text}</p>

                {/* Timestamps and ticks */}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: '5px',
                    fontSize: '12px',
                    color: '#555',
                  }}
                >
                  {/* Format timestamp */}
                  <span>
                    {new Date(msg.timestamp).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true,
                    })}
                  </span>
                  {msg?.sender === '+15078586181' && (
                    <>
                      {msg?.status === 'sent' ? (
                        <Icon icon="hugeicons:tick-02" style={{ fontSize: '20px', color: 'gray' }} />
                      ) : msg?.status === 'delivered' ? (
                        <Icon icon="hugeicons:tick-double-02" style={{ fontSize: '20px', color: 'gray' }} />
                      ) : msg?.status === 'read' ? (
                        <Icon icon="hugeicons:tick-double-02" style={{ fontSize: '20px', color: '#00C1FE' }} />
                      ) : null}
                    </>
                  )}
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      {file && (
        <div className="file-preview">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#F5F5F5',
              padding: '15px',
              maxWidth: '450px',
              borderRadius: '8px',
              boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
            }}
          >
            {/* File Icon */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '40px',
                height: '40px',
                backgroundColor: '#E0E0E0',
                borderRadius: '50%',
                marginRight: '10px',
              }}
            >
              {/* show icon according to file type .word icon for word file pdf icon for pdf and image icon for image and excel icon for excel and file icon for unknown extension */}
              {file?.name?.endsWith('.pdf') ? (
                <Icon icon="vscode-icons:file-type-pdf2" style={{ fontSize: '24px', color: '#f82028' }} />
              ) : file?.name?.endsWith('.doc') || file?.name?.endsWith('.docx') ? (
                <Icon icon="vscode-icons:file-type-word" style={{ fontSize: '24px' }} />
              ) : file?.name?.endsWith('.xls') || file?.name?.endsWith('.xlsx') ? (
                <Icon icon="vscode-icons:file-type-excel" style={{ fontSize: '24px' }} />
              ) : file?.name?.endsWith('.jpg') || file?.name?.endsWith('.jpeg') || file?.name?.endsWith('.png') ? (
                <Icon icon="ic:outline-image" style={{ fontSize: '24px', color: '#FFA500' }} />
              ) : (
                <Icon icon="line-md:file" style={{ fontSize: '24px', color: '#000' }} />
              )}
            </Box>

            {/* File Details */}
            <Box sx={{ flexGrow: 1 }}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <Box component="span" display="flex" alignItems="center">
                  {file?.name?.length > 50 ? `${file?.name?.slice(0, 50)}...` : file?.name}{' '}
                </Box>
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography variant="body2" color="textSecondary">
                  {file?.size ? `${(file.size / 1024).toFixed(2)} KB` : 'Unknown size'}
                </Typography>
                {isSendingFile && (
                  <>
                    <p style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>
                      <CircularProgress size={20} color="primary" />
                    </p>
                  </>
                )}
                {showMediaMessage == 'Uploading file...' && (
                  <>
                    <p style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>
                      <Icon icon="line-md:uploading-loop" style={{ color: 'green', fontSize: '20px' }} />
                      <span style={{ fontSize: '12px', color: 'green', fontWeight: 'normal', marginLeft: '5px' }}>
                        {showMediaMessage}
                      </span>
                    </p>
                  </>
                )}
                {showMediaMessage == 'Preparing to send...' && (
                  <>
                    <p style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>
                      <Icon
                        icon="lets-icons:done-ring-round-duotone-line"
                        style={{ color: 'green', fontSize: '20px' }}
                      />
                      <span style={{ fontSize: '12px', color: 'green', fontWeight: 'normal', marginLeft: '5px' }}>
                        {showMediaMessage}
                      </span>
                    </p>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </div>
      )}

      {isError && (
        //show error message in snack
        <Snackbar open={isError} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Alert onClose={() => setIsError(false)} severity="error">
            {' '}
            Message not sent{' '}
          </Alert>
        </Snackbar>
      )}
      <div className="chat-footer">
        <TextField
          placeholder="Write a message..."
          fullWidth
          disabled={file}
          value={input}
          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          onChange={(e) => setInput(e.target.value)}
          sx={{
            borderRadius: '25px',
            padding: '5px 5px',
            textAlign: 'center',
            height: '50px',
            overflowY: 'scroll',
            minHeight: '50px',
            backgroundColor: '#E6EBF5',
            '& .MuiOutlinedInput-root': {
              borderRadius: '40px',
              '& fieldset': {
                border: 'none', // Remove border
              },
            },
            '&:hover': {
              backgroundColor: '#E6EBF5', // Keep background color same on hover
            },
          }}
          size="small"
        />

        <Button
          sx={{
            '&:hover': {
              backgroundColor: 'transparent !important',
            },
            borderRadius: '50%',
          }}
          onClick={() => fileInputRef.current.click()}
        >
          <Icon icon="mdi:attachment-vertical" style={{ fontSize: '24px', color: 'grey' }} />
          <VisuallyHiddenInput
            type="file"
            ref={fileInputRef}
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
          />
        </Button>
        <Button
          variant="contained"
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            backgroundColor: '#00C1FE',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: 'unset',
            '&:hover': {
              backgroundColor: '#00C1FE',
              color: 'white',
            },
          }}
          onClick={() => handleSendMessage()}
        >
          <Send fontSize="small" />
        </Button>
      </div>
    </div>
  );
};

export default Chat;
