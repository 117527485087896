import {
  Avatar,
  Badge,
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { RecruiterPipelineComponent } from './components';
import { Icon } from '@iconify/react';

function CampaignSection(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2); // Default rows per page
  const [selected, setSelected] = useState([]); // State for selected rows
  const [showReplies, setShowReplies] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // For managing popover state
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle checkbox toggle
  const handleSelect = (id) => {
    setSelected((prev) => (prev.includes(id) ? prev.filter((candidateId) => candidateId !== id) : [...prev, id]));
  };
  const data = [
    {
      name: 'Jane',
      email: 'john@gmail.com',
      company: 'UOL',
      stage: 'Opened',
      activites: 'abc',
      opens: 2,
      clicks: 4,
      sourcer: 'Kristina',
    },
    {
      name: 'John',
      email: 'john@gmail.com',
      company: 'UOL',
      stage: 'Opened',
      activites: 'abc',
      opens: 2,
      clicks: 4,
      sourcer: 'Kristina',
    },
    {
      name: 'Janeiee',
      email: 'john@gmail.com',
      company: 'UOL',
      stage: 'Opened',
      activites: 'abc',
      opens: 2,
      clicks: 4,
      sourcer: 'Kristina',
    },
  ];

  // Handle popover open
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget); // Set the anchor element to the clicked target
  };

  // Handle popover close
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // Boolean to check if popover is open
  const open = Boolean(anchorEl);

  return (
    <div style={{ width: '97%', margin: 'auto' }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: '1rem', backgroundColor: 'white', margin: 'auto' }}
      >
        <>
          <RecruiterPipelineComponent title="Raw Candidates/Overview" color="#E496FF" />
          <RecruiterPipelineComponent title="Cold Candidates" color="#8C85FB" />
          <RecruiterPipelineComponent title="Hot Candidates" color="#00C1FE" />
          <RecruiterPipelineComponent title="Warm Candidates" color="#FF806E" />
        </>
      </Box>
      <Box display="flex" justifyContent="space-around" sx={{ margin: 'auto', marginTop: '15px' }}>
        <Typography sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 2, cursor: 'pointer' }}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <p style={{ fontSize: '20px', marginTop: '10px' }}>Email</p>
            <p style={{ fontSize: '16px', marginTop: '10px' }}>1000 / 1000</p>
          </Box>
        </Typography>
        <Typography sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 2, cursor: 'pointer' }}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <p style={{ fontSize: '20px', marginTop: '10px' }}>Opens</p>
            <p style={{ fontSize: '16px', marginTop: '10px' }}> 20 / 100</p>
          </Box>
        </Typography>
        {!showReplies && (
          <>
            <Typography sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 2, cursor: 'pointer' }}>
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <p style={{ fontSize: '20px', marginTop: '10px' }}>WhatsApp</p>
                <p style={{ fontSize: '16px', marginTop: '10px' }}> 20 / 100</p>
              </Box>
            </Typography>
            <Typography sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 2, cursor: 'pointer' }}>
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <p style={{ fontSize: '20px', marginTop: '10px' }}>SMS</p>
                <p style={{ fontSize: '16px', marginTop: '10px' }}> 20 / 100</p>
              </Box>
            </Typography>
            <Typography sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 2, cursor: 'pointer' }}>
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <p style={{ fontSize: '20px', marginTop: '10px' }}>LinkedIn</p>
                <p style={{ fontSize: '16px', marginTop: '10px' }}> 20 / 100</p>
              </Box>
            </Typography>
            <Typography sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 2, cursor: 'pointer' }}>
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <p style={{ fontSize: '20px', marginTop: '10px' }}>To - Dos</p>
                <p style={{ fontSize: '16px', marginTop: '10px' }}> 20 / 100</p>
              </Box>
            </Typography>
          </>
        )}

        <Typography
          sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 2, cursor: 'pointer' }}
          onClick={() => setShowReplies(!showReplies)}
        >
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <p style={{ fontSize: '20px', marginTop: '10px' }}>Replies</p>
            <p style={{ fontSize: '16px', marginTop: '10px' }}> 90</p>
          </Box>
        </Typography>
        {showReplies && (
          <>
            <Typography
              sx={{
                backgroundColor: '#D0EEF8',
                padding: '20px',
                borderRadius: 2,
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Badge badgeContent={4} color="error">
                <Icon icon="ic:baseline-email" style={{ fontSize: '35px', color: '#00C1FE' }} />
              </Badge>
            </Typography>
            <Typography
              sx={{
                backgroundColor: '#D0EEF8',
                padding: '20px',
                borderRadius: 2,
                cursor: 'pointer',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Badge badgeContent={4} color="error">
                <Icon icon="logos:whatsapp-icon" style={{ fontSize: '35px' }} />
              </Badge>
            </Typography>
            <Typography
              sx={{
                backgroundColor: '#D0EEF8',
                padding: '20px',
                borderRadius: 2,
                cursor: 'pointer',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Badge badgeContent={4} color="error">
                <Icon icon="arcticons:messages" style={{ fontSize: '35px', color: '#009688' }} />
              </Badge>
            </Typography>
            <Typography
              sx={{
                backgroundColor: '#D0EEF8',
                padding: '20px',
                borderRadius: 2,
                cursor: 'pointer',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Badge badgeContent={4} color="error">
                <Icon icon="la:linkedin-in" style={{ fontSize: '35px', color: '#009688' }} />
              </Badge>
            </Typography>
            <Typography
              sx={{
                backgroundColor: '#D0EEF8',
                padding: '20px',
                borderRadius: 2,
                cursor: 'pointer',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Badge badgeContent={4} color="error">
                <Icon icon="material-symbols:call" style={{ fontSize: '35px', color: '#009688' }} />
              </Badge>
            </Typography>
          </>
        )}
        <Typography sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 2, cursor: 'pointer' }}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <p style={{ fontSize: '20px', marginTop: '10px' }}>Interested</p>
            <p style={{ fontSize: '16px', marginTop: '10px' }}> 90 / 90</p>
          </Box>
        </Typography>
        <Typography sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 2, cursor: 'pointer' }}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <p style={{ fontSize: '20px', marginTop: '10px' }}>Not Interested</p>
            <p style={{ fontSize: '16px', marginTop: '10px' }}> 90 / 90</p>
          </Box>
        </Typography>
        <Typography sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 2, cursor: 'pointer' }}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <p style={{ fontSize: '20px', marginTop: '10px' }}>No Response</p>
            <p style={{ fontSize: '16px', marginTop: '10px' }}> 90 / 90</p>
          </Box>
        </Typography>
        <Typography sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 2, cursor: 'pointer' }}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <p style={{ fontSize: '20px', marginTop: '10px' }}>Meeting</p>
            <p style={{ fontSize: '16px', marginTop: '10px' }}> 90 / 90</p>
          </Box>
        </Typography>
      </Box>
      <Box
        sx={{
          paddingLeft: 2,
          paddingRight: 2,
          paddingBottom: 2,
          borderRadius: 4,
          backgroundColor: 'white',
          marginTop: '15px',
        }}
      >
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <TableCell style={{ textAlign: 'center' }}></TableCell>
                <TableCell style={{ textAlign: 'left' }}>Name</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Email</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Company</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Stages</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Activities</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Opens</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Clicks</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Sourcer</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Paginate the rows */}
              {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => (
                <TableRow key={index} selected={selected.includes(row.candidateId)}>
                  <TableCell>
                    <Box display="flex" alignItems="center" justifyContent="space-around">
                      {/* Checkbox for selection */}
                      <Checkbox
                        checked={selected?.includes(row.name)}
                        onChange={() => handleSelect(row.name)}
                        inputProps={{ 'aria-label': 'select row' }}
                        sx={{
                          color: '#808080',
                          '&.Mui-checked': {
                            color: '#00C1FE', // Set checkbox color to blue when checked
                          },
                        }}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {/* Avatar with the first character */}
                      <Avatar
                        style={{
                          marginRight: '8px', // Adds space between avatar and name
                          backgroundColor: '#4CD4FF',
                          color: '#fff',
                          width: '30px',
                          height: '30px',
                          fontSize: '16px',
                          textAlign: 'center',
                        }}
                      >
                        {row.name.charAt(0)}
                      </Avatar>

                      {/* Candidate name */}
                      <p
                        style={{
                          color: '#4CD4FF',
                          fontWeight: 'bold',
                          margin: 0,
                        }}
                      >
                        {row.name}
                      </p>
                    </div>
                  </TableCell>
                  <TableCell>
                    <p>{row.email}</p>
                  </TableCell>
                  <TableCell>
                    <p>{row.company}</p>
                  </TableCell>
                  <TableCell>
                    <p
                      style={{
                        cursor: 'pointer',
                        color: 'white',
                        backgroundColor: '#7492FF',
                        padding: '5px',
                        borderRadius: '5px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100px',
                        textAlign: 'center',
                      }}
                      onClick={handlePopoverOpen} // Open popover on click
                    >
                      {row.stage} &nbsp; <Icon icon="iconamoon:arrow-down-2-fill" style={{ fontSize: '16px' }} />
                    </p>
                  </TableCell>
                  <TableCell>
                    <p>{row.activites}</p>
                  </TableCell>
                  <TableCell>
                    <p>{row.opens}</p>
                  </TableCell>
                  <TableCell>
                    <p>{row.clicks}</p>
                  </TableCell>
                  <TableCell>
                    <p>{row.sourcer}</p>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <List>
            <ListItem>
              {/* Icon and text for "Sourced" */}
              <ListItemIcon>
                <Icon icon="wpf:search" style={{ fontSize: '18px', color: '#7D8592' }} />
              </ListItemIcon>
              <ListItemText primary="Sourced" sx={{ fontSize: '8px', color: '#7D8592' }} />
            </ListItem>

            <ListItem>
              {/* Icon and text for "Messaged" */}
              <ListItemIcon>
              <Icon icon="fluent:arrow-forward-28-regular"  style={{ fontSize: '18px', color: '#7D8592' }} />
              </ListItemIcon>
              <ListItemText primary="Messaged" sx={{ fontSize: '12px', color: '#7D8592' }} />
            </ListItem>

            <ListItem>
              {/* Icon and text for "Opened" */}
              <ListItemIcon>
              <Icon icon="tabler:mail-opened" style={{ fontSize: '18px', color: '#7D8592' }} />
              </ListItemIcon>
              <ListItemText primary="Opened" sx={{ fontSize: '12px', color: '#7D8592' }} />
            </ListItem>

            <ListItem>
              {/* Icon and text for "Clicked" */}
              <ListItemIcon>
            <Icon icon="tabler:hand-click"  style={{ fontSize: '18px', color: '#7D8592' }} />
              </ListItemIcon>
              <ListItemText primary="Clicked" sx={{ fontSize: '12px', color: '#7D8592' }} />
            </ListItem>

            <ListItem>
              {/* Icon and text for "Replied" */}
              <ListItemIcon>
            <Icon icon="akar-icons:arrow-back"  style={{ fontSize: '18px', color: '#7D8592' }} />
              </ListItemIcon>
              <ListItemText primary="Replied" sx={{ fontSize: '12px', color: '#7D8592' }} />
            </ListItem>

            <ListItem>
              {/* Icon and text for "Interested" */}
              <ListItemIcon>
            <Icon icon="hugeicons:smile"style={{ fontSize: '18px', color: '#7D8592' }} />
              </ListItemIcon>
              <ListItemText primary="Interested" sx={{ fontSize: '12px', color: '#7D8592' }} />
            </ListItem>

            <ListItem>
              {/* Icon and text for "Not Interested" */}
              <ListItemIcon>
            <Icon icon="solar:sad-circle-line-duotone" style={{ fontSize: '18px', color: '#7D8592' }} />
              </ListItemIcon>
              <ListItemText primary="Not Interested" sx={{ fontSize: '12px' , color: '#7D8592'}} />
            </ListItem>

            <ListItem>
              {/* Icon and text for "Meeting" */}
              <ListItemIcon>
            <Icon icon="mdi:calendar"style={{ fontSize: '18px', color: '#7D8592' }} />
              </ListItemIcon>
              <ListItemText primary="Meeting" sx={{ fontSize: '12px', color: '#7D8592' }} />
            </ListItem>
          </List>
        </Popover>
        <TablePagination
          component="div"
          count={data?.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[2, 5, 10]} // Optional: Different options for rows per page
        />
      </Box>
    </div>
  );
}

export default CampaignSection;
