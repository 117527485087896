import { Icon } from '@iconify/react';
import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { JobIconForDialog } from './components/images';
import DateAndTimePicker from 'src/components/DateAndTimePicker';
import { CandidateSelectionDialog, JobSelectionDialog } from './components';

function IntenalInterview(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const previousPath = location.state?.previousPath || '/recruiter/app'
  const [openCandidateSelectionDialog, setOpenCandidateSelectionDialog] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState();
  const [openJobSelectionDialog, setOpenJobSelectionDialog] = useState(false);
  const [selectedJob, setSelectedJob] = useState();
  return (
    <div style={{ width: '97%', margin: 'auto' }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <ArrowBack
          fontSize="small"
          onClick={() => {
            navigate(previousPath);
          }}
          sx={{
            color: '#00C1FE',
            cursor: 'pointer',
          }}
        />
        &nbsp;&nbsp;{' '}
        <Typography
          variant="subtitle2"
          sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}
          onClick={() => {
            navigate(previousPath);
          }}
        >
          Back
        </Typography>
      </Box>
      <br />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <p style={{ fontSize: '32px', fontWeight: 'bold', marginTop: '10px', marginLeft: '10px' }}>
          Create Internal Interview
        </p>
        <Box display="flex">
          <Button
            sx={{
              background: '#CED5E0',
              height: '45px',
              border: '1px solid #CED5E0',
              '&:hover': {
                background: '#CED5E0',
                color: 'black',
              },
              borderRadius: '15px',
              padding: '25px',
              marginLeft: '10px',
            }}
            onClick={() => navigate(previousPath)}
          >
            Cancel
          </Button>
          <Button
            sx={{
              background: '#00C1FE',
              color: '#fff',
              height: '45px',
              border: '1px solid #00C1FE',
              '&:hover': {
                background: '#00C1FE',
                color: '#fff',
              },
              borderRadius: '15px',
              padding: '25px',
              marginLeft: '10px',
            }}
          >
            Save and New
          </Button>
          <Button
            sx={{
              background: '#00C1FE',
              color: '#fff',
              height: '45px',
              border: '1px solid #00C1FE',
              '&:hover': {
                background: '#00C1FE',
                color: '#fff',
              },
              borderRadius: '15px',
              padding: '25px',
              marginRight: '10px',
              marginLeft: '10px',
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
      <Box sx={{ backgroundColor: 'white', padding: '40px', borderRadius: 4, height: 'auto', marginTop: '15px' }}>
        <p style={{ fontSize: '18px' }}>Interview Information</p>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box display="flex" flexDirection="column">
              <label htmlFor="interview_name" style={{ fontWeight: 'bold', color: '#9DA3AD', marginLeft: '10px' }}>
                Interview Name
              </label>
              <TextField
                id="interview_name"
                value=""
                type="text"
                placeholder="Interview name"
                size="large"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '20px',
                  marginTop: '5px',
                  marginBottom: '3px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '20px',
                    '& fieldset': {
                      borderColor: '#dfe1e5',
                    },
                    '&:hover fieldset': {
                      borderColor: '#dfe1e5',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#dfe1e5',
                    },
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box display="flex" flexDirection="column">
              <label htmlFor="candidate_name" style={{ fontWeight: 'bold', color: '#9DA3AD', marginLeft: '10px' }}>
                Candidate Name
              </label>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between', // Position the icon at the end
                  padding: '15px 20px', // Adjust padding to match TextField's spacing
                  backgroundColor: 'white',
                  borderRadius: '20px',
                  marginTop: '5px',
                  marginBottom: '3px',
                  border: '1px solid #dfe1e5', // Border styling
                  '&:hover': {
                    borderColor: '#dfe1e5',
                  },
                  '&:focus-within': {
                    borderColor: '#dfe1e5',
                  },
                }}
                onClick={() => setOpenCandidateSelectionDialog(true)}
              >
                {/* Paragraph text */}
                <Typography variant="body1" sx={{ color: '#ABABAB', fontSize: '16px' }}>
                  {selectedCandidate ? selectedCandidate : 'Select Candidate'}
                </Typography>

                {/* Icon at the end */}
                <InputAdornment position="end">
                  <Icon
                    icon="bi:person-fill"
                    style={{ fontSize: '22px', color: '#ABABAB', cursor: 'pointer' }}
                    onClick={() => setOpenCandidateSelectionDialog(true)}
                  />
                </InputAdornment>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box display="flex" flexDirection="column">
              <label htmlFor="dept_name" style={{ fontWeight: 'bold', color: '#9DA3AD', marginLeft: '10px' }}>
                Department Name
              </label>
              <TextField
                id="dept_name"
                value=""
                type="text"
                size="large"
                fullWidth
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '20px',
                  marginTop: '5px',
                  marginBottom: '3px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '20px',
                    '& fieldset': {
                      borderColor: '#dfe1e5',
                    },
                    '&:hover fieldset': {
                      borderColor: '#dfe1e5',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#dfe1e5',
                    },
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} marginTop="15px">
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box display="flex" flexDirection="column">
              <label htmlFor="posting_title" style={{ fontWeight: 'bold', color: '#9DA3AD', marginLeft: '10px' }}>
                Posting Title
              </label>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between', // Position the icon at the end
                  padding: '15px 20px', // Adjust padding to match TextField's spacing
                  backgroundColor: 'white',
                  borderRadius: '20px',
                  marginTop: '5px',
                  marginBottom: '3px',
                  border: '1px solid #dfe1e5', // Border styling
                  '&:hover': {
                    borderColor: '#dfe1e5',
                  },
                  '&:focus-within': {
                    borderColor: '#dfe1e5',
                  },
                }}
                onClick={() => setOpenJobSelectionDialog(true)}
              >
                {/* Paragraph text */}
                <Typography variant="body1" sx={{ color: '#ABABAB', fontSize: '16px' }}>
                  {selectedJob ? selectedJob : 'Select Job opening'}
                </Typography>

                {/* Icon at the end */}
                <InputAdornment position="end">
                  <img src={JobIconForDialog} alt="Select Job" style={{ cursor: 'pointer' }} onClick={() => setOpenJobSelectionDialog(true)} />
                </InputAdornment>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box display="flex" flexDirection="column">
              <label htmlFor="from" style={{ fontWeight: 'bold', color: '#9DA3AD', marginLeft: '10px' }}>
                From
              </label>
              <DateAndTimePicker
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '20px',
                  marginTop: '5px',
                  borderRadius: '20px',
                  borderColor: '#dfe1e5',
                  width: '100%',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box display="flex" flexDirection="column">
              <label htmlFor="to" style={{ fontWeight: 'bold', color: '#9DA3AD', marginLeft: '10px' }}>
                To
              </label>
              <DateAndTimePicker
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '20px',
                  marginTop: '5px',
                  borderRadius: '20px',
                  borderColor: '#dfe1e5',
                  width: '100%',
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} marginTop="15px">
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box display="flex" flexDirection="column">
              <label htmlFor="interviwers" style={{ fontWeight: 'bold', color: '#9DA3AD', marginLeft: '10px' }}>
                Interviewers
              </label>
              <TextField
                id="interviwers"
                value=""
                type="text"
                size="large"
                fullWidth
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '20px',
                  marginTop: '5px',
                  marginBottom: '3px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '20px',
                    '& fieldset': {
                      borderColor: '#dfe1e5',
                    },
                    '&:hover fieldset': {
                      borderColor: '#dfe1e5',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#dfe1e5',
                    },
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box display="flex" flexDirection="column">
              <label htmlFor="interview_owner" style={{ fontWeight: 'bold', color: '#9DA3AD', marginLeft: '10px' }}>
                Interview Owner
              </label>
              <TextField
                id="interview_owner"
                value=""
                type="text"
                size="large"
                fullWidth
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '20px',
                  marginTop: '5px',
                  marginBottom: '3px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '20px',
                    '& fieldset': {
                      borderColor: '#dfe1e5',
                    },
                    '&:hover fieldset': {
                      borderColor: '#dfe1e5',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#dfe1e5',
                    },
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box display="flex" flexDirection="column">
              <label htmlFor="location" style={{ fontWeight: 'bold', color: '#9DA3AD', marginLeft: '10px' }}>
                Location
              </label>
              <TextField
                id="location"
                value=""
                type="text"
                size="large"
                fullWidth
                disabled
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <Icon icon="mynaui:location" style={{ fontSize: '22px', color: '#ABABAB' }} />
                      </InputAdornment>
                    </>
                  ),
                }}
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '20px',
                  marginTop: '5px',
                  marginBottom: '3px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '20px',
                    '& fieldset': {
                      borderColor: '#dfe1e5',
                    },
                    '&:hover fieldset': {
                      borderColor: '#dfe1e5',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#dfe1e5',
                    },
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} marginTop="15px">
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box display="flex" flexDirection="column">
              <label htmlFor="comments" style={{ fontWeight: 'bold', color: '#9DA3AD', marginLeft: '10px' }}>
                Schedule Comments
              </label>
              <TextField
                id="comments"
                value=""
                type="text"
                size="large"
                fullWidth
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '20px',
                  marginTop: '5px',
                  marginBottom: '3px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '20px',
                    '& fieldset': {
                      borderColor: '#dfe1e5',
                    },
                    '&:hover fieldset': {
                      borderColor: '#dfe1e5',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#dfe1e5',
                    },
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box display="flex" flexDirection="column">
              <label htmlFor="name" style={{ fontWeight: 'bold', color: '#9DA3AD', marginLeft: '10px' }}>
                Assessment Name
              </label>
              <TextField
                id="name"
                value=""
                type="text"
                size="large"
                fullWidth
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '20px',
                  marginTop: '5px',
                  marginBottom: '3px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '20px',
                    '& fieldset': {
                      borderColor: '#dfe1e5',
                    },
                    '&:hover fieldset': {
                      borderColor: '#dfe1e5',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#dfe1e5',
                    },
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <br />
        <p style={{ fontSize: '18px' }}>Attachment Information</p>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box display="flex" flexDirection="column">
              <FilePicker label="Cover letter" />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {openCandidateSelectionDialog && (
        <CandidateSelectionDialog
          open={openCandidateSelectionDialog}
          onClose={() => setOpenCandidateSelectionDialog(false)}
          onSelectCandidate={(candidate) => {
            setSelectedCandidate(candidate);
          }}
        />
      )}
      {openJobSelectionDialog && (
        <JobSelectionDialog
          open={openJobSelectionDialog}
          onClose={() => setOpenJobSelectionDialog(false)}
          onSelectJob={(job) => {
            console.log('fdsfg', job);
            setSelectedJob(job);
          }}
        />
      )}
    </div>
  );
}

export default IntenalInterview;

const FilePicker = ({ label }) => {
    const [fileName, setFileName] = useState('');
  
    const handleFileChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        setFileName(file.name);
      } else {
        setFileName('');
      }
    };
  
    return (
      <Box display="flex" flexDirection="column" width="100%">
        <label htmlFor="file-upload" style={{ fontWeight: 'bold', color: '#9DA3AD', marginLeft: '10px' }}>
          {label}
        </label>
        <Box
          display="flex"
          alignItems="center"
          mt={1}
          sx={{
            marginRight: '10px',
            backgroundColor: 'white',
            borderRadius: '20px',
            border: '1px solid #dfe1e5',
            borderColor: '#dfe1e5',
            padding: '10px',
          }}
        >
          <Button
            variant="none"
            component="label"
            style={{
              color: '#9DA3AD',
              '&:hover': {
                backgroundColor: 'white',
              },
            }}
          >
            Browse <input type="file" id="file-upload" hidden onChange={handleFileChange} />
          </Button>
          <Box sx={{ ml: '5px', borderLeft: '2px solid #dfe1e5' }}>
            {' '}
            <span style={{ marginLeft: '10px', color: '#9DA3AD' }}>{fileName ? fileName : 'No file selected'}</span>
          </Box>
        </Box>
      </Box>
    );
  };
  