import { Box, Button, Typography } from '@mui/material';
import React from 'react';

function JobDescription({description}) {
    return (
      <div style={{ height: '80vh', overflowY: 'scroll' }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ backgroundColor: '#D6F4FB', height: '80px' }}
        >
          <p style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>Job Description</p>
        </Box>
        <Box sx={{ backgroundColor: 'white', height: 'auto' }}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              sx={{
                background: '#00C1FE',
                color: '#fff',
                width: '100px',
                height: '45px',
                border: '1px solid #00C1FE',
                '&:hover': {
                  background: '#00C1FE',
                  color: '#fff',
                },
                borderRadius: '5px',
                padding: '5px',
                marginTop: '10px',
                marginRight: '20px',
              }}
            >
              Open Word
            </Button>
          </Box>
          <Box display="flex" flexDirection="column" marginLeft="20px" marginRight="20px">
            <label htmlFor="current_salary" style={{ fontWeight: 'bold', marginLeft: '5px', paddingTop: '20px' }}>
              Role Main Title
            </label>
            <Typography
              variant="subtitle1"
              sx={{
                marginLeft: '5px',
                color: '#7D8592',
                border: '1px solid #D7D7D7',
                borderRadius: '5px',
                padding: '10px',
                marginTop: '5px',
                marginRight: '5px',
              }}
            >
              {description?.mainTitle || 'Clinical Lead'}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" marginLeft="20px" marginRight="20px" marginTop="5px">
            <label htmlFor="current_salary" style={{ fontWeight: 'bold', marginLeft: '5px', paddingTop: '20px' }}>
              Relevant Titles (if any)
            </label>
            <Typography
              variant="subtitle1"
              sx={{
                marginLeft: '5px',
                color: '#7D8592',
                border: '1px solid #D7D7D7',
                borderRadius: '5px',
                padding: '10px',
                marginTop: '5px',
                marginRight: '5px',
              }}
            >
              {description?.relevantTitles || 'Unit Manager , Senior Lead Nurse , Nurse Manager'}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" marginLeft="20px" marginRight="20px" marginTop="5px">
            <label htmlFor="current_salary" style={{ fontWeight: 'bold', marginLeft: '5px', paddingTop: '20px' }}>
              Location & Postal code
            </label>
            <Typography
              variant="subtitle1"
              sx={{
                marginLeft: '5px',
                color: '#7D8592',
                border: '1px solid #D7D7D7',
                borderRadius: '5px',
                padding: '10px',
                marginTop: '5px',
                marginRight: '5px',
              }}
            >
              {description?.location || 'Lowestoft, United Kingdom'}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" marginLeft="20px" marginRight="20px" marginTop="5px">
            <label htmlFor="current_salary" style={{ fontWeight: 'bold', marginLeft: '5px', paddingTop: '20px' }}>
              Radius (Miles)
            </label>
            <Typography
              variant="subtitle1"
              sx={{
                marginLeft: '5px',
                color: '#7D8592',
                border: '1px solid #D7D7D7',
                borderRadius: '5px',
                padding: '10px',
                marginTop: '5px',
                marginRight: '5px',
              }}
            >
              {description?.radiusmiles || 'Under 4 miles'}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" marginLeft="20px" marginRight="20px" marginTop="5px">
            <label htmlFor="current_salary" style={{ fontWeight: 'bold', marginLeft: '5px', paddingTop: '20px' }}>
              Interested in Willing to Relocate? (Y or N)
            </label>
            <Typography
              variant="subtitle1"
              sx={{
                marginLeft: '5px',
                color: '#7D8592',
                border: '1px solid #D7D7D7',
                borderRadius: '5px',
                padding: '10px',
                marginTop: '5px',
                marginRight: '5px',
              }}
            >
              {description?.willing_to_relocate || 'Yes'}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" marginLeft="20px" marginRight="20px" marginTop="5px">
            <label htmlFor="current_salary" style={{ fontWeight: 'bold', marginLeft: '5px', paddingTop: '20px' }}>
              Months back on Job Boards
            </label>
            <Typography
              variant="subtitle1"
              sx={{
                marginLeft: '5px',
                color: '#7D8592',
                border: '1px solid #D7D7D7',
                borderRadius: '5px',
                padding: '10px',
                marginTop: '5px',
                marginRight: '5px',
              }}
            >
              {description?.months_back_on_job_board || 3}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" marginLeft="20px" marginRight="20px" marginTop="5px">
            <label htmlFor="current_salary" style={{ fontWeight: 'bold', marginLeft: '5px', paddingTop: '20px' }}>
              Job Description Attached ?
            </label>
            <Typography
              variant="subtitle1"
              sx={{
                marginLeft: '5px',
                color: '#7D8592',
                border: '1px solid #D7D7D7',
                borderRadius: '5px',
                padding: '10px',
                marginTop: '5px',
                marginRight: '5px',
              }}
            >
              {description?.is_jd_attached || 'Yes'}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" marginLeft="20px" marginRight="20px" marginTop="5px">
            <label htmlFor="current_salary" style={{ fontWeight: 'bold', marginLeft: '5px', paddingTop: '20px' }}>
              Salary Range (Min & Max)
            </label>
            <Typography
              variant="subtitle1"
              sx={{
                marginLeft: '5px',
                color: '#7D8592',
                border: '1px solid #D7D7D7',
                borderRadius: '5px',
                padding: '10px',
                marginTop: '5px',
                marginRight: '5px',
              }}
            >
              {description?.salary_range || '50k per annum'}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" marginLeft="20px" marginRight="20px" marginTop="5px">
            <label htmlFor="current_salary" style={{ fontWeight: 'bold', marginLeft: '5px', paddingTop: '20px' }}>
              Contract or Permanent
            </label>
            <Typography
              variant="subtitle1"
              sx={{
                marginLeft: '5px',
                color: '#7D8592',
                border: '1px solid #D7D7D7',
                borderRadius: '5px',
                padding: '10px',
                marginTop: '5px',
                marginRight: '5px',
              }}
            >
              {description?.contract_or_permanent || 'Contract'}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" marginLeft="20px" marginRight="20px" marginTop="5px">
            <label htmlFor="current_salary" style={{ fontWeight: 'bold', marginLeft: '5px', paddingTop: '20px' }}>
              Essential Qualifications
            </label>
            <Typography
              variant="subtitle1"
              sx={{
                marginLeft: '5px',
                color: '#7D8592',
                border: '1px solid #D7D7D7',
                borderRadius: '5px',
                padding: '10px',
                marginTop: '5px',
                marginRight: '5px',
              }}
            >
              {description?.essential_qualifications || 'Strong clinical skillset'}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" marginLeft="20px" marginRight="20px" marginTop="5px">
            <label htmlFor="current_salary" style={{ fontWeight: 'bold', marginLeft: '5px', paddingTop: '20px' }}>
              Desirable or Preferred requirements
            </label>
            <Typography
              variant="subtitle1"
              sx={{
                marginLeft: '5px',
                color: '#7D8592',
                border: '1px solid #D7D7D7',
                borderRadius: '5px',
                padding: '10px',
                marginTop: '5px',
                marginRight: '5px',
              }}
            >
              {description?.desired_requirements || 'NMC Preferred'}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" marginLeft="20px" marginRight="20px" marginTop="5px">
            <label htmlFor="current_salary" style={{ fontWeight: 'bold', marginLeft: '5px', paddingTop: '20px' }}>
              Industry Category
            </label>
            <Typography
              variant="subtitle1"
              sx={{
                marginLeft: '5px',
                color: '#7D8592',
                border: '1px solid #D7D7D7',
                borderRadius: '5px',
                padding: '10px',
                marginTop: '5px',
                marginRight: '5px',
              }}
            >
              {description?.industry || 'Healthcare'}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" marginLeft="20px" marginRight="20px" marginTop="5px">
            <label htmlFor="current_salary" style={{ fontWeight: 'bold', marginLeft: '5px', paddingTop: '20px' }}>
              Companies of particular interest (if any)
            </label>
            <Typography
              variant="subtitle1"
              sx={{
                marginLeft: '5px',
                color: '#7D8592',
                border: '1px solid #D7D7D7',
                borderRadius: '5px',
                padding: '10px',
                marginTop: '5px',
                marginRight: '5px',
              }}
            >
              {description?.companies_of_interest || 'Google'}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" marginLeft="20px" marginRight="20px" marginTop="5px">
            <label htmlFor="current_salary" style={{ fontWeight: 'bold', marginLeft: '5px', paddingTop: '20px' }}>
              Major reasor for rejecting CVs
            </label>
            <Typography
              variant="subtitle1"
              sx={{
                marginLeft: '5px',
                color: '#7D8592',
                border: '1px solid #D7D7D7',
                borderRadius: '5px',
                padding: '10px',
                marginTop: '5px',
                marginRight: '5px',
              }}
            >
              {description?.major_reason_for_rejecting_cvs || 'Non NMC or no senior Nurse experience'}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" marginLeft="20px" marginRight="20px" marginTop="5px">
            <label htmlFor="current_salary" style={{ fontWeight: 'bold', marginLeft: '5px', paddingTop: '20px' }}>
              Who do we want?
            </label>
            <Typography
              variant="subtitle1"
              sx={{
                marginLeft: '5px',
                color: '#7D8592',
                border: '1px solid #D7D7D7',
                borderRadius: '5px',
                padding: '10px',
                marginTop: '5px',
                marginRight: '5px',
              }}
            >
              {description?.who_do_we_Want || 'Nurse'}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" marginLeft="20px" marginRight="20px" marginTop="5px">
            <label htmlFor="current_salary" style={{ fontWeight: 'bold', marginLeft: '5px', paddingTop: '20px' }}>
              Who do we not want?
            </label>
            <Typography
              variant="subtitle1"
              sx={{
                marginLeft: '5px',
                color: '#7D8592',
                border: '1px solid #D7D7D7',
                borderRadius: '5px',
                padding: '10px',
                marginTop: '5px',
                marginRight: '5px',
              }}
            >
              {description?.who_do_we_not_want || 'Nurse having no experience'}
            </Typography>
          </Box>
          <br />
        </Box>
      </div>
    );
}

export default JobDescription;
