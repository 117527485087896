import { Box, Card, CardContent, Grid, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { company_icon, database_icon, followers_icon, jobs_icon, linkedin_icon } from '../../components/svg';
import { LocationOn, Warehouse } from '@mui/icons-material';

function Company({ company, onSelect }) {
  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 4,
                }}
              >
                <img
                  src={company?.logo}
                  alt="company avatar"
                  height={80}
                  width={80}
                  style={{
                    border: '1px solid #C4CDD5',
                    padding: 2,
                    borderRadius: '50%',
                    cursor: 'pointer',
                  }}
                  onClick={() => window.open(`${company?.profile_url}`, '_blank')}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <h3
                    style={{
                      color: '#3f51b5',
                      fontSize: 18,
                      cursor: 'pointer',
                    }}
                    onClick={() => onSelect(company)}
                  >
                    {company?.name}
                  </h3>
                  <p>
                    {' '}
                    <Tooltip title="Industry" placement="top">
                      <Warehouse />{' '}
                    </Tooltip>
                    {company?.industry},{' '}
                    <Tooltip title="Location" placement="top">
                      {' '}
                      <LocationOn />
                    </Tooltip>{' '}
                    {company?.address}
                  </p>
                  <div
                    style={{
                      color: 'green',
                      fontSize: 16,
                      marginLeft: 4,
                      display: 'flex',
                      gap: 6,
                    }}
                  >
                    <p
                      style={{
                        display: 'flex',
                        gap: 6,
                      }}
                    >
                      <img src={company_icon} alt="company size" /> {company?.actual_range}
                    </p>{' '}
                    |{' '}
                    <p
                      style={{
                        display: 'flex',
                        gap: 6,
                      }}
                    >
                      {' '}
                      <img src={linkedin_icon} alt="followers" /> {company?.staff_count}{' '}
                    </p>{' '}
                    |{' '}
                    <p
                      style={{
                        display: 'flex',
                        gap: 6,
                      }}
                    >
                      {' '}
                      <img src={database_icon} alt="people count" /> {company?.peopleCount}{' '}
                    </p>
                  </div>
                  <p
                    style={{
                      display: 'flex',
                      gap: 6,
                    }}
                  >
                    {' '}
                    <img src={followers_icon} alt="followers" />
                    {company?.follower_count} &nbsp;Followers
                  </p>
                  <p
                    style={{
                      display: 'flex',
                      gap: 4,
                    }}
                  >
                    <img src={jobs_icon} alt="followers" /> {company?.jobPostsCount} Jobs
                  </p>
                  <p
                    style={{
                      display: 'flex',
                      gap: 4,
                    }}
                  >
                    Last Updated on : {(company?.updatedAt).split('T')[0]} at{' '}
                    {(company?.updatedAt).split('T')[1].split('.')[0]}
                  </p>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={7}
              xl={8}
              sx={{
                borderLeft: '1px solid #C4CDD5',
                paddingLeft: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Typography variant="h6">Description</Typography>
              {/* trim description to 200 characters and show see more. when see more is clicked, return onSelect  */}
              <Typography>
                {company?.description?.length > 200 ? `${company.description.slice(0, 200)}...` : company.description}
              </Typography>
              <a
                href={company?.profile_url_encoded}
                target="_blank"
                rel="noreferrer"
                style={{
                  background: '#00C1FE',
                  color: 'white',
                  padding: 10,
                  borderRadius: 50,
                  fontWeight: 'bold',
                  display: 'block',
                  width: 'fit-content',
                  marginLeft: '1rem',
                  textAlign: 'center',
                  textDecoration: 'none',
                }}
              >
                Profile Link Not Working? Click Here
              </a>
            </Grid>
          </Grid>

          <br />
          <hr
            style={{
              color: 'gray',
              backgroundColor: 'gray',
              height: 1,
              borderColor: 'gray',
              width: '85%',
              margin: 'auto',
            }}
          />
        </CardContent>
      </Card>
    </>
  );
}

export default Company;