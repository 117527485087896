import React from 'react';
import { ReedsSearchSection } from 'src/sections/admin/reeds';

function ReedsSearchPage(props) {
    return (
      <div>
        <ReedsSearchSection />
      </div>
    );
}

export default ReedsSearchPage;