export const sectors = [
  'Accountancy',
  'Accountancy (Qualified)',
  'Admin , Secretarial & PA',
  'Apprenticeships',
  'Banking',
  'Charity & Voluntary',
  'Construction & Property',
  'Customer Service',
  'Education',
  'Energy',
  'Engineering',
  'Estate Agency',
  'Financial Services',
  'FMCG',
  'General Insurance',
  'Graduate',
  'Health & Medicine',
  'Hospitality & Catering',
  'Human Resources',
  'IT & Telecoms',
  'Legal',
  'Leisure & Tourism',
  'Manufacturing',
  'Marketing & PR',
  'Media , Digital & Creative',
  'Motoring & Automotive',
  'Purchasing',
  'Recruitment Consultancy',
  'Retail',
  'Sales',
  'Scientific',
  'Security & Safety',
  'Social Care',
  'Strategy & Consultancy',
  'Training',
  'Transport & Logistics',
  'Other',
];

export const jobTypes = ['Permanent', 'Full - time', 'Part - time', 'Temporary', 'Contract']
export const signedInOptions = ['Signed in', 'Not signed in'];

export const active_within = [
    '1 day',
    '2 days',
    '3 days',
    '4 days',
    '5 days',
    '7 days',
    '14 days',
    '1 month',
    '2 months',
    '3 months',
    '6 months',
    '12 months',
    '18 months',
  ];

export const qualificationsss = ['No minimum qualifications', "Bachelor's degree", "Master's degree"];
export const languages = [
  'English',
  'French',
  'Spanish',
  'Arabic',
  'Portuguese',
  'German',
  'Italian',
  'Russian',
  'Chinese',
  'Japanese',
  'Korean',
  'Hindi',
  'Urdu',
  'Other',
];

export const databaseOptions = ["reed.co.uk's CV database" , "Other"]

export const searches = ['Designer', 'Developer', 'Manager'];

export const tableData = [
  {
    title: 'Designer',
    date: '17 oct, 2020',
    link: 'https://www.reed.co.uk/jobs/designer-jobs',
    keywords: 'Designer',
    results: 400,
    scrapper1: {
      results: 200,
      status: 'Completed',
      percentage: '100%',
    },
    scrapper2: {
      results: 100,
      status: 'In-Progress',
      percentage: '50%',
    },
  },
  {
    title: 'Developer',
    date: '17 oct, 2020',
    link: 'https://www.reed.co.uk/jobs/designer-jobs',
    keywords: 'Designer',
    results: 400,
    scrapper1: {
      results: 400,
      status: 'Completed',
      percentage: '100%',
    },
    scrapper2: {
      results: 300,
      status: 'In-Progress',
      percentage: '50%',
    },
  },
  {
    title: 'Designer',
    date: '17 oct, 2020',
    link: 'https://www.reed.co.uk/jobs/designer-jobs',
    keywords: 'Designer',
    results: '200',
    scrapper1: {
      results: 200,
      status: 'Completed',
      percentage: '100%',
    },
    scrapper2: {
      results: 100,
      status: 'In-Progress',
      percentage: '50%',
    },
  },
];