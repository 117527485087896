import { Icon } from '@iconify/react';
import { Box, Button } from '@mui/material';
import React from 'react';
import { CandidatePipelineTable } from './components';
import { useLocation, useNavigate } from 'react-router-dom';

function DatabaseCandidatesSection(props) {
  const navigate = useNavigate();
  const location = useLocation(); // Use the hook to get the location
  const buttons = [
    {
      buttonText: 'Total',
      value: 100,
      borderTop: '#F688F2',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'New',
      value: 25,
      borderTop: '#88BCF6',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'In Review',
      value: 25,
      borderTop: '#A4B0DB',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'Available',
      value: 25,
      borderTop: '#FF9999',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'Engaged',
      value: 25,
      borderTop: '#EBB794',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'Hired',
      value: 25,
      borderTop: '#67E4BB',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'Offered',
      value: 25,
      borderTop: '#E0CC38',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'Rejected',
      value: 25,
      borderTop: '#B0CDCF',
      buttonFontSize: '20px',
    },
  ];
  return (
    <div style={{ width: '97%', margin: 'auto', height: '79vh' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <p style={{ fontSize: '32px', fontWeight: 'bold', marginTop: '10px' }}>Candidates</p>
        <Box display="flex">
          <Button
            startIcon={<Icon icon="tabler:plus" style={{ fontSize: '20px' }} />}
            sx={{
              background: '#00C1FE',
              color: '#fff',
              height: '45px',
              border: '1px solid #00C1FE',
              '&:hover': {
                background: '#00C1FE',
                color: '#fff',
              },
              borderRadius: '15px',
              padding: '25px',
              marginLeft: '10px',
            }}
            onClick={() =>
              navigate(`/recruiter/candidates/addCandidate`, {
                state: { previousPath: location.pathname }, // Pass the current path as state
              })
            }
          >
            Add Candidates
          </Button>
        </Box>
      </Box>
      <CandidatePipelineTable buttons={buttons} onButtonClick={(value) => {}} showHiringPipeline={false} />
    </div>
  );
}

export default DatabaseCandidatesSection;
