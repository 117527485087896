import { Close } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';

const monthsBackEnums = [
  {
    months_back: 1,
    lastActive: '1 day',
  },
  {
    months_back: 1,
    lastActive: '2 days',
  },
  {
    months_back: 1,
    lastActive: '1 Week',
  },
  {
    months_back: 1,
    lastActive: '2 Weeks',
  },
  {
    months_back: 1,
    lastActive: '1 Month',
  },
  {
    months_back: 3,
    lastActive: '3 Months',
  },
  {
    months_back: 6,
    lastActive: '6 Months',
  },
  {
    months_back: 12,
    lastActive: '12 Months',
  },
  {
    months_back: 24,
    lastActive: '24 Months',
  },
  {
    months_back: 36,
    lastActive: 'More than 24 Months',
  },
];


const salaryUnitEnums = [
  {
    unit: 'p.h',
    value: 'Per Hour',
  },
  {
    unit: 'p.d',
    value: 'Per Day',
  },
  {
    unit: 'p.w',
    value: 'Per Week',
  },
  {
    unit: 'p.m',
    value: 'Per Month',
  },
  {
    unit: 'p.a',
    value: 'Per Annum',
  },
];

function MarkRoleAsDoneDialog({ open, onClose, roleId, onSubmit }) {

  const [selectedSalary, setSelectedSalary] = useState(null);
  const [selectedJobBoard, setSelectedJobBoard] = useState(null);
  const [selectedMonthsBack, setSelectedMonthsBack] = useState(null);

  const initialValues = {
    role_id: '',
    user_id: '',
    job_board_used: [],
    reason_of_less_li_profiles: '',
    reason_of_less_cvs: '',
    min_salary: null,
    max_salary: null,
    salary_unit: '',
    miles: null,
    months_back: null,
    salary: '',
  };

  const [jobBoards, setJobBoards] = useState([]);
  const [roleDetails, setRoleDetails] = useState();
  const [selectedJobBoards, setSelectedJobBoards] = useState([]); // Track selected job boards

  const getJobBoards = () => {
    try {
      Get(
        {},
        API_URLS.getAllJobBoards,
        (resp) => {
          setJobBoards(resp?.data);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getJobBoards();
  }, []);

  const getRoleDetails = () => {
    try {
      Get(
        { roleId: roleId, user_id: parseInt(localStorage.getItem('userId')) },
        API_URLS.getRoleByRoleWorkingId,
        (resp) => {
          setRoleDetails(resp?.data?.role);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRoleDetails();
  }, [roleId]);

  // Handle checkbox selection
  // Handle checkbox selection
  const handleCheckboxChange = (jobBoardName) => {
    setSelectedJobBoards(
      (prevSelected) =>
        prevSelected.includes(jobBoardName)
          ? prevSelected.filter((name) => name !== jobBoardName) // Remove if unchecked
          : [...prevSelected, jobBoardName] // Add if checked
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        hideBackdrop={false}
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '800px',
          },
        }}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#E1F7FC',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" className="ml-8">
              Mark Role as Done
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              values.user_id = localStorage.getItem('userId');
              values.role_id = roleId;
              values.salary = `£${values.min_salary} - £${values.max_salary} ${values.salary_unit}`;
              // Process selected job boards into the required format: [{job_board, views}]
              const formattedJobBoards = values.job_board_used.map((item) => ({
                job_board: item.jobboard,
                views: item.views,
              }));

              values.job_board_used = formattedJobBoards;

              onSubmit(values, actions);
            }}
          >
            {({ errors, values, getFieldProps, touched, handleSubmit, setFieldValue }) => (
              <Form>
                <br />
                <Grid container spacing={2}>
                  {/* Map the job boards as MUI checkboxes with a TextField beside each */}
                  <label
                    htmlFor="job_board_used"
                    style={{ fontWeight: 'bold', marginBottom: '5px', marginLeft: '5px' }}
                  >
                    Add Views of Job Boards
                  </label>
                  {jobBoards?.map((jobBoard, index) => {
                    const isChecked = selectedJobBoards.includes(jobBoard.name); // Use jobBoard.name
                    return (
                      <Grid item xs={12} key={index}>
                        <Grid container alignItems="center" spacing={2} justifyContent="space-between">
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="job_board_used"
                                  value={jobBoard.name} // Use jobBoard.name
                                  checked={isChecked}
                                  onChange={() => {
                                    handleCheckboxChange(jobBoard.name); // Use jobBoard.name
                                    if (!isChecked) {
                                      setFieldValue('job_board_used', [
                                        ...values.job_board_used,
                                        { jobboard: jobBoard.name, views: '' }, // Use jobBoard.name
                                      ]);
                                    } else {
                                      setFieldValue(
                                        'job_board_used',
                                        values.job_board_used.filter((item) => item.jobboard !== jobBoard.name) // Use jobBoard.name
                                      );
                                    }
                                  }}
                                  sx={{
                                    color: '#0ec4fe',
                                    '&.Mui-checked': {
                                      color: '#0ec4fe',
                                    },
                                  }}
                                />
                              }
                              label={jobBoard.name} // Display job board name
                            />
                          </Grid>

                          <Grid item>
                            <TextField
                              placeholder={`Views for ${jobBoard.name}`} // Use jobBoard.name
                              size="small"
                              disabled={!isChecked}
                              value={
                                values.job_board_used.find((item) => item.jobboard === jobBoard.name)?.views || '' // Use jobBoard.name
                              }
                              onChange={(e) => {
                                const updatedJobBoards = values.job_board_used.map(
                                  (item) =>
                                    item.jobboard === jobBoard.name ? { ...item, views: e.target.value } : item // Use jobBoard.name
                                );
                                setFieldValue('job_board_used', updatedJobBoards);
                              }}
                              type="number"
                              sx={{
                                backgroundColor: 'white',
                                outline: 'none',
                                border: '1px solid #D9D9D9',
                                marginBottom: '3px',
                                borderRadius: 0,
                                marginLeft: '5px',
                                '& .MuiOutlinedInput-root': {
                                  '&:hover fieldset': {
                                    borderColor: '#cccccc',
                                    borderRadius: 0,
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: '#cccccc',
                                    borderRadius: 0,
                                  },
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}

                  {/* Number of CVs and LIs Submitted */}
                  <Grid item xs={12}>
                    <Box display="flex" flexDirection="column">
                      <label htmlFor="cvs_submitted" style={{ fontWeight: 'bold', paddingTop: '5px' }}>
                        Number of CVs Submitted
                      </label>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: '#7D8592',
                          border: '1px solid #D7D7D7',
                          borderRadius: '5px',
                          padding: '10px',
                          marginTop: '5px',
                          marginRight: '5px',
                        }}
                      >
                        {roleDetails?.cvs_submitted}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box display="flex" flexDirection="column">
                      <label htmlFor="lis_submitted" style={{ fontWeight: 'bold', paddingTop: '5px' }}>
                        Number of LIs Submitted
                      </label>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: '#7D8592',
                          border: '1px solid #D7D7D7',
                          borderRadius: '5px',
                          padding: '10px',
                          marginTop: '5px',
                          marginRight: '5px',
                        }}
                      >
                        {roleDetails?.lis_submitted}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Reason for Less LIs Submitted (only required if less than 10) */}
                  {roleDetails?.lis_submitted < 10 && (
                    <Grid item xs={12}>
                      <Box display="flex" flexDirection="column">
                        <label htmlFor="reason_of_less_li_profiles" style={{ fontWeight: 'bold', paddingTop: '5px' }}>
                          Reason for Less LI Profiles
                        </label>
                        <TextField
                          name="reason_of_less_li_profiles"
                          placeholder="Enter reason"
                          fullWidth
                          multiline
                          required // Make it required
                          value={values.reason_of_less_li_profiles}
                          onChange={(e) => setFieldValue('reason_of_less_li_profiles', e.target.value)}
                          sx={{
                            backgroundColor: 'white',
                            outline: 'none',
                            border: '1px solid #D7D7D7',
                            marginBottom: '3px',
                            marginTop: '5px',
                            borderRadius: '5px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#D7D7D7',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#D7D7D7',
                              },
                            },
                          }}
                          size="small"
                        />
                      </Box>
                    </Grid>
                  )}

                  {/* Reason for Less CVs Submitted (only required if less than 10) */}
                  {roleDetails?.cvs_submitted < 10 && (
                    <Grid item xs={12}>
                      <Box display="flex" flexDirection="column">
                        <label htmlFor="reason_of_less_cvs" style={{ fontWeight: 'bold', paddingTop: '5px' }}>
                          Reason for Less CVs
                        </label>
                        <TextField
                          name="reason_of_less_cvs"
                          placeholder="Enter reason"
                          fullWidth
                          multiline
                          required // Make it required
                          value={values.reason_of_less_cvs}
                          onChange={(e) => setFieldValue('reason_of_less_cvs', e.target.value)}
                          sx={{
                            backgroundColor: 'white',
                            outline: 'none',
                            border: '1px solid #D7D7D7',
                            marginBottom: '3px',
                            marginTop: '5px',
                            borderRadius: '5px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#D7D7D7',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#D7D7D7',
                              },
                            },
                          }}
                          size="small"
                        />
                      </Box>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
                      <TextField
                        size="small"
                        {...getFieldProps('min_salary')}
                        fullWidth
                        placeholder="Salary Min"
                        required
                        type="number"
                        label="Salary Min"
                      />
                      <TextField
                        size="small"
                        {...getFieldProps('max_salary')}
                        fullWidth
                        placeholder="Salary Max"
                        type="number"
                        required
                        label="Salary Max"
                      />
                      <Autocomplete
                        id="salary_unit"
                        size="small"
                        fullWidth
                        options={salaryUnitEnums}
                        maxWidth="100px"
                        value={selectedSalary}
                        getOptionLabel={(option) => option?.value}
                        onChange={(event, newSalaryUnit) => {
                          if (newSalaryUnit) {
                            setSelectedSalary(newSalaryUnit);
                            setFieldValue('salary_unit', newSalaryUnit.unit);
                          } else {
                            setSelectedSalary(null);
                            setFieldValue('salary_unit', null);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Unit"
                            fullWidth
                            sx={{
                              backgroundColor: 'white',
                              borderRadius: '0.3rem',
                              marginBottom: '3px',
                              '& .MuiOutlinedInput-root': {
                                '&:hover fieldset': {
                                  borderColor: '#00C1FE',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#00C1FE',
                                },
                              },
                              '& .MuiInputBase-root': {
                                '&.Mui-focused': {
                                  boxShadow: '0 0 0 1px #00C1FE',
                                },
                              },
                            }}
                          />
                        )}
                      />
                      <TextField
                        size="small"
                        {...getFieldProps('miles')}
                        fullWidth
                        placeholder="Miles"
                        required
                        label="Miles"
                      />
                      <Autocomplete
                        id="months_back"
                        size="small"
                        options={monthsBackEnums}
                        fullWidth
                        value={selectedMonthsBack}
                        getOptionLabel={(option) => option?.lastActive}
                        onChange={(event, newMonthsBack) => {
                          if (newMonthsBack) {
                            setSelectedMonthsBack(newMonthsBack);
                            setFieldValue('months_back', newMonthsBack.months_back);
                          } else {
                            setSelectedMonthsBack(null);
                            setFieldValue('last_active', null);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Months Back"
                            fullWidth
                            sx={{
                              backgroundColor: 'white',
                              borderRadius: '0.3rem',
                              marginBottom: '3px',
                              '& .MuiOutlinedInput-root': {
                                '&:hover fieldset': {
                                  borderColor: '#00C1FE',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#00C1FE',
                                },
                              },
                              '& .MuiInputBase-root': {
                                '&.Mui-focused': {
                                  boxShadow: '0 0 0 1px #00C1FE',
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Box display="flex" justifyContent="center" marginTop="1rem" alignItems="center">
                  <Button
                    size="medium"
                    onClick={() => {
                      if (
                        values.min_salary &&
                        values.max_salary &&
                        values.salary_unit &&
                        values.miles &&
                        values.months_back
                      ) {
                        handleSubmit();
                      } else {
                        alert('Please fill all the required fields');
                      }
                    }}
                    sx={{
                      backgroundColor: '#0ec4fe',
                      fontSize: '16px',
                      color: 'white',
                      marginLeft: '1rem',
                      padding: '10px 20px',
                      '&:hover': {
                        color: 'white !important',
                        backgroundColor: '#0ec4fe !important',
                      },
                    }}
                  >
                    Mark as done
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default MarkRoleAsDoneDialog;
