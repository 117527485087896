import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import React from 'react';

function AskSendEmailConfirmationDialog({ open, onClose }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        hideBackdrop={false}
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{
          sx: {
            ml: 'auto',
            mt: 'auto',
            mb: 'auto',
            mr: 'auto',
            width:"100%",
            maxWidth : '550px',
            height: 'auto',
          },
        }}
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#E2F2FF',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" className="ml-8">
              Send Emails for this Role
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <br />
          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            Are your sure you want to send emails for this role?
          </Typography>
          <br />
          <Box display="flex" justifyContent="flex-end" marginTop="20px">
            <Button
              sx={{
                background: '#A3A3A3',
                color: '#fff',
                height: '45px',
                border: '1px solid #A3A3A3',
                '&:hover': {
                  background: '#A3A3A3',
                  color: '#fff',
                },
                borderRadius: '15px',
                padding: '25px',
                marginLeft: '20px',
              }}
              onClick={onClose}
            >
              No
            </Button>
            <Button
              sx={{
                background: '#00C1FE',
                color: '#fff',
                height: '45px',
                border: '1px solid #00C1FE',
                '&:hover': {
                  background: '#00C1FE',
                  color: '#fff',
                },
                borderRadius: '15px',
                padding: '25px',
                marginLeft: '20px',
              }}
              onClick={onClose}
            >
              Yes
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AskSendEmailConfirmationDialog;
