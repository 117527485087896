import { Alert } from '@mui/material';
import React from 'react';

function TemplatePreview({ template }) {
  return (
    <div className="p-4 bg-white shadow-md rounded-lg">
      {/* Subject */}
      <div className="mb-4">
        <div
          dangerouslySetInnerHTML={{
            __html: template ? template?.subject : '',
          }}
        ></div>
      </div>

      {/* Horizontal Line */}
      <hr className="my-4 border-gray-300" />

      {/* Template Body */}
      <div
        className="mb-4"
        dangerouslySetInnerHTML={{
          __html: template ? template?.body : '',
        }}
      ></div>

      {/* Horizontal Line */}
      <hr className="my-4 border-gray-300" />

      {/* Regards */}
      {template ? (
        <div class="mb-4">
          Regards, <br />
          Amna Yaseen <br />
          Senior Business Development Manager <br />
          Email: amna@ultimateoutsourcing.co.uk <br />
          Tel: +44 20 3833 0307 <br />
          Tel US: +1 347 480 4177 <br />
          <a href="https://ultimateoutsourcing.co.uk/" target="_blank">
            www.ultimateoutsourcing.co.uk
          </a>
          <img
            src="https://ultimateoutsourcing.co.uk/wp-content/uploads/2021/03/300.png"
            alt="Ultimate Outsourcing LTD"
          />
        </div>
      ) : (
        <Alert severity="info">Select a template to preview.</Alert>
      )}

      {/* Disclaimer */}
      <div
        dangerouslySetInnerHTML={{
          __html: template ? template?.disclaimer : '',
        }}
      ></div>
    </div>
  );
}

export default TemplatePreview;
