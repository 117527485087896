import { Box, Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Get } from 'src/actions/API/apiActions';
import MultiDatePicker from 'src/components/MultiDatePicker';
import { notify } from 'src/components/notify';
import { API_URLS } from 'src/constants/apiURLs';
import { calculateTimeTaken, parseJobBoards } from 'src/helpers';

const columns = [
  {
    accessorKey: 'client_number',
    header: 'Client Number',
  },
  {
    accessorKey: 'title',
    header: 'Title',
  },
  {
    accessorKey: 'status',
    header: 'Status',
  },
  {
    accessorKey: 'cvs_submitted',
    header: 'CVs Submitted',
  },
  {
    accessorKey: 'lis_submitted',
    header: 'LIs Submitted',
  },
  { accessorKey: 'timeTaken', header: 'Time Taken' },
  {
    accessorKey: 'jobBoardDetails',
    header: 'Job Boards Used',
    Cell: ({ row }) => (
      <div>
        {row?.original?.jobBoardDetails?.split(', ').map((detail, index) => (
          <div key={index}>{detail}</div>
        ))}
      </div>
    ),
  },
  {
    accessorKey: 'reason_of_less_li_profiles',
    header: 'Reason of Less LI Profiles',
  },
  {
    accessorKey: 'reason_of_less_cvs',
    header: 'Reason of Less CVs',
  },
  {
    accessorKey: 'reworking_reason',
    header: 'Reworking Reason',
  },
  {
    accessorKey: 'months_back',
    header: 'Months Back',
  },
  {
    accessorKey: 'salary',
    header: 'Salary',
  },
  {
    accessorKey: 'miles',
    header: 'Miles',
  },
  {
    accessorKey: 'leave_reason',
    header: 'Leave Reason',
  },
  {
    accessorKey: 'feedback',
    header: 'Feedback',
  },
  // {
  //   accessorKey: 'queries',
  //   header: 'Queries',
  // },
  // {
  //   accessorKey: 'suggestions',
  //   header: 'Suggestions',
  // },
  // {
  //   accessorKey: 'info',
  //   header: 'Info',
  // },
  // {
  //   accessorKey: 'issues',
  //   header: 'Issues',
  // },
];

function ResourcerDashboardPage() {
  const userId = parseInt(localStorage.getItem('userId'));
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [roleDate, setRoleDate] = useState([moment().startOf('month'), moment().endOf('month')]);
  const [pagination, setPagination] = useState({ pageIndex: 1, pageSize: 10 });
  const [totalRecords, setTotalRecords] = useState(0);

  const getResourcerRoles = useCallback(() => {
    try {
      setLoading(true);
      Get(
        {
          resourcerId: userId,
          startDate: roleDate[0],
          endDate: roleDate[1],
          page: pagination.pageIndex,
          pageSize: pagination.pageSize,
          searchString: '',
        },
        API_URLS.getResourcerIndividualSheet,
        (resp) => {
          const data = resp?.data?.data?.map((role) => ({
            ...role,
            timeTaken: calculateTimeTaken(role.createdAt, role.updatedAt),
            jobBoardDetails: parseJobBoards(role?.job_board_used ?? []),
          }));
          setRoles(data);
          setLoading(false);
          setTotalRecords(resp?.data?.totalRecords);
        },
        (error) => {
          notify('Failed to retrieve roles', 'error', 1000);
          setLoading(false);
        }
      );
    } catch (error) {
      notify('Failed to retrieve roles', 'error', 1000);
      setLoading(false);
    }
  }, [userId, roleDate, pagination]);

  useEffect(() => {
    getResourcerRoles();
  }, [getResourcerRoles]);

  return (
    <div>
      <Box
        display="flex"
        sx={{
          width: 'calc(100%)',
          backgroundColor: '#D9EEFF',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            padding: '15px',
            marginLeft: '5px',
          }}
        >
          {roles.length>0&&roles[0].first_name + ' ' + roles[0]?.last_name + ' Work Details'}
        </Typography>
      </Box>

      <MultiDatePicker
        onChange={(dates) => {
          console.log('dates', dates);
          setRoleDate(dates);
        }}
        height="50px"
        width="100%"
      />

      <MaterialReactTable
        columns={columns}
        data={roles}
        isLoading={loading}
        manualPagination
        rowCount={totalRecords}
        state={{
          pagination,
        }}
        onPaginationChange={(newPagination) => setPagination(newPagination)}
        muiTablePaginationProps={{
          rowsPerPageOptions: [10, 30, 50, 100],
          showFirstButton: true,
          showLastButton: true,
        }}
      />
    </div>
  );
}

export default ResourcerDashboardPage;
