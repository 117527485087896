import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardHeader, Avatar, Typography, Grid, Link, Divider, Box, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { ClientRolesTable } from 'src/sections/acm';
import MultiDatePicker from 'src/components/MultiDatePicker';
import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import { red, green, blue, orange } from '@mui/material/colors';

function ClientDetails() {
  const { client_number } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [clientRoles, setClientRoles] = useState();
  const [isExpanded, setIsExpanded] = useState(false);

  const getClientSheetByClientNumber = useCallback(() => {
    try {
      Get(
        {
          client_number: client_number,
        },
        API_URLS.getClientSheetByClientNumber,
        (resp) => {
          console.log(resp);
          setClientRoles(resp?.data);
        },
        (error) => {
          enqueueSnackbar(error, { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [client_number, enqueueSnackbar]);

  useEffect(() => {
    getClientSheetByClientNumber();
  }, [getClientSheetByClientNumber]);

  const toggleExpand = () => setIsExpanded((prev) => !prev);
  
  console.log('Client Roles:', clientRoles);
  return (
    <Box padding={4}>
      <Card elevation={3}>
        <CardHeader
          avatar={
            <Avatar src={clientRoles?.client_assigned_acm?.profile_image || ''}>
              {clientRoles?.first_name?.charAt(0)}
            </Avatar>
          }
          title={
            <Typography variant="h5">
              {clientRoles?.first_name + ' ' + clientRoles?.last_name + ' ( ' + clientRoles?.client_number + ' )'}
            </Typography>
          }
          subheader={`Job Title: ${clientRoles?.job_title}`}
        />
        <CardContent
          sx={{
            backgroundColor: '#f0f8ff',
          }}
        >
          {/* Always Visible Contact Information */}
          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                }}
              >
                <Button
                  sx={{
                    backgroundColor: '#57CC99',
                    color: 'white',
                    padding: '10px',
                    borderRadius: '5px',
                    '&:hover': {
                      backgroundColor: 'darkgreen',
                    },
                  }}
                  size="large"
                >
                  Current Month
                </Button>
                <Button
                  sx={{
                    backgroundColor: '#00C1FE',
                    color: 'white',
                    padding: '10px',
                    borderRadius: '5px',
                    '&:hover': {
                      backgroundColor: '#00A0D2',
                    },
                  }}
                  size="large"
                >
                  Previous Month
                </Button>
                <MultiDatePicker
                  height="47px"
                  onChange={(formattedDates) => {
                    console.log(formattedDates);
                  }}
                />
                <Button
                  sx={{
                    backgroundColor: '#FCE1E1',
                    color: 'red',
                    padding: '10px',
                    borderRadius: '5px',
                    border: '1px solid red',
                  }}
                  size="large"
                >
                  Expiring on {new Date(clientRoles?.client_history[0]?.end_date).toLocaleDateString()}
                </Button>
                <Button
                  sx={{
                    backgroundColor: 'red',
                    color: 'white',
                    padding: '10px',
                    borderRadius: '5px',
                    '&:hover': {
                      backgroundColor: '#00A0D2',
                    },
                  }}
                  size="large"
                >
                  Total Issues : 5
                </Button>
                <Button
                  sx={{
                    backgroundColor: '#57CC99',
                    color: 'white',
                    padding: '10px',
                    borderRadius: '5px',
                    '&:hover': {
                      backgroundColor: '#00A0D2',
                    },
                  }}
                  size="large"
                >
                  Resolved Issues : 3
                </Button>
                <Button
                  sx={{
                    backgroundColor: '#00C1FE',
                    color: 'white',
                    padding: '10px',
                    borderRadius: '5px',
                    '&:hover': {
                      backgroundColor: '#00A0D2',
                    },
                  }}
                  size="large"
                >
                  Pending Issues : 2
                </Button>
                {/* Expand/Collapse Button */}
                <Button
                  onClick={toggleExpand}
                  variant="contained"
                  sx={{
                    backgroundColor: '#57CC99',
                    color: 'white',
                    padding: '10px',
                    borderRadius: '5px',
                    '&:hover': {
                      backgroundColor: '#00A0D2',
                    },
                  }}
                >
                  {isExpanded ? (
                    <>
                      See Less <ExpandLessOutlined sx={{ marginLeft: '5px' }} />
                    </>
                  ) : (
                    <>
                      See More <ExpandMoreOutlined sx={{ marginLeft: '5px' }} />
                    </>
                  )}
                </Button>
              </Box>
            </Grid>
          </Grid>

          {/* Expandable Content */}
          {isExpanded && (
            <>
              {/* Additional Contact Information */}
              <Grid container spacing={2} marginBottom={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" color="primary">
                    Contact Information
                  </Typography>
                  <Divider sx={{ marginBottom: 1 }} />
                  <Typography>
                    Email: <span style={{ fontWeight: 'bold', color: blue[700] }}>{clientRoles?.email}</span>
                  </Typography>
                  <Typography>
                    Phone: <span style={{ fontWeight: 'bold', color: blue[700] }}>{clientRoles?.phone_number}</span>
                  </Typography>
                  <Link href={clientRoles?.person_linkedin} target="_blank" color="secondary">
                    LinkedIn Profile
                  </Link>
                </Grid>

                {/* Company Information */}
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" color="primary">
                    Company Information
                  </Typography>
                  <Divider sx={{ marginBottom: 1 }} />
                  <Typography>
                    Company Name:{' '}
                    <span style={{ fontWeight: 'bold', color: blue[700] }}>{clientRoles?.company_name}</span>
                  </Typography>
                  <Typography>
                    Size: <span style={{ fontWeight: 'bold', color: blue[700] }}>{clientRoles?.company_size}</span>
                  </Typography>
                  <Link href={clientRoles?.company_linkedin} target="_blank" color="secondary">
                    LinkedIn Company
                  </Link>
                </Grid>
              </Grid>

              {/* Project and Subscription Details */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" color="primary">
                    Project Details
                  </Typography>
                  <Divider sx={{ marginBottom: 1 }} />
                  <Typography>
                    Client Number:{' '}
                    <span style={{ fontWeight: 'bold', color: blue[700] }}>{clientRoles?.client_number}</span>
                  </Typography>
                  <Typography>
                    Start Date:{' '}
                    <span style={{ fontWeight: 'bold', color: green[600] }}>
                      {new Date(clientRoles?.client_history[0]?.start_date).toLocaleDateString()}
                    </span>
                  </Typography>
                  <Typography>
                    End Date:{' '}
                    <span
                      style={{
                        fontWeight: 'bold',
                        color:
                          clientRoles?.client_history[0]?.end_date && new Date(clientRoles?.client_history[0]?.end_date) < new Date() ? red[600] : green[600],
                      }}
                    >
                      {new Date(clientRoles?.client_history[0]?.end_date).toLocaleDateString()}
                    </span>
                  </Typography>
                  <Typography>
                    Status:
                    <span
                      style={{
                        fontWeight: 'bold',
                        color:
                          clientRoles?.client_history[0]?.status === 'Active'
                            ? green[600]
                            : clientRoles?.client_history[0]?.status === 'Pending'
                            ? orange[600]
                            : red[600],
                      }}
                    >
                      {clientRoles?.client_history[0]?.status}
                    </span>
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" color="primary">
                    Subscription Info
                  </Typography>
                  <Divider sx={{ marginBottom: 1 }} />
                  <Typography>
                    Type: <span style={{ fontWeight: 'bold', color: blue[700] }}>{clientRoles?.client_history[0]?.subscription_type}</span>
                  </Typography>
                  <Typography>
                    Service:{' '}
                    <span style={{ fontWeight: 'bold', color: blue[700] }}>{clientRoles?.service?.service_name}</span>
                  </Typography>
                  <Typography>
                    Credits Used:
                    <span
                      style={{
                        fontWeight: 'bold',
                        color: clientRoles?.client_history[0]?.credits_used >= clientRoles?.client_history[0]?.remainingCredits ? red[600] : green[600],
                      }}
                    >
                      {clientRoles?.client_history[0]?.credits_used}
                    </span>
                  </Typography>
                  <Typography>
                    Remaining Credits:
                    <span
                      style={{ fontWeight: 'bold', color: clientRoles?.client_history[0]?.remainingCredits > 10 ? green[600] : red[600] }}
                    >
                      {clientRoles?.client_history[0]?.remainingCredits}
                    </span>
                  </Typography>
                  <Typography>
                    Credits per Day:{' '}
                    <span style={{ fontWeight: 'bold', color: blue[700] }}>{clientRoles?.client_history[0]?.no_of_credits_per_day}</span>
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </CardContent>
      </Card>

      {/* Roles Table */}
      {clientRoles && clientRoles?.client_roles_working && (
        <Box marginTop={4}>
          <Card elevation={3}>
            <CardHeader title="Client Roles" />
            <CardContent>
              <ClientRolesTable clientRolesWorking={clientRoles?.client_roles_working} />
            </CardContent>
          </Card>
        </Box>
      )}
    </Box>
  );
}

export default ClientDetails;
