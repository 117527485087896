import { ArrowBack } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CandidateWorkingTable } from './components';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { useSnackbar } from 'notistack';

function CandidateWorking(props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { roleId } = useParams();
  const [roleCandidates, setRoleCandidates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const getCandidateByRoleId = useCallback(() => {
    setLoading(true);
    try {
      Get(
        {
          roleId: roleId,
          page: page,
          limit: limit,
        },
        API_URLS.getRoleCandidatesById,
        (resp) => {
          setRoleCandidates(resp?.data);
          setTotalCount(resp?.data?.pagination?.totalCandidates);
          setLoading(false);
        },
        (error) => {
          console.log(error);
          setLoading(false);
        }
      );
    } catch (error) {
      setLoading(false);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }, [roleId, page, limit]);

  useEffect(() => {
    getCandidateByRoleId();
  }, [getCandidateByRoleId]);

  return (
    <div style={{ width: '97%', margin: 'auto' }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <ArrowBack
          fontSize="small"
          onClick={() => {
            navigate(-1);
          }}
          sx={{
            color: '#00C1FE',
            cursor: 'pointer',
          }}
        />
        &nbsp;&nbsp;{' '}
        <Typography
          variant="subtitle2"
          sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" mt={1}>
        <p style={{ fontSize: '32px', fontWeight: 'bold', marginTop: '10px' }}>
          Candidates for {roleCandidates?.roleTitle}
        </p>
      </Box>
      <CandidateWorkingTable
        candidates={roleCandidates}
        onPageChange={(page) => {
          setPage(page);
        }}
        onRowsPerPageChange={(limit) => {
          setLimit(limit);
        }}
        roleId={roleId}
        loading={loading}
        totalCount={totalCount}
        onReload={()=> getCandidateByRoleId()}
      />
    </div>
  );
}

export default CandidateWorking;
