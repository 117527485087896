import { Icon } from '@iconify/react';
import { Box, Divider, Grid, List, ListItem, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ResumeForm from './ResumeForm';
import { json, useNavigate, useParams } from 'react-router-dom';
import { API_URLS } from 'src/constants/apiURLs';
import { Get, Post } from 'src/actions/API/apiActions';
import { enqueueSnackbar } from 'notistack';
import UploadCVs from './UploadCVs';

const style = {
  p: 1,
  width: '100%',
  borderRadius: 1,
};

function CVUploadComponent() {
  const navigate = useNavigate();
  const { roleId } = useParams();
  const userId = localStorage.getItem('userId');

  const [selectedState, setSelectedState] = useState('cv');
  const [processedFile, setprocessedFile] = useState(null);
  const [resumeData, setResumeData] = useState(null);
  const [role, setRole] = useState(null);

  const getRoleDetails = () => {
    try {
      Get(
        { id: roleId },
        API_URLS.getRoleById,
        (response) => {
          console.log(response);
          setRole(response?.data?.role);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRoleDetails();
  }, [roleId]);

  console.log('rolekjsgbdfs', role);
  const handleResumeData = (data, index) => {
    setprocessedFile(index);
    if (data.type === 'application/pdf') {
      setResumeData(data);
    } else if (data.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      try {
        const formData = new FormData();
        formData.append('file', data.file);
        Post(
          formData,
          API_URLS.extractText,
          (resp) => {
            setResumeData({
              ...data,
              docx: resp,
            });
          },
          (error) => {
            console.log('error', error);
          }
        );
      } catch (error) {
        console.log('error', error);
      }
    } else if (data.type === 'application/msword') {
      setResumeData(data);
    } else {
      setResumeData(data);
    }
  };

  const handleUploadCV = (values, actions)=>{
    console.log("ksdgfvksdfsdf", values)
    const formData = new FormData();
    formData.append('file', resumeData?.file);
    formData.append('role_id', roleId);
    formData.append('user_id', userId);
    formData.append('attachment_date', new Date());
    formData.append('full_name', values?.fullName);
    formData.append('first_name', values?.fullName.split(' ')[0]);
    formData.append('last_name', values?.fullName.split(' ')[1]);
    formData.append('current_title', values?.currentJobTitle);
    formData.append('personal_phone', values?.phoneNumber);
    formData.append('current_company', values?.currentCompany);
    formData.append('personal_email', values?.email);
    formData.append('work_history', JSON.stringify(values?.experience));
    formData.append('qualifications', JSON.stringify(values?.education));
    formData.append('languages', []);
    formData.append('skills', JSON.stringify(values?.skills || []));
    formData.append('candidate_source', 'manual');
    formData.append('cv_job_board', values?.cv_job_board);
    formData.append('months_back', values?.months_back);
    formData.append('radius_miles', values?.radius_miles);
    formData.append('salary', values?.salary);

    console.log("hsfsdfsdfsdf", values, actions);
    try {
      Post(
        formData, 
        API_URLS.uploadCV,
        (resp) => {
          console.log('resp', resp);
          enqueueSnackbar('CV uploaded successfully', { variant: 'success' });
        },
        (error) => {
          console.log('error', error);
        }
      );
    } catch (error) {
      console.log('error', error);
    }
  }
  // const handleAddCVToCRM = (values, actions) => {
  //   const payload = {
  //     role_id: roleId,
  //     user_id: userId,
  //     full_name: values?.fullName,
  //     first_name: values?.fullName.split(' ')[0],
  //     last_name: values?.fullName.split(' ')[1],
  //     current_title: values?.currentJobTitle,
  //     personal_phone: values?.phoneNumber,
  //     current_company: null,
  //     personal_email: values?.email,
  //     work_history: values?.experience,
  //     qualifications: values?.education,
  //     languages: [],
  //     skills: [],
  //     candidate_source: 'manual',
  //   };
  //   try {
  //     Post(
  //       payload,
  //       API_URLS.addCVToRole,
  //       (resp) => {
  //         enqueueSnackbar('CV added to CRM successfully', { variant: 'success' });
  //         setSubmittedCRMIndex(uploadingFileIndex);
  //       },
  //       (error) => {
  //         enqueueSnackbar('Error adding CV to CRM', { variant: 'error' });
  //       }
  //     );
  //   } catch (error) {
  //     enqueueSnackbar('Error adding CV to CRM', { variant: 'error' });
  //   }
  // };

  console.log('dfsdfdresumeData', processedFile);
  return (
    <div style={{ width: '95%', margin: 'auto' }}>
      <Box display="flex" marginBottom="10px" alignItems="center">
        <Icon
          icon="ion:arrow-back"
          style={{ fontSize: '24px', cursor: 'pointer', color: '#00C1FE' }}
          onClick={() => navigate(`/resourcing/roles-processing/${roleId}`)}
        />
        <p style={{ fontSize: '18px', marginLeft: '5px', color: '#00C1FE' }}>Back </p>
      </Box>
      <UploadCVs
        jd={role?.roles_working_role}
        onFileSelected={(data, index) => {
          handleResumeData(data, index);
        }}
      />
      {/* <UploadFiles
        jd={role?.roles_working_role?.doc_file_template}
        uploadProcessingIndex={processedFile}
        onFileSelected={(data, index) => {
          console.log('jsbsdfsdf', data, index);
          handleResumeData(data, index);
        }}
      /> */}
      {/* <UploadAndScoreFiles
        jd={role?.roles_working_role?.doc_file_template}
        submittedCRMIndex={submittedCRMIndex}
        submittedCVIndex={submittedCVIndex}
        uploadProcessingIndex={processedFile}
        onFileSelected={(data, index) => {
          console.log('jsbsdfsdf', data, index);
          handleResumeData(data, index);
        }}
      /> */}
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box display="flex" sx={{ backgroundColor: '#E1F7FC', borderRadius: '10px', height: '70px' }}>
            <ToggleButtonGroup
              value={selectedState}
              exclusive
              onChange={(e, value) => {
                if (value !== null) {
                  setSelectedState(value);
                }
              }}
              aria-label="company sections"
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                padding: 0,
                margin: 0,
                borderRadius: 10,
              }}
            >
              <ToggleButton
                value="cv"
                aria-label="cv"
                style={{
                  border: 'none',
                  color: selectedState === 'cv' ? 'white' : '#00C1FE',
                  backgroundColor: selectedState === 'cv' ? '#00C1FE' : '#E1F7FC',
                  borderTopLeftRadius: '10px', // Apply rounding to the first button
                  borderBottomLeftRadius: '10px',
                  paddingBottom: 2,
                  fontSize: '18px',
                  fontWeight: 'bold',
                  padding: '15px',
                }}
              >
                CV Preview
              </ToggleButton>
              <ToggleButton
                value="text"
                aria-label="text"
                style={{
                  border: 'none',
                  color: selectedState === 'text' ? 'white' : '#00C1FE',
                  backgroundColor: selectedState === 'text' ? '#00C1FE' : '#E1F7FC',
                  borderRadius: 0, // No rounding in the middle
                  paddingBottom: 2,
                  fontSize: '18px',
                  fontWeight: 'bold',
                  padding: '15px',
                }}
              >
                Text Resume
              </ToggleButton>
              <ToggleButton
                value="details"
                aria-label="details"
                style={{
                  border: 'none',
                  color: selectedState === 'details' ? 'white' : '#00C1FE',
                  backgroundColor: selectedState === 'details' ? '#00C1FE' : '#E1F7FC',
                  paddingBottom: 2,
                  fontSize: '18px',
                  fontWeight: 'bold',
                  padding: '15px',
                }}
              >
                Details
              </ToggleButton>
              <ToggleButton
                value="role_jd"
                aria-label="role_jd"
                style={{
                  border: 'none',
                  color: selectedState === 'role_jd' ? 'white' : '#00C1FE',
                  backgroundColor: selectedState === 'role_jd' ? '#00C1FE' : '#E1F7FC',
                  paddingBottom: 2,
                  fontSize: '18px',
                  fontWeight: 'bold',
                  padding: '15px',
                }}
              >
                Job Description
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box display="flex" sx={{ backgroundColor: 'white', height: '100vh', padding: '20px' }}>
            {selectedState === 'cv' && (
              <>
                {resumeData ? (
                  <Box
                    sx={{
                      height: 'auto',
                      width: '100%',
                      borderRadius: '10px',
                      border: '2px dashed #00C1FE',
                      padding: '20px',
                      overflowY: 'scroll',
                    }}
                  >
                    {resumeData?.type === 'application/pdf' ? (
                      <>
                        <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                          Preview: <span style={{ color: '#00C1FE' }}>{resumeData?.file?.name}</span>
                        </Typography>
                        <iframe
                          title={resumeData?.file?.name}
                          src={URL.createObjectURL(resumeData?.file)}
                          width="100%"
                          height="710px"
                        ></iframe>
                      </>
                    ) : resumeData?.type ===
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? (
                      <>
                        {/* set html content dangrously */}
                        <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                          Preview: <span style={{ color: '#00C1FE' }}>{resumeData?.file?.name}</span>
                        </Typography>
                        <div
                          style={{
                            width: '100%',
                            height: 'auto',
                            overflowY: 'scroll',
                            padding: '20px',
                            border: '1px solid #00C1FE',
                            borderRadius: '10px',
                          }}
                          dangerouslySetInnerHTML={{ __html: resumeData?.docx?.htmlContent }}
                        ></div>
                      </>
                    ) : (
                      <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                        No Preview: <span style={{ color: '#00C1FE' }}>{resumeData?.file?.name}</span>
                      </Typography>
                    )}
                  </Box>
                ) : (
                  <Typography variant="subtitle1" sx={{ margin: '20px' }}>
                    No CV selected for preview
                  </Typography>
                )}
              </>
            )}

            {selectedState === 'text' && (
              <>
                {resumeData ? (
                  <p style={{ fontSize: '18px', overflowY: 'scroll' }}>{resumeData?.processed_text}</p>
                ) : (
                  <Typography variant="subtitle1" sx={{ margin: '20px' }}>
                    No Text Resume Available
                  </Typography>
                )}
              </>
            )}

            {selectedState === 'details' && (
              <>
                {resumeData ? (
                  <List style={style}>
                    <ListItem>
                      <Grid container alignItems="center">
                        <Grid item xs={4}>
                          <Typography style={{ fontWeight: 'bold', color: '#00C1FE' }}> Name:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <span style={{ color: '#818283', fontWeight: 'initial' }}>
                            {resumeData?.parsed_resume?.fullName}
                          </span>
                        </Grid>
                      </Grid>
                    </ListItem>
                    <Divider component="li" sx={{ marginBottom: '10px' }} />

                    <ListItem>
                      <Grid container alignItems="center">
                        <Grid item xs={4}>
                          <Typography style={{ fontWeight: 'bold', color: '#00C1FE' }}>Email:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <span style={{ color: '#818283', fontWeight: 'initial' }}>
                            {resumeData?.parsed_resume?.email}
                          </span>
                        </Grid>
                      </Grid>
                    </ListItem>
                    <Divider component="li" sx={{ marginBottom: '10px' }} />

                    <ListItem>
                      <Grid container alignItems="center">
                        <Grid item xs={4}>
                          <Typography style={{ fontWeight: 'bold', color: '#00C1FE' }}>Number:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <span style={{ color: '#818283', fontWeight: 'initial' }}>
                            {resumeData?.parsed_resume?.phoneNumber}
                          </span>
                        </Grid>
                      </Grid>
                    </ListItem>
                    <Divider component="li" sx={{ marginBottom: '10px' }} />

                    <ListItem>
                      <Grid container alignItems="center">
                        <Grid item xs={4}>
                          <Typography style={{ fontWeight: 'bold', color: '#00C1FE' }}>Address:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <span style={{ color: '#818283', fontWeight: 'initial' }}>
                            {resumeData?.parsed_resume?.address?.country} , {resumeData?.parsed_resume?.address?.city}
                          </span>
                        </Grid>
                      </Grid>
                    </ListItem>
                    <Divider component="li" sx={{ marginBottom: '10px' }} />

                    <ListItem>
                      <Grid container alignItems="center">
                        <Grid item xs={4}>
                          <Typography style={{ fontWeight: 'bold', color: '#00C1FE' }}>Current Title:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <span style={{ color: '#818283', fontWeight: 'initial' }}>
                            {resumeData?.parsed_resume?.currentJobTitle}
                          </span>
                        </Grid>
                      </Grid>
                    </ListItem>
                    <Divider component="li" sx={{ marginBottom: '10px' }} />
                    <ListItem>
                      <Grid container alignItems="center">
                        <Grid item xs={4}>
                          <Typography style={{ fontWeight: 'bold', color: '#00C1FE' }}>Education:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          {resumeData?.parsed_resume?.education?.map((edu, index) => (
                            <div key={index}>
                              <span style={{ color: '#818283', fontWeight: 'initial' }}>
                                {edu?.degree ? edu?.degree : ''} {edu?.graduationYear ? edu?.graduationYear : ''}{' '}
                                {edu?.institution ? edu?.institution : ''}
                              </span>
                            </div>
                          ))}
                        </Grid>
                      </Grid>
                    </ListItem>
                  </List>
                ) : (
                  <Typography variant="subtitle1" sx={{ margin: '20px' }}>
                    No Details Available
                  </Typography>
                )}
              </>
            )}

            {selectedState === 'role_jd' && (
              <>
                {role && role?.roles_working_role && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: role?.roles_working_role?.doc_file_template,
                    }}
                  ></div>
                )}
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box display="flex" sx={{ backgroundColor: '#E1F7FC', borderRadius: '10px', height: '70px' }}>
            <Typography variant="h6" sx={{ color: '#00C1FE', padding: '15px', fontWeight: 'bold' }}>
              Extracted Data
            </Typography>
          </Box>
          <Box
            display="flex"
            sx={{
              backgroundColor: 'white',
              height: '100vh',
              padding: '20px',
              overflowY: 'scroll',
              marginBottom: '5px',
            }}
          >
            <ResumeForm
              initialValues={resumeData?.parsed_resume}
              onSubmit={(values, actions) => {
                // handleAddCVToCRM(values, actions);
                handleUploadCV(values, actions);
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default CVUploadComponent;
