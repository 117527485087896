import { Icon } from '@iconify/react';
import { ArrowBack } from '@mui/icons-material';
import { Avatar, Box, Grid, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Job } from './images';

function CallDetails(props) {
  const navigate = useNavigate();
  const [status, setStatus] = useState('details');
  const cardsData = [
    {
      name: 'Call client on Job Opening',
      time: '24:24',
      title: 'Designer',
      recruiter: 'Miss xyz',
      call_type: 'Outbound',
    },
    {
      name: 'Call client on Job Opening',
      time: '24:24',
      title: 'Designer',
      recruiter: 'Miss xyz',
      call_type: 'Outbound',
    },
    {
      name: 'Call client on Job Opening',
      time: '24:24',
      title: 'Designer',
      recruiter: 'Miss xyz',
      call_type: 'Outbound',
    },
    {
      name: 'Call client on Job Opening',
      time: '24:24',
      title: 'Designer',
      recruiter: 'Miss xyz',
      call_type: 'Outbound',
    },
    {
      name: 'Call client on Job Opening',
      time: '24:24',
      title: 'Designer',
      recruiter: 'Miss xyz',
      call_type: 'Outbound',
    },
    {
      name: 'Call client on Job Opening',
      time: '24:24',
      title: 'Designer',
      recruiter: 'Miss xyz',
      call_type: 'Outbound',
    },
  ];
  return (
    <div style={{ width: '97%', margin: 'auto' }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <ArrowBack
          fontSize="small"
          onClick={() => {
            navigate(-1);
          }}
          sx={{
            color: '#00C1FE',
            cursor: 'pointer',
          }}
        />
        &nbsp;&nbsp;{' '}
        <Typography
          variant="subtitle2"
          sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </Typography>
      </Box>
      <br />
      <Box display="flex">
        <p style={{ fontSize: '32px', fontWeight: 'bold', marginTop: '10px', marginLeft: '10px' }}>Call Details</p>
      </Box>
      <br />
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          {cardsData?.map((item, index) => {
            return (
              <>
                <Box
                  key={index} // It's a good practice to include a 'key' when rendering lists in React
                  display="flex"
                  justifyContent="flex-start"
                  flexDirection="column"
                  alignItems="center"
                  sx={{
                    backgroundColor: 'white',
                    padding: '40px',
                    height: 'auto',
                  }}
                >
                  <Box display="flex" justifyContent="space-between" sx={{ width: '100%' }} alignItems="center">
                    <Typography
                      variant="subtitle1"
                      sx={{ display: 'flex', alignItems: 'center', fontWeight: 'normal' }}
                    >
                      <Icon icon="material-symbols-light:call-outline" style={{ fontSize: '20px' }} />
                      &nbsp;{item.name}
                    </Typography>
                    <Typography variant="caption">{item.time}</Typography>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    sx={{ width: '100%' }}
                    marginLeft="50px"
                    marginTop={'10px'}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 'normal' }}>
                      {item.title}
                    </Typography>
                  </Box>

                  <Box
                    display={'flex'}
                    justifyContent="space-between"
                    alignItems={'center'}
                    sx={{ width: '100%' }}
                    marginRight="50px"
                    marginTop={'10px'}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 'normal', marginLeft: '50px' }}>
                      {item.recruiter}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'normal' }}>
                      {item.call_type}
                    </Typography>
                  </Box>
                </Box>
                <hr style={{ width: '100%', color: '#CAC5C5' }} />
              </>
            );
          })}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ borderLeft: '1px solid #CAC5C5' }}>
          <Box display="flex" sx={{ width: '100%', backgroundColor: 'white', padding: '20px' }} alignItems="center">
            <p
              style={{
                marginRight: '20px', // Adds space between avatar and name
                backgroundColor: '#11D7B9',
                color: '#fff',
                width: '85px',
                height: '85px',
                fontSize: '40px',
                borderRadius: '50%',
                textAlign: 'center',
                display: 'flex', // Enable flexbox
                alignItems: 'center', // Vertically center the image
                justifyContent: 'center', // Horizontally center the image
              }}
            >
              <Icon icon="material-symbols:call-sharp" style={{ fontSize: '50px', color: 'white' }} />
            </p>
            <p style={{ fontSize: '28px', marginLeft: '7px' }}> Conversion Call</p>
          </Box>
          <Box sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 4, height: 'auto', margin: '20px' }}>
            <p style={{ fontSize: '18px', margin: '4px' }}>Business Card</p>
            <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
            <Box display="flex" alignItems="flex-start" marginTop="20px" justifyContent="flex-start">
              <Grid container alignItems="flex-start">
                {/* Label (p tag) - Right aligned */}
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '17px', margin: '4px', color: '#838383', textAlign: 'left' }}>Call Type</p>
                </Grid>
                {/* Text Field - Left aligned */}
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="Outbound"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black', // Ensures disabled text is black
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" alignItems="flex-start" marginTop="20px" justifyContent="flex-start">
              <Grid container alignItems="flex-start">
                {/* Label (p tag) - Right aligned */}
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '17px', margin: '4px', color: '#838383', textAlign: 'left' }}>Call Purpose</p>
                </Grid>
                {/* Text Field - Left aligned */}
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="Project"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black', // Ensures disabled text is black
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" alignItems="flex-start" marginTop="20px" justifyContent="flex-start">
              <Grid container alignItems="flex-start">
                {/* Label (p tag) - Right aligned */}
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '17px', margin: '4px', color: '#838383', textAlign: 'left' }}>Call Duration</p>
                </Grid>
                {/* Text Field - Left aligned */}
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="35:12"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black', // Ensures disabled text is black
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" alignItems="flex-start" marginTop="20px" justifyContent="flex-start">
              <Grid container alignItems="flex-start">
                {/* Label (p tag) - Right aligned */}
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '17px', margin: '4px', color: '#838383', textAlign: 'left' }}>Call Owner</p>
                </Grid>
                {/* Text Field - Left aligned */}
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="Miss xyz"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black', // Ensures disabled text is black
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 4, height: 'auto', margin: '20px' }}>
            <p style={{ fontSize: '18px', margin: '4px' }}>Call Information</p>
            <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
            <Box display="flex" alignItems="flex-start" marginTop="20px" justifyContent="flex-start">
              <Grid container alignItems="flex-start">
                {/* Label (p tag) - Right aligned */}
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '17px', margin: '4px', color: '#838383', textAlign: 'left' }}>Call Owner</p>
                </Grid>
                {/* Text Field - Left aligned */}
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="Miss xyz"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black', // Ensures disabled text is black
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" alignItems="flex-start" marginTop="20px" justifyContent="flex-start">
              <Grid container alignItems="flex-start">
                {/* Label (p tag) - Right aligned */}
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '17px', margin: '4px', color: '#838383', textAlign: 'left' }}>Subject</p>
                </Grid>
                {/* Text Field - Left aligned */}
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="Conversion call"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black', // Ensures disabled text is black
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" alignItems="flex-start" marginTop="20px" justifyContent="flex-start">
              <Grid container alignItems="flex-start">
                {/* Label (p tag) - Right aligned */}
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '17px', margin: '4px', color: '#838383', textAlign: 'left' }}>Call Type</p>
                </Grid>
                {/* Text Field - Left aligned */}
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="Outbound"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black', // Ensures disabled text is black
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" alignItems="flex-start" marginTop="20px" justifyContent="flex-start">
              <Grid container alignItems="flex-start">
                {/* Label (p tag) - Right aligned */}
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '17px', margin: '4px', color: '#838383', textAlign: 'left' }}>Call Purpose</p>
                </Grid>
                {/* Text Field - Left aligned */}
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="Project"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black', // Ensures disabled text is black
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" alignItems="flex-start" marginTop="20px" justifyContent="flex-start">
              <Grid container alignItems="flex-start">
                {/* Label (p tag) - Right aligned */}
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '17px', margin: '4px', color: '#838383', textAlign: 'left' }}>Candidate</p>
                </Grid>
                {/* Text Field - Left aligned */}
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="Ali"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#00C5FF', // Ensures disabled text is black
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" alignItems="flex-start" marginTop="20px" justifyContent="flex-start">
              <Grid container alignItems="flex-start">
                {/* Label (p tag) - Right aligned */}
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '17px', margin: '4px', color: '#838383', textAlign: 'left' }}>Related to</p>
                </Grid>
                {/* Text Field - Left aligned */}
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="-"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black', // Ensures disabled text is black
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" alignItems="flex-start" marginTop="20px" justifyContent="flex-start">
              <Grid container alignItems="flex-start">
                {/* Label (p tag) - Right aligned */}
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '17px', margin: '4px', color: '#838383', textAlign: 'left' }}>Posting Title</p>
                </Grid>
                {/* Text Field - Left aligned */}
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="-"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black', // Ensures disabled text is black
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" alignItems="flex-start" marginTop="20px" justifyContent="flex-start">
              <Grid container alignItems="flex-start">
                {/* Label (p tag) - Right aligned */}
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '17px', margin: '4px', color: '#838383', textAlign: 'left' }}>
                    Call STart Time
                  </p>
                </Grid>
                {/* Text Field - Left aligned */}
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="10/09/2020 05:36 AM"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black', // Ensures disabled text is black
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" alignItems="flex-start" marginTop="20px" justifyContent="flex-start">
              <Grid container alignItems="flex-start">
                {/* Label (p tag) - Right aligned */}
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '17px', margin: '4px', color: '#838383', textAlign: 'left' }}>Call Duration</p>
                </Grid>
                {/* Text Field - Left aligned */}
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="05:36 "
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black', // Ensures disabled text is black
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" alignItems="flex-start" marginTop="20px" justifyContent="flex-start">
              <Grid container alignItems="flex-start">
                {/* Label (p tag) - Right aligned */}
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '17px', margin: '4px', color: '#838383', textAlign: 'left' }}>Call Results</p>
                </Grid>
                {/* Text Field - Left aligned */}
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="Candidate accepted offer letter"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black', // Ensures disabled text is black
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} sx={{ borderLeft: '1px solid #CAC5C5', backgroundColor: 'white' }}>
          <Box sx={{ padding: '20px' }}>
            <ToggleButtonGroup
              value={status}
              exclusive
              onChange={(e, value) => {
                if (value !== null) {
                  setStatus(value);
                }
              }}
              aria-label="company sections"
              style={{
                display: 'flex',

                justifyContent: 'flex-start',
                marginLeft: '20px',
                marginBottom: '10px', // Add margin bottom for spacing
              }}
            >
              <ToggleButton
                value="details"
                aria-label="details"
                style={{
                  border: 'none',
                  color: status === 'details' ? '#00C1FE' : 'black',
                  borderBottom: status === 'details' ? '3px solid #00C1FE' : 'none',
                  borderRadius: 0,
                  paddingBottom: 2,
                  backgroundColor: 'white',
                  fontSize: '14px',
                  fontWeight: 'normal',
                }}
              >
                Details
              </ToggleButton>
              <ToggleButton
                value="conversions"
                aria-label="conversions"
                style={{
                  border: 'none',
                  color: status === 'conversions' ? '#00C1FE' : 'black',
                  borderBottom: status === 'conversions' ? '3px solid #00C1FE' : 'none',
                  borderRadius: 0,
                  paddingBottom: 2,
                  marginLeft: '10px',
                  backgroundColor: 'white',
                  fontSize: '14px',
                  fontWeight: 'normal',
                }}
              >
                Conversions
              </ToggleButton>
            </ToggleButtonGroup>
            <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '-10px' }} />
            {status === 'details' && (
              <>
                <Box display="flex" alignItems="center" justifyContent="flex-start" sx={{ padding: '20px' }}>
                  <Avatar
                    style={{
                      marginRight: '20px', // Adds space between avatar and name
                      backgroundColor: '#4CD4FF',
                      color: '#fff',
                      width: '35px',
                      height: '35px',
                      fontSize: '18px',
                      textAlign: 'center',
                    }}
                  >
                    A
                  </Avatar>
                  <Box display="flex" flexDirection="column" alignItems="flex-start">
                    <Box display="flex">
                      <Typography
                        variant="subtitle2"
                        sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}
                      >
                        {'Ali Raza'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" marginLeft="10px" marginTop="15px" alignItems="center">
                  <Icon icon="fluent:building-20-regular" style={{ fontSize: '20px' }} />
                  <p style={{ fontSize: '14px', marginLeft: '5px' }}> Chandlers</p>
                </Box>
                <Box display="flex" marginLeft="10px" marginTop="20px" alignItems="center">
                  <Icon icon="hugeicons:telephone" style={{ fontSize: '20px' }} />
                  <p style={{ fontSize: '14px', marginLeft: '5px' }}> 2313 32132141 2</p>
                </Box>
                <Box display="flex" marginLeft="10px" marginTop="20px" alignItems="center">
                  <Icon icon="material-symbols:call-outline" style={{ fontSize: '20px' }} />
                  <p style={{ fontSize: '14px', marginLeft: '5px' }}> 2313 32132141 2</p>
                </Box>
                <Box display="flex" marginLeft="10px" marginTop="20px" alignItems="center">
                  <Icon icon="ic:outline-email" style={{ fontSize: '20px' }} />
                  <p style={{ fontSize: '14px', marginLeft: '5px' }}> 2313 32132141 2</p>
                </Box>
                <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
                <Box marginLeft="10px">
                  <Typography variant="subtitle1" sx={{ textAlign: 'left', marginTop: '10px' }}>
                    Associated Job Openings
                  </Typography>
                  <Box display="flex" alignItems="center" justifyContent="flex-start" sx={{ padding: '20px' }}>
                    <p
                      style={{
                        marginRight: '20px', // Adds space between avatar and name
                        color: '#fff',
                        width: '85px',
                        height: '85px',
                        fontSize: '40px',
                        borderRadius: '20px',
                        textAlign: 'center',
                        display: 'flex', // Enable flexbox
                        alignItems: 'center', // Vertically center the image
                        justifyContent: 'center', // Horizontally center the image
                      }}
                    >
                      <Icon icon="hugeicons:new-job" style={{ fontSize: '50px', color: '#7D8592' }} />
                    </p>

                    <Box display="flex" flexDirection="column" alignItems="flex-start">
                      <Typography variant="subtitle1" sx={{ textTransform: 'none', color: '#4C4C4C' }}>
                        {'Designer'}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ textTransform: 'none', color: '#4C4C4C' }}>
                        Filled - Apr 21
                      </Typography>
                      <Typography variant="subtitle1" sx={{ textTransform: 'none', color: '#4C4C4C' }}>
                        Converted - Employee
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
                <Box marginLeft="10px">
                  <Typography variant="subtitle1" sx={{ textAlign: 'left', marginTop: '10px' }}>
                    To - Dos
                  </Typography>
                  <Typography variant="caption" sx={{ textAlign: 'left', marginTop: '10px' }}>
                    No To-Dos found.
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default CallDetails;
