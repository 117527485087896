export const screening = [{ label: 'In Review', group: 'Screening' }];

export const interview = [
  { label: 'Interview to be sceduled', group: 'Interview' },
  { label: 'Interview Scheduled', group: 'Interview' },
  { label: 'Interview in progress', group: 'Interview' },
  { label: 'Rejected hireable', group: 'Interview' },
];

export const offered = [
  { label: 'Offer planned', group: 'Offered' },
  { label: 'Offer accepted', group: 'Offered' },
  { label: 'Offer declined', group: 'Offered' },
  { label: 'Offer withdrawn', group: 'Offered' },
];

export const hired = [
  { label: 'Hired', group: 'Hired' },
  { label: 'Hired for Interview', group: 'Hired' },
  { label: 'Forward to on-boarding', group: 'Hired' },
];

export const rejected = [
  { label: 'Rejected', group: 'Rejected' },
  { label: 'Rejected for Interview', group: 'Rejected' },
];

export const archived = [{ label: 'Archived', group: 'Archived' }];

export const shortlist = [
  { label: 'Hired', group: 'Shortlist' },
  { label: 'On-Hold', group: 'Shortlist' },
  { label: 'Strongly Hired', group: 'Shortlist' },
  { label: 'Shortlist', group: 'Shortlist' },
  { label: 'Wait-List', group: 'Shortlist' },
  { label: 'Move to next round', group: 'Shortlist' },
];

export const rejectedHireable = [
  { label: 'Rejected', group: 'Rejected' },
  { label: 'Strongly Rejected', group: 'Rejected' },
];