import React from 'react';
import { JobOpeningsSection } from 'src/sections/direct360';

function JobOpeningsPage(props) {
  return (
    <div>
      <JobOpeningsSection />
    </div>
  );
}

export default JobOpeningsPage;
