import { Box, Button, Grid, Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import _ from 'lodash'; // Import lodash for debounce
import { StatsCard } from '.';
import HighchartsPieChart from '../../campaigns/components/HighchartsPieChart';
import { DownloadOutlined } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
function TeamReportingPage({ selectedUser, formattedDates, country, sector, onStatsChange }) {
  const userReportColumns = useMemo(
    () => [
      {
        accessorKey: 'full_name',
        header: 'Lead Expert',
        minWidth: 170,
        Cell: ({ row }) => {
          // Extract full name and first name
          const firstName = row.original.user.first_name || '';
          const firstChar = firstName.charAt(0).toUpperCase();
          return (
            <Box component="span" display="flex" alignItems="center">
              {/* Show first character as an image */}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{
                  marginRight: '10px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '35px',
                    width: '35px',
                    borderRadius: '50%',
                    border: '1px solid #ccc',
                    backgroundColor: '#f3f3f3',
                    fontSize: '15px',
                    color: '#333',
                    cursor: 'pointer',
                  }}
                >
                  {firstChar}
                </div>
              </Box>
              {/* Show the full name */}
              <span> {row?.original?.full_name}</span>
            </Box>
          );
        },
      },
      {
        accessorKey: 'total_person_assigned',
        header: 'Total',
        minWidth: 170,
        Cell: ({ cell }) => {
          return (
            <div
              style={{
                color: 'blue',
                fontWeight: 'bold',
              }}
            >
              {cell.getValue()}
            </div>
          );
        },
      },
      {
        accessorKey: 'not_worked',
        header: 'Not Worked',
        minWidth: 170,
        // make the cell value green
        Cell: ({ cell }) => {
          return (
            <div
              style={{
                color: 'red',
                fontWeight: 'bold',
              }}
            >
              {cell.getValue()}
            </div>
          );
        },
      },
      {
        accessorKey: 'worked',
        header: 'Worked',
        minWidth: 170,
        Cell: ({ cell }) => {
          return (
            <div
              style={{
                color: 'mediumseagreen',
                fontWeight: 'bold',
              }}
            >
              {cell.getValue()}
            </div>
          );
        },
      },
      {
        accessorKey: 'emails_not_added',
        header: 'Not found',
        minWidth: 170,
        Cell: ({ cell }) => {
          return (
            <div
              style={{
                color: 'grey',
                fontWeight: 'bold',
              }}
            >
              {cell.getValue()}
            </div>
          );
        },
      },
      {
        accessorKey: 'emails_added',
        header: 'Found',
        minWidth: 170,
        Cell: ({ cell }) => {
          return (
            <div
              style={{
                color: 'orange',
                fontWeight: 'bold',
              }}
            >
              {cell.getValue()}
            </div>
          );
        },
      },
      // {
      //   accessorKey: 'emails_added',
      //   header: 'Emails Added',
      //   minWidth: 170,
      //   Cell: ({ cell }) => {
      //     return (
      //       <div
      //         style={{
      //           color: 'orange',
      //           fontWeight: 'bold',
      //         }}
      //       >
      //         {cell.getValue()}
      //       </div>
      //     );
      //   },

      // },
      {
        accessorKey: 'added_and_verified',
        header: 'Veirfied',
        minWidth: 170,
        Cell: ({ cell }) => {
          return (
            <div
              style={{
                color: 'green',
                fontWeight: 'bold',
              }}
            >
              {cell.getValue()}
            </div>
          );
        },
      },
      {
        accessorKey: 'added_and_bounced',
        header: 'Bounced',
        minWidth: 170,
        Cell: ({ cell }) => {
          return (
            <div
              style={{
                color: 'yellowgreen',
                fontWeight: 'bold',
              }}
            >
              {cell.getValue()}
            </div>
          );
        },
      },
      {
        accessorKey: 'status_not_updated',
        header: 'Not Checked',
        minWidth: 170,
        Cell: ({ cell }) => {
          return (
            <div
              style={{
                color: 'black',
                fontWeight: 'bold',
              }}
            >
              {cell.getValue()}
            </div>
          );
        },
      },
    ],
    [] // Recalculate when counters change
  );

  // const [userWorkReport, setUserWorkReport] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  // const [searchString, setSearchString] = useState('');
  const [isError, setIsError] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [teamsAssignedStats, setTeamsAssignedStats] = useState();
  const [userReportV3, setUserReportV3] = useState([]);
  const [newStats, setNewStats] = useState();
  // const getUserWorkReport = useCallback(
  //   _.debounce(() => {
  //     try {
  //       setLoadingData(true)
  //       Get(
  //         {
  //           user_id: selectedUser,
  //           from_date: formattedDates[0],
  //           to_date: formattedDates[1],
  //         },
  //         API_URLS.getUserWorkReport,
  //         (resp) => {
  //           let temp = resp?.data.data;
  //           //filter the records where user_assignedis not null
  //           temp = temp?.filter((item) => item?.user_assigned !== null);
  //           let data = temp?.map((row) => {
  //             row.full_name = `${row?.assigned_user?.first_name} ${row?.assigned_user?.last_name}`;
  //             return row;
  //           });
  //           setUserWorkReport(data);
  //           setTotalCount(data?.length);
  //           setLoadingData(false)
  //         },
  //         (error) => {
  //           // enqueueSnackbar('Failed to get the user report', { variant: 'error' });
  //           setLoadingData(false)
  //           setIsError(true);
  //         }
  //       );
  //     } catch (error) {
  //       // enqueueSnackbar('Something went wrong', { variant: 'error' });
  //       setLoadingData(false)
  //       setIsError(true);
  //     }
  //   }, 300), // 300ms debounce time
  //   [selectedUser, formattedDates]
  // );

  const getUserWorkReportV3 = useCallback(
    _.debounce(() => {
      try {
        setLoadingData(true);
        Get(
          {
            user_id: selectedUser,
            from_date: formattedDates[0],
            to_date: formattedDates[1],
            country_id: country?.id,
            sector_id: sector?.id,
          },
          API_URLS.getUserWorkReportV3,
          (resp) => {
            
            let temp = [];
            if (resp?.data.reports) {
              temp = resp?.data.reports;
              // extract the full name from the assigned user which is object as leadExpert{first_name,last_name}
              temp = temp?.map((row) => {
                row.full_name = `${row?.user?.first_name} ${row?.user?.last_name}`;
                return row;
              });

              setUserReportV3(temp);

              // set the total count of the records
              setTotalCount(resp?.data?.length || 0);
              // set the overall stats

              setNewStats(resp?.data?.overallStats);
            } else {
              setUserReportV3([]);
            }
            setLoadingData(false);
          },
          (error) => {
            // enqueueSnackbar('Failed to get the user report', { variant: 'error' });
            setLoadingData(false);
            setIsError(true);
          }
        );
      } catch (error) {
        // enqueueSnackbar('Something went wrong', { variant: 'error' });
        setLoadingData(false);
        setIsError(true);
      }
    }, 300), // 300ms debounce time
    [selectedUser, formattedDates, country, sector]
  );

  // Effect to trigger debounced API call
  useEffect(() => {
    // getUserWorkReport();
    getUserWorkReportV3();
  }, [getUserWorkReportV3]);

  const getTeamAssignedStats = useCallback(() => {
    try {
      Get(
        {
          user_id: selectedUser,
          from_date: formattedDates[0],
          to_date: formattedDates[1],
          country_id: country?.id,
          sector_id: sector?.id,
        },
        API_URLS.getTeamAssignedPersonsStats,
        (resp) => {
          setTeamsAssignedStats(resp?.data);
        },
        (error) => {
          // enqueueSnackbar('Failed to get the team stats', { variant: 'error' });
        }
      );
    } catch (error) {
      // enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }, [selectedUser, formattedDates, country, sector]);

  useEffect(() => {
    getTeamAssignedStats();
  }, [getTeamAssignedStats]);

  const withEmailReporting = [
    {
      name: 'Checked Emails',
      value: teamsAssignedStats ? teamsAssignedStats?.checkedEmailsCount : 0,
      color: '#EB65E5',
    },
    {
      name: 'Unchecked Emails',
      value: teamsAssignedStats ? teamsAssignedStats?.unCheckedEmailsCount : 0,
      color: '#F58538',
    },
  ];

  const checkedEmailReporting = [
    {
      name: 'Bounced',
      value: teamsAssignedStats ? teamsAssignedStats?.bouncedEmailsCount : 0,
      color: '#F14040',
    },
    {
      name: 'Verified',
      value: teamsAssignedStats ? teamsAssignedStats?.verifiedEmailsCount : 0,
      color: '#3FC28A',
    },
  ];

  const awsEmailReporting = [
    {
      name: 'Bounced',
      value: teamsAssignedStats ? teamsAssignedStats?.awsBouncedEmails : 0,
      color: '#5C61F2',
    },
    {
      name: 'Verified',
      value: teamsAssignedStats ? teamsAssignedStats?.awsVerifiedLeads : 0,
      color: '#00C1FE',
    },
  ];

  const totalReporting = [
    {
      name: 'With Emails',
      value: teamsAssignedStats ? teamsAssignedStats?.withEmailsCount : 0,
      color: '#EB65E5',
    },
    {
      name: 'Without Emails',
      value: teamsAssignedStats ? teamsAssignedStats?.withoutEmailsCount : 0,
      color: '#F58538',
    },
  ];

  return (
    <div>
      <div style={{ marginTop: '10PX' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            <StatsCard
              mainLabel="Total Leads"
              leftLabel="Worked"
              rightLabel="Not Worked"
              mainNumber={newStats?.total_person_assigned}
              leftNumber={newStats?.worked}
              rightNumber={newStats?.not_worked}
              onLeftClick={() => {}}
              onRightClick={() => {}}
              onMainClick={() => {}}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatsCard
              mainLabel="Worked"
              leftLabel="Found"
              rightLabel="Not Found"
              mainNumber={newStats?.worked}
              leftNumber={newStats?.emails_added}
              rightNumber={newStats?.emails_not_added}
              onLeftClick={() => {}}
              onRightClick={() => {}}
              onMainClick={() => {}}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatsCard
              mainLabel="Found"
              leftLabel="Verified"
              rightLabel="Bounced"
              mainNumber={newStats?.emails_added}
              leftNumber={newStats?.added_and_verified}
              rightNumber={newStats?.added_and_bounced}
              onLeftClick={() => {}}
              onRightClick={() => {}}
              onMainClick={() => {}}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatsCard
              mainLabel="Worked"
              leftLabel="Not Checked"
              rightLabel="Replacements"
              mainNumber={newStats?.worked}
              leftNumber={newStats?.status_not_updated}
              rightNumber={newStats?.replacements_added}
              onLeftClick={() => {}}
              onRightClick={() => {}}
              onMainClick={() => {}}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatsCard
              mainLabel="AWS Check"
              leftLabel="Verified"
              rightLabel="Bounced"
              leftNumber={teamsAssignedStats?.awsVerifiedLeads}
              mainNumber={teamsAssignedStats?.awsTotal}
              rightNumber={teamsAssignedStats?.awsBouncedEmails}
              onLeftClick={() => {}}
              onRightClick={() => {}}
              onMainClick={() => {}}
            />
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: '10px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            <HighchartsPieChart
              title="Team Email Reporting"
              chartData={totalReporting}
              subheader={`Total : ${teamsAssignedStats ? teamsAssignedStats?.totalPersons : 0}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <HighchartsPieChart
              title="With Email Reporting"
              chartData={withEmailReporting}
              subheader={`Total : ${teamsAssignedStats ? teamsAssignedStats?.withEmailsCount : 0}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <HighchartsPieChart
              title="Checked Email Reporting"
              chartData={checkedEmailReporting}
              subheader={`Total : ${teamsAssignedStats ? teamsAssignedStats?.checkedEmailsCount : 0}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <HighchartsPieChart
              title="Amazon Checked Email Reporting"
              chartData={awsEmailReporting}
              subheader={`Total : ${teamsAssignedStats ? teamsAssignedStats?.awsTotal : 0}`}
            />
          </Grid>
        </Grid>
      </div>

      <br />
      <Box display="flex" marginLeft="5px" gap={2}>
        <Typography variant="h4"> Lead Experts</Typography>
        <Button
          style={{
            outline: '1px solid rgba(0, 0, 0, 0.12)',
            backdropFilter: 'blur(2px)',
            width: '150px',
            height: '40px',
            backgroundColor: true ? '#00C1FE' : '#ECF6FE',
            color: true ? 'white' : '#00C1FE',
            border: '1px solid #00C1FE',
            marginRight: '5px',
            '&:hover': {
              backgroundColor: '#00C1FE',
              color: 'white',
            },
          }}
          size="small"
          onClick={() => {
            const ws = XLSX.utils.json_to_sheet(userReportV3);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, 'user report.xlsx');
          }}
        >
          <DownloadOutlined />
          Download Report
        </Button>
      </Box>
      <br />
      <div
        style={{
          marginLeft: '5px',
        }}
      >
        <MaterialReactTable
          enableStickyHeader
          enableTopToolbar={false}
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 120,
            },
          }}
          enableColumnActions={false}
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: 'error',
                  children: 'Error Loading Data',
                }
              : undefined
          }
          columns={userReportColumns}
          data={userReportV3}
          positionToolbarAlertBanner="bottom"
          manualPagination
          rowCount={totalCount}
          onPaginationChange={setPagination}
          manualFiltering
          // onGlobalFilterChange={setSearchString}
          state={{
            pagination,
            isLoading: loadingData,
          }}
        />

        <Typography>Emails</Typography>
        <MaterialReactTable
          enableStickyHeader
          enableTopToolbar={false}
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 120,
            },
          }}
          enableColumnActions={false}
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: 'error',
                  children: 'Error Loading Data',
                }
              : undefined
          }
          columns={userReportColumns}
          data={userReportV3}
          positionToolbarAlertBanner="bottom"
          manualPagination
          rowCount={totalCount}
          onPaginationChange={setPagination}
          manualFiltering
          // onGlobalFilterChange={setSearchString}
          state={{
            pagination,
            isLoading: loadingData,
          }}
        />
      </div>
    </div>
  );
}

export default TeamReportingPage;
