import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Post } from 'src/actions/API/apiActions';
import { notify } from 'src/components/notify';
import { API_URLS } from 'src/constants/apiURLs';
import { HTMLEditor } from 'src/sections/admin/templates/components';
// import { Post_SendRoleFollowupEmailToClient_URL } from 'src/constants/apiURLs';

function SendFollowupEmailDialog({ open, onClose, clientData }) {
  const [clientEmail, setClientEmail] = useState();
  const [fromEmail, setFromEmail] = useState();
  const [subject, setsubject] = useState();
  const [html, setHtml] = useState();

  const sendEmail = () => {
    try {
      Post(
        {
          toEmail: clientEmail,
          fromEmail: fromEmail,
          subject: subject,
          html: html,
        },
        API_URLS.sendRoleFollowupEmailToClient,
        (resp) => {
          onClose();
          notify('Email Sent Successfully', 'success', 1000);
        },
        (error) => {
          notify('Error Sending Email', 'error', 1000);
        }
      );
    } catch (error) {
      notify('Something went wrong at server', 'error', 1000);
    }
  };
  return (
    <div>
      <Dialog open={open} onClose={onClose} maxWidth>
        <DialogTitle
          sx={{
            backgroundColor: '#F4F9FD',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h4">Send Followup Email </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <br />
          <Grid container spacing={2}>
            <br />
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                id="toEmail"
                name="toEmail"
                value={clientEmail ? clientEmail : clientData?.email}
                onChange={(e) => setClientEmail(e.target.value)}
                type="string"
                label="Client Email"
                size="small"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                id="fromEmail"
                name="fromEmail"
                value={fromEmail}
                onChange={(e) => setFromEmail(e.target.value)}
                type="string"
                label="From Email"
                size="small"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                id="subject"
                name="subject"
                value={subject}
                onChange={(e) => setsubject(e.target.value)}
                type="string"
                label="Email Subject"
                size="small"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <HTMLEditor
                showTags={false}
                editorLabel="Email content"
                htmlText={html}
                onEditorStateChange={(html) => {
                  setHtml(html?.data);
                }}
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" sx={{ marginTop: '1rem' }}>
            <Button
              color="primary"
              sx={{
                fontSize: '18px',
                borderRadius: '10px',
                borderWidth: '3px',
                height: '54px',
                color: 'white',
                width: '200px',
                backgroundColor: '#0ec4fe',
                boxShadow: '-5px 7px 20px rgba(0, 0, 0, 0.2)',
                marginLeft: '10px',
                marginRight: '30px',
                '&:hover': {
                  color: 'white !important',
                  borderColor: '#0ec4fe !important',
                  boxShadow: '-5px 7px 20px rgba(0, 0, 0, 0.2) !important',
                  backgroundColor: '#0ec4fe !important',
                },
              }}
              onClick={() => sendEmail()}
            >
              Send
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SendFollowupEmailDialog;
