import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's Snow theme CSS
import { Alert, Typography } from '@mui/material';
import './editor.css';

const HTMLEditor = ({ editorLabel, htmlText, onEditorStateChange, showTags }) => {
  const leadTagList = [
    '[First Name]',
    '[Last Name]',
    '[Company Name]',
    '[Title]',
    '[Email]',
    '[Recent Job Post]',
    '[Industry]',
  ];

  const BDTags = ['[BD Name]', '[BD Title]', '[BD Email]', '[BD Telephone]'];
  const [copiedTag, setCopiedTag] = useState(null);
  const [editorContent, setEditorContent] = useState(htmlText || ''); // State to manage editor content
  const editorRef = useRef(null);

  useEffect(() => {
    // Update editor content if htmlText changes
    setEditorContent(htmlText);
  }, [htmlText]);

  const handleCopyToClipboard = (tag) => {
    navigator.clipboard.writeText(tag);
    setCopiedTag(tag);
    setTimeout(() => setCopiedTag(null), 1000); // Reset copiedTag after 1 second
  };

  // Custom toolbar configuration
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // Toggled buttons
    ['blockquote', 'code-block'],
    ['link', 'image', 'video'], // Media options
    [{ list: 'ordered' }, { list: 'bullet' }], // List options
    ['clean'], // Clear formatting
    [{ header: [1, 2, 3, 4, 5, 6, false] }], // Header options
    [{ script: 'sub' }, { script: 'super' }], // Superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }], // Indent/outdent
    [{ direction: 'rtl' }], // Text direction (right-to-left)
    [{ size: ['small', false, 'large', 'huge'] }], // Font size options
    [{ color: [] }, { background: [] }], // Text color and background
    [{ font: [] }], // Font family
    [{ align: [] }], // Text alignment
  ];

  const modules = {
    toolbar: toolbarOptions, // Attach custom toolbar
  };

  return (
    <div>
      <h3>{editorLabel || ''}</h3>
      {showTags && (
        <>
          <Typography variant="h6">Lead Tags</Typography>
          <div className="tag-list-container">
            <div className="tags-container">
              {leadTagList.map((tag, index) => (
                <span
                  key={index}
                  onClick={() => handleCopyToClipboard(tag)} // Only copy tag, do not insert into editor
                  className={`tag ${copiedTag === tag ? 'copied' : ''}`}
                >
                  {tag}
                </span>
              ))}
            </div>
          </div>
          <Typography variant="h6">BD Tags</Typography>
          <div className="tag-list-container">
            <div className="tags-container">
              {BDTags.map((tag, index) => (
                <span
                  key={index}
                  onClick={() => handleCopyToClipboard(tag)} // Only copy tag, do not insert into editor
                  className={`tag ${copiedTag === tag ? 'copied' : ''}`}
                >
                  {tag}
                </span>
              ))}
            </div>
            {copiedTag && <p className="copied-message">Copied Tag: {copiedTag}</p>}
            <br />
            <Alert severity="error">Note: Do not edit the tags in the editor.</Alert>
          </div>
        </>
      )}
      {/* 
      Numbered List
      <Typography variant="h6">Numbered List</Typography>
      <div className="numbered-list-container">
        {[1, 2, 3, 4, 5].map((number) => (
          <span
            key={number}
            onClick={() => handleCopyToClipboard(`${number}.`)} // Copy number instead of inserting
            className="numbered-list-item"
          >
            {number}
          </span>
        ))}
      </div> */}

      <ReactQuill
        ref={editorRef} // Attach ref for Quill instance
        value={editorContent} // Controlled input for editor
        onChange={(content, delta, source, editor) => {
          setEditorContent(content); // Update local state with new content
          onEditorStateChange({ data: editor.getHTML() }); // Send HTML data to parent component
        }}
        style={{ height: '500px', overflowY: 'scroll', maxHeight: '500px', width: '100%' }} // Editor styling
        modules={modules} // Attach modules with toolbar
        theme="snow" // Use the Snow theme
      />
    </div>
  );
};

export default HTMLEditor;
