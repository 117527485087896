import React, { useCallback, useEffect, useState } from 'react';
import { ArrowPipeline, CustomizedRolesComponent, ReactMultiDatePicker, ServiceRolesComponent } from '../components';
import { Box, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';

function RoleTypeBasedRoles({ roleType, onBack }) {
  const [roleTypeStats, setRoleTypeStats] = useState();
  const [formattedDates, setFormattedDates] = useState([null, null]);
  const [status, setStatus] = useState({ status: '' });

  const [typePage, setTypePage] = useState(0);
  const [typeLimit, setTypeLimit] = useState(10);
  const [previousRoles, setPreviousRoles] = useState(false);
  const [advanceRoles, setAdvanceRoles] = useState(false);

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const [prePage, setPrePage] = useState(0);
  const [preLimit, setPreLimit] = useState(10);
  const [directPage, setDirectPage] = useState(0);
  const [directLimit, setDirectLimit] = useState(10);
  const [selectedService, setSelectedService] = useState({ service: '' });
  const getRoleTypeBasedServiceRoles = useCallback(() => {
    try {
      Get(
        {
          role_type: roleType.roleType,
          page: page,
          limit: limit,
          start_date: formattedDates[0],
          end_date: formattedDates[1],
          pre_page: prePage,
          pre_limit: preLimit,
          direct_page: directPage,
          direct_limit: directLimit,
          previous_roles: previousRoles,
          advance_roles: advanceRoles,
          status: status?.status,
          type_page: typePage,
          type_limit: typeLimit,
          selected_service: selectedService?.service,
        },
        API_URLS.getRolesTypeBasedServiceRolesWithStats,
        (resp) => {
          setRoleTypeStats(resp?.data);
        },
        (error) => {
        }
      );
    } catch (error) {
    }
  }, [
    roleType,
    formattedDates,
    status,
    typePage,
    typeLimit,
    page,
    limit,
    prePage,
    preLimit,
    directPage,
    directLimit,
    selectedService,
    previousRoles,
    advanceRoles,
  ]);

  useEffect(() => {
    getRoleTypeBasedServiceRoles();
  }, [getRoleTypeBasedServiceRoles]);

  return (
    <div>
      <Box display="flex" justifyContent="flex-start" alignItems="center" width="95%" margin="auto" marginBottom="5px">
        <Icon
          icon="ic:round-arrow-back"
          style={{ fontSize: '25px', color: '#00C1FE', cursor: 'pointer' }}
          onClick={() => {
            onBack();
          }}
        />
        <Typography variant="subtitle1" sx={{ marginLeft: '10px', color: '#00C1FE' }}>
          Back to total
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: '1rem', backgroundColor: 'white', width: '95%', margin: 'auto' }}
      >
        <>
          <ArrowPipeline
            title={roleType?.roleType}
            total={roleTypeStats ? roleTypeStats?.serviceStats?.total : 0}
            left={roleTypeStats ? roleTypeStats?.serviceStats?.pending : 0}
            center={roleTypeStats ? roleTypeStats?.serviceStats?.inProgress : 0}
            right={roleTypeStats ? roleTypeStats?.serviceStats?.done : 0}
            color="#E496FF"
            onClick={() => {
              setSelectedService((prevState) => ({ ...prevState, service: '' }));
              setStatus((prevState) => ({ ...prevState, status: '' }));
            }}
            onLeftClick={() => {
              setSelectedService((prevState) => ({ ...prevState, service: '' }));
              setStatus((prevState) => ({ ...prevState, status: 'Pending' }));
            }}
            onCenterClick={() => {
              setSelectedService((prevState) => ({ ...prevState, service: '' }));
              setStatus((prevState) => ({ ...prevState, status: 'In-Progress' }));
            }}
            onRightClick={() => {
              setSelectedService((prevState) => ({ ...prevState, service: '' }));
              setStatus((prevState) => ({ ...prevState, status: 'Done' }));
            }}
            leftTooltip="Pending"
            centerTooltip="In progress"
            rightTooltip="Done"
          />
          <ArrowPipeline
            title="CV Sourcing"
            total={roleTypeStats ? roleTypeStats?.cvSourcing?.total : 0}
            left={roleTypeStats ? roleTypeStats?.cvSourcing?.pending : 0}
            center={roleTypeStats ? roleTypeStats?.cvSourcing?.inProgress : 0}
            right={roleTypeStats ? roleTypeStats?.cvSourcing?.done : 0}
            color="#F85ED6"
            onClick={() => {
              setSelectedService((prevState) => ({ ...prevState, service: 'CV Sourcing' }));
              setStatus((prevState) => ({ ...prevState, status: '' }));
            }}
            onLeftClick={() => {
              setSelectedService((prevState) => ({ ...prevState, service: 'CV Sourcing' }));
              setStatus((prevState) => ({ ...prevState, status: 'Pending' }));
            }}
            onCenterClick={() => {
              setSelectedService((prevState) => ({ ...prevState, service: 'CV Sourcing' }));
              setStatus((prevState) => ({ ...prevState, status: 'In-Progress' }));
            }}
            onRightClick={() => {
              setSelectedService((prevState) => ({ ...prevState, service: 'CV Sourcing' }));
              setStatus((prevState) => ({ ...prevState, status: 'Done' }));
            }}
            leftTooltip="Pending"
            centerTooltip="In progress"
            rightTooltip="Done"
          />
          <ArrowPipeline
            title="Pre Qualification"
            total={roleTypeStats ? roleTypeStats?.preQualification?.total : 0}
            left={roleTypeStats ? roleTypeStats?.preQualification?.pending : 0}
            center={roleTypeStats ? roleTypeStats?.preQualification?.inProgress : 0}
            right={roleTypeStats ? roleTypeStats?.preQualification?.done : 0}
            color="#8C85FB"
            onClick={() => {
              setSelectedService((prevState) => ({ ...prevState, service: 'Pre Qualification' }));
              setStatus((prevState) => ({ ...prevState, status: '' }));

            }}
            onLeftClick={() => {
              setSelectedService((prevState) => ({ ...prevState, service: 'Pre Qualification' }));
              setStatus((prevState) => ({ ...prevState, status: 'Pending' }));
            }}
            onCenterClick={() => {
              setSelectedService((prevState) => ({ ...prevState, service: 'Pre Qualification' }));
              setStatus((prevState) => ({ ...prevState, status: 'In-Progress' }));
            }}
            onRightClick={() => {
              setSelectedService((prevState) => ({ ...prevState, service: 'Pre Qualification' }));
              setStatus((prevState) => ({ ...prevState, status: 'Done' }));
            }}
            leftTooltip="Pending"
            centerTooltip="In progress"
            rightTooltip="Done"
          />
          <ArrowPipeline
            title="360/Direct"
            total={roleTypeStats ? roleTypeStats?.direct360?.total : 0}
            left={roleTypeStats ? roleTypeStats?.direct360?.pending : 0}
            center={roleTypeStats ? roleTypeStats?.direct360?.inProgress : 0}
            right={roleTypeStats ? roleTypeStats?.direct360?.done : 0}
            color="#00C1FE"
            onClick={() => {
              setSelectedService((prevState) => ({ ...prevState, service: '360/Direct' }));
              setStatus((prevState) => ({ ...prevState, status: '' }));
            }}
            onLeftClick={() => {
              setSelectedService((prevState) => ({ ...prevState, service: '360/Direct' }));
              setStatus((prevState) => ({ ...prevState, status: 'Pending' }));
            }}
            onCenterClick={() => {
              setSelectedService((prevState) => ({ ...prevState, service: '360/Direct' }));
              setStatus((prevState) => ({ ...prevState, status: 'In-Progress' }));
            }}
            onRightClick={() => {
              setSelectedService((prevState) => ({ ...prevState, service: '360/Direct' }));
              setStatus((prevState) => ({ ...prevState, status: 'Done' }));
            }}
            leftTooltip="Pending"
            centerTooltip="In progress"
            rightTooltip="Done"
          />
        </>
      </Box>
      <br />
      <Box display="flex" justifyContent="flex-end" sx={{ marginBottom: '1rem', width: '95%', margin: 'auto' }}>
        <ReactMultiDatePicker
          onChange={(dateRange) => {
            setFormattedDates(dateRange);
            setPreviousRoles(false);
            setAdvanceRoles(false);
          }}
          style={{
            width: '240px',
            height: '50px',
            paddingRight: '5px',
            marginRight: '5px',
            border: '2px solid #00C1FE',
            outline: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: '10px',
            boxShadow: '-5px 7px 20px rgba(0, 0, 0, 0.2) !important',
            backgroundColor: '#ECF6FE',
            color: '#00C1FE',
            boxSizing: 'border-box',
            paddingLeft: '10px',
          }}
        />
      </Box>
      {!selectedService?.service && (
        <>
          <div style={{ width: '95%', margin: 'auto', marginTop: '5px' }}>
            <CustomizedRolesComponent
              rolesss={roleTypeStats?.typeRoles?.roles}
              title={roleType.roleType}
              onPageChange={(page) => setTypePage(page)}
              onRowsPerPageChange={(limit) => setTypeLimit(limit)}
              onPreviousClick={(title) => {
                setPreviousRoles(true);
                setAdvanceRoles(false);
                setFormattedDates([null, null]);
              }}
              OnAdvanceClick={(title) => {
                setAdvanceRoles(true);
                setPreviousRoles(false);
                setFormattedDates([null, null]);
              }}
              totalCount={roleTypeStats?.typeRoles?.pagination?.totalRoles}
            />
          </div>
        </>
      )}

      {selectedService?.service === 'CV Sourcing' && (
        <div style={{ width: '95%', margin: 'auto' }}>
          <ServiceRolesComponent
            roleType={roleType?.roleType}
            title={selectedService?.service}
            onPreviousClick={(title) => {
              setPreviousRoles(true);
              setAdvanceRoles(false);
              setFormattedDates([null, null]);
            }}
            OnAdvanceClick={(title) => {
              setAdvanceRoles(true);
              setPreviousRoles(false);
              setFormattedDates([null, null]);
            }}
            roles={roleTypeStats ? roleTypeStats?.cvSourcing?.roles : []}
            onPageChange={(page) => setPage(page)}
            onRowsPerPageChange={(limit) => setLimit(limit)}
            totalCount={roleTypeStats?.cvSourcing?.total}
          />
        </div>
      )}
      {selectedService?.service === 'Pre Qualification' && (
        <div style={{ width: '95%', margin: 'auto' }}>
          <ServiceRolesComponent
            roleType={roleType?.roleType}
            title={selectedService?.service}
            onPreviousClick={(title) => {
              setPreviousRoles(true);
              setAdvanceRoles(false);
              setFormattedDates([null, null]);
            }}
            OnAdvanceClick={(title) => {
              setAdvanceRoles(true);
              setPreviousRoles(false);
              setFormattedDates([null, null]);
            }}
            roles={roleTypeStats ? roleTypeStats?.preQualification?.roles : []}
            onPageChange={(page) => setPrePage(page)}
            onRowsPerPageChange={(limit) => setPreLimit(limit)}
            totalCount={roleTypeStats?.preQualification?.total}
          />
        </div>
      )}
      {selectedService?.service === '360/Direct' && (
        <div style={{ width: '95%', margin: 'auto' }}>
          <ServiceRolesComponent
            roleType={roleType?.roleType}
            title={selectedService?.service}
            onPreviousClick={(title) => {
              setPreviousRoles(true);
              setAdvanceRoles(false);
              setFormattedDates([null, null]);
            }}
            OnAdvanceClick={(title) => {
              setAdvanceRoles(true);
              setPreviousRoles(false);
              setFormattedDates([null, null]);
            }}
            roles={roleTypeStats ? roleTypeStats?.direct360?.roles : []}
            onPageChange={(page) => setDirectPage(page)}
            onRowsPerPageChange={(limit) => setDirectLimit(limit)}
            totalCount={roleTypeStats?.direct360?.total}
          />
        </div>
      )}
    </div>
  );
}

export default RoleTypeBasedRoles;
