export const travel_time = [
    { label: '15 mins', group: 'Travel Time' },
    { label: '30 mins', group: 'Travel Time' },
    { label: '45 mins', group: 'Travel Time' },
    { label: '1 hour', group: 'Travel Time' },
    { label: '1 hour 30 mins', group: 'Travel Time' },
    { label: '2 hours', group: 'Travel Time' },
  ];
  
  export const distance = [
    { label: '0 miles', group: 'Distance' },
    { label: 'within 0.5 miles', group: 'Distance' },
    { label: 'within 1 mile', group: 'Distance' },
    { label: 'within 3 miles', group: 'Distance' },
    { label: 'within 5 miles', group: 'Distance' },
    { label: 'within 10 miles', group: 'Distance' },
    { label: 'within 20 miles', group: 'Distance' },
    { label: 'within 30 miles', group: 'Distance' },
    { label: 'within 40 miles', group: 'Distance' },
    { label: 'within 60 miles', group: 'Distance' },
    { label: 'within 80 miles', group: 'Distance' },
  ];

export const current_salary = ['Any', 'Annual', 'Daily', 'Hourly'];
export const salary_ranges = [
  '£0 - £9,999',
  '£10,000 - £13,999',
  '£14,000 - £17,999',
  '£18,000 - £21,999',
  '£22,000 - £25,999',
  '£26,000 - £29,999',
  '£30,000 - £34,999',
  '£35,000 - £39,999',
  '£40,000 - £44,999',
  '£45,000 - £49,999',
  '£50,000 - £54,999',
  '£55,000 - £59,999',
  '£60,000 - £64,999',
  '£65,000 - £69,999',
  '£70,000 - £74,999',
  '£75,000 - £79,999',
  '£80,000 - £84,999',
  '£85,000 - £89,999',
  '£90,000 - £94,999',
  '£95,000 - £99,999',
  '£100,000+',
];

export const active_within = [
  'All',
  '1 day',
  '2 days',
  '3 days',
  '4 days',
  '5 days',
  '7 days',
  '14 days',
  '1 month',
  '2 months',
  '3 months',
  '6 months',
  '12 months',
  '18 months',
];

export const hideProfileViesOptions = ['Today', '3 days', '5 days', '7 days'];

export const candidateLocation = ['East London', 'South East London', 'Bristol', 'Derby', 'Manchester'];
export const activeWithinLast = ['East London', 'South Est London', 'Bristol', 'Derby'];

export const currentSalary = [
  '£0 - £9,999',
  '£10,000 - £13,999',
  '£14,000 - £17,999',
  '£18,000 - £21,999',
  '£22,000 - £25,999',
  '£26,000 - £29,999',
  '£30,000 - £34,999',
];

export const jobTitles = ['Web designer', 'Freelancer', 'Web developer', 'Graphic designer', 'Assistant designer'];

export const disciplines = ['Design', 'Development', 'Marketing', 'Sales', 'Management'];
export const profileType = ['Regular Profile', 'Early Profile'];
export const jobTypes = ['Permanent', 'Temporary', 'Contract'];
export const hours = ['Full Time', 'Part Time'];