import { ArrowBack } from '@mui/icons-material';
import { Autocomplete, Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HTMLEditor } from 'src/sections/admin/templates/components';
import { ScheduleEmailForCandidate } from '.';

function SendEmailToCandidate(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const previousPath = location.state?.previousPath || `/recruiter/app`;
  const emailTemplates = ['Direct', 'S&R'];
  const [openScheduleEmailDialog, setOpenScheduleEmailDialog] = useState(false);
  return (
    <div style={{ width: '97%', margin: 'auto' }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <ArrowBack
          fontSize="small"
          onClick={() => {
            navigate(previousPath);
          }}
          sx={{
            color: '#00C1FE',
            cursor: 'pointer',
          }}
        />
        &nbsp;&nbsp;{' '}
        <Typography
          variant="subtitle2"
          sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}
          onClick={() => {
            navigate(previousPath);
          }}
        >
          Back
        </Typography>
      </Box>
      <br />
      <Box>
        <p style={{ fontSize: '32px', fontWeight: 'bold', marginTop: '10px' }}>Send Email</p>
      </Box>
      <br />
      <Box sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 4, height: 'auto', marginTop: '15px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1px solid #dfe1e5',
                paddingBottom: '8px',
                justifyContent: 'center',
              }}
            >
              <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
                From
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <Box>
              <TextField
                id="standard-basic"
                label=""
                variant="standard"
                fullWidth
                sx={{
                  '& .MuiInput-underline:before': {
                    borderBottomColor: '#dfe1e5', // Default color
                  },
                  '& .MuiInput-underline:hover:before': {
                    borderBottomColor: '#dfe1e5', // Hover color (same as default)
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#dfe1e5', // Focused color (same as default)
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} marginTop="20px">
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1px solid #dfe1e5',
                paddingBottom: '8px',
                justifyContent: 'center',
              }}
            >
              <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
                Choose Email Template
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <Box>
              <Autocomplete
                id="clear-on-escape"
                clearOnEscape
                options={emailTemplates} // Use the emailTemplates array for options
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Template"
                    variant="standard"
                    fullWidth
                    sx={{
                      '& .MuiInput-underline:before': {
                        borderBottomColor: '#dfe1e5', // Default color
                      },
                      '& .MuiInput-underline:hover:before': {
                        borderBottomColor: '#dfe1e5', // Hover color (same as default)
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: '#dfe1e5', // Focused color (same as default)
                      },
                    }}
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} marginTop="20px">
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1px solid #dfe1e5',
                paddingBottom: '8px',
                justifyContent: 'center',
              }}
            >
              <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
                To
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <Box>
              <TextField
                id="standard-basic"
                label=""
                variant="standard"
                fullWidth
                sx={{
                  '& .MuiInput-underline:before': {
                    borderBottomColor: '#dfe1e5', // Default color
                  },
                  '& .MuiInput-underline:hover:before': {
                    borderBottomColor: '#dfe1e5', // Hover color (same as default)
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#dfe1e5', // Focused color (same as default)
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} marginTop="20px">
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1px solid #dfe1e5',
                paddingBottom: '8px',
                justifyContent: 'center',
              }}
            >
              <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
                BCC
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <Box>
              <TextField
                id="standard-basic"
                label=""
                variant="standard"
                fullWidth
                sx={{
                  '& .MuiInput-underline:before': {
                    borderBottomColor: '#dfe1e5', // Default color
                  },
                  '& .MuiInput-underline:hover:before': {
                    borderBottomColor: '#dfe1e5', // Hover color (same as default)
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#dfe1e5', // Focused color (same as default)
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} marginTop="20px">
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1px solid #dfe1e5',
                paddingBottom: '8px',
                justifyContent: 'center',
              }}
            >
              <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
                Subject
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <Box>
              <TextField
                id="standard-basic"
                label=""
                variant="standard"
                fullWidth
                sx={{
                  '& .MuiInput-underline:before': {
                    borderBottomColor: '#dfe1e5', // Default color
                  },
                  '& .MuiInput-underline:hover:before': {
                    borderBottomColor: '#dfe1e5', // Hover color (same as default)
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#dfe1e5', // Focused color (same as default)
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} marginTop="20px">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1px solid #dfe1e5',
                paddingBottom: '8px',
                justifyContent: 'flex-start',
              }}
            >
              <Typography variant="subtitle1" sx={{ textAlign: 'left' }}>
                Editor Options
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} marginTop="20px">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                borderBottom: '1px solid #dfe1e5',
                paddingBottom: '8px',
                justifyContent: 'flex-start',
              }}
            >
              <HTMLEditor showTags={false} htmlText={null} onEditorStateChange={(html) => {}} />
            </Box>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" alignItems="center" marginTop="20px">
          <Button
            sx={{
              background: '#A3A3A3',
              height: '45px',
              border: '1px solid #A3A3A3',
              color: 'white',
              '&:hover': {
                background: '#A3A3A3',
                color: 'white',
              },
              borderRadius: '15px',
              padding: '25px',
              marginLeft: '10px',
            }}
            onClick={() => navigate(previousPath)}
          >
            Cancel
          </Button>
          <Button
            sx={{
              background: '#fff', // White background
              color: '#00C1FE', // Text color set to #00C1FE
              height: '45px',
              border: '1px solid #00C1FE', // Border color set to #00C1FE
              '&:hover': {
                background: '#00C1FE', // Lighter background on hover
                color: '#fff', // Text color turns white on hover
              },
              borderRadius: '15px',
              padding: '25px',
              marginLeft: '10px',
            }}
            onClick={() => setOpenScheduleEmailDialog(true)}
          >
            Schedule
          </Button>

          <Button
            sx={{
              background: '#00C1FE',
              color: '#fff',
              height: '45px',
              border: '1px solid #00C1FE',
              '&:hover': {
                background: '#00C1FE',
                color: '#fff',
              },
              borderRadius: '15px',
              padding: '25px',
              marginRight: '10px',
              marginLeft: '10px',
            }}
          >
            Send
          </Button>
        </Box>
      </Box>
      {openScheduleEmailDialog && (
        <ScheduleEmailForCandidate open={openScheduleEmailDialog} onClose={() => setOpenScheduleEmailDialog(false)} />
      )}
    </div>
  );
}

export default SendEmailToCandidate;
