import { ArrowBack } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CandidatePipelineTable } from './components';

function InterviewCandidates(props) {
  const navigate = useNavigate();
  const buttons = [
    {
      buttonText: 'Total',
      value: 100,
      borderTop: '#F68AF2',
      buttonFontSize: '22px',
    },
    {
      buttonText: 'Scheduled',
      value: 25,
      borderTop: '#88BCF6',
      buttonFontSize: '22px',
    },
    {
      buttonText: 'Conflict',
      value: 25,
      borderTop: '#A4B0DB',
      buttonFontSize: '22px',
    },
    {
      buttonText: 'Resolved',
      value: 25,
      borderTop: '#FF9999',
      buttonFontSize: '22px',
    },
  ];
  return (
    <div style={{ width: '97%', margin: 'auto' }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <ArrowBack
          fontSize="small"
          onClick={() => {
            navigate(`/recruiter/app`);
          }}
          sx={{
            color: '#00C1FE',
            cursor: 'pointer',
          }}
        />
        &nbsp;&nbsp;{' '}
        <Typography
          variant="subtitle2"
          sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}
          onClick={() => {
            navigate(`/recruiter/app`);
          }}
        >
          Back to Dashboard
        </Typography>
      </Box>
      <br />
      <Box>
        <p style={{ fontSize: '32px', fontWeight: 'bold', marginTop: '10px' }}>Interview Candidates</p>
      </Box>
      <CandidatePipelineTable buttons={buttons} onButtonClick={(value) => {}} showHiringPipeline={true}  />
    </div>
  );
}

export default InterviewCandidates;
