import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { ArrowBack } from '@mui/icons-material';
import { UCover404 } from 'src/images';
import { Icon } from '@iconify/react';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';

const JobContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
  flexWrap: 'wrap',

}));

const NoJobsMessage = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.text.secondary,
  marginTop: theme.spacing(4),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),

  border: '1px solid grey',
  borderRadius: theme.shape.borderRadius,
  width: '100%',
  height: 'auto',
  '&:hover': {
    boxShadow: theme.shadows[5],
  },
  padding: theme.spacing(2),
  margin: theme.spacing(2),
  '&:hover': {
    //apply box shadow 
    boxShadow: theme.shadows[4],
  }
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  '& img': {
    borderRadius: theme.shape.borderRadius,
    marginTop:"-30px"
  },
}));

function CompanyDetails({ company, onBack }) {
  const [selected, setSelected] = useState('about');

  // Helper function to determine if the cover URL is valid
function isValidCoverUrl(url) {
  return url && url !== '' && url !== 'undefined' && !url.startsWith('data:');
}

  return (
    <>
      <div>
        <ArrowBack
          fontSize="large"
          style={{ color: '#00C1FE', marginLeft: '1rem', cursor: 'pointer' }}
          onClick={() => onBack()}
        />
      </div>
      <div style={{ margin: '1rem', borderRadius: '15px', background: 'white' }}>
        <div>
          <div>
            <img
              src={isValidCoverUrl(company?.cover) ? company.cover : UCover404}
              alt="company cover"
              style={{
                width: '100%',
                height: 'auto',
                maxHeight: 400,
                objectFit: company?.cover ? 'cover' : 'fill',
                borderRadius: '15px 15px 0 0',
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: 20,
                padding: 20,
              }}
            >
              <img
                src={company?.logo}
                alt="company avator"
                style={{
                  width: 150,
                  height: 150,
                  objectFit: 'cover',
                  marginTop: -75,
                  border: '5px solid white',
                  // borderRadius: '50%',
                }}
              />
            </div>
          </div>
          <Typography variant="h3" marginLeft={4}>
            {company?.name}
          </Typography>
          <Typography
            variant="subtitle1"
            marginLeft={4}
            style={{
              color: 'grey',
              fontSize: 10,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {company?.tagline || ''}
          </Typography>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 10,
              padding: 10,
              marginLeft: 20,
            }}
          >
            <p style={{ color: 'black', fontSize: 13 }}>
              {company?.industry},{' '}
              {company?.address || company?.headquarter_city + ', ' + company?.headquarter_geographic_area}
            </p>
            <p style={{ color: 'black', fontSize: 13 }}> -&nbsp; {company?.follower_count} Followers</p>
            <p style={{ color: 'black', fontSize: 13 }}> -&nbsp; {company?.actual_range} Employees</p>
            <p style={{ color: 'black', fontSize: 13 }}> -&nbsp; {company?.staff_count} on LinkedIn</p>
            <p style={{ color: 'black', fontSize: 13 }}> -&nbsp; {company?.peopleCount} on CRM</p>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: 5,
              padding: 10,
              width: '40%',
            }}
          >
            <a
              href={company?.website}
              target="_blank"
              rel="noreferrer"
              style={{
                background: '#00C1FE',
                color: 'white',
                padding: 10,
                borderRadius: 50,
                display: 'flex', // Change display to flex
                alignItems: 'center', // Align items vertically in the center
                justifyContent: 'center', // Center content horizontally
                fontWeight: 'bold',
                width: 170,
                marginLeft: '1rem',
                textDecoration: 'none',
                gap: 5, // Add space between text and icon
              }}
            >
              Visit Website <Icon icon="icon-park-outline:share" />
            </a>

            <a
              href={company?.profile_url}
              target="_blank"
              rel="noreferrer"
              style={{
                background: '#00C1FE',
                color: 'white',
                padding: 10,
                borderRadius: 50,
                fontWeight: 'bold',
                display: 'block',
                width: 170,
                marginLeft: '1rem',
                textAlign: 'center',
                textDecoration: 'none',
              }}
            >
              Visit Profile
            </a>
            <a
              href={company?.profile_url_encoded}
              target="_blank"
              rel="noreferrer"
              style={{
                background: '#00C1FE',
                color: 'white',
                padding: 10,
                borderRadius: 50,
                fontWeight: 'bold',
                display: 'block',
                width: 'fit-content',
                marginLeft: '1rem',
                textAlign: 'center',
                textDecoration: 'none',
              }}
            >
              Profile Link Not Working? Click Here
            </a>
          </div>

          <hr style={{ width: '100%', margin: 'auto' }} />

          <ToggleButtonGroup
            value={selected}
            exclusive
            onChange={(e, value) => {
              if (value !== null) {
                setSelected(value);
              }
            }}
            aria-label="company sections"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              gap: 40,
              padding: 10,
            }}
          >
            <ToggleButton
              value="about"
              aria-label="about"
              style={{
                border: 'none',
                color: selected === 'people' ? '3px solid  #00C1FE' : 'black',
                borderBottom: selected === 'about' ? '3px solid #00C1FE' : 'none',
                borderRadius: 0,
                paddingBottom: 5,
                backgroundColor: 'white',
              }}
            >
              About
            </ToggleButton>
            <ToggleButton
              value="jobs"
              aria-label="jobs"
              style={{
                border: 'none',
                color: selected === 'people' ? '3px solid  #00C1FE' : 'black',
                borderBottom: selected === 'jobs' ? '3px solid  #00C1FE' : 'none',
                borderRadius: 0,
                paddingBottom: 5,
                backgroundColor: 'white',
              }}
            >
              Jobs
            </ToggleButton>
            <ToggleButton
              value="people"
              aria-label="people"
              style={{
                border: 'none',
                color: selected === 'people' ? '3px solid  #00C1FE' : 'black',
                borderBottom: selected === 'people' ? '3px solid  #00C1FE' : 'none',
                borderRadius: 0,
                paddingBottom: 5,
                backgroundColor: 'white',
              }}
            >
              People
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <div style={{ margin: '1rem', borderRadius: '15px', background: 'white' }}>
        {selected === 'about' && (
          <>
            <About
              aboutText={company?.description}
              actual_range={company?.actual_range}
              associated_members={company?.staff_count}
              industry={company?.industry}
              specialieties={company?.specialities}
              website={company?.website}
              headquarter_country={company?.headquarter_country}
              phone_number={company?.phone_number}
              founded={company?.founded_on}
              company={company}
            />
          </>
        )}
      </div>
      {selected === 'jobs' && <Jobs jobList={company?.job_posts || []} logo={company?.logo} company={company} />}
      {selected === 'people' && <People people={company?.employees} company={company} />}
    </>
  );
}

export default CompanyDetails;

const About = ({
  aboutText,
  industry,
  website,
  phone_number,
  actual_range,
  associated_members,
  specialieties,
  headquarter_country,
  founded,
  company,
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'start',
      width: '98%',
      margin: 'auto',
      padding: 20,
      fontSize: 20,
    }}
  >
    <Typography variant="h4">Overview</Typography>
    <br />
    <Typography variant="body1">{aboutText}</Typography>
    <br />
    <Typography variant="subtitle1">Website</Typography>
    <a
      href={website}
      target="_blank"
      rel="noreferrer"
      style={{
        color: '#0A66C2',
        textDecoration: 'none',
        fontSize: '17px',
        fontWeight: 'bold',
        '&:hover': { textDecoration: 'underline' },
      }}
    >
      {website || 'N/A'}
    </a>
    <Typography variant="subtitle1" sx={{ marginTop: '15px', fontSize: '17px' }}>
      Industry
    </Typography>
    <Typography variant="body1" sx={{ fontSize: '14px', color: 'gray' }}>
      {industry || 'N/A'}
    </Typography>
    <Typography variant="subtitle1" sx={{ marginTop: '15px', fontSize: '17px' }}>
      Phone Number
    </Typography>
    <Typography variant="body1" sx={{ fontSize: '14px', color: 'gray' }}>
      {phone_number || 'Not Available'}
    </Typography>
    <Typography variant="subtitle1" sx={{ marginTop: '15px', fontSize: '17px' }}>
      Company size
    </Typography>
    <Typography variant="body1" sx={{ fontSize: '14px', color: 'gray' }}>
      {`${actual_range} employees` || 'Not Available'}
    </Typography>
    <Typography variant="body1" sx={{ fontSize: '14px', color: 'gray' }}>
      {associated_members || 'Not Available'}&nbsp; associated members
    </Typography>
    <Typography variant="subtitle1" sx={{ marginTop: '15px', fontSize: '17px' }}>
      Headquarters
    </Typography>
    <Typography variant="body1" sx={{ fontSize: '14px', color: 'gray' }}>
      {headquarter_country || 'Not Available'}
    </Typography>
    <Typography variant="subtitle1" sx={{ marginTop: '15px', fontSize: '17px' }}>
      Founded
    </Typography>
    <Typography variant="body1" sx={{ fontSize: '14px', color: 'gray' }}>
      {founded || 'Not Available'}
    </Typography>
    <Typography variant="subtitle1" sx={{ marginTop: '15px', fontSize: '17px' }}>
      Specialities
    </Typography>
    <Typography variant="body1" sx={{ fontSize: '14px', color: 'gray', marginBottom: '10px' }}>
      {' '}
      {specialieties || 'Not Available'}
    </Typography>
    <Typography variant="subtitle1" sx={{ marginTop: '15px', fontSize: '17px' }}>
      Locations
    </Typography>
    <Typography variant="body1" sx={{ fontSize: '14px', color: 'gray', marginBottom: '10px' }}>
      {company?.headquarter_line1 || ''}, {' ' + company?.headquarter_line2 || ''} ,
      {' ' + company?.headquarter_city || ''} ,{' ' + company?.headquarter_geographic_area || ''},{' '}
      {' ' + company?.headquarter_postal_code || ''},{' ' + company?.headquarter_country || ''}
    </Typography>
  </div>
);

const Jobs = ({ jobList, logo, company }) => {
  const [jobPostSearchString, setJobPostSearchString] = useState('');
  const [search, setSearch] = useState(false);
  const [searchedResults, setSearchedResults] = useState([]);

  const searchJobPosts = () => {
    try {
      Get(
        {
          company_id: company?.id,
          searchTerm: jobPostSearchString,
        },
        API_URLS.searchJobPostsOfCompany,
        (resp) => {
          setSearchedResults(resp?.data);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  if (search) {
    searchJobPosts();
    setSearch(false);
  }

  const timeAgo = (createdAt) => {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const diffInSeconds = Math.floor((now - createdDate) / 1000);

    if (diffInSeconds < 60) {
      return `${diffInSeconds} seconds ago`;
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
      return `${diffInMinutes} minutes ago`;
    }

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
      return `${diffInHours} hours ago`;
    }

    const diffInDays = Math.floor(diffInHours / 24);
    return `${diffInDays} days ago`;
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setJobPostSearchString(value);

    // If the search string is empty, show the full list of job posts
    if (value === '') {
      setSearchedResults([]);
    }
  };

  return (
    <>
      <div style={{ margin: '1rem', borderRadius: '15px', background: 'white', height: '80%' }}>
        <Box display="flex" justifyContent="center" paddingBottom="10px">
          <Typography variant="subtitle1" sx={{ paddingTop: '15px', fontSize: '20px' }}>
            {company?.name} has {jobList?.length} job openings
          </Typography>
        </Box>
        <Box
          display="flex"
          margin="auto"
          paddingBottom="1.5rem"
          padding="auto"
          justifyContent="center"
          alignItems="center"
        >
          <TextField
            InputProps={{
              startAdornment: (
                <Icon
                  icon="mingcute:search-line"
                  style={{ fontSize: '24px', fontWeight: 'bold', marginRight: '8px' }}
                />
              ),
            }}
            variant="outlined"
            placeholder="Search..."
            size="small"
            value={jobPostSearchString}
            onChange={handleInputChange}
            sx={{
              width: '45%',
              backgroundColor: 'white',
              borderRadius: '0.3rem',
              paddingLeft: '1rem',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {},
                '&:hover fieldset': {
                  borderColor: '#00C1FE',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#00C1FE',
                },
              },
              '& .MuiInputBase-root': {
                '&.Mui-focused': {
                  boxShadow: '0 0 0 1px #00C1FE',
                },
              },
            }}
          />
          <Button
            onClick={() => setSearch(true)}
            variant="contained"
            size="small"
            sx={{
              color: 'white',
              width: '10%',
              backgroundColor: '#00C1FE',
              borderRadius: '5px',
              padding: '10px',
              marginLeft: '5px',
              fontSize: '12px',
              '&:hover': {
                backgroundColor: '#00C1FE',
              },
              borderRadius: '20px',
            }}
          >
            Search
          </Button>
        </Box>
      </div>

      <div style={{ margin: '1rem', borderRadius: '15px', background: 'white' }}>
        <JobContainer>
          {/* If no jobs found */}
          {jobList?.length === 0 && (
            <NoJobsMessage variant="h5" margin="1rem">
              No jobs found
            </NoJobsMessage>
          )}

          {/* If jobs exist and no search string or results */}
          {jobList?.length > 0 && searchedResults?.length === 0 && !jobPostSearchString && (
            <Grid container spacing={2}>
              {jobList?.map((job, index) => (
                <Grid item xs={12} md={3} key={index}>
                  <StyledCard key={index}>
                    <StyledCardContent>
                      <img
                        src={logo}
                        alt="company logo"
                        style={{
                          width: 80,
                          height: 80,
                          objectFit: 'cover',
                          marginBottom: 3,
                        }}
                      />
                      <Typography variant="h6">{job?.job_title}</Typography>
                      <Typography variant="subtitle1" sx={{ color: 'grey' }}>
                        {company?.name}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ color: 'grey' }}>
                        {job?.city} {job?.state}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ color: 'grey' }}>
                        {timeAgo(job?.createdAt)}
                      </Typography>
                    </StyledCardContent>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>
          )}

          {/* If search is performed but no results found */}
          {searchedResults?.length === 0 && jobList?.length > 0 && search && (
            <Box display="flex" justifyContent="center" alignItems="center" margin="auto">
              <Typography variant="h5" sx={{ textAlign: 'center', padding: '1rem' }}>
                No jobs match your search
              </Typography>
            </Box>
          )}

          {/* If search results are found */}
          {searchedResults?.length > 0 && (
            <Grid container spacing={2}>
              {searchedResults?.map((job, index) => (
                <Grid item xs={12} md={3} key={index}>
                  <StyledCard key={index}>
                    <StyledCardContent>
                      <img
                        src={logo}
                        alt="company logo"
                        style={{
                          width: 80,
                          height: 80,
                          objectFit: 'cover',
                          marginBottom: 3,
                        }}
                      />
                      <Typography variant="h6">{job?.job_title}</Typography>
                      <Typography variant="subtitle1" sx={{ color: 'grey' }}>
                        {company?.name}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ color: 'grey' }}>
                        {job?.city} {job?.state}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ color: 'grey' }}>
                        {timeAgo(job?.createdAt)}
                      </Typography>
                    </StyledCardContent>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>
          )}
        </JobContainer>
      </div>
    </>
  );
};

const People = ({ people, company }) => {
  const [personSearchString, setPersonSearchString] = useState('');
  const [search, setSearch] = useState(false);
  const [searchedResults, setSearchedResults] = useState([]);

  // Function to validate the URL
const isValidUrl = (url) => {
  console.log('kjdghfsdjkbfsdf', url);
  // Checks if the URL is not empty, not 'undefined', and starts with 'http' or 'https'
  return url?.includes('http://') || url?.includes('https://');
};

  const searchPersons = () => {
    try {
      Get(
        {
          company_id: company?.id,
          searchTerm: personSearchString,
        },
        API_URLS.searchPersonsOfCompany,
        (resp) => {
          setSearchedResults(resp?.data);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  // Trigger search when the user clicks the search button
  if (search) {
    searchPersons();
    setSearch(false); // reset search flag
  }

  // Reset the search results when the search string is cleared
  const handleInputChange = (e) => {
    const value = e.target.value;
    setPersonSearchString(value);

    // If the search string is empty, show the full list of people
    if (value === '') {
      setSearchedResults([]);
    }
  };

  // Define a default fallback text style
const fallbackStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 65,
  width: 65,
  border: '1px solid #3f51b5',
  borderRadius: '50%',
  backgroundColor: '#3f51b5',
  color: '#fff',
  fontSize: '24px',
  fontWeight: 'bold',
  cursor: 'pointer',
};

  const getInitials = (name) => {
    if (!name) return '';
    return name.split(' ')[0][0].toUpperCase(); // Extracts first character of the first name
  };

  return (
    <>
      <div style={{ margin: '1rem', borderRadius: '15px', background: 'white', height: '20%' }}>
        <Box display="flex" justifyContent="center" paddingBottom="10px">
          <Typography variant="subtitle1" sx={{ paddingTop: '15px', fontSize: '20px' }}>
            {people?.length} associated members
          </Typography>
        </Box>
        <Box
          display="flex"
          margin="auto"
          paddingBottom="1.5rem"
          padding="auto"
          justifyContent="center"
          alignItems="center"
        >
          <TextField
            InputProps={{
              startAdornment: (
                <Icon
                  icon="mingcute:search-line"
                  style={{ fontSize: '24px', fontWeight: 'bold', marginRight: '8px' }}
                />
              ),
            }}
            variant="outlined"
            placeholder="Search person..."
            size="small"
            value={personSearchString}
            onChange={handleInputChange}
            sx={{
              width: '45%',
              backgroundColor: 'white',
              borderRadius: '0.3rem',
              paddingLeft: '1rem',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {},
                '&:hover fieldset': {
                  borderColor: '#00C1FE',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#00C1FE',
                },
              },
              '& .MuiInputBase-root': {
                '&.Mui-focused': {
                  boxShadow: '0 0 0 1px #00C1FE',
                },
              },
            }}
          />
          <Button
            onClick={() => setSearch(true)}
            variant="contained"
            size="small"
            sx={{
              color: 'white',
              width: '10%',
              backgroundColor: '#00C1FE',
              borderRadius: '5px',
              padding: '10px',
              marginLeft: '5px',
              fontSize: '12px',
              '&:hover': {
                backgroundColor: '#00C1FE',
              },
              borderRadius: '20px',
            }}
          >
            Search
          </Button>
        </Box>
      </div>

      <div style={{ margin: '1rem', borderRadius: '15px', background: 'white', paddingTop: '1rem' }}>
        {/* If no people found */}
        {people?.length === 0 && (
          <Box display="flex" justifyContent="center" alignItems="center" margin="auto">
            <Typography variant="h5" sx={{ textAlign: 'center', padding: '1rem' }}>
              No persons found
            </Typography>
          </Box>
        )}

        {/* If people exist and no search string or results */}
        {people?.length > 0 && searchedResults?.length === 0 && !personSearchString && (
          <Grid container spacing={1}>
            {people.map((person, index) => (
              <Grid item xs={12} md={3} lg={3} key={index}>
                <Card sx={{ margin: '1rem', border: '1px solid grey' }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {isValidUrl(person?.avator) ? (
                        <img
                          src={person?.avator}
                          alt={`avator of ${person?.full_name}`}
                          height={65}
                          width={65}
                          style={{
                            border: '1px solid #3f51b5',
                            padding: 2,
                            borderRadius: '50%',
                            cursor: 'pointer',
                            objectFit: 'cover',
                          }}
                          onError={(e) => (e.currentTarget.src = '')} // Handle image load errors
                        />
                      ) : (
                        <div style={fallbackStyle}>{getInitials(person?.first_name)}</div>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '10px',
                      }}
                    >
                      <Typography variant="h6" sx={{ color: '#00C1FE', cursor: 'pointer' }}>
                        {person?.full_name}
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'grey' }}>
                        {person?.current_title}
                      </Typography>
                      <a
                        href={person?.profile_url}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: '#3f51b5',
                          textDecoration: 'none',
                          border: '1px solid #00C1FE',
                          borderRadius: '20px',
                          width: '100%',
                          margin: '5px',
                          textAlign: 'center',
                          padding: '5px',
                          display: 'inline-block',
                        }}
                      >
                        View Profile
                      </a>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}

        {/* If search is performed but no results found */}
        {searchedResults?.length === 0 && people?.length > 0 && search && (
          <Box display="flex" justifyContent="center" alignItems="center" margin="auto">
            <Typography variant="h5" sx={{ textAlign: 'center', padding: '1rem' }}>
              No persons match your search
            </Typography>
          </Box>
        )}

        {/* If search results are found */}
        {searchedResults?.length > 0 && (
          <Grid container spacing={1}>
            {searchedResults?.map((person, index) => (
              <Grid item xs={12} md={3} lg={3} key={index}>
                <Card sx={{ margin: '1rem', border: '1px solid grey' }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {isValidUrl(person?.avator) ? (
                        <img
                          src={person?.avator}
                          alt={`avator of ${person?.full_name}`}
                          height={65}
                          width={65}
                          style={{
                            border: '1px solid #3f51b5',
                            padding: 2,
                            borderRadius: '50%',
                            cursor: 'pointer',
                            objectFit: 'cover',
                          }}
                          onError={(e) => (e.currentTarget.src = '')} // Handle image load errors
                        />
                      ) : (
                        <div style={fallbackStyle}>{getInitials(person?.first_name)}</div>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '10px',
                      }}
                    >
                      <Typography variant="h6" style={{ color: '#00C1FE', cursor: 'pointer' }}>
                        {person?.full_name}
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'grey' }}>
                        {person?.current_title}
                      </Typography>
                      <a
                        href={person?.profile_url}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: '#3f51b5',
                          textDecoration: 'none',
                          border: '1px solid #00C1FE',
                          borderRadius: '20px',
                          width: '100%',
                          margin: '5px',
                          textAlign: 'center',
                          padding: '5px',
                        }}
                      >
                        View Profile
                      </a>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </>
  );
};

export { About, Jobs, People };
