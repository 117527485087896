import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Grid, TextField, ToggleButton, ToggleButtonGroup, Typography , Popover} from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { People } from './components/images';
import { Icon } from '@iconify/react';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import { CancelInterviewDialog, SubmitInterviewReviewDialog } from './components';
import ActionConfirmationDialog from 'src/components/ActionConfirmationDialog';

function InterviewDetails(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const previousPath = location.state?.previousPath || '/recruiter/app';
  const [selectedState, setSelectedState] = useState('overview');
  const [openSubmitReviewDialog, setOpenSubmitReviewDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCancelInterviewDialog, setOpenCancelInterviewDialog] = useState(false);
  const [openDeleteInterviewDialog, setOpenDeleteInterviewDialog] = useState(false);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div style={{ width: '97%', margin: 'auto' }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <ArrowBack
          fontSize="small"
          onClick={() => {
            navigate(previousPath);
          }}
          sx={{
            color: '#00C1FE',
            cursor: 'pointer',
          }}
        />
        &nbsp;&nbsp;{' '}
        <Typography
          variant="subtitle2"
          sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}
          onClick={() => {
            navigate(previousPath);
          }}
        >
          Back to Dashboard
        </Typography>
      </Box>
      <Box
        display="flex"
        sx={{ marginTop: '20px', backgroundColor: '#E2F2FF', borderRadius: 4, height: '120px' }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center" justifyContent="flex-start" sx={{ padding: '20px' }}>
          <p
            style={{
              marginRight: '20px', // Adds space between avatar and name
              backgroundColor: '#00C1FE',
              color: '#fff',
              width: '95px',
              height: '75px',
              fontSize: '40px',
              borderRadius: '20px',
              textAlign: 'center',
              display: 'flex', // Enable flexbox
              alignItems: 'center', // Vertically center the image
              justifyContent: 'center', // Horizontally center the image
            }}
          >
            <img src={People} alt="job" style={{ maxWidth: '100%', maxHeight: '100%' }} />
          </p>

          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Box display="flex">
              <Typography variant="h5" sx={{ textTransform: 'none', color: '#838383', cursor: 'pointer' }}>
                Internal Interview
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" marginTop="5px">
              <p
                style={{
                  fontSize: '16px',
                  color: 'white',
                  color: '#838383',
                }}
              >
                Vichal
              </p>
            </Box>
          </Box>
        </Box>
        <Box display="flex" sx={{ padding: '20px', marginRight: '30px' }}>
          <Button
            style={{ backgroundColor: '#00C1FE', padding: '15px', color: 'white', borderRadius: '15px' }}
            size="large"
            onClick={() => {
              setOpenSubmitReviewDialog(true);
            }}
          >
            Submit Evolution
          </Button>
          <Button
            style={{ backgroundColor: '#C7E6FF', marginLeft: '10px', borderRadius: '15px' }}
            size="large"
            onClick={(event) => handleOpen(event)}
          >
            <Icon icon="solar:menu-dots-bold" style={{ fontSize: '24px', color: 'white' }} />
          </Button>
        </Box>
      </Box>
      <Box display="flex" marginTop="20px" justifyContent="space-between" alignItems="center">
        <ToggleButtonGroup
          value={selectedState}
          exclusive
          onChange={(e, value) => {
            if (value !== null) {
              setSelectedState(value);
            }
          }}
          aria-label="state"
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginLeft: '20px',
            marginBottom: '10px', // Add margin bottom for spacing
            backgroundColor: '#E6EDF5',
            borderRadius: '35px',
            padding: '10px',
          }}
        >
          <ToggleButton
            value="overview"
            aria-label="overview"
            style={{
              color: selectedState === 'overview' ? 'white' : 'black',
              borderRadius: selectedState === 'overview' ? '25px' : 0,
              backgroundColor: selectedState === 'overview' ? '#00C1FE' : '#E6EDF5',
              border: selectedState === 'overview' ? '1px solid #00C1FE' : 'none',
              paddingBottom: 2,
              fontSize: '18px',
              paddingTop: '5px',
              paddingBottom: '5px',
              paddingLeft: '20px',
              paddingRight: '20px',
              textAlign: 'center',
              fontWeight: 'normal',
            }}
          >
            Overview
          </ToggleButton>
          <ToggleButton
            value="timeline"
            aria-label="timeline"
            style={{
              color: selectedState === 'timeline' ? 'white' : 'black',
              backgroundColor: selectedState === 'timeline' ? '#00C1FE' : '#E6EDF5',
              paddingBottom: 2,
              fontSize: '18px',
              paddingTop: '5px',
              borderRadius: selectedState === 'timeline' ? '25px' : 0,
              border: selectedState === 'timeline' ? '1px solid #00C1FE' : 'none',
              paddingBottom: '5px',
              paddingLeft: '20px',
              paddingRight: '20px',
              textAlign: 'center',
              marginLeft: '15px',
              fontWeight: 'normal',
            }}
          >
            Timeline
          </ToggleButton>
        </ToggleButtonGroup>
        <Box display="flex" marginRight="30px">
          <p style={{ color: '#838383' }}>Last Update : 02:24 PM</p> &nbsp; | &nbsp;
          <p style={{ color: '#00C1FE' }}> Hide empty fields</p>
        </Box>
      </Box>
      {selectedState === 'overview' && <InterviewOverview />}
      {selectedState === 'timeline' && <InterviewTimeline />}
      {openSubmitReviewDialog && (
        <SubmitInterviewReviewDialog open={openSubmitReviewDialog} onClose={() => setOpenSubmitReviewDialog(false)} />
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            p: 0,
            marginTop: '10px',
            width: '12%',
            borderRadius: '25px',
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: '25px',
            },
          },
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          p={1}
          justifyContent="center"
          marginTop="auto"
          marginBottom="auto"
        >
          <p
            style={{ fontSize: '18px', color: '#838383', marginTop: '5px', cursor: 'pointer' }}
            onClick={() => setOpenCancelInterviewDialog(open)}
          >
            {' '}
            Cancel Interview{' '}
          </p>
          <p style={{ fontSize: '18px', color: '#838383', marginTop: '10px', marginBottom: '5px', cursor : 'pointer' }} onClick={()=> setOpenDeleteInterviewDialog(true)}> Delete </p>
        </Box>
      </Popover>
      {openCancelInterviewDialog && (
        <CancelInterviewDialog
          open={openCancelInterviewDialog}
          onClose={() => {
            setOpenCancelInterviewDialog(false);
            setAnchorEl(null);
          }}
        />
      )}
         {openDeleteInterviewDialog && (
        <ActionConfirmationDialog
          open={openDeleteInterviewDialog}
          onClose={() => setOpenDeleteInterviewDialog(false)}
          title={`Are you sure you want to delete this interview?`}
          actionButtonText="Yes"
          onSubmit={() => setOpenDeleteInterviewDialog(false)}
        />
      )}
    </div>
  );
}

const InterviewOverview = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  return (
    <>
      <Box sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 4, height: 'auto', marginTop: '15px' }}>
        <p style={{ fontSize: '18px', margin: '4px' }}>Business Card</p>
        <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={6} md={6} lg={6} marginTop={'20px'}>
            <Box display="flex" alignItems="center" marginTop="20px">
              <Grid container alignItems="center">
                {/* Label (p tag) - Right aligned */}
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                    Department Name
                  </p>
                </Grid>
                {/* Text Field - Left aligned */}
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value=""
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black', // Ensures disabled text is black
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box display="flex" alignItems="center" marginTop="20px">
              <Grid container alignItems="center">
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>Posting Title</p>
                </Grid>
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="Designer"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box display="flex" alignItems="center" marginTop="20px">
              <Grid container alignItems="center">
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>Interviewers</p>
                </Grid>
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="MIss xyz"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box display="flex" alignItems="center" marginTop="20px">
              <Grid container alignItems="center">
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>From</p>
                </Grid>
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="03/06/2020 03:00 PM"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} marginTop={'20px'}>
            <Box display="flex" alignItems="center" marginTop="20px">
              <Grid container alignItems="center">
                {/* Label (p tag) - Right aligned */}
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>To</p>
                </Grid>
                {/* Text Field - Left aligned */}
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="03/06/2020 03:00 PM"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black', // Ensures disabled text is black
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box display="flex" alignItems="center" marginTop="20px">
              <Grid container alignItems="center">
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>Location</p>
                </Grid>
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="London"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 4, height: 'auto', marginTop: '15px' }}>
        <p style={{ fontSize: '18px', margin: '4px' }}>Participants</p>
        <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '5px' }}>
          <thead>
            <tr style={{ borderBottom: '1px solid #CAC5C5' }}>
              <th style={{ padding: '10px', textAlign: 'left', color: '#838383', fontWeight: 'normal' }}>
                Partcipant Name
              </th>
              <th style={{ padding: '10px', textAlign: 'left', color: '#838383', fontWeight: 'normal' }}>Email</th>
              <th style={{ padding: '10px', textAlign: 'left', color: '#838383', fontWeight: 'normal' }}>Role</th>
            </tr>
          </thead>
          <tbody>
            {/* You can add rows here as needed */}
            <tr>
              <td style={{ padding: '10px', color: '#3CD0FE', fontWeight: 'normal' }}>Miss xyz </td>
              <td style={{ padding: '10px', color: '#838383', fontWeight: 'normal' }}>abc@gmail.com</td>
              <td style={{ padding: '10px', color: '#838383', fontWeight: 'normal' }}>Interviewer</td>
            </tr>
          </tbody>
        </table>
      </Box>
      <Box sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 4, height: 'auto', marginTop: '15px' }}>
        <Box display="flex" alignItems={'center'}>
          <p style={{ fontSize: '18px', margin: '4px', color: '#4CD4FF' }}>
            {isExpanded ? 'Hide Details' : 'Show Details'}
          </p>
          <Icon
            icon="iconamoon:arrow-down-2-fill"
            style={{ fontSize: '20px', color: '#4CD4FF', marginLeft: '10px', cursor: 'pointer' }}
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          />
        </Box>
        <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
        {isExpanded && (
          <>
            <p style={{ fontSize: '18px', margin: '20px' }}>Job Opening Information</p>

            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6} md={6} lg={6} marginTop={'5px'}>
                <Box display="flex" alignItems="center" marginTop="10px">
                  <Grid container alignItems="center">
                    {/* Label (p tag) - Right aligned */}
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Interview Name
                      </p>
                    </Grid>
                    {/* Text Field - Left aligned */}
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="Internal Interview"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black', // Ensures disabled text is black
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Department Name
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value=""
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#4CD4FF',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>From</p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="03/06/2020 03:00 PM"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Interviewer
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="Miss xyz"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>Location</p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="London"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Cerated By
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="Miss xyz , Thu 21 Mar 2024 05:00 PM"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Modified By
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="Miss xyz , Thu 21 Mar 2024 05:00 PM"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} marginTop={'5px'}>
                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    {/* Label (p tag) - Right aligned */}
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Candidate Name{' '}
                      </p>
                    </Grid>
                    {/* Text Field - Left aligned */}
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="Ali Raza"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black', // Ensures disabled text is black
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Posting Title
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="Designer"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>To</p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="03/06/2020 03:00 PM"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Interview Owner
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="Miss xyz"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Schedule Comments
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="This candidate associated to job opening"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Assessment Name
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value=""
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>Provider</p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="Google Meet"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <p style={{ fontSize: '18px', margin: '20px' }}>Evaluation Information</p>
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item xs={12} sm={6} md={6} lg={6} marginTop={'5px'}>
                <Box display="flex" alignItems="center" marginTop="10px">
                  <Grid container alignItems="center">
                    {/* Label (p tag) - Right aligned */}
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Interview Status
                      </p>
                    </Grid>
                    {/* Text Field - Left aligned */}
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="On Hold"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black', // Ensures disabled text is black
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
};
const InterviewTimeline = () => {
  const timelineData = [
    {
      time: '9:30 AM',
      status: 'Status Changed - of Designer Ali Raza',
      description: 'On hold - In progress',
      actionBy: 'by miss xyz',
      icon: 'material-symbols:person-outline',
    },
    {
      time: '9:45 AM',
      status: 'Status Changed - of Designer Ali Raza',
      description: 'In progress - Qualified',
      actionBy: 'by miss xyz',
      icon: 'fluent-mdl2:text-document-edit',
    },
    {
      time: '10:00 AM',
      status: 'Candidate saved as draft by Ultimate',
      description: '',
      actionBy: '',
      icon: 'fluent:drafts-24-regular',
    },
    {
      time: '9:30 AM',
      status: 'Status Changed - of Designer Ali Raza',
      description: 'On hold - In progress',
      actionBy: 'by miss xyz',
      icon: 'material-symbols:person-outline',
    },
  ];
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: 4,
        height: 'auto',
        marginTop: '15px',
      }}
      justifyContent="flex-start"
    >
      <br />
      <br />
      <Timeline position="right">
        {' '}
        {/* Keep position 'right' */}
        {timelineData?.map((item, index) => (
          <TimelineItem key={index} sx={{ display: 'flex', alignItems: 'center' }}>
            {' '}
            {/* Align items center */}
            {/* Time on the left */}
            <TimelineOppositeContent
              sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-end', // Align time to the right
                alignItems: 'center', // Vertical centering
                marginRight: '20px', // Adjust spacing between time and icon
              }}
              variant="body2"
              color="text.secondary"
            >
              {item.time}
            </TimelineOppositeContent>
            <TimelineSeparator sx={{ flexShrink: 0 }}>
              <TimelineConnector sx={{ height: 50 }} />
              <TimelineDot
                sx={{
                  backgroundColor: '#C7E6FF',
                  borderColor: '#C7E6FF',
                  borderRadius: '10px',
                  padding: '15px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center', // Center icon in dot
                }}
              >
                <Icon icon={item.icon} width={30} height={30} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            {/* Content on the right */}
            <TimelineContent
              sx={{
                flex: 2, // Make content take up more space
                paddingLeft: '20px', // Adjust spacing between icon and content
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center', // Align content vertically
              }}
            >
              <Typography variant="body2">{item.status}</Typography>
              {item.description && <Typography variant="body2">{item.description}</Typography>}
              {item.actionBy && <Typography variant="body2">{item.actionBy}</Typography>}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      <br />
    </Box>
  );
};
export default InterviewDetails;
