import { Icon } from '@iconify/react';
import { Box, Button } from '@mui/material';
import React from 'react';
import { CandidatePipelineTable } from './components';

function ApplicationsSection(props) {
  return (
    <div style={{ width: '97%', margin: 'auto', height: '79vh' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <p style={{ fontSize: '32px', fontWeight: 'bold', marginTop: '10px' }}>All Applications</p>
        <Box display="flex">
          <Button
            startIcon={<Icon icon="tabler:plus" style={{ fontSize: '20px' }} />}
            sx={{
              background: '#00C1FE',
              color: '#fff',
              height: '45px',
              border: '1px solid #00C1FE',
              '&:hover': {
                background: '#00C1FE',
                color: '#fff',
              },
              borderRadius: '15px',
              padding: '25px',
              marginLeft: '10px',
            }}
          >
            Create Application
          </Button>
        </Box>
      </Box>
      <br />
      <CandidatePipelineTable buttons={null} onButtonClick={(value) => {}} showHiringPipeline={true} />
    </div>
  );
}

export default ApplicationsSection;
