import React from 'react';
import { SearchHistory } from 'src/sections/resourcing';

function SearchHistoryPage(props) {
    return (
        <div>
            <SearchHistory/>
        </div>
    );
}

export default SearchHistoryPage;