import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { enqueueSnackbar } from 'notistack';

function SendTestEmailDialog({ open, onClose, subject, content }) {
  const [toEmail, setToEmail] = useState();

  const handleSendEmail = () => {
    try {
      Post(
        {
          subject: subject,
          htmlText: content,
          to: toEmail,
          from: 'maryam@ultimateoutsourcing.co.uk',
        },
        API_URLS.sendTestEmail,
        (resp) => {
          enqueueSnackbar('Test email sent', { variant: 'success' });
          onClose();
        },
        (error) => {
          enqueueSnackbar(error, { variant: 'error' });
        }
      );
    } catch {
      enqueueSnackbar('Error sending test email', { variant: 'error' });
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        hideBackdrop={false}
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{
          sx: {
            width: '100%',
          },
        }}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#E1F7FC',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" className="ml-8">
              Send Test Email
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" marginLeft="10px" marginRight="10px" marginTop="1rem" >
            <label htmlFor="to" style={{ fontWeight: 'bold', paddingTop: '5px' }}>
              Enter test email
            </label>
            <TextField
              name="to"
              fullWidth
              multiline
              required // Make it required
              value={toEmail}
              onChange={(e) => setToEmail(e.target.value)}
              sx={{
                backgroundColor: 'white',
                outline: 'none',
                border: '1px solid #D7D7D7',
                marginBottom: '3px',
                marginTop: '5px',
                borderRadius: '5px',
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#D7D7D7',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D7D7D7',
                  },
                },
              }}
              size="large"
            />
          </Box>
          <Box display="flex" justifyContent="center" marginTop="1rem" alignItems="center">
            <Button
              type="submit"
              size="medium"
              sx={{
                backgroundColor: '#0ec4fe',
                fontSize: '16px',
                color: 'white',
                marginLeft: '1rem',
                padding: '10px 20px',
                '&:hover': {
                  color: 'white !important',
                  backgroundColor: '#0ec4fe !important',
                },
              }}
              onClick={() => handleSendEmail()}
            >
              Send email
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SendTestEmailDialog;
