export const IsParsable = (data) => {
    try {
          JSON.parse(data);
         } catch (e) {
          return false;
        }
     return true;
}

  // Calculate time difference and format
  export const calculateTimeTaken = (createdAt, updatedAt) => {
    const created = new Date(createdAt);
    const updated = new Date(updatedAt);
    const diffInSeconds = (updated - created) / 1000;

    if (diffInSeconds < 60) return `${Math.round(diffInSeconds)} seconds`;
    if (diffInSeconds < 3600) return `${Math.round(diffInSeconds / 60)} minutes`;
    if (diffInSeconds < 86400) return `${Math.round(diffInSeconds / 3600)} hours`;
    if (diffInSeconds < 604800) return `${Math.round(diffInSeconds / 86400)} days`;
    if (diffInSeconds < 2629746) return `${Math.round(diffInSeconds / 604800)} weeks`;
    if (diffInSeconds < 31556952) return `${Math.round(diffInSeconds / 2629746)} months`;
    return `${Math.round(diffInSeconds / 31556952)} years`;
  };

  // Parse job board data
  export const parseJobBoards = (jobBoardData) => {
    if (!Array.isArray(jobBoardData)) return 'No data';
    return jobBoardData
      .map((board) => {
        try {
          const parsed = JSON.parse(board);
          return `${parsed.job_board}: ${parsed.views || '0'} views`;
        } catch (error) {
          console.error('Failed to parse job board data:', error);
          return 'Invalid data';
        }
      })
      .join(', ');
  };