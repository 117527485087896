import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function AddJob(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const previousPath = location.state?.previousPath || '/recruiter/app';
  return (
    <div style={{ width: '97%', margin: 'auto' }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <ArrowBack
          fontSize="small"
          onClick={() => {
            navigate(previousPath);
          }}
          sx={{
            color: '#00C1FE',
            cursor: 'pointer',
          }}
        />
        &nbsp;&nbsp;{' '}
        <Typography
          variant="subtitle2"
          sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}
          onClick={() => {
            navigate(previousPath);
          }}
        >
          Back
        </Typography>
      </Box>
      <br />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <p style={{ fontSize: '32px', fontWeight: 'bold', marginTop: '10px', marginLeft: '10px' }}>Add Job Opening</p>
        <Box display="flex">
          <Button
            sx={{
              background: '#CED5E0',
              height: '45px',
              border: '1px solid #CED5E0',
              '&:hover': {
                background: '#CED5E0',
                color: 'black',
              },
              borderRadius: '15px',
              padding: '25px',
              marginLeft: '10px',
            }}
            onClick={() => navigate(previousPath)}
          >
            Cancel
          </Button>
          <Button
            sx={{
              background: '#00C1FE',
              color: '#fff',
              height: '45px',
              border: '1px solid #00C1FE',
              '&:hover': {
                background: '#00C1FE',
                color: '#fff',
              },
              borderRadius: '15px',
              padding: '25px',
              marginLeft: '10px',
            }}
          >
            Save and Publish
          </Button>
          <Button
            sx={{
              background: '#00C1FE',
              color: '#fff',
              height: '45px',
              border: '1px solid #00C1FE',
              '&:hover': {
                background: '#00C1FE',
                color: '#fff',
              },
              borderRadius: '15px',
              padding: '25px',
              marginRight: '10px',
              marginLeft: '10px',
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default AddJob;
