import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Tooltip,
} from '@mui/material';
import { Cancel, Edit, Send } from '@mui/icons-material';
import { Icon } from '@iconify/react';

function QueryBox({ queryData, onSubmitQuery, onUpdateQuery, onMaximize, onMinimize }) {
  const [showIssue, setShowIssue] = useState([]);
  const [inputIssue, setInputIssue] = useState('');
  const [isEditingIssue, setIsEditingIssue] = useState(false);
  const [issueIndex, setIssueIndex] = useState(null);

  const [maximizeTextField, setMaximizeTextField] = useState(false);
  const [maximizeBox, setMaximizeBox] = useState(false);
  useEffect(() => {
    setShowIssue(queryData);
  }, [queryData]);

  const handleCancelIssueClick = () => {
    setIsEditingIssue(false);
    setInputIssue('');
  };

  const handleSubmitIssue = () => {
    if (inputIssue.trim() !== '') {
      if (isEditingIssue) {
        onUpdateQuery(issueIndex, inputIssue);
        setIsEditingIssue(false);
      } else {
        onSubmitQuery(inputIssue);
      }
      setInputIssue('');
    }
  };

  const handleEditIssueClick = (query, index) => {
    setIsEditingIssue(true);
    setIssueIndex(index);
    setInputIssue(query?.query_added);
  };

  const renderMessages = (messages, handleEditClick, type) => {
    return (
      <Box
        sx={{
          height: maximizeTextField ? '50px' : '170px', // Ensure this covers full width
          overflowY: 'scroll',
          flexGrow: 1,
        }}
      >
        {messages?.map((message, index) => {
          const isLeftAligned = index % 2 === 0; // Alternate alignment
          return (
            <Box
              key={message.id}
              display="flex"
              width="100%"
              justifyContent="space-around"
              alignItems="center"
              mb={2}
              sx={{
                flexDirection: isLeftAligned ? 'row' : 'row-reverse',
                width: '100%', // Ensure this covers full width
              }}
            >
              <Box
                display="flex"
                flexDirection={isLeftAligned ? 'row' : 'row-reverse'}
                justifyContent={isLeftAligned ? 'flex-start' : 'flex-end'}
                alignItems="center"
                width="100%"
                sx={{
                  maxWidth: '100%', // Allow the message to cover up to 90% of the width
                  textAlign: isLeftAligned ? 'left' : 'right',
                }}
              >
                <Avatar sx={{ width: '35px', height: '35px', mr: isLeftAligned ? 0 : 1 }}>
                  {message?.full_name[0]?.toUpperCase()}
                </Avatar>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems={isLeftAligned ? 'flex-start' : 'flex-end'}
                  sx={{
                    ml: isLeftAligned ? 1 : 0,
                    mr: isLeftAligned ? 0 : 1,
                    width: '100%', // Ensure the container for text takes full available width
                  }}
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="subtitle2" component="span">
                      <strong>{message?.full_name}</strong>
                    </Typography>
                    <Typography variant="caption" component="div" color="textSecondary">
                      {message?.date_time?.split(',')[1]}
                    </Typography>
                    <Typography variant="caption" component="div" color="textSecondary">
                      {message?.is_edited ? ', Edited' : ''}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: '#EFEFEF',
                      borderRadius: '12px',
                      padding: '7px',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      width: isLeftAligned ? '100%' : '90%', // Fix conditional syntax
                      textAlign: 'left',
                      // maxWidth: '100%',
                      height: 'auto',
                    }}
                  >
                    <Typography variant="body2" component="span" color="textPrimary">
                      {message[`${type}_added`]}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box>
                <IconButton size="small" onClick={() => handleEditClick(message, index)} sx={{ ml: 1 }}>
                  <Edit fontSize="small" style={{ color: '#00C1FE' }} />
                </IconButton>
              </Box>
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <>
      <Card
        sx={{
          color: '#0D6EFD',
          borderRadius: '10px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          alignContent="center"
          sx={{
            backgroundColor: '#E1F7FC',
            color: '#00C1FE',
            padding: '8px',
          }}
        >
          <Typography variant="h6" sx={{ padding: '10px' }}>
            Queries
          </Typography>
          {maximizeBox ? (
            <>
              <Tooltip title="Minimize" placement="top">
                <Icon
                  icon="solar:minimize-bold"
                  style={{ fontSize: '20px', marginRight: '10px', cursor: 'pointer' }}
                  onClick={() => {
                    setMaximizeBox(false);
                    onMinimize();
                  }}
                />
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title="Maximize" placement="top">
                <Icon
                  icon="fluent:arrow-maximize-20-regular"
                  style={{ fontSize: '20px', cursor: 'pointer', marginRight: '10px' }}
                  onClick={() => {
                    setMaximizeBox(true);
                    onMaximize();
                  }}
                />
              </Tooltip>
            </>
          )}
        </Box>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          {renderMessages(showIssue, handleEditIssueClick, 'query')}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', marginTop: '5px', marginBottom: '-15px' }}>
            <TextField
              placeholder="Enter query"
              fullWidth
              multiline
              value={inputIssue}
              onChange={(e) => setInputIssue(e.target.value)}
              sx={{
                mr: 1,
                borderRadius: '25px',
                padding: '5px 5px',
                textAlign: 'center',
                height: maximizeTextField ? '150px' : '50px',
                minHeight: '30px',
                overflowY: 'scroll',
                backgroundColor: '#E6EBF5',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '40px',
                  '& fieldset': {
                    border: 'none', // Remove border
                  },
                },
                '&:hover': {
                  backgroundColor: '#E6EBF5', // Keep background color same on hover
                },
              }}
              size="small"
            />

            <Button
              variant="contained"
              sx={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                backgroundColor: '#00C1FE',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: 'unset',
                '&:hover': {
                  backgroundColor: '#00C1FE',
                  color: 'white',
                },
              }}
              onClick={handleSubmitIssue}
            >
              <Send fontSize="small" />
            </Button>

            {isEditingIssue && (
              <Button
                variant="contained"
                sx={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '50%',
                  backgroundColor: '#00C1FE',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: 'unset',
                  '&:hover': {
                    backgroundColor: '#00C1FE',
                    color: 'white',
                  },
                  ml: 1,
                }}
                onClick={handleCancelIssueClick}
              >
                <Icon icon="charm:cross" style={{ fontSize: '30px', fontWeight: 'bold' }} />
              </Button>
            )}
            {maximizeTextField ? (
              <>
                <Tooltip title="Minimize" placement="top">
                  <Icon
                    icon="solar:minimize-bold"
                    style={{ fontSize: '20px', marginTop: '-15px', marginRight: '-10px', cursor: 'pointer' }}
                    onClick={() => {
                      setMaximizeTextField(false);
                    }}
                  />
                </Tooltip>
              </>
            ) : (
              <>
                <Tooltip title="Maximize" placement="top">
                  <Icon
                    icon="fluent:arrow-maximize-20-regular"
                    style={{ fontSize: '20px', marginTop: '-15px', marginRight: '-10px', cursor: 'pointer' }}
                    onClick={() => {
                      setMaximizeTextField(true);
                    }}
                  />
                </Tooltip>
              </>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default QueryBox;
