import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';

function ActionConfirmationDialog({
  open,
  onClose,
  onSubmit,
  title,
  ActionConfirmationText,
  isActionPerforming,
  actionPermormingText,
  actionButtonText,
  actionCancellationText
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '70%',
            minHeight: '20vh',
          },
        }}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
          },
        }}
      >
        <DialogTitle>
          <Box display="flex">
            <Typography variant="h4" className="ml-8 text-center ">
              Action Confirmation
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display="flex">
            <Typography variant="h5" className="ml-8 text-center ">
              {title}
            </Typography>
          </Box>
          <br />
          {isActionPerforming ? (
            <Typography variant="h4" className="mt-8">
              {actionPermormingText}
            </Typography>
          ) : (
            <Typography variant="h4" className="mt-8">
              {ActionConfirmationText}
            </Typography>
          )}
          <br />
          <Box display="flex" justifyContent="space-around">
            <Button
              onClick={onClose}
              style={{
                border: '2px solid black',
                borderRadius: '10px',
                width: '100px',
                padding: '8px 14px', // Adjust padding as needed
                textAlign: 'center',
              }}
            >
              {actionCancellationText ? actionCancellationText : 'No'}
            </Button>
            <Button
              onClick={onSubmit}
              style={{
                backgroundColor: '#FA8282',
                color: 'white',
                borderRadius: '10px',
                width: '100px',
                padding: '8px 16px', // Adjust padding as needed
                textAlign: 'center',
              }}
            >
              {actionButtonText}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ActionConfirmationDialog;
