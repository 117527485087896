import { Close } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

function UpdateEmailResponse({ open, onClose, onSubmit }) {
  const [response, setResponse] = useState();
  const [responseContent, setResponseContent] = useState();
  const responseOptions = ['Interested', 'Not interested', 'Auto reply', 'No response'];
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        hideBackdrop={false}
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{
          sx: {
            ml: 'auto',
            mt: 'auto',
            mb: 'auto',
            mr: 'auto',
            width: '100%',
            maxWidth: '600px',
            maxHeight: '800px',
          },
        }}
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#E2F2FF',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" className="ml-8">
              Update Email Response
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem" flexDirection="column">
            <FormControl>
              <label
                htmlFor="select-single-native"
                style={{ fontWeight: 'bold', textAlign: 'left', marginBottom: '5px' }}
              >
                Select Response:
              </label>
              <Autocomplete
                options={responseOptions} // Array of options
                getOptionLabel={(option) => option} // Specifies which field to display
                value={response ? responseOptions?.find((option) => option === response) : null}
                onChange={(event, newValue) => setResponse(newValue)}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select an option" // Optional placeholder
                    sx={{
                      width: '500px',
                      '.MuiOutlinedInput-root': {
                        color: '#000000', // Text color
                        padding: '8px 10px',
                        height: '50px',
                        backgroundColor: '#FFFFFF', // White background
                        border: '2px solid #D9D9D9', // Grey border
                      },
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#D9D9D9', // Grey border color
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#D9D9D9', // Hover border color
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#D9D9D9', // Focus border color
                      },
                      '.MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
                        color: '#000000', // Icon color
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Box>
          {/* <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem" flexDirection="column">
            <FormControl>
              <label
                htmlFor="select-single-native"
                style={{ fontWeight: 'bold', textAlign: 'left', marginBottom: '5px' }}
              >
                Reponse Content
              </label>
              <TextField
                type="text"
                id="responseContent"
                name="responseContent"
                placeholder={`Paste response content...`}
                value={responseContent}
                onChange={(e) => {
                  setResponseContent(e.target.value);
                }}
                fullWidth
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '0.3rem',
                  marginTop: '5px',
                  marginRight: '20px',
                  border: '1px solid #CAC5C5',
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: '#00C1FE',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#00C1FE',
                    },
                  },
                  '& .MuiInputBase-root': {
                    '&.Mui-focused': {
                      boxShadow: '0 0 0 1px #00C1FE',
                    },
                  },
                }}
              />
            </FormControl>
          </Box> */}
          <Box display="flex" justifyContent="center">
            <Button
              sx={{
                background: '#00C1FE',
                color: '#fff',
                width: '150px',
                border: '1px solid #00C1FE',
                '&:hover': {
                  background: '#00C1FE',
                  color: '#fff',
                },
                borderRadius: '5px',
                padding: '10px',
                marginTop: '15px',
              }}
              onClick={() => {
                onSubmit(response);
              }}
            >
              Submit response
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default UpdateEmailResponse;
