import React, { useState, useEffect, useMemo } from 'react';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import Icon from 'react-multi-date-picker/components/icon';
import Footer from 'react-multi-date-picker/plugins/range_picker_footer';

function MultiDatePicker({ onChange, height = '40px', width = '300px' }) {
  const [value, setValue] = useState(() => {
    const today = new DateObject();
    const startOfMonth = new DateObject({ year: today.year, month: today.month.number, day: 1 });
    return [startOfMonth, today];
  });

  // Calculate current and previous months
  const previousMonth = new DateObject().subtract(1, 'month');

  const formattedDates = useMemo(() => {
    return value.map((date) => {
      const { year, month, day } = date;
      return `${year}-${String(month.number).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    });
  }, [value]);

  useEffect(() => {
    onChange && onChange(formattedDates);
  }, [formattedDates, onChange]);

  const getDisplayValue = () => {
    if (value.length === 0) return 'Select date - Select date';
    if (value.length === 1) return `${formattedDates[0]} - Select date`;
    return `${formattedDates[0]} - ${formattedDates[1]}`;
  };

  return (
    <DatePicker
      value={value}
      onChange={setValue}
      range
      numberOfMonths={2}
      currentDate={previousMonth}
      plugins={[<Footer position="bottom" />]}
      render={(value, openCalendar) => {
        const displayValue = getDisplayValue();

        return (
          <div
            style={{
              position: 'relative',
              width,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <input
              value={displayValue}
              onFocus={openCalendar}
              readOnly
              style={{
                width: '100%',
                height,
                paddingRight: '40px',
                border: '1px solid #00C1FE',
                borderRadius: '5px',
                backgroundColor: '#ECF6FE',
                color: '#00C1FE',
                paddingLeft: '10px',
                marginRight: '10px',
                boxSizing: 'border-box',
              }}
            />
            <Icon
              icon="mdi:calendar-outline"
              style={{
                position: 'absolute',
                fontSize: '24px',
                left: '90%',
                transform: 'translate(-50%, -50%)',
                color: '#00C1FE',
                cursor: 'pointer',
              }}
              onClick={openCalendar}
            />
          </div>
        );
      }}
    />
  );
}

export default MultiDatePicker;
