import React from 'react';
import { InterviewSection } from 'src/sections/direct360';

function InterviewsPage(props) {
  return (
    <div>
      <InterviewSection />
    </div>
  );
}

export default InterviewsPage;
