import { Icon } from '@iconify/react';
import {
  TablePagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Button,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function ServiceRolesComponent({ onPreviousClick, OnAdvanceClick, title, roleType, roles  , onPageChange , onRowsPerPageChange , totalCount}) {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onPageChange(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    onRowsPerPageChange(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page whenever rows per page changes
  };


  const getStatusColor = (status) => {
    switch (status) {
      case 'Pending':
        return '#FDC748';
      case 'In-Progress':
      case 'Re-working':
        return '#00C1FE';
      case 'Completed':
      default:
        return 'black';
    }
  };

  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case 'Pending':
        return '#FFF3D8';
      case 'In-Progress':
      case 'Re-working':
        return '#D1F1FC';
      case 'Completed':
      default:
        return 'black';
    }
  };
  return (
    <div>
      <Grid container spacing={2} justifyContent="space-around" sx={{ marginTop: '3rem' }}>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <Button
            variant="outlined"
            startIcon={<Icon icon="ri:skip-back-fill" />}
            sx={{
              fontSize: '15px',
              borderRadius: '15px',
              borderWidth: '2px',
              color: '#0ec4fe',
              height: '55px',
              borderColor: '#0ec4fe',
              backgroundColor: '#EEF7FF',
              '&:hover': {
                color: '#0ec4fe !important',
                backgroundColor: '#EEF7FF !important',
                border: '2px solid #0ec4fe !important',
              },
            }}
            size="large"
            fullWidth
            onClick={() => {
              onPreviousClick(title);
            }}
          >
            Previous Roles
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          fullWidth
          sx={{
            borderRadius: '10px',
            backgroundColor: '#DCECFF',
            height: '55px',
            textAlign: 'center',
            fontSize: '18px',
            fontWeight: 'bold',
            marginTop: '15px',
          }}
        >
          <Typography variant="h5" sx={{ width: '100%' }}>
            {`${roleType}    -   ${title}`}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <Button
            variant="outlined"
            startIcon={<Icon icon="fluent:next-16-filled" />}
            sx={{
              fontSize: '15px',
              borderRadius: '15px',
              borderWidth: '2px',
              color: '#0ec4fe',
              height: '55px',
              borderColor: '#0ec4fe',
              backgroundColor: '#EEF7FF',
              '&:hover': {
                color: '#0ec4fe !important',
                borderColor: '#0ec4fe !important',
                backgroundColor: '#EEF7FF !important',
                border: '2px solid #0ec4fe !important',
              },
            }}
            size="large"
            fullWidth
            onClick={() => {
              OnAdvanceClick(title);
            }}
          >
            Advance Roles
          </Button>
        </Grid>
      </Grid>
      <br />
      <Paper
        sx={{
          width: '100%',
          overflowX: 'auto',
          position: 'relative',
          display: 'block',
          maxWidth: '100%',
          backgroundColor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.10)',
          border: '1px solid #D9D9D9',
          borderRadius: '10px',
          '& td, & th': {
            whiteSpace: 'nowrap',
            borderBottom: '1px solid #D9D9D9',
          },
        }}
      >
        <TableContainer sx={{ borderRadius: '10px', overflow: 'hidden' }}>
          <Table aria-labelledby="tableTitle">
            <TableHead>
              <TableRow style={{ backgroundColor: '#E1F7FC', padding: '5px' }}>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>R</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>S</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>ACM</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>No</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Client No</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Role</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Role No</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Services</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Status</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Resourcer</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roles?.length > 0 ? (
                <>
                  {roles?.map((role) => (
                    <TableRow>
                      <TableCell sx={{ borderRadius: '10px 0 0 10px' }}>{role?.acm_name}</TableCell>
                      <TableCell>{role?.acm_name}</TableCell>
                      <TableCell>{role?.acm_name}</TableCell>
                      <TableCell>{isNaN(role?.role_number) ? 'N/A' : role?.role_number}</TableCell>
                      <TableCell>{role?.client_number}</TableCell>
                      <TableCell style={{ color: '#00C1FE' }}>
                        <Typography
                          onClick={() => navigate(`/acm/roles-processing`)}
                          sx={{ fontSize: '15px', cursor: 'pointer' }}
                        >
                          {role?.roles_working_role?.title}
                        </Typography>
                      </TableCell>
                      <TableCell>{`${role?.role_client_working?.credits_used} / ${role?.role_client_working?.no_of_credits}`}</TableCell>

                      <TableCell>{role?.role_service_working?.service_name}</TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            backgroundColor: getStatusBackgroundColor(role?.status),
                            color: getStatusColor(role?.status),
                            padding: '6px',
                            width: '120px',
                            margin: '5px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            borderRadius: '15px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Icon icon="fluent-mdl2:radio-bullet" style={{ marginRight: '5px', fontSize: '20px' }} />{' '}
                          {role?.status}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderRadius: '0 10px 10px 0' }}>
                        {role?.roles_working_resourcer
                          ? `${role?.roles_working_resourcer?.first_name} ${role?.roles_working_resourcer?.last_name}`
                          : 'Pending'}
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={10} style={{ textAlign: 'center', padding: '10px' }}>
                    No roles found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount} // The total number of records (from server)
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

export default ServiceRolesComponent;
