import { Close } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {  companySize, subscription_types } from 'src/constants/constants';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { enqueueSnackbar } from 'notistack';
import * as Yup from 'yup';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Grow
      ref={ref}
      {...props}
      timeout={{ enter: 1000, exit: 500 }}
      easing={{ enter: 'cubic-bezier(0.4, 0, 0.2, 1)', exit: 'cubic-bezier(0.4, 0, 0.2, 1)' }}
    />
  );
});

function AddNewClient({ open, onClose, onSubmit }) {
  const userId = localStorage.getItem('userId');

  const [services, setServices] = useState([]);

  const initialValue = {
    service_id: null,
    client_number: '',
    status: 'Active',
    first_name: '',
    last_name: '',
    job_title: '',
    company_name: '',
    email: '',
    company_linkedin: '',
    person_linkedin: '',
    subscription_type: '',
    no_of_credits: null,
    no_of_credits_per_day: null,
    no_of_leads: 0,
    project_length: 0,
    start_date: null,
    end_date: null,
    reminder_date: null,
    amount: null,
    company_size: null,
    phone_number: null,

    selectedService: null,

    user_id: userId,
    assigned_acm: userId,
  };

    const basicValidationSchema = Yup.object().shape({
      first_name: Yup.string().required('First name is required'),
      last_name: Yup.string().required('Last name is required'),
      // job_title: Yup.string().required('Enter job title'),
      // company_name: Yup.string().required('Enter company name'),
      email: Yup.string().email('Enter a valid email').required('Email is required'),
      client_number : Yup.number().required('Client number is required'),
      // client_number: Yup.number()
      //   .required('Client Number is required')
      //   .test('is-in-service-range', 'Client Number is out of range', function (value) {
      //     const { selectedService } = this.parent; // Get the selected service object from the form data
      //     if (selectedService) {
      //       return value >= selectedService?.minId && value <= selectedService?.maxId;
      //     }
      //     return true; // If selectedService is not provided, validation passes
      //   }),
      no_of_credits: Yup.number()
        .nullable(false)
        .test('is-valid-credits', 'Number of credits is required for selected services', function (value) {
          const { selectedService } = this.parent; // Get the selected service object from the form data
          if (selectedService) {
            const { serviceName } = selectedService;
            const servicesRequiringCredits = ['CV-Sourcing', 'Pre-Qualification', '360/Direct'];
            const isServiceRequiringCredits = servicesRequiringCredits.includes(serviceName);
            // Check if the number of credits is required for selected services
            if (isServiceRequiringCredits) {
              return !!value;
            }
          }
          return true; // If not applicable, validation passes
        }),
      no_of_credits_per_day: Yup.number()
        .nullable(false)
        .test(
          'is-valid-credits-per-day',
          'Number of credits per day is required for selected services',
          function (value) {
            const { selectedService } = this.parent; // Get the selected service object from the form data
            if (selectedService) {
              const { serviceName } = selectedService;
              const servicesRequiringCreditsPerDay = ['CV-Sourcing', 'Pre-Qualification', '360/Direct'];
              const isServiceRequiringCreditsPerDay = servicesRequiringCreditsPerDay.includes(serviceName);
              // Check if the number of credits per day is required for selected services
              if (isServiceRequiringCreditsPerDay) {
                return !!value;
              }
            }
            return true; // If not applicable, validation passes
          }
        )
        .test('is-less', 'Credits per day must be less than total credits', function (value) {
          const credits = this.parent.no_of_credits;
          if (credits) {
            return value < credits;
          }
          return true;
        }),
      start_date: Yup.date().nullable().required('Start Date is required'),
      end_date: Yup.date()
        .nullable()
        .min(Yup.ref('start_date'), 'End Date must be after Start Date')
        .required('End date is required'),

      reminder_date: Yup.date()
        .nullable()
        .min(Yup.ref('start_date'), 'Reminder Date must be after Start Date')
        .max(Yup.ref('end_date'), 'Reminder Date must be before End Date')
        .test('is-valid-reminder-date', 'Reminder Date is required for selected services', function (value) {
          const { selectedService, start_date } = this.parent; // Get the selected service object from the form data
          if (selectedService) {
            const { serviceName } = selectedService;
            const servicesRequiringReminder = ['CV-Sourcing', 'Pre-Qualification', '360/Direct'];
            const isServiceRequiringReminder = servicesRequiringReminder.includes(serviceName);
            // Check if the reminder date is required for selected services
            if (isServiceRequiringReminder && start_date) {
              return !!value;
            }
          }
          return true; // If not applicable, validation passes
        })
        .typeError('Invalid date format'),
    });

  const getServices = () => {
    try {
      Get(
        {},
        API_URLS.getAllServices,
        (resp) => {
          setServices(resp?.data);
        },
        (error) => {
          enqueueSnackbar('Failed to fetch services', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  useEffect(() => {
    getServices();
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            display: 'flex',
            width: '100%',
            minHeight: '25vh',
          },
        }}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
          },
        }}
      >
        <DialogTitle style={{ backgroundColor: '#D1F3FA', width: '100%' }}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5">Add New Client</Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {/* <animated.div style={animatedProps}> */}
          <Formik
            initialValues={initialValue}
            validationSchema={basicValidationSchema}
            onSubmit={(values, actions) => {
              onSubmit(values, actions);
            }}
          >
            {({ values, errors, getFieldProps, setFieldValue, touched, handleSubmit, handleBlur }) => (
              <Form>
                <br />
                <Grid container spacing={2}>
                  {/* Service Selection */}
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Autocomplete
                      id="service_id"
                      options={services}
                      value={values.selectedService}
                      defaultValue={values.selectedService}
                      getOptionLabel={(option) => option.service_name}
                      onChange={(event, newService) => {
                        if (newService) {
                          setFieldValue('service_id', newService.id);
                          setFieldValue('selectedService', newService);
                        } else {
                          setFieldValue('selectedService', null);
                          setFieldValue('service_id', null);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Service"
                          fullWidth
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            marginBottom: '3px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {values.selectedService && (
                    <>
                      {/* Cleint Number */}
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                          id="client_number"
                          name="client_number"
                          value={values.client_number}
                          type="text"
                          placeholder={`Enter client number [${values?.selectedService?.min_id} - ${values?.selectedService?.max_id}] *`}
                          {...getFieldProps('client_number')}
                          size="small"
                          onBlur={handleBlur}
                          error={touched.client_number && Boolean(errors.client_number)}
                          helperText={touched.client_number && errors.client_number}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            marginBottom: '3px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      </Grid>
                      {/* First Name */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="first_name"
                          type="text"
                          value={values.first_name}
                          {...getFieldProps('first_name')}
                          placeholder="First Name"
                          size="small"
                          error={touched.first_name && Boolean(errors.first_name)}
                          helperText={touched.first_name && errors.first_name}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            marginBottom: '3px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      </Grid>
                      {/* Last Name */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="last_name"
                          value={values.last_name}
                          type="text"
                          {...getFieldProps('last_name')}
                          placeholder="Last Name *"
                          size="small"
                          error={touched.last_name && Boolean(errors.last_name)}
                          helperText={touched.last_name && errors.last_name}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            marginBottom: '3px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      </Grid>
                      {/* job_title */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="job_title"
                          type="text"
                          value={values.job_title}
                          {...getFieldProps('job_title')}
                          placeholder="Job Title "
                          size="small"
                          error={touched.job_title && Boolean(errors.job_title)}
                          helperText={touched.job_title && errors.job_title}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            marginBottom: '3px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      </Grid>
                      {/* company_name */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="company_name"
                          type="text"
                          value={values.company_name}
                          {...getFieldProps('company_name')}
                          placeholder="Company Name"
                          size="small"
                          error={touched.company_name && Boolean(errors.company_name)}
                          helperText={touched.company_name && errors.company_name}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            marginBottom: '3px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="company_linkedin"
                          type="text"
                          value={values.company_linkedin}
                          {...getFieldProps('company_linkedin')}
                          placeholder="Company LI"
                          size="small"
                          error={touched.company_linkedin && Boolean(errors.company_linkedin)}
                          helperText={touched.company_linkedin && errors.company_linkedin}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            marginBottom: '3px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Autocomplete
                          id="company_size"
                          size="small"
                          fullWidth
                          name="company_size"
                          value={values?.company_size} // Change here
                          options={companySize}
                          getOptionLabel={(option) => option}
                          onChange={(event, newValue) => {
                            setFieldValue('company_size', newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Company Size"
                              variant="outlined"
                              required
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{
                                backgroundColor: 'white',
                                borderRadius: '0.3rem',
                                marginBottom: '3px',
                                '& .MuiOutlinedInput-root': {
                                  '&:hover fieldset': {
                                    borderColor: '#00C1FE',
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: '#00C1FE',
                                  },
                                },
                                '& .MuiInputBase-root': {
                                  '&.Mui-focused': {
                                    boxShadow: '0 0 0 1px #00C1FE',
                                  },
                                },
                              }}
                            />
                          )}
                        />
                      </Grid>
                      {/* Email */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="email"
                          type="text"
                          value={values.email}
                          {...getFieldProps('email')}
                          placeholder="Email *"
                          size="small"
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            marginBottom: '3px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="person_linkedin"
                          type="text"
                          value={values.person_linkedin}
                          {...getFieldProps('person_linkedin')}
                          placeholder="Client LI"
                          size="small"
                          error={touched.person_linkedin && Boolean(errors.person_linkedin)}
                          helperText={touched.person_linkedin && errors.person_linkedin}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            marginBottom: '3px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="phone_number"
                          type="number"
                          value={values.phone_number}
                          {...getFieldProps('phone_number')}
                          placeholder="Phone Number"
                          size="small"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            marginBottom: '3px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      </Grid>
                      {/* Subscription Type */}
                      {(values?.selectedService?.service_name === 'CV-Sourcing' ||
                        values.selectedService.service_name === 'Pre-Qualification' ||
                        values?.selectedService?.service_name === '360/Direct') && (
                        <>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Autocomplete
                              id="service_id"
                              size="small"
                              options={subscription_types}
                              fullWidth
                              getOptionLabel={(option) => option.label}
                              onChange={(event, newService) => {
                                if (newService) {
                                  setFieldValue('subscription_type', newService.id);
                                } else {
                                  setFieldValue('subscription_type', null);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Subscription Type"
                                  fullWidth
                                  sx={{
                                    backgroundColor: 'white',
                                    borderRadius: '0.3rem',
                                    marginBottom: '3px',
                                    '& .MuiOutlinedInput-root': {
                                      '&:hover fieldset': {
                                        borderColor: '#00C1FE',
                                      },
                                      '&.Mui-focused fieldset': {
                                        borderColor: '#00C1FE',
                                      },
                                    },
                                    '& .MuiInputBase-root': {
                                      '&.Mui-focused': {
                                        boxShadow: '0 0 0 1px #00C1FE',
                                      },
                                    },
                                  }}
                                  k
                                />
                              )}
                            />
                          </Grid>
                          {/* Credits */}
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField
                              name="no_of_credits"
                              id="no_of_credits"
                              type="number"
                              {...getFieldProps('no_of_credits')}
                              placeholder="Credits *"
                              value={values.no_of_credits}
                              size="small"
                              fullWidth
                              error={touched.no_of_credits && Boolean(errors.no_of_credits)}
                              helperText={touched.no_of_credits && errors.no_of_credits}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{
                                backgroundColor: 'white',
                                borderRadius: '0.3rem',
                                marginBottom: '3px',
                                '& .MuiOutlinedInput-root': {
                                  '&:hover fieldset': {
                                    borderColor: '#00C1FE',
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: '#00C1FE',
                                  },
                                },
                                '& .MuiInputBase-root': {
                                  '&.Mui-focused': {
                                    boxShadow: '0 0 0 1px #00C1FE',
                                  },
                                },
                              }}
                            />
                          </Grid>
                          {/* Credits Per Day */}
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField
                              name="no_of_credits_per_day"
                              id="no_of_credits_per_day"
                              type="number"
                              {...getFieldProps('no_of_credits_per_day')}
                              placeholder="Credits Per Day *"
                              size="small"
                              fullWidth
                              value={values.no_of_credits_per_day}
                              error={touched.no_of_credits_per_day && Boolean(errors.no_of_credits_per_day)}
                              helperText={touched.no_of_credits_per_day && errors.no_of_credits_per_day}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{
                                backgroundColor: 'white',
                                borderRadius: '0.3rem',
                                marginBottom: '3px',
                                '& .MuiOutlinedInput-root': {
                                  '&:hover fieldset': {
                                    borderColor: '#00C1FE',
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: '#00C1FE',
                                  },
                                },
                                '& .MuiInputBase-root': {
                                  '&.Mui-focused': {
                                    boxShadow: '0 0 0 1px #00C1FE',
                                  },
                                },
                              }}
                            />
                          </Grid>
                        </>
                      )}

                      {/* No Of Leads */}

                      {values?.selectedService?.service_name === 'Lead-Generation' && (
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id="no_of_leads"
                            type="number"
                            placeholder="No of leads"
                            {...getFieldProps('no_of_leads')}
                            size="small"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{
                              backgroundColor: 'white',
                              borderRadius: '0.3rem',
                              marginBottom: '3px',
                              '& .MuiOutlinedInput-root': {
                                '&:hover fieldset': {
                                  borderColor: '#00C1FE',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#00C1FE',
                                },
                              },
                              '& .MuiInputBase-root': {
                                '&.Mui-focused': {
                                  boxShadow: '0 0 0 1px #00C1FE',
                                },
                              },
                            }}
                          />
                        </Grid>
                      )}
                      {/* Project Length */}
                      {(values?.selectedService?.service_name === 'IT and Consulting' ||
                        values?.selectedService?.service_name === 'VA') && (
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id="project_length"
                            type="number"
                            {...getFieldProps('project_length')}
                            placeholder="Project Length (optional)"
                            size="small"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{
                              backgroundColor: 'white',
                              borderRadius: '0.3rem',
                              marginBottom: '3px',
                              '& .MuiOutlinedInput-root': {
                                '&:hover fieldset': {
                                  borderColor: '#00C1FE',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#00C1FE',
                                },
                              },
                              '& .MuiInputBase-root': {
                                '&.Mui-focused': {
                                  boxShadow: '0 0 0 1px #00C1FE',
                                },
                              },
                            }}
                          />
                        </Grid>
                      )}

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          name="amount"
                          id="amount"
                          type="number"
                          {...getFieldProps('amount')}
                          placeholder="Amount"
                          value={values.amount}
                          size="small"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            marginBottom: '3px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="start_date"
                          name="start_date"
                          type="date"
                          label="Start Date *"
                          placeholder={`Select start date `}
                          {...getFieldProps('start_date')}
                          value={values.start_date ? dayjs(values.start_date).format('YYYY-MM-DD') : 'Select Date'}
                          //   defaultValue={values.s}
                          size="small"
                          error={touched.start_date && Boolean(errors.start_date)}
                          helperText={touched.start_date && errors.start_date}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            marginBottom: '3px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="end_date"
                          name="end_date"
                          type="date"
                          placeholder={`Select end date`}
                          {...getFieldProps('end_date')}
                          label="End Date *"
                          value={values.end_date ? dayjs(values.end_date).format('YYYY-MM-DD') : 'Select Date'}
                          //   defaultValue={values.end_date ? dayjs(values.end_date).format('YYYY-MM-DD') : ''}
                          size="small"
                          error={touched.end_date && Boolean(errors.end_date)}
                          helperText={touched.end_date && errors.end_date}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            marginBottom: '3px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="reminder_date"
                          name="reminder_date"
                          type="date"
                          placeholder={`Select reminder date`}
                          {...getFieldProps('reminder_date')}
                          size="small"
                          label="Reminder Date *"
                          value={
                            values.reminder_date ? dayjs(values.reminder_date).format('YYYY-MM-DD') : 'Select Date'
                          }
                          //   defaultValue={values.reminder_date ? dayjs(values.reminder_date).format('YYYY-MM-DD') : ''}
                          error={touched.reminder_date && Boolean(errors.reminder_date)}
                          helperText={touched.reminder_date && errors.reminder_date}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '0.3rem',
                            marginBottom: '3px',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <Box display="flex" justifyContent="flex-end" marginTop="1rem">
                  <Button
                    size="medium"
                    sx={{
                      backgroundColor: '#A3A3A3',
                      fontSize: '16px',
                      color: 'white',
                      marginLeft: '1rem',
                      '&:hover': {
                        color: 'white !important',
                        backgroundColor: '#A3A3A3 !important',
                      },
                    }}
                    onClick={onClose}
                  >
                    Cancle
                  </Button>
                  <Button
                    size="medium"
                    sx={{
                      backgroundColor: '#0ec4fe',
                      fontSize: '16px',
                      color: 'white',
                      marginLeft: '1rem',
                      '&:hover': {
                        color: 'white !important',
                        backgroundColor: '#0ec4fe !important',
                      },
                    }}
                    onClick={() => {
                      if (Object.keys(errors)?.length > 0) {
                        enqueueSnackbar('Please fill all the required fields, required fields are marked as *', {
                          variant: 'error',
                        });
                      } else {
                        handleSubmit();
                      }
                    }}
                  >
                    Add Client
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
          {/* </animated.div> */}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddNewClient;
