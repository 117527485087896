import React, { useCallback, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  LinearProgress,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
} from '@mui/material';
import { Icon } from '@iconify/react';
import './toggleSwitch.css';
import { API_URLS } from 'src/constants/apiURLs';
import { Get, Post } from 'src/actions/API/apiActions';
import { useSnackbar } from 'notistack';

function ToggleSwitch({ isChecked, onToggle }) {
  return (
    <label className="switch">
      <input type="checkbox" checked={isChecked} onChange={() => onToggle(!isChecked)} aria-label="Toggle Row" />
      <span className="slider round" />
    </label>
  );
}

function ScrapperProgress({ scrapper }) {
  const { results, status, percentage } = scrapper;
  const progressValue = parseInt(percentage, 10) || 0;
  const progressBarColor = {
    Completed: '#198038',
    'In-Progress': '#104ED1',
    Pending: '#F1C21B',
  }[status];

  return (
    <Box display="flex" alignItems="center">
      <Box
        sx={{
          width: 30,
          height: 25,
          borderRadius: '50%',
          backgroundColor: progressBarColor,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: 1,
          border: status === 'In-Progress' ? '2px solid blue' : 'none',
        }}
      >
        {status === 'Completed' && <Icon icon="charm:tick" color="white" />}
        {status === 'Pending' && <Icon icon="tabler:exclamation-mark" color="black" />}
        {status === 'In-Progress' && <Icon icon="line-md:loading-twotone-loop" color="#0ec4fe" />}
      </Box>
      <Box flex={1}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <p style={{ fontSize: 12, fontWeight: 'bold' }}>
            {results} <span style={{ fontSize: 10 }}>({percentage})</span>
          </p>
          <p style={{ fontSize: 10 }}>{status}</p>
        </Box>
        <LinearProgress
          variant="determinate"
          value={progressValue}
          sx={{
            '& .MuiLinearProgress-bar': { backgroundColor: progressBarColor },
          }}
        />
      </Box>
    </Box>
  );
}

function ScrapperProgressTable() {
  const { enqueueSnackbar } = useSnackbar();
  const [expandedRow, setExpandedRow] = useState(null);
  const [searches, setSearches] = useState([]);
  const [toggles, setToggles] = useState({});
  const [pagination, setPagination] = useState({ page: 0, pageSize: 25, total: 0 });
  const [openConfirmationdial, setOpenConfirmationdial] = useState(false);
  const [selectedSearch, setSelectedSearch] = useState(null);
  const fetchSearches = useCallback(() => {
    Get(
      { page: pagination.page, page_size: pagination.pageSize },
      API_URLS.getAllSearchesWithPagination,
      (resp) => {
        setSearches(resp.data.rows);
        setPagination((prev) => ({ ...prev, total: resp.data.count }));
      },
      (error) => enqueueSnackbar(error.message, { variant: 'error' })
    );
  }, [pagination.page, pagination.pageSize, enqueueSnackbar]);

  useEffect(() => {
    fetchSearches();
  }, [fetchSearches]);

  const handleRowToggle = (index, isEnabled) => {
    setToggles((prev) => ({ ...prev, [index]: isEnabled }));
  };

  const handleRowClick = (index) => {
    setExpandedRow((prev) => (prev === index ? null : index));
  };

  const handleChangeSearchStatus = () => {
    try {
      Post(
        {
          id: selectedSearch?.id,
          status: !selectedSearch?.search_status,
        },
        API_URLS.changeSearchStatus,
        (resp) => {
          enqueueSnackbar('Search Status Changed Succefully', { variant: 'success' });
          setOpenConfirmationdial(false);
          fetchSearches();
        },
        (error) => {
          enqueueSnackbar("Error Changing Search Status", { variant: 'error' });
          setOpenConfirmationdial(false);
        }
      );
    } catch (error) {
      enqueueSnackbar("Error Changing Search Status", { variant: 'error' });
      setOpenConfirmationdial(false);
    }
  }
  return (
    <TableContainer component={Paper} sx={{ border: '1px solid #D6D4D4' }}>
      <Table>
        <TableHead>
          <TableRow>
            {['Job Title', 'Date', 'Link', 'Keywords', 'Total Results', 'Scrapper1', 'Scrapper2', 'Actions'].map(
              (header) => (
                <TableCell key={header} style={{ fontWeight: 'bold' }}>
                  {header}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {searches.map((row, index) => (
            <React.Fragment key={row?.title + index}>
              <TableRow onClick={() => handleRowClick(index)} sx={{ cursor: 'pointer' }}>
                <TableCell>
                  {row?.search_title}
                  {/* {row?.previousSearches?.length > 0 && (
                    <Icon
                      icon={expandedRow === index ? 'iconamoon:arrow-up-2-fill' : 'iconamoon:arrow-down-2-fill'}
                      style={{ marginLeft: 8, color: '#0ec4fe' }}
                    />
                  )} */}
                </TableCell>
                <TableCell>{row?.search_date.split('T')[0]}</TableCell>
                <TableCell>
                  <a href={row?.search_link} target="_blank" rel="noopener noreferrer" style={{ color: '#87B7FF' }}>
                    {row?.search_link?.substring(0, 30)}...
                  </a>
                </TableCell>
                <TableCell>{row?.search_title}</TableCell>
                <TableCell>{row?.search_results}</TableCell>
                {/* <TableCell>
                  {row?.page < (parseInt(row?.search_results) / parseInt(row?.page_size)).toFixed(0)
                    ? row?.page
                    : (parseInt(row?.search_results) / parseInt(row?.page_size)).toFixed(0)}
                </TableCell>
                <TableCell>{(parseInt(row?.search_results) / parseInt(row?.page_size)).toFixed(0)}</TableCell> */}

                <TableCell>
                  <ScrapperProgress scrapper={row?.scrapper1 || 0} />
                </TableCell>
                <TableCell>
                  <ScrapperProgress scrapper={row?.scrapper2 || 0} />
                </TableCell>
                <TableCell>
                  <ToggleSwitch
                    isChecked={row?.search_status || false}
                    onToggle={()=>{
                      setSelectedSearch(row);
                      setOpenConfirmationdial(true);
                    }}
                  />
                </TableCell>
              </TableRow>

              {/* {expandedRow === index &&
                row?.previousSearches?.map((prev, i) => (
                  <TableRow key={`${index}-${i}`} sx={{ backgroundColor: '#f0f0f0' }}>
                    <TableCell style={{ paddingLeft: 50 }}>{row?.title} (Previous)</TableCell>
                    <TableCell>{prev?.date}</TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell>{prev?.results}</TableCell>
                    <TableCell>
                      <ScrapperProgress scrapper={prev?.scrapper1} />
                    </TableCell>
                    <TableCell>
                      <ScrapperProgress scrapper={prev?.scrapper2} />
                    </TableCell>
                    <TableCell />
                  </TableRow>
                ))} */}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
      <OpenConfirmationDialog open={openConfirmationdial} onClose={() => setOpenConfirmationdial(false)} onSubmit={()=>handleChangeSearchStatus()}/>
    </TableContainer>
  );
}

export default ScrapperProgressTable;

const OpenConfirmationDialog = ({ open, onClose, onSubmit }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to Change the status of this search?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onSubmit} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
