import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
    const RecruiterPipelineComponent = ({
      title,
      onClick,
      color,
    }) => {
      const useStyles = makeStyles({
        concaveBox: {
          position: 'relative',
          width: '350px',
          height: '90px',
          backgroundColor: '#d1f3fa',
          clipPath: 'polygon(10% 10%, 90% 10%, 100% 50%, 90% 90%, 10% 90%, 20% 50%)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          padding: '10px',
          cursor: 'pointer',
          transition: 'box-shadow 0.3s ease', // Smooth transition for shadow effect
          boxShadow: '0 0 0 4px blue', // Border effect using box-shadow when active
          borderBottom: `13px solid ${color}`,
          // borderTop: '13px solid red',
          // borderLeft: '13px solid red',
        },
        title: {
          marginLeft: '10px',
          marginTop: '5px',
          fontWeight: 'bold',
          paddingTop: '10px',
          justifyContent: 'end',
        },
        stats: {
          position: 'relative',
          display: 'flex',
          justifyContent: 'space-between',
          width: '40%',
          marginLeft: '10%',
          marginBottom: '5px',
        },
      });
    
      const classes = useStyles();
      return (
        <Box className={classes.concaveBox}>
          <Typography variant="body2" className={classes.title} onClick={onClick} sx={{ paddingTop: '5px' }}>
            {title}{' '}
          </Typography>
        </Box>
      );
    };
    
    


export default RecruiterPipelineComponent;