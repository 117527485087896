import { AddCircle } from '@mui/icons-material';
import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Papa from 'papaparse';
import { Get, Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { enqueueSnackbar } from 'notistack';
import { format } from 'date-fns';
import { get } from 'lodash';

function BlockedDomains(props) {
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [showCSVImportBox, setShowCSVImportBox] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [domain, setDomain] = useState(null);
  const [csvData, setCsvData] = useState();
  const [blockedDomains, setBlockedDomains] = useState([]);

  const columns = [
    {
      accessorKey: 'domain',
      header: 'Domain',
    },
    {
      accessorKey: 'status',
      header: 'Status',
      minWidth: 150,
      Cell: ({ cell }) => (
        <Box component="span">
          <>
            <Typography
              variant="body2"
              style={{
                color: 'white',
                backgroundColor: '#4D4D4D',
                padding: '5px',
                borderRadius: '5px',
                width: '100px',
                textAlign: 'center',
              }}
            >
              Blocked
            </Typography>
          </>
        </Box>
      ),
    },
    {
      accessorKey: 'blocked_attempts',
      header: 'Blocked attempts',
      minWidth: 150,
      Cell: ({ cell }) => (
        <Box component="span">
          <>
            <Typography
              variant="body2"
              style={{
                color: 'white',
                backgroundColor: '#52C062',
                padding: '5px',
                borderRadius: '5px',
                width: '50px',
                textAlign: 'center',
              }}
            >
              {cell.getValue()}
            </Typography>
          </>
        </Box>
      ),
    },
    {
      accessorKey: 'updatedAt',
      header: 'Last update ',
      minWidth: 200,
      Cell: ({ cell }) => {
        // Convert the date string to the desired format
        const formattedDate = format(new Date(cell.getValue()), 'MMM d, yyyy, hh:mm a');
        return (
          <Box component="span">
            <Typography
              variant="body2"
              style={{
                color: 'black',
                backgroundColor: 'transparent',
                padding: '5px',
                borderRadius: '5px',
                width: '150px',
                textAlign: 'center',
              }}
            >
              {formattedDate}
            </Typography>
          </Box>
        );
      },
    },
  ];

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedFile(file);

      // Parse the CSV file and set the parsed data in state
      Papa.parse(file, {
        header: true,
        complete: (result) => {

          // Filter out rows where the email field is empty
          const filteredData = result.data.filter((row) => row.Domain && row.Domain.trim() !== '');

          // Set the filtered data in state
          setCsvData(filteredData);
        },
        error: (error) => {
          console.error('Parsing Error:', error);
          // Handle parsing error if needed
        },
      });
    }
  };

  const uploadFile = () => {
    try {
      Post(
        { domains: csvData },
        API_URLS.addDomainList,
        (resp) => {
          setUploadedFile(null);
          enqueueSnackbar('Domains list added successfully', { variant: 'success' });
          getBlockedDomains();
        },
        (error) => {
          enqueueSnackbar('Failed to add domain list', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const getBlockedDomains = () => {
    try {
      setIsLoading(true);
      Get(
        {},
        API_URLS.getBlockedDomains,
        (resp) => {
          // enqueueSnackbar('Suppression list fetched successfully', { variant: 'success' });
          setIsLoading(false);
          setIsError(false);
          setBlockedDomains(resp?.data);
        },
        (error) => {
          enqueueSnackbar('Failed to fetch suppression list', { variant: 'error' });
          setIsLoading(false);
          setIsError(true);
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
      setIsLoading(false);
      setIsError(true);
    }
  };

  useEffect(() => {
    getBlockedDomains();
  }, []);

  const addBlockedDomain = () => {
    try {
      Post(
        { domain: domain },
        API_URLS.addSingleDomain,
        (resp) => {
          enqueueSnackbar('Domain added to blocked  list successfully', { variant: 'success' });
          getBlockedDomains();
          setDomain(null);
          setShowCSVImportBox(false)
        },
        (error) => {
          enqueueSnackbar('Failed to add domain to blocked list', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  return (
    <>
      <Box display="flex" margin="20px">
        <Button
          startIcon={<AddCircle />}
          className="w-68 px-6 py-2 text-white text-lg rounded-md bg-[#00C1FE] hover:bg-[#00C1FE]"
          onClick={() => {
            setShowCSVImportBox(true);
          }}
        >
          Import list of Domains to block
        </Button>
      </Box>
      <br />
      {showCSVImportBox && (
        <Box margin="20px">
          <Paper
            elevation={3}
            sx={{
              padding: '20px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              transition: 'box-shadow 0.3s ease-in-out',
              '&:hover': {
                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
              },
              backgroundColor: '#F6F9FC',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h6">Import via CSV file</Typography>
                <Typography variant="body1" style={{ marginTop: '10px' }}>
                  <strong>CSV Format:</strong>
                </Typography>
                <Typography variant="body1" style={{ marginTop: '10px' }}>
                  <ul style={{ paddingLeft: '20px' }}>
                    <li>Your CSV should only contain one column containing only domains with header Domain.</li>
                  </ul>
                </Typography>
                <br />
                <Box display="flex">
                  <Button
                    className="w-58 px-6 py-2 text-white  rounded-md bg-[#00C1FE] hover:bg-[#00C1FE] mx-4"
                    component="label"
                  >
                    {uploadedFile ? 'Change file' : 'Select file'}
                    <VisuallyHiddenInput type="file" accept=".csv" onChange={handleFileChange} />
                  </Button>

                  {uploadedFile && (
                    <>
                      <Typography variant="body1" style={{ marginTop: '10px' }}>
                        <strong>File Uploaded:</strong> {uploadedFile.name}
                      </Typography>
                      <Button
                        className="w-58 px-6 py-2 text-white  rounded-md bg-[#00C1FE] hover:bg-[#00C1FE] mx-4"
                        component="label"
                        onClick={() => uploadFile()}
                      >
                        Upload file
                        {/* <VisuallyHiddenInput type="file" accept=".csv" onChange={handleFileChange} /> */}
                      </Button>
                    </>
                  )}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">Import domains individually</Typography>
                <br />
                <TextField
                  className="rounded-full"
                  id="domain"
                  name="domain"
                  value={domain ? domain : null}
                  placeholder="Enter Domain"
                  multiline
                  rows={6}
                  onChange={(e) => setDomain(e.target.value)}
                  fullWidth
                  sx={{
                    borderRadius: '0.5rem',
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#00C1FE',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#00C1FE',
                      },
                    },
                    '& .MuiInputBase-root': {
                      '&.Mui-focused': {
                        boxShadow: '0 0 0 1px #00C1FE',
                      },
                    },
                  }}
                />
                <br />
                <Box sx={{ marginTop: '10px' }} display="flex" justifyContent="flex-end">
                  <Button
                    className="w-58 px-6 py-2 text-white  rounded-md bg-[#00C1FE] hover:bg-[#00C1FE] mx-4"
                    component="label"
                    onClick={() => addBlockedDomain()}
                  >
                    Add Domain
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      )}

      <br />
      <div style={{ margin: '1rem' }}>
        <MaterialReactTable
          columns={columns}
          data={blockedDomains}
          enableColumnActions={false}
          enableColumnFilters={false}
          enableTopToolbar={false}
          defaultColumn={{
            size: 80,
          }}
          manualPagination
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          muiTableBodyRowProps={{ hover: false }}
          state={{
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            sorting,
          }}
          muiTableProps={{
            sx: {
              borderRadius: '30px',
            },
          }}
        />
      </div>
    </>
  );
}

export default BlockedDomains;
