import React from 'react';
import { RecruiterDashboard } from 'src/sections/direct360';

function Direct360Page(props) {
    return (
        <div>
            <RecruiterDashboard />
        </div>
    );
}

export default Direct360Page;