import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CandidatePipelineTable } from './components';
import { StartRoleWorkingDialog } from './candidates-working';
import { Get, Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { useSnackbar } from 'notistack';

function RoleApplications(props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { roleId } = useParams();
  const userId = localStorage.getItem('userId');
  // const location = useLocation();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [openStartRoleWorkingDialog, setOpenStartRoleWorkingDialog] = useState(false);
  const [roleCandidates, setRoleCandidates] = useState([]);
  const [roleStatus, setRoleStatus] = useState();
  const [canStartRole, setCanStartRole] = useState(true);
  const [loading, setLoading] = useState(false);
  const buttons = [
    {
      buttonText: 'Total',
      value: 100,
      borderTop: '#F688F2',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'New',
      value: 25,
      borderTop: '#88BCF6',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'In Review',
      value: 25,
      borderTop: '#A4B0DB',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'Available',
      value: 25,
      borderTop: '#FF9999',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'Engaged',
      value: 25,
      borderTop: '#EBB794',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'Hired',
      value: 25,
      borderTop: '#67E4BB',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'Offered',
      value: 25,
      borderTop: '#E0CC38',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'Rejected',
      value: 25,
      borderTop: '#B0CDCF',
      buttonFontSize: '20px',
    },
  ];

  const getCandidateByRoleId = useCallback(() => {
    setLoading(true);
    try {
      Get(
        {
          roleId: roleId,
          page: page,
          limit: limit,
        },
        API_URLS.getRoleCandidatesById,
        (resp) => {
          setRoleCandidates(resp?.data);
          setRoleStatus(resp?.data?.roleStatus);
          setLoading(false);
        },
        (error) => {
          console.log(error);
          setLoading(false);
        }
      );
    } catch (error) {
      setLoading(false);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }, [roleId, page, limit]);

  useEffect(() => {
    getCandidateByRoleId();
  }, [getCandidateByRoleId]);


  const getRoleStatus = useCallback(
    (roleStatus) => {
      if (roleStatus?.length === 0) {
        setCanStartRole(true); // No statuses mean the role can be started by any user.
        return;
      } else {
        let isInProgress = false;
        roleStatus?.forEach((status) => {
          // console.log("consolfgdgfdgfjgudfhgfdhughufdguhfded", status.status, status.user_id, userId);
          if (status?.status === 'In-Progress' && status?.user_id == userId) {
            isInProgress = true;
          }
        });

        // setCanStartRole(isInProgress); // If the user is in progress, disable the button.
        if(isInProgress){
          setCanStartRole(false);
        }else{
          setCanStartRole(true);
        }
        // console.log('isInProgress:', isInProgress);
      }
    },
    [userId]
  );

    // Simulate fetching roleStatus and updating based on API response
    useEffect(() => {
      // Assuming `roleStatus` is passed as a prop or fetched elsewhere.
      getRoleStatus(roleStatus);
    }, [roleStatus, getRoleStatus , userId]);
  // implement the fade loader from react-spinners until the data is fetched
  const startRole = (payload) => {
    try {
      Post(
        {
          roleId: roleId,
          userId: userId,
          primaryTask: payload?.primaryTask ? payload?.primaryTask : null,
          subTask: payload?.subTask ? payload?.subTask : null,
        },
        API_URLS.startRoleByRecruiter,
        (resp) => {
          enqueueSnackbar('Role started successfully', { variant: 'success' });
          setOpenStartRoleWorkingDialog(false);
          getCandidateByRoleId()
        },
        (error) => {
          console.log(error);
          enqueueSnackbar(error?.response?.data?.message || 'Something went wrong', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  return (
    <div style={{ width: '97%', margin: 'auto', height: '90vh' }}>

      <>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <ArrowBack
            fontSize="small"
            onClick={() => {
              navigate(-1);
            }}
            sx={{
              color: '#00C1FE',
              cursor: 'pointer',
            }}
          />
          &nbsp;&nbsp;{' '}
          <Typography
            variant="subtitle2"
            sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}
            onClick={() => {
              navigate(-1);
            }}
          >
            Back to Dashboard
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex">
            <p style={{ fontSize: '32px', fontWeight: 'bold', marginTop: '10px' }}>Candidates associated to</p>
            {loading ? (
              <Skeleton variant="text" width="10%" style={{ textAlign: 'left' }} />
            ) : (
              <p style={{ fontSize: '32px', fontWeight: 'bold', marginTop: '10px', marginLeft: '10px' }}>
                {roleCandidates?.roleTitle}
              </p>
            )}
          </Box>

          <Box display="flex" alignItems="center" marginRight="1rem">
            {!canStartRole && (
              <Button
                sx={{
                  background: '#00C1FE',
                  color: '#fff',
                  width: '150px',
                  border: '1px solid #00C1FE',
                  '&:hover': {
                    background: '#00C1FE',
                    color: '#fff',
                  },
                  borderRadius: '5px',
                  padding: '10px',
                  marginTop: '15px',
                }}
                onClick={() => {
                  navigate(`/recruiter/jobApplicants/candidateWorking/${roleId}`);
                }}
              >
                Role Working
              </Button>
            )}

            <Button
              sx={{
                background: '#00C1FE',
                color: '#fff',
                width: '150px',
                marginLeft: '10px',
                border: '1px solid #00C1FE',
                '&:hover': {
                  background: '#00C1FE',
                  color: '#fff',
                },
                borderRadius: '5px',
                padding: '10px',
                marginTop: '15px',
              }}
              disabled={canStartRole}
              onClick={() => {
                navigate(`/recruiter/jobApplicants/roleSequence/${roleId}`);
              }}
            >
              Sequences
            </Button>
            <Button
              sx={{
                background: '#00C1FE',
                color: '#fff',
                width: '150px',
                marginLeft: '10px',
                border: '1px solid #00C1FE',
                '&:hover': {
                  background: '#00C1FE',
                  color: '#fff',
                },
                borderRadius: '5px',
                padding: '10px',
                marginTop: '15px',
              }}
              disabled={!canStartRole}
              onClick={() => {
                setOpenStartRoleWorkingDialog(true);
              }}
            >
              {canStartRole ? 'Start Role' : 'In Progress'}
            </Button>
          </Box>
        </Box>
        {/* <br /> */}
        <CandidatePipelineTable
          buttons={null}
          onButtonClick={(value) => {}}
          showHiringPipeline={true}
          onPageChange={(page) => setPage(page)}
          onRowsPerPageChange={(limit) => setLimit(limit)}
          candidates={roleCandidates}
          totalCandidates={roleCandidates?.pagination?.totalCandidates}
          showButtons={false}
          loading={loading}
        />
      </>
      {/* )} */}

      {openStartRoleWorkingDialog && (
        <StartRoleWorkingDialog
          open={openStartRoleWorkingDialog}
          onClose={() => {
            setOpenStartRoleWorkingDialog(false);
          }}
          onSubmit={(payload) => startRole(payload)}
        />
      )}
    </div>
  );
}

export default RoleApplications;
