import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { format } from 'date-fns';
import { enqueueSnackbar } from 'notistack';
import MultiDatePicker from 'src/components/MultiDatePicker';
import { EmailCampaignPieChart } from '../../dashboard/components';

function EmailBasedReporting({ users, selectedUser, emailSentBy, status, formattedDates }) {
  const calculateStatus = (rowData) => {
    

    if (rowData?.is_email_delivered === false && rowData?.is_email_bounced === true) {
      return 'Bounced';
    }
    if (rowData?.is_email_delivered) {
      if (rowData?.is_lead_complained) {
        return 'Complained';
      } else if (rowData?.is_lead_replied) {
        return 'Replied';
      } else if (rowData?.is_email_spammed) {
        return 'Spammed';
      } else if (rowData?.is_email_unsubscribed) {
        return 'Unsubscribed';
      } else if (rowData?.is_email_clicked) {
        return 'Clicked';
      } else if (rowData?.is_email_opened) {
        return 'Opened';
      } else {
        return 'Sent';
      }
    }

    return 'Unknown';
  };

  // const [formattedDates, setFormattedDates] = useState([null, null]);

  const emailColumns = useMemo(
    () => [
      {
        accessorKey: 'email',
        header: 'Email',
        enableClickToCopy: true,
        minWidth: 170,
      },
      {
        accessorKey: 'email_report_person',
        header: 'Name',
        minWidth: 170,
        // check that if the person_name is null or undefined then show not known
        Cell: ({ row }) => {
          return (
            <Box component="span">
              <Typography variant="body2">
                {row?.original?.email_report_person ? <span>{row?.original?.email_report_person?.first_name + ' ' + row?.original?.email_report_person?.last_name}</span> : <span>Not Known</span>}
              </Typography>
            </Box>
          );
        },
      },
      {
        accessorKey: 'status',
        header: 'Status',
        minWidth: 170,
        Cell: ({ row }) => {
          const status = calculateStatus(row?.original);

          // Define color mappings for each status
          const statusStyles = {
            Bounced: { bgColor: '#FFEFEF', textColor: '#FC5751' },
            Sent: { bgColor: '#FEEAE0', textColor: '#F17A45' },
            Complained: { bgColor: '#ECF9F3', textColor: '#3FC28A' },
            Replied: { bgColor: '#0ED962', textColor: 'white' },
            Spammed: { bgColor: '#E9EDF9', textColor: '#2147C5' },
            Unsubscribed: { bgColor: '#FEEAE0', textColor: '#FEBE2D' },
            Clicked: { bgColor: '#EAF8FF', textColor: '#30BCFF' },
            Opened: { bgColor: '#E7FBEF', textColor: '#0ED962' },
            Unknown: { bgColor: '#F17A45', textColor: 'white' },
          };

          const { bgColor, textColor } = statusStyles[status] || statusStyles.Unknown;

          return (
            <Box component="span">
              <Typography
                variant="body2"
                style={{
                  color: textColor,
                  backgroundColor: bgColor,
                  padding: '5px',
                  borderRadius: '5px',
                  width: '100px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                {status}
              </Typography>
            </Box>
          );
        },
      },
      {
        accessorKey: 'email_report_user',
        header: 'Lead Expert',
        minWidth: 170,
        Cell: ({ row }) => {
          return (
            <Box component="span">
              <Typography variant="body2">
                {row?.original?.email_report_user ? <span>{row?.original?.email_report_user?.first_name + ' ' + row?.original?.email_report_user?.last_name}</span> : <span>Not Known</span>}
              </Typography>
            </Box>
          );
        },
      },
      {
        accessorKey: 'sent_by',
        header: 'Sent by',
        minWidth: 170,
        Cell: ({ cell }) => {
          return cell.getValue() ? cell.getValue() : 'Not Known';
        },
      },
      {
        accessorKey: 'updatedAt',
        header: 'Last update',
        minWidth: 200,
        Cell: ({ cell }) => {
          // Convert the date string to the desired format
          const formattedDate = format(new Date(cell.getValue()), 'MMM d, yyyy, hh:mm a');
          return (
            <Box component="span">
              <Typography
                variant="body2"
                style={{
                  color: 'black',
                  backgroundColor: 'transparent',
                  padding: '5px',
                  borderRadius: '5px',
                  width: '150px',
                  textAlign: 'center',
                }}
              >
                {formattedDate}
              </Typography>
            </Box>
          );
        },
      },
    ],
    []
  );
  const [emailBasedReports, setEmailBasedReports] = useState([]);
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [totalCount, setTotalCount] = useState(0);
  const [searchString, setSearchString] = useState('');

  const [awsStats, setAwsStats] = useState();

  const getEmailBasedReports = useCallback(() => {
    try {
      setIsLoading(true);
      Get(
        {
          pageSize: pagination.pageSize,
          pageNumber: pagination.pageIndex + 1,
          searchString: searchString,
          status: status,
          email_sent_by: emailSentBy,
          lead_expert: selectedUser,
          from_date: formattedDates[0],
          to_date: formattedDates[1],
        },
        API_URLS.getEmailBasedReports,
        (resp) => {
          
          let data = resp?.data?.rows;
          // // filter the data where email_record_person is null
          // const notEmailRecordsPerson = data?.filter((row) => row?.email_report_person === null);
          // console.log('haanyehpersons', notEmailRecordsPerson);
          // data = data?.map((row) => {
          //   row.person_name = `${row?.email_report_person?.first_name} ${row?.email_report_person?.last_name}`;
          //   row.user_name = `${row?.email_report_user?.first_name} ${row?.email_report_user?.last_name}`;
          //   return row;
          // });
          setEmailBasedReports(data);
          setTotalCount(resp?.data?.count);
          setIsLoading(false);
          setIsError(false);
        },
        (error) => {
          setIsError(true);
          setIsLoading(false);
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong at server', { variant: 'error' });
      setIsError(true);
      setIsLoading(false);
    }
  }, [
    pagination.pageSize,
    pagination.pageIndex,
    searchString,
    status,
    emailSentBy,
    selectedUser,
    formattedDates,
    enqueueSnackbar,
  ]);

  useEffect(() => {
    getEmailBasedReports();
  }, [getEmailBasedReports]);

  const getStats = useCallback(() => {
    try {
      Get(
        {
          from_date: formattedDates[0],
          to_date: formattedDates[1],
          bd_user: emailSentBy,
          lead_expert: selectedUser,
        },
        API_URLS.getEmailCampaignReporting,
        (resp) => {
          setAwsStats(resp?.data?.stats);
        },
        (error) => {
        }
      );
    } catch (error) {
    }
  }, [formattedDates, emailSentBy, selectedUser]);

  useEffect(() => {
    getStats();
  }, [getStats]);

  const awsEmailsStats = [
    {
      name: 'Sent',
      value: awsStats ? parseInt(awsStats?.total_emails_sent, 10) : 0, // Parse string to number
      color: '#F17A45',
    },
    {
      name: 'Opened',
      value: awsStats ? parseInt(awsStats?.total_opens, 10) : 0,
      color: '#0ED962',
    },
    {
      name: 'Clicked',
      value: awsStats ? parseInt(awsStats?.total_clicks, 10) : 0,
      color: '#30BCFF',
    },
    {
      name: 'Marked as spam',
      value: awsStats ? parseInt(awsStats?.total_spams, 10) : 0,
      color: '#2147C5',
    },
    {
      name: 'Complaints',
      value: awsStats ? parseInt(awsStats?.total_complaints, 10) : 0,
      color: '#92FF44',
    },
    {
      name: 'Unsubscribed',
      value: awsStats ? parseInt(awsStats?.total_unsubscribed, 10) : 0,
      color: '#FEBE2D',
    },
    {
      name: 'Bounce Back',
      value: awsStats ? parseInt(awsStats?.total_bounces, 10) : 0,
      color: '#FC5751',
    },
  ];

  return (
    <>
      <Box display="flex" marginLeft="5px">
        <Typography variant="h4"> Amazon Email Based Reporting</Typography>
      </Box>
      {/* 
      <Grid container spacing={1}>
    
        <Grid item xs={12} sm={6} md={2}>
          <MultiDatePicker
            onChange={(dateRange) => {
              setFormattedDates(dateRange);
            }}
            numberOfMonths={2}
            height="54px"
            width="220px"
          />
        </Grid>
      </Grid> */}
      <br />
      <EmailCampaignPieChart title="Amazon Report" chartData={awsEmailsStats} />
      <br />
      <div style={{ margin: '5px' }}>
        <MaterialReactTable
          columns={emailColumns}
          data={emailBasedReports}
          enableColumnActions={false}
          rowCount={totalCount}
          enableColumnFilters={false}
          enableTopToolbar={false}
          defaultColumn={{
            size: 50,
          }}
          manualPagination
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setSearchString}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          muiTableBodyRowProps={{ hover: false }}
          state={{
            columnFilters,
            isLoading,
            pagination,
            showAlertBanner: isError,
            searchString,
            sorting,
          }}
          muiTableProps={{
            sx: {
              borderRadius: '30px',
            },
          }}
        />
      </div>
    </>
  );
}

export default EmailBasedReporting;
