import { Icon } from '@iconify/react';
import { Close } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

function CandidateSelectionDialog({ open, onClose, onSelectCandidate }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2); // Default rows per page
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCandidateSelect = (candidate) => {
    setSelectedCandidate(candidate);
    onSelectCandidate(candidate); // Return the selected candidate to the parent component
    // onClose(); // Close the dialog
  };

  const data = [
    {
      name: 'Ali Raza',
      stage: 'In Review',
      city: 'London',
      modified_time: 'Dec 30 , 2020 05:20',
      recruiter: 'Robert',
      resourcer: 'Janieee',
      acm: 'Amna',
    },
    {
      name: 'Vincee Nikolett',
      stage: 'In Review',
      city: 'London',
      modified_time: 'Dec 30 , 2020 05:20',
      recruiter: 'Robert',
      resourcer: 'Janieee',
      acm: 'Amna',
    },
    {
      name: 'Varga Boglarka',
      stage: 'In Review',
      city: 'London',
      modified_time: 'Dec 30 , 2020 05:20',
      recruiter: 'Robert',
      resourcer: 'Janieee',
      acm: 'Amna',
    },
  ];

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        hideBackdrop={false}
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{
          sx: {
            ml: 'auto',
            mt: 'auto',
            mb: 'auto',
            mr: 'auto',
            width: '100%',
            maxWidth: '1500px',
            height: 'auto',
          },
        }}
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#E2F2FF',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" className="ml-8">
              Select Candidate
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <br />
          <Box display="flex" justifyContent="flex-end">
            <TextField
              id="standard-basic"
              placeholder="Search"
              variant="standard"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon icon="bi:search" style={{ fontSize: '22px', color: '#ABABAB' }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: '300px',
                '& .MuiInputBase-root': {
                  borderRadius: '20px',
                  '&:hover fieldset': {
                    borderColor: '#dfe1e5',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#dfe1e5',
                  },
                },
              }}
            />
          </Box>
          <br />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
                  <TableCell style={{ textAlign: 'left' }}>Select</TableCell> {/* New Actions column */}
                  <TableCell style={{ textAlign: 'left' }}>Candidate name</TableCell>
                  <TableCell style={{ textAlign: 'left' }}>City</TableCell>
                  <TableCell style={{ textAlign: 'left' }}>Candidate Stage</TableCell>
                  <TableCell style={{ textAlign: 'left' }}>Modified Time</TableCell>
                  <TableCell style={{ textAlign: 'left' }}>Recruiter</TableCell>
                  <TableCell style={{ textAlign: 'left' }}>Resourcer</TableCell>
                  <TableCell style={{ textAlign: 'left' }}>ACM</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Paginate the rows */}
                {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={selectedCandidate === row.name}
                            onChange={() => handleCandidateSelect(row.name)}
                            value={row.name}
                            name="candidate-radio"
                            sx={{
                              color: '#D2DBE8',
                              fontSize: '24px',
                              '&.Mui-checked': {
                                color: '#00C1FE', // Checked color
                              },
                            }}
                          />
                        }
                        label="" // You can add a label if needed, but it will not display as it's a radio button for selection only
                      />
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {/* Avatar with the first character */}
                        <Avatar
                          style={{
                            marginRight: '8px', // Adds space between avatar and name
                            backgroundColor: '#4CD4FF',
                            color: '#fff',
                            width: '30px',
                            height: '30px',
                            fontSize: '16px',
                            textAlign: 'center',
                          }}
                        >
                          {row.name.charAt(0)}
                        </Avatar>

                        {/* Candidate name */}
                        <p
                          style={{
                            color: '#4CD4FF',
                            fontWeight: 'bold',
                            margin: 0,
                          }}
                        >
                          {row.name}
                        </p>
                      </div>
                    </TableCell>
                    <TableCell>
                      <p>{row.city}</p>
                    </TableCell>
                    <TableCell>
                      <p>{row.stage}</p>
                    </TableCell>
                    <TableCell>
                      <p>{row.modified_time}</p>
                    </TableCell>
                    <TableCell>
                      <p>{row.recruiter}</p>
                    </TableCell>
                    <TableCell>
                      <p>{row.resourcer}</p>
                    </TableCell>
                    <TableCell>
                      <p>{row.acm}</p>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={data?.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[2, 5, 10]} // Optional: Different options for rows per page
          />
          <br />

          <Box display="flex" justifyContent="center" marginTop="1rem" alignItems="center">
            <Button
              size="medium"
              sx={{
                backgroundColor: '#0ec4fe',
                fontSize: '16px',
                color: 'white',
                width: '10%',
                marginLeft: '1rem',
                padding: '10px 20px',
                '&:hover': {
                  color: 'white !important',
                  backgroundColor: '#0ec4fe !important',
                },
              }}
              onClick={() => {
                onSelectCandidate(selectedCandidate);
                onClose();
              }}
            >
              Submit
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CandidateSelectionDialog;
