import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function CreateTask(props) {
  const navigate = useNavigate();
  return (
    <div style={{ width: '97%', margin: 'auto' }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <ArrowBack
          fontSize="small"
          onClick={() => {
            navigate(-1);
          }}
          sx={{
            color: '#00C1FE',
            cursor: 'pointer',
          }}
        />
        &nbsp;&nbsp;{' '}
        <Typography
          variant="subtitle2"
          sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </Typography>
      </Box>
      <br />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <p style={{ fontSize: '32px', fontWeight: 'bold', marginTop: '10px', marginLeft: '10px' }}>Create Task</p>
        <Box display="flex">
          <Button
            sx={{
              background: '#CED5E0',
              height: '45px',
              border: '1px solid #CED5E0',
              '&:hover': {
                background: '#CED5E0',
                color: 'black',
              },
              borderRadius: '15px',
              padding: '25px',
              marginLeft: '10px',
            }}
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button
            sx={{
              background: '#00C1FE',
              color: '#fff',
              height: '45px',
              border: '1px solid #00C1FE',
              '&:hover': {
                background: '#00C1FE',
                color: '#fff',
              },
              borderRadius: '15px',
              padding: '25px',
              marginLeft: '10px',
            }}
          >
            Save and New
          </Button>
          <Button
            sx={{
              background: '#00C1FE',
              color: '#fff',
              height: '45px',
              border: '1px solid #00C1FE',
              '&:hover': {
                background: '#00C1FE',
                color: '#fff',
              },
              borderRadius: '15px',
              padding: '25px',
              marginRight: '10px',
              marginLeft: '10px',
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
      <br />
      <Box sx={{ backgroundColor: 'white', padding: '40px', borderRadius: 4, height: 'auto', marginTop: '15px' }}>
        <p style={{ fontSize: '18px' }}>Task Information</p>
        <Grid container spacing={2} sx={{ marginTop: '10px' }}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box display="flex" alignItems="center" marginTop="10px">
              <Grid container alignItems="center">
                <Grid item xs={4} md={2}>
                  <p style={{ fontSize: '18px', color: 'black', textAlign: 'left' }}>Task Owner</p>
                </Grid>
                <Grid item xs={8} md={10}>
                  <TextField
                    id="subject"
                    value=""
                    type="text"
                    size="large"
                    fullWidth
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '20px',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '20px',
                        '& fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&:hover fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#dfe1e5',
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" alignItems="center" marginTop="25px">
              <Grid container alignItems="center">
                <Grid item xs={4} md={2}>
                  <p style={{ fontSize: '18px', color: 'black', textAlign: 'left' }}>Date</p>
                </Grid>
                <Grid item xs={8} md={10}>
                  <TextField
                    id="subject"
                    value=""
                    type="date"
                    size="large"
                    fullWidth
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '20px',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '20px',
                        '& fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&:hover fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#dfe1e5',
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" alignItems="center" marginTop="25px">
              <Grid container alignItems="center">
                <Grid item xs={4} md={2}>
                  <p style={{ fontSize: '18px', color: 'black', textAlign: 'left' }}>Status</p>
                </Grid>
                <Grid item xs={8} md={10}>
                  <TextField
                    id="subject"
                    value=""
                    type="text"
                    size="large"
                    fullWidth
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '20px',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '20px',
                        '& fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&:hover fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#dfe1e5',
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box display="flex" alignItems="center" marginTop="10px">
              <Grid container alignItems="center">
                <Grid item xs={4} md={2}>
                  <p style={{ fontSize: '18px', color: 'black', textAlign: 'left' }}>Subject</p>
                </Grid>
                <Grid item xs={8} md={10}>
                  <TextField
                    id="subject"
                    value=""
                    type="text"
                    size="large"
                    fullWidth
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '20px',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '20px',
                        '& fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&:hover fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#dfe1e5',
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" alignItems="center" marginTop="25px">
              <Grid container alignItems="center">
                <Grid item xs={4} md={2}>
                  <p style={{ fontSize: '18px', color: 'black', textAlign: 'left' }}>Candidate</p>
                </Grid>
                <Grid item xs={8} md={10}>
                  <TextField
                    id="subject"
                    value=""
                    type="text"
                    size="large"
                    fullWidth
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '20px',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '20px',
                        '& fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&:hover fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#dfe1e5',
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" alignItems="center" marginTop="25px">
              <Grid container alignItems="center">
                <Grid item xs={4} md={2}>
                  <p style={{ fontSize: '18px', color: 'black', textAlign: 'left' }}>Priority</p>
                </Grid>
                <Grid item xs={8} md={10}>
                  <TextField
                    id="subject"
                    value=""
                    type="text"
                    size="large"
                    fullWidth
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '20px',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '20px',
                        '& fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&:hover fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#dfe1e5',
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box display="flex" alignItems="center" marginTop="10px">
              <FormControlLabel
                value="end"
                control={<Checkbox sx={{ color: '#B8B8B8', '&.Mui-checked': { color: '#00C1FE' } }} />}
                label="Send Notification Email"
                labelPlacement="start"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box display="flex" alignItems="center" marginTop="10px">
              <Grid container alignItems="center">
                <Grid item xs={4} md={1} lg={1}>
                  <p style={{ fontSize: '18px', color: 'black', textAlign: 'left' }}>Description</p>
                </Grid>
                <Grid item xs={10} md={11} lg={11}>
                  <TextField
                    id="subject"
                    value=""
                    type="text"
                    size="large"
                    fullWidth
                    multiline
                    rows={8}
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '20px',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '20px',
                        '& fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&:hover fieldset': {
                          borderColor: '#dfe1e5',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#dfe1e5',
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default CreateTask;
