import React, { useCallback, useEffect, useState } from 'react';
import { Get, Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import MaterialReactTable from 'material-react-table';
import { Box, Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
function CandidatesList() {
  const userId = parseInt(localStorage.getItem('userId'), 10);
  const [candidates, setCandidates] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  // Fetch candidates from API
  const getCandidates = useCallback(() => {
    Get(
      { user_id: userId },
      API_URLS.getDailyCandidates,
      (response) => setCandidates(response?.data),
      (error) => console.error(error)
    );
  }, [userId]);

  useEffect(() => {
    getCandidates();
  }, [getCandidates]);

  const assignDailyCandidates=()=>{
    Post(
      { user_id: userId },
      API_URLS.assignDailyCandidates,
      (response) => {
        setCandidates(response?.data?.candidates);
      },
      (error) => {
        enqueueSnackbar(
          'Either you are already assigned candidates or there is some server error. Ask admin to resolve',
          { variant: 'error' }
        );
      }
    );
  }

  const downloadCandidates=()=>{
    const ws = XLSX.utils.json_to_sheet(candidates);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Candidates');
    const fileName = 'Candidates.xlsx';
    XLSX.writeFile(wb, fileName);
  }
  // Define columns for MRT
  const columns = [
    {
      accessorKey: 'personal_email', // Corresponds to the data field
      header: 'Email',
      size: 250,
    },
    {
      accessorKey: 'full_name',
      header: 'Full Name',
      size: 200,
    },
    {
      accessorKey: 'current_title',
      header: 'Current Title',
      size: 200,
    },
    {
      accessorKey: 'location',
      header: 'Location',
      size: 150,
    },
  ];

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" gutterBottom>
        Candidates List
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginBottom: 2,
        }}
      >
        <Button onClick={assignDailyCandidates} variant="contained" color="primary">
          Get Candidates
        </Button>
        <Box sx={{ width: 10 }} />
        <Button onClick={downloadCandidates} variant="contained" color="primary">
          Download
        </Button>
      </Box>
      <MaterialReactTable
        columns={columns}
        data={candidates}
        enablePagination
        enableSorting
        enableColumnResizing
        // muiTableProps={{
        //   sx: { minHeight: 400 },
        // }}
        initialState={{ pagination: { pageSize: 50 } }}
      />
    </Box>
  );
}

export default CandidatesList;
