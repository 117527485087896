import React from 'react';
import { TeamSheetSection } from 'src/sections/acm/role-section';

function ReportSheetsPage(props) {
    return (
      <div>
        <TeamSheetSection />
      </div>
    );
}

export default ReportSheetsPage;