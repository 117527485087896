import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Quill's Snow theme CSS
import './quilCustomStyles.css';

export default function QuillEditor({ htmlValue, onTextChange }) {
  const [text, setText] = useState('');

  useEffect(() => {
    setText(htmlValue);
  }, [htmlValue]);

  // Custom toolbar configuration
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // Toggled buttons
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'], // Media and formula options

    [{ header: 1 }, { header: 2 }], // Header options (H1, H2)
    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }], // Ordered, bullet, checklist
    [{ script: 'sub' }, { script: 'super' }], // Superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }], // Indent/outdent
    [{ direction: 'rtl' }], // Text direction (right-to-left)

    [{ size: ['small', false, 'large', 'huge'] }], // Font size options
    [{ header: [1, 2, 3, 4, 5, 6, false] }], // Header options

    [{ color: [] }, { background: [] }], // Text color and background
    [{ font: [] }], // Font family
    [{ align: [] }], // Text alignment

    ['clean'], // Clear formatting
  ];

  const modules = {
    toolbar: toolbarOptions, // Attach custom toolbar
  };

  return (
    <div className="card">
      <ReactQuill
        value={text}
        onChange={(content, delta, source, editor) => {
          setText(content);
          onTextChange(editor.getHTML()); // Get HTML content
        }}
        modules={modules} // Attach modules with toolbar
        theme="snow" // Use the Snow theme
        style={{ height: '500px', overflowY: 'scroll', maxHeight: '500px' }} // Editor styling
      />
    </div>
  );
}
