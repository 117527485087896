import { useState, useEffect } from 'react';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';

const useFetchRoleUsers = (role) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        Get(
          { role: role },
          API_URLS.getRoleBasedUsers,
          (response) => {
            console.log('bdusers', response.data);
            setUsers(response.data?.roleBaseUsers);
          },
          (error) => {
            console.log('error', error);
          }
        );
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, [role]);

  return { users, loading, error };
};

export default useFetchRoleUsers;
