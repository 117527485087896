import { Icon } from '@iconify/react';
import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AddJobBoardDialog } from './components';
import { Get, Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { enqueueSnackbar } from 'notistack';
function JobBoardsSection(props) {
    const [openAddJobBoardDialog, setOpenAddJobBoardDialog] = useState(false);
    const [jobBoards, setJobBoards] = useState([]);

    const handleAddJobBoard = (name) => {
      try {
        Post(
          { name: name },
          API_URLS.addJobBoard,
          (resp) => {
            enqueueSnackbar('Job Board added successfully', { variant: 'success' });
            setOpenAddJobBoardDialog(false);
          },
          (error) => {
            enqueueSnackbar(error?.response?.data?.message || 'Failed to add Job Board', { variant: 'error' });
          }
        );
      } catch (error) {
        enqueueSnackbar('Failed to add Job Board', { variant: 'error' });
      }
    };

    const getJobBoards = ()=>{
        try {
            Get(
                {},
                API_URLS.getAllJobBoards,
                (resp) =>{
                    console.log(resp);
                    setJobBoards(resp?.data);
                },
                (error) =>{
                    console.log(error);
                }
            )
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(()=>{
        getJobBoards();
    },[])
    return (
      <div style={{ width: '95%' }}>
        <Box display="flex" marginLeft="10px">
          <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
            Job Boards
          </Typography>
        </Box>
        <Box display="flex" marginLeft="10px">
          <Button
            variant="outlined"
            startIcon={<Icon icon="material-symbols:add" />}
            sx={{
              fontSize: '18px',
              borderRadius: '10px',
              borderWidth: '2px',
              color: '#0ec4fe',
              height: '50px',
              borderColor: '#0ec4fe',
              marginLeft: '10px',
              marginTop: '20px',
              '&:hover': {
                color: '#0ec4fe !important',
                borderColor: '#0ec4fe !important',
                backgroundColor: 'transparent !important',
                border: '2px solid #0ec4fe !important',
              },
            }}
            size="small"
            onClick={() => setOpenAddJobBoardDialog(true)}
          >
            Add Job Board
          </Button>
        </Box>
        {openAddJobBoardDialog && (
          <AddJobBoardDialog
            open={openAddJobBoardDialog}
            onClose={() => setOpenAddJobBoardDialog(false)}
            onSubmit={(name)=>{
                handleAddJobBoard(name);
            }}
            jobBoards={jobBoards}
          />
        )}
      </div>
    );
}

export default JobBoardsSection;