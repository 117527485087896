import React from 'react';

function RQAPage(props) {
  return (
    <div>
      <h1>This is Resourcing QA Page</h1>
    </div>
  );
}

export default RQAPage;
