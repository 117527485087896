import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Grid, MenuItem, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import QuillEditor from './QuilEditor';
import { useNavigate, useParams } from 'react-router-dom';
import { CVsTable, InfoBox, IssueBox, LITable, QueryBox, SuggestionBox } from '.';
import { Get, Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import ActionConfirmationDialog from 'src/components/ActionConfirmationDialog';
import { enqueueSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import { IsParsable } from 'src/helpers';

function RolesProcessing(props) {
  const navigate = useNavigate();
  const { roleId } = useParams();

  const [info, setInfo] = useState([]);
  const [queries, setQueries] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [issues, setIssues] = useState([]);

  const userId = localStorage.getItem('id');
  const [role, setRole] = useState();
  const roleOptions = [
    {
      id: 'markdone',
      label: 'Mark Done',
    },
    {
      id: 'leavrole',
      label: 'Leave Role',
    },
    {
      id: 'timeout',
      label: 'Time Out',
    },
  ];

  // Initialize state with the first option's value
  const [selectedRole, setSelectedRole] = useState(roleOptions[0]?.id);
  const [openStartRoleConfirmationDialog, setOpenStartRoleConfirmationDialog] = useState(false);
  const handleOptionChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const getRoleDetails = () => {
    try {
      Get(
        { id: roleId },
        API_URLS.getRoleById,
        (response) => {
          setRole(response?.data?.role);
          const newInfo = [];
          const newSuggestions = [];
          const newQueries = [];
          const newIssues = [];
          response?.data?.role?.role_working_history?.info?.map((info) => {
            if (IsParsable(info)) {
              const tempInfo = JSON.parse(info);
              newInfo.push(tempInfo);
            } else {
              const tempInfo = {
                role: 'none',
                info_added: 'No Info added yet',
                full_name: 'none',
              };
              newInfo.push(tempInfo);
            }
          });

          response?.data?.role?.role_working_history?.queries?.map((query) => {
            if (IsParsable(query)) {
              const tempQuery = JSON.parse(query);
              newQueries.push(tempQuery);
            } else {
              const tempQuery = {
                role: 'none',
                query_added: 'No query added yet',
                full_name: 'none',
              };
              newQueries.push(tempQuery);
            }
          });

          response?.data?.role?.role_working_history?.suggestions?.map((suggestion) => {
            if (IsParsable(suggestion)) {
              const tempSuggesion = JSON.parse(suggestion);
              newSuggestions.push(tempSuggesion);
            } else {
              const tempSuggesion = {
                role: 'none',
                suggestionAdded: 'No suggestion added yet',
                fullName: 'none',
              };
              newSuggestions.push(tempSuggesion);
            }
          })

          response?.data?.role?.role_working_history?.issues?.map((issue) => {
            if (IsParsable(issue)) {
              const tempIssue = JSON.parse(issue);
              newIssues.push(tempIssue);
            } else {
              const tempIssue = {
                role: 'none',
                issueAdded: 'No issue added yet',
                fullName: 'none',
              };
              newIssues.push(tempIssue);
            }
          })

          setIssues(newIssues);
          setInfo(newInfo);
          setQueries(newQueries);
          setSuggestions(newSuggestions);
        },
        (error) => {
        }
      );
    } catch (error) {
    }
  };

  useEffect(() => {
    getRoleDetails();
  }, [roleId]);

  const startRole = (status) => {
    try {
      Post(
        { id: roleId, status: status , user_id: userId},
        API_URLS.startRole,
        (response) => {
          setOpenStartRoleConfirmationDialog(false);
          getRoleDetails();
          enqueueSnackbar('Role started successfully', { variant: 'success' });
        },
        (error) => {
        }
      );
    } catch (error) {
    }
  };
  return (
    <div style={{ margin: '1rem' }}>
      <Box display="flex" justifyContent="space-between" alignItems="space-between">
        <Button
          variant="none"
          sx={{
            color: '#00C1FE',
          }}
          onClick={() => {
            navigate('/acm/roles');
          }}
        >
          <ArrowBack fontSize="medium" />
          &nbsp;&nbsp; <Typography variant="h6">Back to Roles</Typography>
        </Button>
      </Box>
      <Box mt={2} />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '125vh',
              borderRadius: '15px',
              //give box sahdow
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.30)',
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              alignContent="center"
              sx={{
                backgroundColor: '#E1F7FC',
                color: '#00C1FE',
                padding: '10px',
              }}
            >
              <Typography variant="h6" sx={{ padding: '10px' }}>
                Role Details
              </Typography>
              {/* {userRole === 'resourcer' && ( */}
              <Select
                value={selectedRole}
                onChange={handleOptionChange}
                sx={{
                  width: '140px',

                  '.MuiSelect-select': {
                    color: '#FFFFFF',
                    padding: '10px 14px',
                    height: '10px',
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#00C1FE', // Default border color
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#00C1FE', // Hover border color
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#00C1FE', // Focus border color (blue in this case)
                  },
                  '.MuiSelect-select.MuiSelect-outlined': {
                    backgroundColor: '#00C1FE',
                    border: '2px solid #00C1FE',
                  },
                  '.MuiSelect-icon': {
                    color: '#FFFFFF',
                  },
                }}
              >
                {roleOptions?.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <CardContent>
              <div
                style={{
                  // make content scrollable
                  overflowY: 'scroll',
                  height: '99vh',
                }}
                dangerouslySetInnerHTML={{ __html: role?.roles_working_role?.doc_file_template }}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4} display="flex" flexDirection="column" justifyContent="space-between">
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '60%',
              borderRadius: '15px',
              //give box sahdow
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.30)',
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              alignContent="center"
              sx={{
                backgroundColor: '#E1F7FC',
                color: '#00C1FE',
                padding: '8px',
              }}
            >
              <Typography variant="h6" sx={{ padding: '10px' }}>
                Query String
              </Typography>
              {/* {userRole === 'resourcer' && ( */}
              <Button
                sx={{
                  background: role?.status === 'Pending' ? '#00C1FE' : 'transparent',
                  color: role?.status === 'Pending' ? '#fff' : '#00C1FE',
                  width: '140px',
                  height: '45px',
                  border: '1px solid #00C1FE',
                  '&:hover': {
                    background: role?.status === 'Pending' ? '#00C1FE' : 'transparent',
                    color: role?.status === 'Pending' ? '#fff' : '#00C1FE',
                  },
                  borderRadius: '5px',
                  padding: '5px',
                  margin: '5px',
                  opacity: role?.status !== 'Pending' ? 0.6 : 1, // Adds a subtle disabled effect
                }}
                onClick={() => {
                  setOpenStartRoleConfirmationDialog(true);
                }}
                disabled={role?.status !== 'Pending'}
              >
                {role?.status === 'Pending' ? 'Start Role' : role?.status}
              </Button>
            </Box>
            <QuillEditor
              htmlValue={''}
              // onTextChange={(html) => handleQueryStringUpdate(html)}
              onTextChange={(html) => {}}
            />
            <Box display="flex" justifyContent="flex-end" marginRight="1rem">
              <Button
                sx={{
                  background: '#00C1FE',
                  color: '#fff',
                  width: '100px',
                  height: '45px',
                  border: '1px solid #00C1FE',
                  '&:hover': {
                    background: '#00C1FE',
                    color: '#fff',
                  },
                  borderRadius: '5px',
                  padding: '5px',
                  marginTop: '15px',
                }}
                onClick={() => {
                  navigate(`/acm/candidates/${roleId}`);
                }}
              >
                <Icon icon="gg:search-loading" style={{ fontSize: '20px' }} /> &nbsp; Search
              </Button>
            </Box>
          </Card>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '40%',
              marginTop: '10px',
              borderRadius: '15px',
              //give box sahdow
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.30)',
            }}
          >
            <IssueBox
              // issueData={issues}
              // onSubmitIssue={handleAddIssue}
              // onUpdateIssue={(index, issue) => handleEditIssueClick(index, issue)}
              issueData={issues}
              onSubmitIssue={() => {}}
              onUpdateIssue={(index, issue) => {}}
            />
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            alignContent="center"
            sx={{
              backgroundColor: '#E1F7FC',
              color: '#00C1FE',
              padding: '12px',
            }}
          >
            <Typography variant="h6" sx={{ padding: '10px' }}>
              Focus Points
            </Typography>
          </Box>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '30%',
              marginTop: '10px',
              borderRadius: '15px',
              //give box sahdow
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.30)',
            }}
          >
            <InfoBox
              // issueData={issues}
              // onSubmitIssue={handleAddIssue}
              // onUpdateIssue={(index, issue) => handleEditIssueClick(index, issue)}
              issueData={[]}
              onSubmitIssue={() => {}}
              onUpdateIssue={(index, issue) => {}}
            />
          </Card>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '30%',
              marginTop: '10px',
              borderRadius: '15px',
              //give box sahdow
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.30)',
            }}
          >
            <QueryBox
              // issueData={issues}
              // onSubmitIssue={handleAddIssue}
              // onUpdateIssue={(index, issue) => handleEditIssueClick(index, issue)}
              issueData={[]}
              onSubmitIssue={() => {}}
              onUpdateIssue={(index, issue) => {}}
            />
          </Card>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '30%',
              marginTop: '10px',
              borderRadius: '15px',
              //give box sahdow
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.30)',
            }}
          >
            <SuggestionBox
              // issueData={issues}
              // onSubmitIssue={handleAddIssue}
              // onUpdateIssue={(index, issue) => handleEditIssueClick(index, issue)}
              issueData={[]}
              onSubmitIssue={() => {}}
              onUpdateIssue={(index, issue) => {}}
            />
          </Card>
        </Grid>
      </Grid>
      <Box mt={2} />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <LITable />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CVsTable />
        </Grid>
      </Grid>
      {/* <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{
                  backgroundColor: '#E1F7FC',
                }}
              >
                <CardHeader
                  title="Role LIs"
                  sx={{
                    color: '#00C1FE',
                    textAlign: 'start',
                  }}
                />
                {isLIProfilesAdding && <CircularProgress thickness={3} />}
                {userRole === 'resourcer' && (
                  <Button
                    variant="contained"
                    sx={{
                      background: '#00C1FE',
                      color: '#fff',
                      border: '1px solid #00C1FE',
                      '&:hover': {
                        background: 'transparent',
                        color: '#00C1FE',
                      },
                      borderRadius: '5px',
                      padding: '5px',
                      margin: '5px',
                      width: '100px',
                    }}
                    onClick={() => {
                      setLinkedinProfileDialogOpen(true);
                    }}
                  >
                    Add LIs
                  </Button>
                )}
              </Box>
              <CardContent>
                <RoleLIProfileTable roleId={roleId} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
              }}
            >
              <Box
                display="flex"
                sx={{
                  color: '#00C1FE',
                  backgroundColor: '#E1F7FC',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <CardHeader
                  title="Role CVs"
                  sx={{
                    textAlign: 'start',
                  }}
                />
                {userRole === 'resourcer' && (
                  <Button
                    variant="contained"
                    sx={{
                      background: '#00C1FE',
                      color: '#fff',
                      border: '1px solid #00C1FE',
                      '&:hover': {
                        background: 'transparent',
                        color: '#00C1FE',
                      },
                      borderRadius: '5px',
                      padding: '5px',
                      margin: '5px',
                      width: '100px',
                    }}
                    onClick={handleCandidateManagement}
                  >
                    Add CVs
                  </Button>
                )}
              </Box>
              <CardContent>
                <RoleCVTable client_number={role?.client_number} roleId={roleId} />
                <br />
  
                <h3>Uploaded CVs for this role</h3>
                {roleCVs.map((cv, index) => {
                  return (
                    <div>
                      <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ marginRight: '10px' }}>{cv?.fileName}</p>
                        <IconButton aria-label="download" onClick={() => downloadFile(cv?.fileName)}>
                          <Download />
                        </IconButton>
                  
                      </div>
                    </div>
                  );
                })}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <ExcelReader
          open={linkedinProfileDialogOpen}
          onClose={() => setLinkedinProfileDialogOpen(false)}
          onSubmit={(profilesData) => handleLIProfilesSubmit(profilesData)}
        />
        {openCVPreviewDialog && (
          <CVPreviewDialog
            open={openCVPreviewDialog}
            onClose={() => setOpenCVPreviewDialog(false)}
            file={fileToPrevieq}
          />
        )} */}

      {openStartRoleConfirmationDialog && (
        <ActionConfirmationDialog
          open={openStartRoleConfirmationDialog}
          onClose={() => setOpenStartRoleConfirmationDialog(false)}
          title={`Are you sure you want to start ${role?.roles_working_role?.title} role?`}
          actionButtonText="Yes"
          onSubmit={() => startRole('In-Progress')}
        />
      )}
    </div>
  );
}

export default RolesProcessing;