import React from 'react';
import { JobBoardsSection } from 'src/sections/resourcing';

function JobBoards(props) {
    return (
      <div>
        <JobBoardsSection />
      </div>
    );
}

export default JobBoards;