import { ArrowBack } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';
import {  ScreeningCandidatesTable } from './components';
import { useNavigate } from 'react-router-dom';

function ScreeningCandidates(props) {
    const navigate = useNavigate();
    const buttons = [
      {
        buttonText: 'Total',
        value: 100,
        totalValue: 1000,
        valueSize: '16px',
        borderTop: '#F688F2',
        buttonFontSize: '17px',
        valueSize: '16px',
      },
      {
        buttonText: 'Email 1',
        value: 25,
        totalValue: 1000,
        borderTop: '#88BCF6',
        valueSize: '16px',
        buttonFontSize: '17px',
      },
      {
        buttonText: 'Email 2',
        value: 25,
        totalValue: 1000,
        valueSize: '16px',
        borderTop: '#A4B0DB',
        buttonFontSize: '17px',
      },
      {
        buttonText: 'WhatsApp',
        value: 25,
        totalValue: 1000,
        borderTop: '#FF9999',
        valueSize: '16px',
        buttonFontSize: '17px',
      },
      {
        buttonText: 'Mobile Text',
        value: 25,
        totalValue: 1000,
        borderTop: '#EBB794',
        valueSize: '16px',
        buttonFontSize: '17px',
        valueSize: '16px',
      },
      {
        buttonText: 'Call 1',
        value: 25,
        totalValue: 1000,
        borderTop: '#67E4BB',
        buttonFontSize: '17px',
        valueSize: '16px',
      },
      {
        buttonText: 'Call 2',
        value: 25,
        totalValue: 1000,
        borderTop: '#E0CC38',
        buttonFontSize: '17px',
        valueSize: '16px',
      },
      {
        buttonText: 'LI Connection',
        value: 25,
        totalValue: 1000,
        borderTop: '#3F8CFF',
        buttonFontSize: '17px',
        valueSize: '16px',
      },
      {
        buttonText: 'LI InMail',
        value: 25,
        totalValue: 1000,
        borderTop: '#3FC28A',
        buttonFontSize: '17px',
        valueSize: '16px',
      },
      {
        buttonText: 'No Response',
        value: 25,
        totalValue: 1000,
        borderTop: '#F58538',
        buttonFontSize: '17px',
        valueSize: '16px',
      },
      {
        buttonText: 'Not Interested',
        value: 25,
        totalValue: 1000,
        borderTop: '#6d80c5',
        buttonFontSize: '17px',
        valueSize: '16px',
      },
      {
        buttonText: 'Interested',
        value: 25,
        totalValue: 1000,
        borderTop: '#00FFFF',
        buttonFontSize: '17px',
        valueSize: '16px',
      },
    ];
  return (
    <div style={{ width: '97%', margin: 'auto' }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <ArrowBack
          fontSize="small"
          onClick={() => {
            navigate(`/recruiter/app`);
          }}
          sx={{
            color: '#00C1FE',
            cursor: 'pointer',
          }}
        />
        &nbsp;&nbsp;{' '}
        <Typography
          variant="subtitle2"
          sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}
          onClick={() => {
            navigate(`/recruiter/app`);
          }}
        >
          Back to Dashboard
        </Typography>
      </Box>
      <br />
      <Box>
        <p style={{ fontSize: '32px', fontWeight: 'bold', marginTop: '10px' }}>Screening Candidates</p>
      </Box>
      <ScreeningCandidatesTable buttons={buttons} onButtonClick={(value) => {}} />
    </div>
  );
}

export default ScreeningCandidates;
