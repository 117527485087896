import { Icon } from '@iconify/react';
import { Close } from '@mui/icons-material';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, timelineItemClasses, TimelineSeparator } from '@mui/lab';
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="left"
      ref={ref}
      {...props}
      timeout={{ enter: 1000, exit: 500 }}
      easing={{ enter: 'cubic-bezier(0.4, 0, 0.2, 1)', exit: 'cubic-bezier(0.4, 0, 0.2, 1)' }}
    />
  );
});
function CandidateFeedbackDialog({ open, onClose }) {
  const recruiters = ['Taniya', 'Adeel', 'Arroba', 'Sidra'];
  const [selectedRecruiter, setSelectedRecruiter] = useState(recruiters[0]);
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        hideBackdrop={false}
        disableBackdropClick
        disableEscapeKeyDown
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            ml: 'auto',
            mt: '0.5rem',
            mr: '0.5rem',
            width: '100%',
            maxWidth: '700px',
            height: '100%',
          },
        }}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#E2F2FF',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" className="ml-8">
              Feedback
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <div style={{ width: '99%', margin: 'auto' }}>
            <Box display="flex" alignItems="flex-start" justifyContent="flex-start">
              <Box display="flex" flexDirection="column" alignItems="center">
                <Avatar
                  style={{
                    marginTop: '20px',
                    backgroundColor: '#4CD4FF',
                    color: '#fff',
                    width: '45px',
                    height: '45px',
                    fontSize: '16px',
                    textAlign: 'center',
                  }}
                >
                  A
                </Avatar>
                <p style={{ marginTop: '10px', fontSize: '16px', marginLeft: '10px' }}>Ahmad Raza</p>
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" marginTop="10px" alignItems="center">
                <Icon icon="ic:baseline-email" style={{ fontSize: '20px', color: '00C1FE' }} />
                <p style={{ fontSize: '15px', marginLeft: '10px' }}> ahmad@gmail.com </p>
              </Box>
              <Box display="flex" marginTop="10px" alignItems="center">
                <Icon icon="material-symbols:call" style={{ fontSize: '20px', color: '00C1FE' }} />
                <p style={{ fontSize: '15px', marginLeft: '10px' }}> +44 5454 5435435 </p>
              </Box>
            </Box>
            <Box sx={{ marginTop: '20px', backgroundColor: '#E3F3FF', height: '520px' }}>
              <ToggleButtonGroup
                value={selectedRecruiter}
                exclusive
                onChange={(e, value) => {
                  if (value !== null) {
                    setSelectedRecruiter(value);
                  }
                }}
                aria-label="company sections"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  padding: 10,
                  marginLeft: '20px',
                }}
              >
                {recruiters?.map((recruiter, index) => (
                  <ToggleButton
                    key={index}
                    value={recruiter}
                    style={{
                      border: 'none',
                      color: selectedRecruiter === recruiter ? '#00C1FE' : '#838383',
                      borderBottom: selectedRecruiter === recruiter ? '3px solid #00C1FE' : 'none',
                      borderRadius: 0,
                      paddingBottom: 2,
                      backgroundColor: 'transparent',
                      fontSize: '16px',
                      fontWeight: 'normal',
                    }}
                  >
                    {recruiter}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
              <hr style={{ width: '100%', margin: 'auto', marginTop: '-10px', color: '#D8D8D8' }} />
              <br />
              <Box display="flex" justifyContent="flex-start" flexDirection='column'>
                <CandidateCommunicationTimeline />
              </Box>
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CandidateFeedbackDialog;

const CandidateCommunicationTimeline = () => {
  return (
    <>
      <Typography variant="subtitle1" sx={{ marginBottom: '10px', marginLeft: '30px' }}>
        Designer
      </Typography>
      <Box display="flex" justifyContent="flex-start">
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              paddingLeft: 1.5,
            },
          }}
        >
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                sx={{
                  backgroundColor: '#3FC28A',
                  borderColor: '#3FC28A',
                  borderRadius: '50%',
                  padding: '7px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center', // Center icon in dot
                }}
              >
                <Icon icon="ic:baseline-email" style={{ fontSize: '20px', color: 'white' }} />
              </TimelineDot>
              <TimelineConnector sx={{ height: '40px' }} />
            </TimelineSeparator>
            <TimelineContent>
              <Box display="flex" justifyContent="space-between">
                <p style={{ fontSize: '10px', backgroundColor: '#FFE5E5', color: 'red', padding: '2px' }}>
                  No response
                </p>
                <p style={{ fontSize: '10px' }}>07/07/2024 7:23 PM</p>
              </Box>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                sx={{
                  backgroundColor: '#3FC28A',
                  borderColor: '#3FC28A',
                  borderRadius: '50%',
                  padding: '7px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center', // Center icon in dot
                }}
              >
                <Icon icon="ic:baseline-email" style={{ fontSize: '20px', color: 'white' }} />
              </TimelineDot>
              <TimelineConnector sx={{ height: '40px' }} />
            </TimelineSeparator>
            <TimelineContent>
              <Box display="flex" justifyContent="space-between">
                <p style={{ fontSize: '10px', backgroundColor: '#D7FFD6', color: 'green', padding: '2px' }}>
                  Interested
                </p>
                <p style={{ fontSize: '10px' }}>07/07/2024 7:23 PM</p>
              </Box>
              <Box
                sx={{
                  backgroundColor: 'white',
                  padding: '10px',
                  height: 'auto',
                  marginTop: '2px',
                }}
              >
                <p style={{ fontSize: '12px' }}>I am interested in this job role.</p>
              </Box>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </Box>

      <br />
    </>
  );
};
