import { Icon } from '@iconify/react';
import { Box, Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import React, { useCallback, useEffect, useState } from 'react';
import { Post } from 'src/actions/API/apiActions';
import { notify } from 'src/components/notify';
// import { Post_GetRolesReport_URL } from 'src/constants/apiURLs';

function RolesReportComponent({ fromDate, toDate, roleStatus }) {
  console.log('errewrewre', roleStatus);
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [rowsToRender, setRowsToRender] = useState([]);
  const columns = [
    {
      accessorKey: 'id',
      header: 'Sr.',
    },
    {
      accessorKey: 'roleService.serviceName',
      header: 'Service',
      minWidth: 170,
    },
    {
      accessorKey: 'clientNumber',
      header: 'Client No',
      minWidth: 170,
    },
    {
      accessorKey: 'title',
      header: 'Role Title',
      minWidth: 170,
    },
    {
      accessorKey: 'roleStatus',
      header: 'Status',
      Cell: ({ cell }) => (
        <Box component="span">
          {cell.row.original.roleStatus === 'done' ? (
            <Typography
              variant="body2"
              style={{
                color: 'white',
                backgroundColor: '#74C111',
                padding: '5px',
                borderRadius: '5px',
                width: '100px',
                textAlign: 'center',
              }}
            >
              Done
            </Typography>
          ) : (
            <Typography
              style={{
                color: 'white',
                backgroundColor: '#E79D58',
                padding: '5px',
                borderRadius: '5px',
                textAlign: 'center',
                width: '100px',
              }}
            >
              Pending
            </Typography>
          )}
        </Box>
      ),
    },
    {
      accessorKey: 'doneByUser',
      header: 'Resourcer',
      Cell: ({ cell }) => (
        <Box component="span">
          <span>
            {!cell?.row?.original.doneByUser ? (
              <Icon icon="mdi:question-mark-circle-outline" style={{ fontSize: '22px' }} />
            ) : (
              cell?.row?.original.doneByUser?.first_name + ' ' + cell?.row?.original.doneByUser?.last_name
            )}
          </span>
        </Box>
      ),
    },
    {
      accessorKey: 'location',
      header: 'Location',
    },
    {
      accessorKey: 'noOfCVsUploaded',
      header: 'CVs',
      Cell: ({ cell }) => (
        <Box component="span">
          <span>{!cell?.row?.original.noOfCVsUploaded ? 0 : cell?.row?.original.noOfCVsUploaded}</span>
        </Box>
      ),
    },
    {
      accessorKey: 'noOfLiPrfilesAdded',
      header: 'LIS',
      Cell: ({ cell }) => (
        <Box component="span">
          <span>{cell?.row?.original.noOfLiPrfilesAdded ? cell?.row?.original.noOfLiPrfilesAdded : 0}</span>
        </Box>
      ),
    },
  ];
  const getRolesReport = useCallback(() => {
    try {
      setLoading(true);
      Post(
        {
          fromDate: fromDate,
          toDate: toDate,
        },
        null, // Post_GetRolesReport_URL,
        (resp) => {
          setRoles(resp);
          //   // filter roles according to status
          //   if (roleStatus === 'done') {
          //   const  temp = resp.filter((role) => role.roleStatus === 'done');
          //     setRowsToRender(temp);
          //   } else if (roleStatus === 'pending') {
          //    const  temp = resp.filter((role) => role.roleStatus === 'pending');
          //     setRowsToRender(temp);
          //   }else{
          //       setRoles(resp);
          //   }
          setLoading(false);
          //   notify('Roles Report fetched successfully', 'success', 1000);
        },
        (error) => {
          setLoading(false);
          notify('Failed to fetch Roles Report', 'error', 1000);
        }
      );
    } catch (error) {
      setLoading(false);
      notify('Something went wrong at server', 'error', 1000);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    getRolesReport();
    if (roleStatus === 'done') {
      const temp = roles.filter((role) => role.roleStatus === 'done');
      setRowsToRender(temp);
    } else if (roleStatus === 'pending') {
      const temp = roles.filter((role) => role.roleStatus === 'pending');
      setRowsToRender(temp);
    } else {
      setRowsToRender(roles);
    }
  }, [getRolesReport, roleStatus]);

  const defaultRows = rowsToRender.length > 0 ? rowsToRender : roles;
  return (
    <div style={{ margin: '2rem' }}>
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 50,
          },
        }}
        columns={columns}
        data={defaultRows}
        defaultColumn={{
          size: 100, //make columns wider by default
        }}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableSorting={false}
        enableTopToolbar={false}
        positionToolbarAlertBanner="bottom"
        manualFiltering
        state={{
          isLoading: loading,
        }}
        actionsColumnIndex={-1}
      />
    </div>
  );
}

export default RolesReportComponent;
