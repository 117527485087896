import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Grid, Popover, Stack, TextField, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Job } from './components/images';
import { Icon } from '@iconify/react';

function JobDetails(props) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [job, setJob] = useState();
  const buttons = [
    {
      buttonText: 'Total',
      value: 100,
      borderTop: '#F688F2',
      buttonFontSize: '22px',
    },
    {
      buttonText: 'Screening',
      value: 25,
      borderTop: '#88BCF6',
      buttonFontSize: '22px',
    },
    {
      buttonText: 'Submissions',
      value: 25,
      borderTop: '#A4B0DB',
      buttonFontSize: '22px',
    },
    {
      buttonText: 'Rejected',
      value: 25,
      borderTop: '#FF9999',
      buttonFontSize: '22px',
    },
    {
      buttonText: 'Interview',
      value: 25,
      borderTop: '#EBB794',
      buttonFontSize: '22px',
    },
    {
      buttonText: 'Offered',
      value: 25,
      borderTop: '#67E4BB',
      buttonFontSize: '22px',
    },
    {
      buttonText: 'Hired',
      value: 25,
      borderTop: '#E0CC38',
      buttonFontSize: '22px',
    },
  ];
  const [isExpanded, setIsExpanded] = useState(true);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div style={{ width: '97%', margin: 'auto' }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <ArrowBack
          fontSize="small"
          onClick={() => {
            navigate(-1);
          }}
          sx={{
            color: '#00C1FE',
            cursor: 'pointer',
          }}
        />
        &nbsp;&nbsp;{' '}
        <Typography
          variant="subtitle2"
          sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}
          onClick={() => {
            navigate(-1);
          }}
        >
          Back to Dashboard
        </Typography>
      </Box>
      <Box
        display="flex"
        sx={{ marginTop: '20px', backgroundColor: '#E2F2FF', borderRadius: 4, height: '120px' }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center" justifyContent="flex-start" sx={{ padding: '20px' }}>
          <p
            style={{
              marginRight: '20px', // Adds space between avatar and name
              backgroundColor: '#00C1FE',
              color: '#fff',
              width: '85px',
              height: '85px',
              fontSize: '40px',
              borderRadius: '20px',
              textAlign: 'center',
              display: 'flex', // Enable flexbox
              alignItems: 'center', // Vertically center the image
              justifyContent: 'center', // Horizontally center the image
            }}
          >
            <img src={Job} alt="job" style={{ maxWidth: '100%', maxHeight: '100%' }} />
          </p>

          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Box display="flex">
              <Typography variant="h5" sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}>
                {job?.name || 'Designer'}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" marginTop="5px">
              <p
                style={{
                  fontSize: '14px',
                  backgroundColor: '#4CD4FF',
                  borderRadius: '5px',
                  padding: '7px',
                  color: 'white',
                }}
              >
                {job?.stage || 'Waiting for approval'}
              </p>
              <Button
                sx={{
                  marginLeft: '10px',
                  padding: '10px',
                  backgroundColor: '#C7E6FF',
                  '&:hover': { backgroundColor: '#C7E6FF' },
                }}
                size="small"
              >
                <Icon icon="charm:refresh" style={{ fontSize: '18px', color: '#00C1FE' }} />
              </Button>
            </Box>
          </Box>
        </Box>
        <Box display="flex" sx={{ padding: '20px', marginRight: '30px' }}>
          {/* <Tooltip title="Edit" placement="bottom">
            <Button style={{ backgroundColor: '#C7E6FF', padding: '10px' }} size="large">
              <Icon icon="lucide:edit" style={{ fontSize: '24px', color: 'white' }} />
            </Button>
          </Tooltip> */}
          <Tooltip title="Associate Candidate" placement="bottom">
            <Button
              style={{ backgroundColor: '#C7E6FF', marginLeft: '10px' }}
              size="large"
              onClick={() => navigate(`/recruiter/jobApplicants/1`)}
            >
              <Icon icon="mdi:person-add-outline" style={{ fontSize: '24px', color: 'white' }} />
            </Button>
          </Tooltip>
          <Tooltip title="Publish Job" placement="bottom">
            <Button style={{ backgroundColor: '#C7E6FF', marginLeft: '10px' }} size="large" onClick={handleOpen}>
              <Icon icon="fluent-mdl2:web-publish" style={{ fontSize: '24px', color: 'white' }} />
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <Box display="flex" marginTop="20px" justifyContent="flex-end" alignItems="center">
        <Box display="flex" marginRight="30px">
          <p style={{ color: '#838383' }}>Last Update : 02:24 PM</p> &nbsp; | &nbsp;
          <p style={{ color: '#00C1FE' }}> Hide empty fields</p>
        </Box>
      </Box>
      <Box sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 4, height: 'auto', marginTop: '15px' }}>
        <p style={{ fontSize: '18px', margin: '4px', marginLeft: '35px', marginBottom: '10px' }}>Hiring Pipeline</p>
        <Box
          sx={{
            width: '95%',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            margin: 'auto',
            backgroundColor: '#F4F9FD',
            marginBottom: '10px',
          }}
        >
          <Stack direction="row">
            {buttons?.map((button) => (
              <Button
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 2,
                  outline: 'none',
                  flexGrow: 1,
                  border: 'none',
                  '&:hover': {
                    backgroundColor: '#F4F9FD',
                    outline: 'none',
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    borderTop: `7px solid ${button?.borderTop}`,
                  },
                  '&:focus, &:active': {
                    outline: 'none',
                    borderTop: `7px solid ${button?.borderTop}`,
                    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                    boxShadow: 'none',
                  },
                  borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                  borderTop: `7px solid ${button?.borderTop}`,
                  borderRadius: 0,
                }}
              >
                <p style={{ fontSize: '20px', fontWeight: 'normal' }}>{button?.value}</p>
                <p style={{ fontSize: `${button?.buttonFontSize}`, color: '#838383', fontWeight: 'normal' }}>
                  {button?.buttonText}
                </p>
              </Button>
            ))}
          </Stack>
        </Box>
      </Box>
      <Box sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 4, height: 'auto', marginTop: '15px' }}>
        <p style={{ fontSize: '18px', margin: '4px' }}>Business Card</p>
        <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item xs={12} sm={6} md={12} lg={12} marginTop={'20px'}>
            <Box display="flex" alignItems="center" marginTop="20px">
              <Grid container alignItems="center">
                {/* Label (p tag) - Right aligned */}
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>Title</p>
                </Grid>
                {/* Text Field - Left aligned */}
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="Designer"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#4CD4FF', // Ensures disabled text is black
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box display="flex" alignItems="center" marginTop="20px">
              <Grid container alignItems="center">
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>Target Date</p>
                </Grid>
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="04/20/2030"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box display="flex" alignItems="center" marginTop="20px">
              <Grid container alignItems="center">
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>City</p>
                </Grid>
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="London"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box display="flex" alignItems="center" marginTop="20px">
              <Grid container alignItems="center">
                <Grid item xs={4} md={3}>
                  <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                    Assigned Recruiters
                  </p>
                </Grid>
                <Grid item xs={8} md={9}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value="Miss xyz"
                    disabled
                    sx={{
                      marginLeft: '20px',
                      width: '50%',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <br />
      </Box>
      <Box sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 4, height: 'auto', marginTop: '15px' }}>
        <Box display="flex" alignItems={'center'}>
          <p style={{ fontSize: '18px', margin: '4px', color: '#4CD4FF' }}>
            {isExpanded ? 'Hide Details' : 'Show Details'}
          </p>
          <Icon
            icon="iconamoon:arrow-down-2-fill"
            style={{ fontSize: '20px', color: '#4CD4FF', marginLeft: '10px', cursor: 'pointer' }}
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          />
        </Box>
        <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
        {isExpanded && (
          <>
            <p style={{ fontSize: '18px', margin: '20px' }}>Job Opening Information</p>

            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6} md={6} lg={6} marginTop={'5px'}>
                <Box display="flex" alignItems="center" marginTop="10px">
                  <Grid container alignItems="center">
                    {/* Label (p tag) - Right aligned */}
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Posting Title
                      </p>
                    </Grid>
                    {/* Text Field - Left aligned */}
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="Designer"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#4CD4FF', // Ensures disabled text is black
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Number of Positions
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="5"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Assigned Recruiter(s)
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="Miss xyz"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Target Date
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="04/20/2030"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Job Opening Status
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="Wating for approval"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>Industry</p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="Accounting"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>Salary</p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="-"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Created By
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="Miss xyz"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} marginTop={'5px'}>
                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    {/* Label (p tag) - Right aligned */}
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Job Opening ID
                      </p>
                    </Grid>
                    {/* Text Field - Left aligned */}
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="uol-2-pf"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black', // Ensures disabled text is black
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Department Name
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value=""
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Hiring Manager
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="Miss xyz"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Date Opened
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="03/20/2002"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>Job Type</p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="Full Time"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Work Experience
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="Fresher"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Required Skills
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="-"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Modified By
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="Miss xyz"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <p style={{ fontSize: '18px', margin: '20px' }}>Address Information</p>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6} md={6} lg={6} marginTop={'5px'}>
                <Box display="flex" alignItems="center" marginTop="10px">
                  <Grid container alignItems="center">
                    {/* Label (p tag) - Right aligned */}
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>City</p>
                    </Grid>
                    {/* Text Field - Left aligned */}
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="London"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black', // Ensures disabled text is black
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>Country</p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="United States"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} marginTop={'5px'}>
                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    {/* Label (p tag) - Right aligned */}
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        State/Province
                      </p>
                    </Grid>
                    {/* Text Field - Left aligned */}
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="Florida"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black', // Ensures disabled text is black
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" alignItems="center" marginTop="20px">
                  <Grid container alignItems="center">
                    <Grid item xs={4} md={3}>
                      <p style={{ fontSize: '18px', margin: '4px', color: '#838383', textAlign: 'right' }}>
                        Zip/Postal Code
                      </p>
                    </Grid>
                    <Grid item xs={8} md={9}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value="32301"
                        disabled
                        sx={{
                          marginLeft: '20px',
                          width: '50%',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <p style={{ fontSize: '18px', margin: '20px' }}>Description</p>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6} md={2} lg={2} marginTop={'5px'}>
                <p style={{ fontSize: '16px', margin: '20px', color: '#8B8B8B' }}>Job Description</p>
              </Grid>
              <Grid item xs={12} sm={6} md={10} lg={10} marginTop={'5px'}>
                <p style={{ fontSize: '16px', margin: '20px', color: '#8B8B8B' }}>
                  We are looking for a motivated and experienced Software Engineer to join our growing team. The ideal
                  candidate will have a passion for technology, an eye for detail, and a dedication to quality software
                  development.
                </p>

                <p style={{ fontSize: '16px', margin: '20px', color: '#8B8B8B', fontWeight: 'bold' }}>Requirements:</p>
                <ul style={{ marginLeft: '40px', color: '#8B8B8B', fontSize: '16px' }}>
                  <li>3+ years of experience in software development, especially in full-stack development.</li>
                  <li>Proficiency in JavaScript, React, and Node.js.</li>
                  <li>Experience with RESTful APIs and cloud services like AWS or Azure.</li>
                  <li>Strong understanding of databases like MySQL, MongoDB, or PostgreSQL.</li>
                  <li>Excellent problem-solving skills and attention to detail.</li>
                  <li>Bachelor's degree in Computer Science or related field.</li>
                </ul>

                <p style={{ fontSize: '16px', margin: '20px', color: '#8B8B8B', fontWeight: 'bold' }}>Benefits:</p>
                <ul style={{ marginLeft: '40px', color: '#8B8B8B', fontSize: '16px' }}>
                  <li>Competitive salary and performance-based bonuses.</li>
                  <li>Flexible work hours and remote working options.</li>
                  <li>Health insurance and wellness programs.</li>
                  <li>Generous paid time off and holidays.</li>
                  <li>Opportunities for professional growth and career advancement.</li>
                </ul>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            p: 0,
            marginTop: '10px',
            width: '21%',
            borderRadius: '25px',
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: '25px',
            },
          },
        }}
      >
        <Box
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          justifyContent="flex-start"
          marginTop="auto"
          marginBottom="auto"
          padding="15px"
        >
          <p style={{ fontSize: '18px', color: 'black', marginTop: '5px', cursor: 'pointer' }}>Publish on Job Boards</p>
          <p style={{ fontSize: '18px', color: 'black', marginTop: '10px', marginBottom: '5px', cursor: 'pointer' }}>
            Publish on Career Site
          </p>
          <p style={{ fontSize: '18px', color: 'black', marginTop: '10px', marginBottom: '5px', cursor: 'pointer' }}>
            Publish on Career Site and Free Job Boards
          </p>
        </Box>
      </Popover>
    </div>
  );
}

export default JobDetails;
