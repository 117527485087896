import { Box, Button, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';

function HelpWithSearchComponent(props) {
    const [allKeywords, setAllKeywords] = useState();
    const [anyKeywords, setAnyKeywords] = useState();
    const [exactPhrase, setExactPhrase] = useState();
    const [postalCode, setPostalCode] = useState();
    const [noneKeywords, setNoneKeywords] = useState();
    const [miles, setMiles] = useState();
    return (
      <div style={{ marginTop: '20px' }}>
        <Box marginLeft="20px">
          <p style={{ fontSize: '20px', fontWeight: 'bold' }}>The candidate CV must contain</p>
          <p style={{ fontSize: '18px', color: '#555555' }}>Separate multiple words or phrases with a comma</p>
        </Box>
        <br />
        <Grid container spacing={2} sx={{ marginLeft: '20px' }} alignItems="center">
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <p style={{ fontSize: '20px' }}>All of these keywords</p>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <TextField
              id="allKeywords"
              name="allKeywords"
              type="text"
              value={allKeywords}
              placeholder="Acountants , Accounting, Banking"
              size="large"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setAllKeywords(e.target.value)}
              sx={{
                backgroundColor: 'white',
                outline: 'none',
                width: '80%',
                border: '1px solid #D9D9D9',
                marginBottom: '3px',
                borderRadius: 0,
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#cccccc',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#cccccc',
                  },
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginLeft: '20px' }} alignItems="center" marginTop="20px">
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <p style={{ fontSize: '20px' }}>Any of these keywords</p>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <TextField
              id="allKeywords"
              name="allKeywords"
              type="text"
              value={anyKeywords}
              placeholder="Sales"
              size="large"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setAnyKeywords(e.target.value)}
              sx={{
                backgroundColor: 'white',
                outline: 'none',
                width: '80%',
                border: '1px solid #D9D9D9',
                marginBottom: '3px',
                borderRadius: 0,
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#cccccc',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#cccccc',
                  },
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginLeft: '20px' }} alignItems="center" marginTop="20px">
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <p style={{ fontSize: '20px' }}>This exact phrase</p>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <TextField
              id="phrase"
              name="phrase"
              type="text"
              value={exactPhrase}
              placeholder="Sales Manager"
              size="large"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setExactPhrase(e.target.value)}
              sx={{
                backgroundColor: 'white',
                outline: 'none',
                width: '80%',
                border: '1px solid #D9D9D9',
                marginBottom: '3px',
                borderRadius: 0,
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#cccccc',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#cccccc',
                  },
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginLeft: '20px' }} alignItems="center" marginTop="20px">
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <p style={{ fontSize: '20px' }}>None of these keywords</p>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <TextField
              id="noneKeywords"
              name="noneKeywords"
              type="text"
              value={noneKeywords}
              placeholder="Risk assessment"
              size="large"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setNoneKeywords(e.target.value)}
              sx={{
                backgroundColor: 'white',
                outline: 'none',
                width: '80%',
                border: '1px solid #D9D9D9',
                marginBottom: '3px',
                borderRadius: 0,
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#cccccc',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#cccccc',
                  },
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginLeft: '20px' }} alignItems="center" marginTop="20px">
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              id="miles"
              name="miles"
              type="text"
              value={miles}
              placeholder="Within 0 miles of"
              size="large"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setMiles(e.target.value)}
              sx={{
                backgroundColor: 'white',
                outline: 'none',
                border: '1px solid #D9D9D9',
                marginBottom: '3px',
                borderRadius: 0,
                marginLeft: '5px',
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#cccccc',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#cccccc',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              id="postalCode"
              name="postalCode"
              type="text"
              value={postalCode}
              placeholder="Town or postcode"
              size="large"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setPostalCode(e.target.value)}
              sx={{
                backgroundColor: 'white',
                outline: 'none',
                border: '1px solid #D9D9D9',
                marginBottom: '3px',
                borderRadius: 0,

                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#cccccc',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#cccccc',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Button
              size="large"
              sx={{
                backgroundColor: '#0ec4fe',
                color: 'white',
                width: '70%',
                borderRadius: 0,
                padding: '10px 30px',
                '&:hover': {
                  color: 'white !important',
                  backgroundColor: '#0ec4fe !important',
                },
              }}
            >
              Run Search
            </Button>
          </Grid>
        </Grid>
      </div>
    );
}

export default HelpWithSearchComponent;