import { CloudUpload, ContentPaste, Done, Visibility } from '@mui/icons-material';
import { Box, Button, Grid, LinearProgress, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { Post } from 'src/actions/API/apiActions';
import { Python_API_URLs } from 'src/constants/pythonAPIURLs';

const DropZone = styled(Box)(({ theme, isDragging }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  backgroundColor: isDragging ? theme.palette.grey[100] : theme.palette.background.paper,
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
}));

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
function UploadFiles({ uploadProcessingIndex, onFileSelected, jd }) {
  const [isDragging, setIsDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const [processingIndex, setProcessingIndex] = useState(null);
  const [processedIndex, setProcessedIndex] = useState([]);
  const [extractedData, setExtractedData] = useState([]);
  const [cvs_score, setCvs_score] = useState(null);

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const newFiles = Array.from(event.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const getCVsScore = (file, index) => {
    try {
      const formData = new FormData();
      files.forEach((file, indx) => {
        formData.append(`cvs`, file); // Use unique keys for each file
      });
      formData.append('job_description_html', jd);
      // setProcessingIndex(index);

      Post(
        formData,
        Python_API_URLs.match_cv,
        (resp) => {
          setCvs_score(resp?.results);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleExtractCVData = (file, index) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      setProcessingIndex(index);

      Post(
        formData,
        Python_API_URLs.extractData,
        (resp) => {
          setProcessedIndex((prevProcessedIndex) => [...prevProcessedIndex, index]);

          const data = {
            ...resp,
            file: file,
            type: file.type,
            name: file.name,
          };

          // Update extracted data state immediately
          setExtractedData((prevExtractedData) => [...prevExtractedData, data]);

          if (index === 0) {
            onFileSelected(data, index); // Pass the data to view the file
          }

          console.log(resp);
          setProcessingIndex(null); // Reset processing index after completion
          // processNextFile(index + 1); // Process next file after current is done
        },
        (error) => {
          console.log(error);
          // setProcessingIndex(null); // Reset processing index on error
          // processNextFile(index + 1); // Continue with the next file even after error
        }
      );
    } catch (error) {
      console.log(error);
      // setProcessingIndex(null); // Handle exceptions
      // processNextFile(index + 1); // Continue with the next file even after exception
    }
  };

  const processNextFile = (nextIndex) => {
    if (nextIndex < files.length) {
      handleExtractCVData(files[nextIndex], nextIndex);
    }
  };

  const handleBulkExtraction = () => {
    const nextIndex = processedIndex.length > 0 ? processedIndex[processedIndex.length - 1] + 1 : 0;
    processNextFile(nextIndex);
  };

  useEffect(() => {
    if (uploadProcessingIndex !== null) {
      files.forEach((file, index) => {
        if (index === uploadProcessingIndex) {
          // remove the file from the list
          const newFiles = files.filter((_, i) => i !== index);
          setFiles(newFiles);
        }
      });
    }
  }, [uploadProcessingIndex]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box
            display="flex"
            sx={{
              backgroundColor: 'white',
              borderRadius: '10px',
              border: '2px dashed #00C1FE',
            }}
            alignItems="center"
            justifyContent="center"
          >
            <DropZone
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              isDragging={isDragging}
            >
              <br />
              <Button
                component="label"
                startIcon={<CloudUpload />}
                fullWidth
                sx={{
                  border: '2px solid #00C1FE',
                  backgroundColor: '#00C1FE',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#00C1FE',
                  },
                  borderRadius: '5px',
                  // marginTop: '10px',
                  width: '180px',
                  // padding: '10px',
                }}
              >
                Upload files
                <VisuallyHiddenInput type="file" multiple onChange={handleFileChange} />
              </Button>
            </DropZone>
          </Box>
        </Grid>
        {files?.length > 0 && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
              display="flex"
              sx={{
                backgroundColor: 'white',
                borderRadius: '10px',
                border: '2px dashed #00C1FE',
                padding: '20px',
                height: '30vh',
                overflowY: 'scroll',
              }}
              justifyContent="space-between"
              flexDirection="column"
            >
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle1">Files uploaded: {files?.length}</Typography>
                <Button
                  sx={{
                    m: 1,
                    borderRadius: '5px',
                    border: '1px solid #00C1FE',
                    color: '#00C1FE',
                    padding: '5px',
                    width: '110px',
                  }}
                  onClick={() => getCVsScore()}
                  size="small"
                >
                  Score All
                </Button>
                <Button
                  sx={{
                    m: 1,
                    borderRadius: '5px',
                    border: '1px solid #00C1FE',
                    color: '#00C1FE',
                    padding: '5px',
                    width: '110px',
                  }}
                  onClick={() => handleBulkExtraction()}
                  size="small"
                >
                  Extract All
                </Button>
              </Box>
              {files?.map((file, index) => (
                <Box key={index} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body1">
                    {index + 1} {(file?.name).trim(0, 10)}
                  </Typography>
                  <Typography variant="body1">
                    {cvs_score?.[index]?.match_score !== 'Error in scoring' && cvs_score?.[index]?.match_score
                      ? cvs_score[index].match_score + '%'
                      : 'Not A Match'}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Button
                      sx={{
                        m: 1,
                        borderRadius: '5px',
                        border: '1px solid #00C1FE',
                        color: '#00C1FE',
                        padding: '5px',
                        width: '110px',
                      }}
                      onClick={() => setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index))}
                      size="small"
                    >
                      Remove file
                    </Button>

                    <Button
                      sx={{
                        m: 1,
                        borderRadius: '5px',
                        border: '1px solid #00C1FE',
                        color: '#00C1FE',
                        padding: '5px',
                        width: '110px',
                      }}
                      size="small"
                      onClick={() => {
                        const data = {
                          file: file,
                          type: file.type,
                          name: file.name,
                        };
                        onFileSelected(data, index);
                      }}
                    >
                      <Tooltip title="View" placement="top">
                        <ContentPaste />
                      </Tooltip>
                    </Button>
                    {processingIndex === index ? (
                      <Box display="flex" alignItems="center" sx={{ width: '110px', ml: 1 }}>
                        <LinearProgress sx={{ width: '100%', marginLeft: '5px', color: '#00C1FE' }} />
                      </Box>
                    ) : (
                      <>
                        {processedIndex.includes(index) ? (
                          <Button
                            sx={{
                              m: 1,
                              borderRadius: '5px',
                              border: '1px solid #00C1FE',
                              color: '#00C1FE',
                              padding: '5px',
                              width: '110px',
                            }}
                            size="small"
                            onClick={() => onFileSelected(extractedData[index])}
                          >
                            <Tooltip title="View" placement="top">
                              View <Visibility />
                            </Tooltip>
                          </Button>
                        ) : (
                          <Button
                            sx={{
                              m: 1,
                              borderRadius: '5px',
                              border: '1px solid #00C1FE',
                              color: '#00C1FE',
                              padding: '5px',
                              width: '110px',
                            }}
                            size="small"
                            onClick={() => handleExtractCVData(file, index)}
                          >
                            Extract data
                          </Button>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default UploadFiles;
