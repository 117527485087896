import { Icon } from '@iconify/react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import PieChart from 'src/components/chart/PieChart';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { enqueueSnackbar } from 'notistack';
import { HighchartsBarChart } from '../../campaigns/components';
import { BouncedEmails, EmailBasedReportingGraphs, OpenedEmailsTables, SpamEmailsTable, UnsubEmailsTable } from '.';

export default function CampaignReport({ campaign, onBack }) {
  
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [sendingAnalytics, setSendingAnalytics] = useState();
  const [emailNotificationAnalytics, setEmailNotificationAnalytics] = useState();
  const [emailDelivered, setEmailDelivered] = useState();
  const [emailBasedEventStats, setEmailBasedEventStats] = useState();

  const getSendingStats = useCallback(() => {
    try {
      Get(
        {
          campaign_id : campaign?.id,
          from_date: fromDate,
          to_date: toDate,
        },
        API_URLS.getCampaignSendingStats,
        (resp) => {
          //   enqueueSnackbar('Sending stats fetched successfully', { variant: 'success' });
          
          setSendingAnalytics(resp?.data);
        },
        (error) => {
          enqueueSnackbar('Error fetching sending stats', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }, [campaign, fromDate, toDate]);

  useEffect(() => {
    getSendingStats();
  }, [getSendingStats]);

  const getEmailNotificationStats = useCallback(() => {
    try {
      Get(
        {
          campaign_id : campaign?.id,
          from_date: fromDate,
          to_date: toDate,
        },
        API_URLS.getAmazonSentStats,
        (resp) => {
          // enqueueSnackbar('Email notification stats fetched successfully', { variant: 'success' });
          setEmailNotificationAnalytics(resp?.data);
        },
        (error) => {
          // enqueueSnackbar('Error fetching email notification stats', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }, [campaign, fromDate, toDate]);

  useEffect(() => {
    getEmailNotificationStats();
  }, [getEmailNotificationStats]);

  // // call the above functions after every minute
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     getSendingStats();
  //     getEmailNotificationStats();
  //   }, 60000);
  //   return () => clearInterval(interval);
  // }, [getSendingStats, getEmailNotificationStats]);

  const barChartData = [
    {
      name: 'Event Metrics',
      data: [
        parseInt(emailNotificationAnalytics?.email_delivered, 10) || 0,
        parseInt(emailNotificationAnalytics?.email_opened, 10) || 0,
        parseInt(emailNotificationAnalytics?.email_clicked, 10) || 0,
        parseInt(emailNotificationAnalytics?.total_bounces, 10) || 0,
        parseInt(emailNotificationAnalytics?.email_spammed, 10) || 0,
        parseInt(emailNotificationAnalytics?.emails_unopened, 10) || 0,
        parseInt(emailNotificationAnalytics?.email_complaints, 10) || 0,
      ],
      colors: ['#999999', '#89C885', '#5AA2CB', '#4d4d4d', '#CE5C56', '#FFD700', '#FFA500'],
      // colorByPoint: true, // Allows individual bars to have different colors
    },
  ];

  const calculatePercentages = (analytics) => {
    if (!analytics) return {};

    const totalDeliveries = parseInt(analytics.email_delivered, 10) || 0;
    const totalOpens = parseInt(analytics.email_opened, 10) || 0;
    const totalClicks = parseInt(analytics.email_clicked, 10) || 0;
    const totalBounces = parseInt(analytics.total_bounces, 10) || 0;
    const totalSpams = parseInt(analytics.email_spammed, 10) || 0;
    const totalUnopened = parseInt(analytics.emails_unopened, 10) || 0;
    const totalComplaints = parseInt(analytics.email_complaints, 10) || 0;

    // Avoid division by zero
    const safePercentage = (value) => {
      return totalDeliveries === 0 ? '0.00' : ((value / totalDeliveries) * 100).toFixed(2);
    };

    return {
      openRate: safePercentage(totalOpens),
      clickRate: safePercentage(totalClicks),
      bounceRate: safePercentage(totalBounces),
      spamRate: safePercentage(totalSpams),
      unopenedRate: safePercentage(totalUnopened),
      complaintRate: safePercentage(totalComplaints),
    };
  };

  const percentages = calculatePercentages(emailNotificationAnalytics);

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <Icon
          icon="ic:round-arrow-back"
          style={{ fontSize: '30px', color: '#00C1FE', cursor: 'pointer' }}
          onClick={() => onBack()}
        />
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <div>
            <label
              htmlFor="fromDate"
              style={{
                display: 'block',
                fontSize: '18px',
                marginBottom: '8px',
                color: '#0ec4fe',
                marginLeft: '10px',
              }}
            >
              From Date
            </label>
            <TextField
              id="fromDate"
              type="date"
              fullWidth
              value={fromDate}
              sx={{
                fontSize: '18px',
                borderRadius: '10px',
                color: '#0ec4fe',
                border: '3px solid #0ec4fe',
                marginLeft: '10px',
                '&:hover': {
                  color: '#0ec4fe',
                  boxShadow: 'none',
                  backgroundColor: 'transparent',
                  border: '3px solid #0ec4fe !important',
                },
              }}
              size="medium"
              onChange={(e) => {
                setFromDate(new Date(e.target.value).toISOString().split('T')[0]);
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div>
            <label
              htmlFor="toDate"
              style={{
                display: 'block',
                fontSize: '18px',
                marginBottom: '8px',
                color: '#0ec4fe',
                marginLeft: '10px',
              }}
            >
              To Date
            </label>
            <TextField
              id="toDate"
              type="date"
              value={toDate}
              fullWidth
              sx={{
                fontSize: '18px',
                borderRadius: '10px',
                color: '#0ec4fe',
                marginLeft: '10px',
                border: '3px solid #0ec4fe',
                '&:hover': {
                  color: '#0ec4fe',
                  boxShadow: 'none',
                  backgroundColor: 'transparent',
                  border: '3px solid #0ec4fe !important',
                },
              }}
              size="medium"
              onChange={(e) => setToDate(new Date(e.target.value).toISOString().split('T')[0])}
            />
          </div>
        </Grid>
      </Grid>
{/* 
      <br />
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          sx={{
            borderRadius: '10px',
            backgroundColor: '#F6F9FC',
            height: '60px',
            textAlign: 'center',
            fontSize: '18px',
            fontWeight: 'bold',
            marginTop: '15px',
          }}
        >
          <Typography variant="h5">Job Posts Based Reporting</Typography>
        </Grid>
      </Grid> */}
      <br />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Box sx={{ marginLeft: '10px' }}>
            {sendingAnalytics ? (
              <PieChart
                title={`${campaign?.name} Sending Anayltics`}
                chartData={[
                  { label: 'Emails sent', value: sendingAnalytics?.email_delivered || 0.5 }, // Dummy value if zero
                  { label: 'Emails not sent', value: sendingAnalytics?.email_bounced || 0.5 }, // Dummy value if zero
                ]}
                chartColors={
                  sendingAnalytics?.email_delivered === 0 && sendingAnalytics?.email_bounced === 0
                    ? ['#D3D3D3', '#D3D3D3'] // Grey color if both values are zero
                    : ['#579FC8', '#CE5C56'] // Original colors otherwise
                }
              />
            ) : (
              <PieChart
                title={`${campaign?.name} Sneding Anayltics`}
                chartData={[
                  { label: 'Emails sent', value: 0 }, // Dummy value
                  { label: 'Emails not sent', value: 0 }, // Dummy value
                ]}
                chartColors={['#D3D3D3', '#D3D3D3']} // Grey color if sendingAnalytics is null
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <HighchartsBarChart
            title="Email Sending Metrics"
            subheader="Event Metrics by Type"
            chartLabels={['Recipents', 'Opens', 'Clicks', 'Bounces', 'Spams', 'Un-Opened', 'Complaints']}
            chartData={barChartData}
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={6} md={6}>
          <Box
            sx={{
              marginLeft: '10px',
              backgroundColor: '#F6F9FC',
              padding: '16px',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              height: '200px',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <Typography variant="body1">
              <span
                style={{
                  backgroundColor: '#89C885',
                  color: 'white',
                  padding: '8px',
                  borderRadius: '10px',
                  fontWeight: 'bold',
                  fontSize: '20px',
                  display: 'inline-block', // Ensure the width is respected
                  width: '100px',
                  textAlign: 'center', // Center the text inside the span
                }}
              >
                {percentages?.openRate || '0.00'}%
              </span>
              &nbsp; &nbsp; <span style={{ fontSize: '20px' }}>Opened</span>
              &nbsp; &nbsp;
              <span
                style={{
                  backgroundColor: '#FFFFFF',
                  color: '#9fadc6',
                  border: '1px solid #D4DAE6',
                  borderRadius: '5px',
                  padding: '3px',
                  fontSize: '12px',
                  display: 'inline-block', // Ensure the width is respected
                  width: '120px',
                  textAlign: 'center', // Center the text inside the span
                }}
              >
                {' '}
                {emailNotificationAnalytics?.email_opened ? emailNotificationAnalytics?.email_opened : 0} Unique opens{' '}
              </span>
            </Typography>
            <Typography variant="body1">
              <span
                style={{
                  backgroundColor: '#F5CA0B',
                  color: 'white',
                  padding: '8px',
                  borderRadius: '10px',
                  fontWeight: 'bold',
                  fontSize: '20px',
                  display: 'inline-block', // Ensure the width is respected
                  width: '100px',
                  textAlign: 'center', // Center the text inside the span
                }}
              >
                {emailNotificationAnalytics?.emails_unopened || '0'}
              </span>
              &nbsp; &nbsp; <span style={{ fontSize: '20px' }}>Not Opened</span>
            </Typography>
            <Typography variant="body1">
              <span
                style={{
                  backgroundColor: '#39A0CC',
                  color: 'white',
                  padding: '8px',
                  borderRadius: '10px',
                  fontWeight: 'bold',
                  fontSize: '20px',
                  display: 'inline-block', // Ensure the width is respected
                  width: '100px',
                  textAlign: 'center', // Center the text inside the span
                }}
              >
                {' '}
                {percentages?.clickRate || '0.00'}%{' '}
              </span>
              &nbsp; &nbsp; <span style={{ fontSize: '20px' }}>Clicked a link</span>
              &nbsp; &nbsp;
              <span
                style={{
                  backgroundColor: '#FFFFFF',
                  color: '#9fadc6',
                  border: '1px solid #D4DAE6',
                  borderRadius: '5px',
                  padding: '3px',
                  fontSize: '12px',
                  display: 'inline-block', // Ensure the width is respected
                  width: '120px',
                  textAlign: 'center', // Center the text inside the span
                }}
              >
                {' '}
                {emailNotificationAnalytics?.email_clicked ? emailNotificationAnalytics?.email_clicked : 0} Unique clicks{' '}
              </span>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box
            sx={{
              marginLeft: '10px',
              backgroundColor: '#F6F9FC',
              padding: '16px',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              height: '200px',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <Typography variant="body1">
              <span
                style={{
                  backgroundColor: '#4D4D4D',
                  color: 'white',
                  padding: '8px',
                  borderRadius: '10px',
                  fontWeight: 'bold',
                  fontSize: '20px',
                  display: 'inline-block', // Ensure the width is respected
                  width: '100px',
                  textAlign: 'center', // Center the text inside the span
                }}
              >
                {' '}
                {percentages?.bounceRate || '0.00'}%
              </span>{' '}
              &nbsp; &nbsp; <span style={{ fontSize: '20px' }}>Bounced</span>
              &nbsp; &nbsp;
              <span
                style={{
                  backgroundColor: '#FFFFFF',
                  color: '#9fadc6',
                  border: '1px solid #D4DAE6',
                  borderRadius: '5px',
                  padding: '3px',
                  fontSize: '12px',
                  display: 'inline-block', // Ensure the width is respected
                  width: '120px',
                  textAlign: 'center', // Center the text inside the span
                }}
              >
                {' '}
                {emailNotificationAnalytics?.email_bounced ? emailNotificationAnalytics?.email_bounced : 0} Bounced{' '}
              </span>
            </Typography>
            <Typography variant="body1">
              <span
                style={{
                  backgroundColor: '#CE5C56',
                  color: 'white',
                  padding: '8px',
                  borderRadius: '10px',
                  fontWeight: 'bold',
                  fontSize: '20px',
                  display: 'inline-block', // Ensure the width is respected
                  width: '100px',
                  textAlign: 'center', // Center the text inside the span
                }}
              >
                {percentages?.spamRate || '0.00'}%
              </span>
              &nbsp; &nbsp; <span style={{ fontSize: '20px' }}>Marked as Spam</span>
              &nbsp; &nbsp;
              <span
                style={{
                  backgroundColor: '#FFFFFF',
                  color: '#9fadc6',
                  border: '1px solid #D4DAE6',
                  borderRadius: '5px',
                  padding: '3px',
                  fontSize: '12px',
                  display: 'inline-block', // Ensure the width is respected
                  width: '120px',
                  textAlign: 'center', // Center the text inside the span
                }}
              >
                {' '}
                {emailNotificationAnalytics?.email_spammed ? emailNotificationAnalytics?.email_spammed : 0} marked as spam{' '}
              </span>
            </Typography>
            <Typography variant="body1">
              <span
                style={{
                  backgroundColor: '#FFA500',
                  color: 'white',
                  padding: '8px',
                  borderRadius: '10px',
                  fontWeight: 'bold',
                  fontSize: '20px',
                  display: 'inline-block', // Ensure the width is respected
                  width: '100px',
                  textAlign: 'center', // Center the text inside the span
                }}
              >
                {percentages?.complaintRate || '0.00'}%
              </span>
              &nbsp; &nbsp; <span style={{ fontSize: '20px' }}>Complaints</span>
              &nbsp; &nbsp;
              <span
                style={{
                  backgroundColor: '#FFFFFF',
                  color: '#9fadc6',
                  border: '1px solid #D4DAE6',
                  borderRadius: '5px',
                  padding: '3px',
                  fontSize: '12px',
                  display: 'inline-block', // Ensure the width is respected
                  width: '120px',
                  textAlign: 'center', // Center the text inside the span
                }}
              >
                {' '}
                {emailNotificationAnalytics?.email_complaints ? emailNotificationAnalytics?.email_complaints : 0}{' '}
                complaints{' '}
              </span>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <br />
      {/* <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          sx={{
            borderRadius: '10px',
            backgroundColor: '#F6F9FC',
            height: '60px',
            textAlign: 'center',
            fontSize: '18px',
            fontWeight: 'bold',
            marginTop: '15px',
          }}
        >
          <Typography variant="h5">Email Based Reporting</Typography>
        </Grid>
      </Grid> */}
      <br />
      {/* <EmailBasedReportingGraphs emailDelivered={emailDelivered} campaignBasedData={campaignBasedData} /> */}
      <br />
      <BouncedEmails campaign={campaign} fromDate={fromDate} toDate={toDate} />
      <br />
      <OpenedEmailsTables campaign={campaign} fromDate={fromDate} toDate={toDate} />
      <br />
      <SpamEmailsTable campaign={campaign} fromDate={fromDate} toDate={toDate} />
      <br />
      <UnsubEmailsTable campaign={campaign} fromDate={fromDate} toDate={toDate} />
    </>
  );
}
